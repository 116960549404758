import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";

import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationsList,
  getIsLoading,
} from "../../../../app/modules/Organizations/_redux/organizationsSelectors";
import { actions as Organizations } from "../../../../app/modules/Organizations/_redux/organizationsRedux";

import { IconButton } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { OrganizationDetailDialog } from "../../modals/editorganization-dialog/OrganizationDetailDialog";
import RingLoader from "react-spinners/RingLoader";
import "./common.css";
import HelpIcon from "../../Help/HelpIcon";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function OrganizationTableWidget1({ className }) {
  const dispatch = useDispatch();
  const organizations = useSelector(getOrganizationsList);
  const isLoading = useSelector(getIsLoading);

  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  /**
   * To Fetch Users
   */
  useEffect(() => {
    dispatch(Organizations.getOrganizationsList());
  }, []);

  /**
   * To Set Add Update Modal Data
   */
  const toggleModalFn = (show, data) => {
    setModalShow(show);
    setModalData(data);
  };

  return (
    <div className={`card card-custom ${className}`}>
      {/* begin::Header */}
      {isLoading && (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      )}
      <div className="card-header border-0 py-8 custom-header align-items-center">
        <h3 className="card-title flex-column m-0">
          <span className="card-label font-weight-bolder text-dark">
            Organization
          </span>
        </h3>
      </div>
      <div className="card-toolbar">
        {/* <EditOrganizationDialog
          show={modalShow}
          toggleModal={(value) => setModalShow(value)}
          data={modalData}
        /> */}
        <OrganizationDetailDialog
          show={modalShow}
          toggleModal={(value) => setModalShow(value)}
          data={modalData}
          orgId={modalData?._id}
        />
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-0">
        {/* begin::Table */}
        <div className="table-responsive">
          <table
            className="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr className="text-left">
                <th
                  className="pl-0"
                  style={{
                    minWidth: "200px",
                  }}
                >
                  Organization
                </th>
                {/* <th
                  className="pl-0"
                  style={{
                    minWidth: "200px",
                  }}
                >
                  Company logo
                </th> */}
                <th
                  style={{
                    minWidth: "100px",
                  }}
                >
                  Revenue($)
                </th>
                {/* <th
                  style={{
                    minWidth: "100px",
                  }}
                >
                  scope
                </th> */}
                <th
                  style={{
                    minWidth: "100px",
                  }}
                >
                  E-Commerce Revenue
                </th>
                <th
                  style={{
                    minWidth: "100px",
                  }}
                >
                  Traditional Retail Revenue
                </th>
                <th
                  style={{
                    minWidth: "100px",
                  }}
                >
                  E-Commerce Unit
                </th>
                <th
                  style={{
                    minWidth: "100px",
                  }}
                >
                  Traditional Retail Unit
                </th>
                {/* <th
                  style={{
                    minWidth: "100px",
                  }}
                >
                  city
                </th> */}
                {/* <th
                  style={{
                    minWidth: "100px",
                  }}
                >
                  state
                </th> */}
                {/* <th
                  style={{
                    minWidth: "100px",
                  }}
                >
                  sold
                </th> */}
                {/* <th
                  style={{
                    minWidth: "100px",
                  }}
                >
                  Channel
                </th> */}
                <th
                  className="pr-0 text-right"
                  style={{
                    minWidth: "100px",
                    position: "sticky",
                    right: 0,
                    backgroundColor: "white",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {organizations.length > 0 ? (
                organizations.map((o) => (
                  <tr key={o._id}>
                    <td className="pl-0">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span
                          style={{ overflow: "hidden" }}
                          className="symbol-label"
                        >
                          <span className="">
                            <img
                              src={o.iconLogo}
                              style={{
                                maxHeight: 50,
                                width: 50,
                              }}
                            />
                          </span>
                        </span>
                      </div>
                      <Link
                        to="#"
                        className="text-dark-75 font-weight-bolder text-capitalize text-hover-primary mb-1 font-size-lg"
                      >
                        {o.companyName}
                      </Link>
                    </td>
                    {/* <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        <img src={o.iconLogo} style={{ height: 50 }} />
                      </span>
                    </td> */}
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {formatter.format(o.overAllRevenue)}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {`${o.eCommerceRevenuePercentage}%`}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {`${o.traditionalRetailRevenuePercentage}%`}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {o.eCommerceUnit}
                      </span>
                    </td>
                    {/* <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {o.geographicScope}
                      </span>
                    </td> */}
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {o.traditionalRetailUnit}
                      </span>
                    </td>
                    {/* <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {o.city}
                      </span>
                    </td> */}
                    {/* <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {o.state.map((st) => st.name).join(",")}
                      </span>
                    </td> */}
                    {/* <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {o.productSold}
                      </span>
                    </td> */}
                    {/* <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {o.salesChannelUtilized}
                      </span>
                    </td> */}
                    <td
                      style={{
                        position: "sticky",
                        right: 0,
                        backgroundColor: "white",
                      }}
                      className="pr-0 text-right"
                    >
                      {/* <a
                      href=""
                        onClick={() => {
                          let data = pick(o, [
                            "companyName",
                            "geographicScope",
                            "overAllRevenue",
                            "city",
                            "state",
                            "productSold",
                            "salesChannelUtilized",
                          ]);
                          const { _id: id } = o;
                          toggleModalFn(true, { ...data, id });
                        }}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      > */}
                      <Link to={`/organization/edit/${o._id}`}>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          ></SVG>
                        </span>
                      </Link>
                      <IconButton
                        onClick={toggleModalFn.bind(this, true, o)}
                        aria-label="detail"
                      >
                        <Visibility style={{ color: "#3699FF" }} />
                      </IconButton>
                      {/* <span
                        style={{ margin: "0 5px" }}
                        className="svg-icon svg-icon-md svg-icon-primary"
                      >
                        <Visibility color="primary" />
                      </span> */}
                      {/* </a> */}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="pl-0 text-center" colSpan={8}>
                    <span className="svg-icon d-block p-3 nodata__icon">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/ico_nodata.svg")}
                      ></SVG>
                    </span>
                    <h3 className="text-dark font-weight-bolder d-block mb-0">
                      No Record Found
                    </h3>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* end::Table */}
      </div>
      {/* end::Body */}

      <HelpIcon pageName="OrganizationListSection" />
    </div>
  );
}
