import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";


const updateOrganization = (organization) => {
  const {
    _id,
    createdBy,
    updatedBy,
    updatedAt,
    createdAt,
    ...data
  } = organization;
  return Services({
    url: `/admin/organization/${_id}`,
    method: "PUT",
    data: data,
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const getOrganizations = () => {
  return Services({
    url: `/admin/organization`,
    method: "GET",
  });
  
};

export const _updateOrganization = (organization) => {
  const promise = new Promise(async (res) => {
    let response = await updateOrganization(organization);
    res(response);
  });

  return promise;
};


