import React, { useState, useRef } from "react";

import "./ZoomSlider.css";

const ZoomSlider = ({ value, valueToScale }) => {
  const inputRef = useRef(null);

  function handleZoomClick(out) {
    if (inputRef.current === null) return;
    const range = inputRef.current;
    if (out) range.stepDown();
    else range.stepUp();
    valueToScale(parseFloat(range.value));
  }

  function handleZoomChanged() {
    if (inputRef.current === null) return;
    const range = inputRef.current;
    valueToScale(parseFloat(range.value));
  }

  return (
    <div className="zoomSlider">
      <button className="zoomButton" onClick={() => handleZoomClick(false)}>
        +
      </button>
      <div className="zoomRangeContainer">
        <input
          ref={inputRef}
          className="zoomRangeInput"
          onChange={handleZoomChanged}
          value={value}
          type="range"
          min="-50"
          max="100"
        />
      </div>
      <button className="zoomButton" onClick={() => handleZoomClick(true)}>
        -
      </button>
    </div>
  );
};

export default ZoomSlider;
