import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_helpers";
import { GetApp } from "@material-ui/icons";
import { actions as HELP_SERVICE_ACTIONS } from "../../../../app/modules/HelpSection/_redux/HelpSectionRedux";
import { HelpEditTemplate } from "../../modals/HelpEditTemplate-dialog/HelpEditTemplate";
import RingLoader from "react-spinners/RingLoader";
import "./common.css";

export function HelpTemplateTableWidget({ className }) {
  const dispatch = useDispatch();
  const isLoading = false;
  const pageList = useSelector((store) => store.helpSection.pageList);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    dispatch(HELP_SERVICE_ACTIONS.getPagelist());
  }, []);

  /**
   * To Set Add Update Modal Data
   */
  const toggleModalFn = (show, data) => {
    setModalShow(show);
    setModalData(data);
  };

  const handleHide = () => {
    setModalShow(false);
  };
  const print = (fileUrl) => {
    let file_path = fileUrl;
    let a = document.createElement("a");
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
    a.target = "_blank";
    a.click();
  };
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        {isLoading && (
          <div className="loader_wrapper">
            <RingLoader color={"darkcyan"} loading={true} size={40} />
          </div>
        )}
        <div className="card-header border-0 py-5 custom-header align-items-center">
          <h3 className="card-title flex-column m-0">
            <span className="card-label font-weight-bolder text-dark">
              Help List
            </span>
          </h3>
          <div className="card-toolbar">
            <HelpEditTemplate
              show={modalShow}
              isUpdate={true}
              modalData={modalData}
              onHide={handleHide}
            />
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pl-0" style={{ minWidth: "400px" }}>
                    Screen Name
                  </th>
                  <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                    Uploaded File
                  </th>
                  <th className="pr-0 text-right" style={{ minWidth: "80px" }}>
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {(pageList || []).map((page, index) => (
                  <tr key={`procedureDetail_${index}`}>
                    <td className="pl-0 py-4">
                      <div className="d-flex align-items-center">
                        <div>{page?.label}</div>
                      </div>
                    </td>
                    <td className="pr-0 text-right">
                      {page?.fileUrl != "" && (
                        <IconButton
                          className="btn btn-icon btn-light btn-hover-primary btn-sm"
                          onClick={print.bind(this, page?.fileUrl)}
                          aria-label="detail"
                          style={{
                            marginRight: 10,
                            height: 34,
                            width: 34,
                            borderRadius: 5,
                            color: "#0072B8",
                          }}
                        >
                          <GetApp className="download" />
                        </IconButton>
                      )}
                    </td>
                    <td className="pl-0 py-4 text-right">
                      <a
                        onClick={() => {
                          let { _id: id, createdAt, updatedAt, ...rest } = page;
                          toggleModalFn(true, { ...rest, id });
                        }}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>
                    </td>
                  </tr>
                ))}
                {!(pageList || []).length && !isLoading && (
                  <tr>
                    <td className="pl-0 text-center" colSpan={8}>
                      <span className="svg-icon d-block p-3 nodata__icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/ico_nodata.svg")}
                        ></SVG>
                      </span>
                      <h3 className="text-dark font-weight-bolder d-block mb-0">
                        No Record Found
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
