import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Link, useHistory,useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import RingLoader from "react-spinners/RingLoader";
import { actions } from "../../../../app/modules/Question/_redux/questionsRedux";
import {
  getQuestionLoader,
  getSubtopicDetails,
} from "../../../../app/modules/Question/_redux/questionsSelectors";
import { toAbsoluteUrl } from "../../../_helpers";
import { AddTopicQuestionDialog } from "../../modals/addtopicquestion-dialog/AddTopicQuestionDialog";

import "./common.css";
import { DeleteButtonWithConfirm } from "./DeleteButtonWithConfirm";


export function TopicQuestionTableWidget1({ className }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const subtopicDetails = useSelector(getSubtopicDetails);
  const isLoading = useSelector(getQuestionLoader);
  const history = useHistory();

  const [questionId, setQuestionId] = useState("");
  const [name, setName] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    dispatch(actions.getSubtopicDetails(id));
  }, []);

  const handleDelete = (questionIdDelete) => {
    dispatch(actions.deleteQuestion(questionIdDelete, id));
  };

  const handleHide = () => {
    setQuestionId("");
    setName("");
    setIsUpdate(false);
    setModalShow(false);
  };

  const handleUpdate = (questionIdUpdate, questionNameUpdate) => {
    setQuestionId(questionIdUpdate);
    setName(questionNameUpdate);
    setIsUpdate(true);
    setModalShow(true);
  };

  return (
    <div className={`card card-custom ${className}`}>
      {/* begin::Header */}
      {isLoading && (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      )}
      <div className="card-header border-0 py-5 custom-header align-items-center">
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={history.goBack}
            aria-label="detail"
            style={{
              marginRight: 10,
              height: 34,
              width: 34,
              borderRadius: 5,
              color: "#3699FF",
            }}
          >
            <ArrowBackIos className="back" />
          </IconButton>
          <h3 className="card-title flex-column m-0">
            <span className="card-label font-weight-bolder text-dark">
              {subtopicDetails?.name}
            </span>
          </h3>
        </div>
        <div className="card-toolbar">
          <a
            onClick={() => setModalShow(true)}
            className="btn btn-success font-weight-bolder font-size-sm"
          >
            <span className="svg-icon svg-icon-md svg-icon-white">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
                className="h-50 align-self-center"
              ></SVG>
            </span>
            Add New Question
          </a>
          <AddTopicQuestionDialog
            parentSubtopic={id}
            show={modalShow}
            id={questionId}
            name={name}
            isUpdate={isUpdate}
            onHide={handleHide}
          />
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-0">
        {/* begin::Table */}
        <div className="table-responsive">
          <table
            className="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr className="text-left">
                <th className="pl-0" style={{ minWidth: "400px" }}>
                  name
                </th>
                <th style={{ minWidth: "300px" }}>modified name</th>
                <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                  action
                </th>
              </tr>
            </thead>
            <tbody>
              {subtopicDetails.questions.map((question) => (
                <tr key={`question_${question._id}`}>
                  <td className="pl-0 py-4">
                    <div className="d-flex align-items-center">
                      {/* <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="symbol-label">
                          <span className="svg-icon h-75 align-self-end">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/User.svg"
                              )}
                            />
                          </span>
                        </span>
                      </div> */}
                      <div>
                        <a className="text-dark-75 font-weight-bolder text-capitalize text-hover-primary d-block mb-1 font-size-lg">
                          {question?.text}
                        </a>
                        <span className="text-muted font-weight-bold d-block">
                          Modified on {question?.updatedAt}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                      Modified by{" "}
                      {`${question?.modifiedBy?.firstName} ${question?.modifiedBy?.lastName}`}
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <Link
                      to={`/diagram/${question?._id}`}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Settings-1.svg"
                          )}
                        ></SVG>
                      </span>
                    </Link>
                    <a
                      onClick={handleUpdate.bind(
                        this,
                        question?._id,
                        question?.text
                      )}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        ></SVG>
                      </span>
                    </a>
                    {/* <a
                      onClick={handleDelete.bind(this, question._id)}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                        ></SVG>
                      </span>
                    </a> */}
                    <DeleteButtonWithConfirm
                      onDelete={handleDelete.bind(this, question._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* end::Table */}
      </div>
      {/* end::Body */}
    </div>
  );
}
