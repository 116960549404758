import React, { useEffect } from "react";
import SVG from "react-inlinesvg";
import { Link, useHistory,useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import { GetApp,ArrowBackIos } from "@material-ui/icons";
import { format } from "date-fns";
import parseISO from "date-fns/parseISO";
import RingLoader from "react-spinners/RingLoader";

import { actions } from "../../../../app/modules/Users/_redux/usersRedux";

import { toAbsoluteUrl } from "../../../_helpers";

import "./common.css";
import {
  getUser,
  getUserIsLoading,
  getUserTraverseQuestion,
} from "../../../../app/modules/Users/_redux/usersSelectors";
import { UserInfo } from "../UserInfo/UserInfo";



export function UserDetailsTableWidget({ className }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const questions = useSelector(getUserTraverseQuestion(id)) || [];
  const user = useSelector(getUser(id));
  const isLoading = useSelector(getUserIsLoading);
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.fetchUserTraverseQuestions(id));
  }, []);

  const print = (reportUrl) => {
    var file_path = reportUrl;
    var a = document.createElement("a");
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
    a.click();
  };

  return (
    <>
      <div className={`card card-custom`} style={{ marginBottom: 20 }}>
        <div className="card-body py-4 d-flex align-items-center">
          <IconButton
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={history.goBack}
            aria-label="detail"
            style={{
              marginRight: 20,
              height: 34,
              width: 34,
              borderRadius: 5,
              color: "#3699FF",
            }}
          >
            <ArrowBackIos className="back" />
          </IconButton>
          <div>
            <UserInfo user={user} />
          </div>
        </div>
      </div>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        {isLoading && (
          <div className="loader_wrapper">
            <RingLoader color={"darkcyan"} loading={true} size={40} />
          </div>
        )}
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pl-0" style={{ minWidth: "400px" }}>
                    Tool Name
                  </th>
                  <th style={{ minWidth: "300px" }}>Tool Box</th>
                  <th style={{ minWidth: "300px" }}>Modified name</th>
                  <th style={{ minWidth: "" }}>Status</th>
                  <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {(questions || [])?.map?.((question) => (
                  <tr key={`question_${question.questionId}`}>
                    <td className="pl-0 py-4">
                      <div className="d-flex align-items-center">
                        {/* <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <span className="svg-icon h-75 align-self-end">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/User.svg"
                                )}
                              />
                            </span>
                          </span>
                        </div> */}
                        <div>
                          <a className="text-dark-75 font-weight-bolder text-capitalize text-hover-primary d-block mb-1 font-size-lg">
                            {question?.question}
                          </a>
                          <span className="text-muted font-weight-bold d-block">
                            {/* Modified on {question?.updatedAt} */}
                            Modified on{" "}
                            {format(parseISO(question?.updatedAt), "PPP p")}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {question?.topic}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        Modified by {question?.updatedBy}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {question?.isCompleted ? (
                          <div
                            style={{
                              color: "green",
                              fontWeight: 500,
                              fontSize: 12,
                            }}
                          >
                            Completed
                          </div>
                        ) : (
                          <div
                            style={{
                              color: "#eb800a",
                              fontWeight: 500,
                              fontSize: 12,
                            }}
                          >
                            In Progress
                          </div>
                        )}
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      {question.reportUrl && (
                        <IconButton
                          className="btn btn-icon btn-light btn-hover-primary btn-sm"
                          onClick={print.bind(this, question.reportUrl)}
                          aria-label="detail"
                          style={{
                            marginRight: 10,
                            height: 34,
                            width: 34,
                            borderRadius: 5,
                            color: "#0072B8",
                          }}
                        >
                          <GetApp className="download" />
                        </IconButton>
                      )}
                      <Link
                        to={`/travers-history/${question?.transactionId}`}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Settings-1.svg"
                            )}
                          ></SVG>
                        </span>
                      </Link>
                    </td>
                  </tr>
                ))}
                {!(questions || []).length && !isLoading && (
                  <tr>
                    <td className="pl-0 text-center" colSpan={8}>
                      <span className="svg-icon d-block p-3 nodata__icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/ico_nodata.svg")}
                        ></SVG>
                      </span>
                      <h3 className="text-dark font-weight-bolder d-block mb-0">
                        No Record Found
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
