import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";


export const getDiagramDetailForTraversHistory = (transactionId) => {
    return Services({
      url: "/user/history/details/"+ transactionId,
      method: "GET"
      
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        error?.response?.data?.message &&
          setToast(error?.response?.data?.message);
        return Promise.reject(error);
      });
  };
export const getInputDetailForTraversHistory = (transactionId) => {
    return Services({
      url: "/user/history/"+ transactionId,
      method: "GET"
      
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        error?.response?.data?.message &&
          setToast(error?.response?.data?.message);
        return Promise.reject(error);
      });
  };