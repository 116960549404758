import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { actions as USERS_ACTIONS } from "../../../../app/modules/Users/_redux/usersRedux";
import { actions as ORG_ACTIONS } from "../../../../app/modules/Organizations/_redux/organizationsRedux";
import { getUserSchema } from "../../../_utils";
import { Dropdown, Text, MultiSelect } from "../../formik-form-fields";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./AddUserDialog.css";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { getOrganizationsList } from "../../../../app/modules/Organizations/_redux/organizationsSelectors";

const orgOptions = [{ label: "Executive", value: "executive" }];

export function AddUserDialog({ toggleModal, data, ...props }) {
  const dispatch = useDispatch();
  const [organizations, setOrganizations] = useState([]);
  const organizationList = useSelector(getOrganizationsList);

  const getUserRole = () => {
    if (data?.id || orgOptions.length > 1) {
      return data?.id ? data?.userRole : [];
    } else {
      return [orgOptions[0].value];
    }
  };

  const initialValues = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    phone: "",
    mobile: "",
    designation: "",
    password: "",
    organizationId: "",
    userRole: getUserRole(),
  };

  useEffect(() => {
    dispatch(ORG_ACTIONS.getOrganizationsList());
  }, []);

  useEffect(() => {
    const modifiedOrganizations = organizationList.map((org) => ({
      label: org.companyName,
      value: org._id,
    }));
    setOrganizations(modifiedOrganizations);
  }, [organizationList]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getUserSchema(data && data?.id),
    onSubmit: ({
      id,
      firstName,
      lastName,
      email,
      countryCode,
      phone,
      designation,
      password,
      organizationId,
      userRole,
    }) => {
      dispatch(
        USERS_ACTIONS.add({
          id,
          firstName,
          lastName,
          email,
          countryCode,
          phone,
          designation,
          password,
          organizationId,
          userRole,
        })
      );
      toggleModal(false);
    },
  });

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      Object.keys(data).forEach((field) => {
        formik.setFieldValue(field, data[field]);
      });

      if (!data?.userRole?.length) {
        formik.setFieldValue("userRole", getUserRole());
      }

      formik.setFieldValue("mobile", `${data.countryCode}${data.phone}`);
      setTimeout(() =>
        formik.validateForm({
          ...data,
          mobile: `${data.countryCode}${data.phone}`,
        })
      );
    }
  }, [data]);

  /**
   * To generate random password
   */
  const generatePassword = () => {
    var length = 9,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      let number = Math.random();
      retVal += charset.charAt(Math.floor(number * n));
    }
    formik.setFieldValue("password", retVal);
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        toggleModal(false);
      }}
      onExit={() => {
        formik.resetForm();
      }}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">{data?.id ? `Edit` : `Add`} User</h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={() => {
            toggleModal(false);
          }}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <form>
          <div className="form-group fv-plugins-icon-container">
            <Text
              label="First Name"
              labelClass="form-label text-dark-50 font-weight-bolder"
              name="firstName"
              inputClass="form-control form-control-solid h-auto py-5 px-6"
              formik={formik}
              placeholder="Enter First Name"
              hideError={true}
            />
            <div className="error">{formik.errors["firstName"]}</div>
          </div>
          <div className="form-group fv-plugins-icon-container">
            <Text
              label="Last Name"
              labelClass="form-label text-dark-50 font-weight-bolder"
              name="lastName"
              inputClass="form-control form-control-solid h-auto py-5 px-6"
              formik={formik}
              placeholder="Enter Last Name"
              hideError={true}
            />
            <div className="error">{formik.errors["lastName"]}</div>
          </div>
          {!data?.id && (
            <div className="form-group fv-plugins-icon-container">
              <Text
                label="Email"
                labelClass="form-label text-dark-50 font-weight-bolder"
                name="email"
                inputClass="form-control form-control-solid h-auto py-5 px-6"
                formik={formik}
                placeholder="Enter Email"
                type="email"
                hideError={true}
              />
              <div className="error">{formik.errors["email"]}</div>
            </div>
          )}
          {!data?.id && (
            <div className="form-group fv-plugins-icon-container">
              <label className="form-label text-dark-50 font-weight-bolder">
                Organization
              </label>
              <Dropdown
                formik={formik}
                inputClass="form-control form-control-solid h-auto py-5 px-6"
                name="organizationId"
                placeholder="Select Organization"
                isDisabled={!organizations.length}
                options={organizations}
                hideError={true}
              />
              <div className="error">{formik.errors["organizationId"]}</div>
            </div>
          )}

          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              User Type
            </label>
            <MultiSelect
              formik={formik}
              inputClass="form-control form-control-solid h-auto py-2 px-2 br-2 abc"
              name="userRole"
              placeholder="Select User Type"
              options={orgOptions}
            />
            <div className="error">{formik.errors["userRole"]}</div>
          </div>

          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Mobile Number
            </label>
            <PhoneInput
              defaultCountry={"US"}
              // error={!!formikProps.errors[column.field]}
              // helperText={formikProps.errors[column.field]}
              //inputComponent={Text}
              countryCallingCodeEditable={false}
              international
              className="form-control form-control-solid h-auto py-5 px-6 phone-layout"
              onChange={(e) => {
                formik.setFieldValue("mobile", e);
                if (e && e?.length) {
                  const countryCodeLength = e.length - 10;
                  formik.setFieldValue(
                    "countryCode",
                    e.substring(0, countryCodeLength)
                  );
                  formik.setFieldValue(
                    "phone",
                    e.substring(countryCodeLength, e.length)
                  );
                }
              }}
              value={formik.values["mobile"]}
              initialValueFormat="national"
              //autoFormat={false}
              // disabled={isSubmitting}

              placeholder="Enter mobile number"
            />
            <div className="error">
              {formik.errors["mobile"] ||
                formik.errors["phone"] ||
                formik.errors["countryCode"]}
            </div>
          </div>
          <div className="form-group fv-plugins-icon-container">
            <Text
              label="User Title"
              labelClass="form-label text-dark-50 font-weight-bolder"
              name="designation"
              inputClass="form-control form-control-solid h-auto py-5 px-6"
              formik={formik}
              placeholder="Enter user title"
              hideError={true}
            />
            <div className="error">{formik.errors["designation"]}</div>
          </div>

          {!data?.id && (
            <>
              <div className="form-group fv-plugins-icon-container pwd-group mb-4">
                <Text
                  label="Password"
                  labelClass="form-label text-dark-50 font-weight-bolder"
                  name="password"
                  inputClass="form-control form-control-solid h-auto py-5 px-6"
                  formik={formik}
                  placeholder="Enter Password"
                  hideError={true}
                />
                <div className="error">{formik.errors["password"]}</div>
                <br />
                <span className="pwd-copy">Copy</span>
              </div>
              <div className="pwd-generate" onClick={() => generatePassword()}>
                Generate Password
              </div>
            </>
          )}

          {/* <div className="mx-auto my-13 pt-0 w-100px">
            <Button onClick={handleSubmit}>Submit</Button>
          </div> */}
        </form>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-0 mb-8">
        <Button className="w-100px btn-primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
