import React from "react";
import Chip from '@material-ui/core/Chip';

export function CustomToolTip(props) {
    
    return <div id='myTooltipDiv' className='tooltip' style={{ top: props.toolTipPosition.y, left: props.toolTipPosition.x }}>
        <h6>{props.toolTipText.text}</h6>
        {!props.toolTipText.isMultiInput && <>{props.toolTipText.data !== undefined && props.toolTipText.data.length > 0 && props.toolTipText.data.map((item) => {
            return <Chip key={item?.[props.toolTipText.columnName]} className="tooltip-chip" label={item?.[props.toolTipText.columnName]} variant="outlined" />
        })}</> }
        {props.toolTipText.isMultiInput && <div dangerouslySetInnerHTML={{__html: props.toolTipText.data}}></div>}
    </div>
}

