import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";

import * as HELP_SECTION_SERVICES from "../../../../_service/helpSection/helpSection.service";

export const actionTypes = {
  ADD: "[PAGE_DATA_ADD] Action",
  LOADING: "[PAGE_DATA_LOADING] Action",
  ERROR: "[PAGE_DATA_ERROR] Action",
  LIST: "[PAGE_DATA_LIST] Action",
  GET_PAGE_DATA_API: "[GET_PAGE_DATA_API] Action",
  GET_SINGLE_PAGE_DATA: "[GET_SINGLE_PAGE_DATA] Action",
  SET_SINGLE_PAGE_DATA: "[SET_SINGLE_PAGE_DATA] Action",
  DELETE: "[PAGE_DATA_DELETE] Action",
};

const initialHelpSectionState = {
  isLoading: false,
  error: "",
  redirect: false,
  pageList: [],
  singlePageDetails: [],
};

export const reducer = persistReducer(
  { storage, key: "helpSection" },
  (state = initialHelpSectionState, action) => {
    switch (action.type) {
      case actionTypes.LOADING: {
        const { isLoading } = action.payload;

        return { ...state, isLoading };
      }

      case actionTypes.ERROR: {
        const { error } = action.payload;

        return { ...state, error };
      }

      case actionTypes.LIST: {
        const { pageList } = action.payload;

        return { ...state, pageList };
      }
      case actionTypes.SET_SINGLE_PAGE_DATA: {
        const { pagedata } = action.payload;

        return { ...state, singlePageDetails: pagedata };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  add: (fileData) => ({ type: actionTypes.ADD, payload: { fileData } }),
  delete: (fileId) => ({ type: actionTypes.DELETE, payload: { fileId } }),
  isLoading: (isLoading) => ({
    type: actionTypes.LOADING,
    payload: { isLoading },
  }),

  error: (error) => ({ type: actionTypes.ERROR, payload: { error } }),
  list: (pageList) => ({ type: actionTypes.LIST, payload: { pageList } }),

  getPagelist: () => ({ type: actionTypes.GET_PAGE_DATA_API }),
  getsinglePageData: (pageName) => ({
    type: actionTypes.GET_SINGLE_PAGE_DATA,
    payload: { pageName },
  }),
  setsinglePageData: (pagedata) => ({
    type: actionTypes.SET_SINGLE_PAGE_DATA,
    payload: { pagedata },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.ADD, function* addSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { fileData } = action.payload;

      yield call(HELP_SECTION_SERVICES.uploadFile, fileData);
      yield put(actions.getPagelist());
      yield put(actions.error(""));
    } catch (error) {
      yield put(actions.error("File Upload Fails!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.GET_PAGE_DATA_API, function* listSaga() {
    yield put(actions.isLoading(true));
    try {
      const response = yield call(HELP_SECTION_SERVICES.getPageList);
      yield put(
        actions.list(response.data?.data || initialHelpSectionState.pageList)
      );
      yield put(actions.error(""));
    } catch (error) {
      yield put(actions.error("Failed Page list fetch!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(
    actionTypes.GET_SINGLE_PAGE_DATA,
    function* getsinglePageDataSaga(action) {
      yield put(actions.isLoading(true));
      try {
        const { pageName } = action.payload;
        const response = yield call(
          HELP_SECTION_SERVICES.getSinglePageData,
          pageName
        );
        yield put(
          actions.setsinglePageData(
            response.data?.data || initialHelpSectionState.singlePageDetails
          )
        );
        yield put(actions.error(""));
      } catch (error) {
        console.log(error);
        yield put(actions.error("Failed Page Name fetch!"));
      }
      yield put(actions.isLoading(false));
    }
  );

  yield takeLatest(actionTypes.DELETE, function* addSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { fileId } = action.payload;
      yield call(HELP_SECTION_SERVICES.deleteFile, fileId);
      yield put(actions.getPageList());
      yield put(actions.error(""));
    } catch (error) {
      yield put(actions.error("File Delete Fails!"));
    }
    yield put(actions.isLoading(false));
  });
}
