// Mixed
export { MixedWidget1 } from "./mixed/MixedWidget1";
export { UploadFileWidget } from "./upload-file/UploadFileWidget";

// Advance tables
export { TopicTablesWidget1 } from "./advance-tables/TopicTableWidget1";
export { SubTopicTablesWidget2 } from "./advance-tables/SubTopicTableWidget2";
export { TopicQuestionTableWidget1 } from "./advance-tables/TopicQuestionTableWidget1";
export { ProfileOverviewTableWidget1 } from "./advance-tables/ProfileOverviewTableWidget1";

