import React, { useEffect, useState } from "react";
import { Switch, FormControlLabel } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../../../../app/modules/toast";
import { FileVariableLookUp } from "../FileVariableLookUp";
import { actions as DIAGRAM_ACTIONS } from "../../../../../app/modules/Diagram/_redux/DiagramRedux";
import { AddCircleOutlined } from "@material-ui/icons";
const LookUpNodeInput = ({
  formData,
  localVariableArray,
  linkNodeArray,
  nodeKey,
  save,
}) => {
  const dispatch = useDispatch();

  const [singleSelectVariables, setSingleSelectVariables] = useState([]);
  const [inputData, setInputData] = useState(
    formData.inputs || [
      {
        variableLabel: "",
        variableValue: "",
        fileVariableSelection: false,
        localVariableName: "",
        showFilter: false,
        inputType: "String",
        fileValue: "",
        isEdit: true,
      },
    ]
  );
  const [fileError, setFileError] = useState(false);
  console.log(localVariableArray);
  console.log(linkNodeArray);
  useEffect(() => {
    let currentNodeId = linkNodeArray.find((item) => item.key == nodeKey)
      ?.nodeId;
    let singleVariables = [];

    localVariableArray.map((variable) => {
      let variableNodeId = linkNodeArray.find(
        (item) => item.key == variable.key
      );

      if (
        nodeKey !== variable.key &&
        variable.category === "MultiOption" &&
        variable.isSingle &&
        variableNodeId != undefined &&
        variableNodeId.nodeId < currentNodeId
      ) {
        singleVariables.push(variable);
      }

      if (
        variable.category === "LookUp" &&
        variableNodeId.nodeId < currentNodeId
      ) {
        singleVariables.push(variable);
      }
    });

    if (singleVariables.length > 0) {
      singleVariables.sort((variableFirst, variableSecond) => {
        const variableLabelFirst = variableFirst?.variableLabel?.toLowerCase(); // Convert names to lowercase for case-insensitive sorting
        const variableLabelSecond = variableSecond?.variableLabel?.toLowerCase();

        if (variableLabelFirst < variableLabelSecond) return -1;
        if (variableLabelFirst > variableLabelSecond) return 1;
        return 0;
      });
    }

    setSingleSelectVariables(singleVariables);
  }, []);

  const [nodeDisplayLabel, setNodeDisplayLabel] = useState(
    formData?.nodeDisplayLabel
  );
  const [nodeDisplayLabelLength, setNodeDisplayLabelLength] = useState(
    formData?.nodeDisplayLabel?.length
  );
  const handleNodeLabel = (value) => {
    setNodeDisplayLabel(value);
    setNodeDisplayLabelLength(value.length);
  };

  const manualInputData = useSelector((store) => store.diagram.manualInputData);

  const [filterVariable, setFilterVariable] = useState([]);
  const [errorValidationArray, setErrorValidation] = useState([]);

  const [showFilterVariable, setShowFilterVariable] = useState(false);
  const handleInputChange = (index, event) => {
    let name = event.target.name;
    let value = event.target.value;
    const oldInput = [...inputData];
    Object.freeze(oldInput[index]);
    let newObject = { ...oldInput[index] };

    if (name === "localVariableName") {
      handleVariableSelection(value);
      newObject.showFilter = true;
    }

    if (name === "inputType" && value == "File") {
      newObject.fileVariableSelection = true;
    }
    if (name === "inputType" && value == "String") {
      newObject.fileVariableSelection = false;
    }
    newObject[name] = value;
    oldInput[index] = newObject;
    setInputData(oldInput);
  };
  const handleVariableSelection = (value, index) => {
    let fullVal = value;
    if (fullVal != "") {
      setShowFilterVariable(true);
      let newfilterVariable = manualInputData.filter((variable) => {
        return variable?.name?.toLowerCase().includes(fullVal?.toLowerCase());
      });
      setFilterVariable(newfilterVariable);
    } else {
      setShowFilterVariable(false);
    }
  };

  const handleSelectVariable = (variable, dataIndex) => {
    setShowFilterVariable(false);
    const oldInput = [...inputData];
    Object.freeze(oldInput[dataIndex]);
    let newObject = { ...oldInput[dataIndex] };
    newObject.showFilter = false;
    newObject.localVariableName = variable.name;
    oldInput[dataIndex] = newObject;
    setInputData(oldInput);
  };

  const saveForm = () => {
    if (nodeDisplayLabel == "" || nodeDisplayLabel == undefined) {
      setToast("Please Enter Node Display Label ", "error", "top-center");
      return;
    }
    if (nodeDisplayLabelLength > 25) {
      setToast(
        "Please Enter Node Display Label less then 25 characters",
        "error",
        "top-center"
      );
      return;
    }
    let oldInput = [...inputData];
    let errorValidation = [];
    let error = false;
    oldInput.forEach((input, index) => {
      Object.freeze(input);
      let newObject = { ...input };
      newObject.isEdit = false;
      error = false;

      let validation = {};
      let findIndexForUsedVariable = localVariableArray.findIndex(
        (item) =>
          item.variableName == input.localVariableName && item.key != nodeKey
      );
      let checkUsedVariableIndex = oldInput.findIndex(
        (item) => item.localVariableName === input.localVariableName
      );
      const regexForVariable = /^[a-zA-Z0-9_]*$/g;
      if (findIndexForUsedVariable > -1) {
        error = true;
        validation.localVariableName = "Please use unique tool variable name ";
      } else if (
        !isNaN(input.localVariableName) ||
        !regexForVariable.test(input.localVariableName)
      ) {
        error = true;
        validation.localVariableName = "Please enter valid tool variable name";
      } else if (
        checkUsedVariableIndex > -1 &&
        index != checkUsedVariableIndex
      ) {
        error = true;
        validation.localVariableName = "Please enter unique variable name";
      }

      if (input.variableLabel === "" || input.variableLabel === undefined) {
        error = true;
        newObject.isEdit = true;
        validation.variableLabel = "Please enter node diplay label";
      }
      if (error) {
        newObject.isEdit = true;
      } else {
        newObject.isEdit = false;
      }
      if (!error) {
        let filterServerVariable = manualInputData.filter(
          (item) => item.name === input.localVariableName
        );
        if (filterServerVariable.length > 0) {
          newObject.variableName = input.localVariableName;
        } else {
          newObject.variableName = "";
        }
        newObject.showFilter = false;
        newObject.isEdit = false;
      }

      errorValidation.push(validation);
      if (newObject.inputType == "String" && newObject.variableValue == "") {
        error = true;
        newObject.isEdit = true;
      } else {
        let multiIdentityColRow = input.fileValue.multiIdentityColRow;
        if (input.fileValue != "") {
          if (multiIdentityColRow.length == 0) {
            error = true;
            setToast("Please Add Operation On File", "error", "top-center");
            newObject.isEdit = true;
          } else {
            let operationalFields = multiIdentityColRow[0].operationalFields;
            if (operationalFields.length == 0) {
              error = true;
              newObject.isEdit = true;
              setToast("Please Add Operation On File", "error", "top-center");
            } else {
              if (
                operationalFields[0].identityCol == "" ||
                operationalFields[0].identityColRow == "" ||
                operationalFields[0].equation == ""
              ) {
                error = true;
                newObject.isEdit = true;
                setToast(
                  "Please Select Value in operation",
                  "error",
                  "top-center"
                );
              }
            }
          }
        }
      }

      oldInput[index] = newObject;
      return true;
    });

    if (error) {
      setInputData(oldInput);
      setErrorValidation(errorValidation);
    } else if (fileError) {
      setToast("Please Check Error in File Selection", "error", "top-center");
      return;
    } else {
      save({
        inputs: oldInput,
        nodeDisplayLabel,
      });
    }
  };

  const handleremoveInput = (index) => {
    const oldInput = [...inputData];
    oldInput.splice(index, 1);
    setInputData(oldInput);
    const errorValidation = [...errorValidationArray];
    errorValidation.splice(index, 1);
    setErrorValidation(errorValidation);
  };

  const handleFileInput = (value, index) => {
    const oldInput = [...inputData];
    Object.freeze(oldInput[index]);
    let newObject = { ...oldInput[index] };
    let newFileObject = {
      outerOperation: value.outerOperation,
      identityValue: value.identityValue,
      fileName: value.fileName,
      fileId: value.fileId,
      multiIdentityColRow: value.multiIdentityColRow,
    };

    newObject.fileValue = newFileObject;
    newObject.fileVariableSelection = true;
    oldInput[index] = newObject;
    setInputData(oldInput);
  };

  const handleEdit = (key) => {
    const oldInput = [...inputData];

    const updatedInput = oldInput.map((obj, index) =>
      index === key ? { ...obj, isEdit: true } : { ...obj, isEdit: false }
    );
    setInputData(updatedInput);
  };
  return (
    <>
      <form
        className="py-5 px-5 form-col lookupnode-modal"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="form-group fv-plugins-icon-container">
              <label className={"form-label"}>
                Node Display Label{" "}
                <small>
                  {" "}
                  <span
                    className={nodeDisplayLabelLength > 25 ? "error" : "info"}
                  >
                    ({nodeDisplayLabelLength} / 25 charaters)
                  </span>
                </small>
                :
              </label>

              <input
                placeholder="Node Display Label"
                type="text"
                value={nodeDisplayLabel}
                onChange={(e) => {
                  handleNodeLabel(e.target.value);
                }}
                className="form-control form-control-solid h-auto py-5 px-6"
                name={"nodeDisplayLabel"}
              />
            </div>
          </div>
        </div>

        <div>
          {inputData.map((item, index) => {
            return (
              <div className="" key={index}>
                {(item?.isEdit == undefined || item?.isEdit == false) && (
                  <div className="formfilldata-block d-flex flex-wrap">
                    <div className="filldata-group d-flex align-items-center flex-wrap">
                      <div className="form-group d-flex align-items-center mb-2">
                        <div className="formlabel"> Display Name:</div>
                        <div className="formvalue">{item.variableLabel}</div>
                      </div>
                      <div className="form-group d-flex align-items-center mb-2">
                        <div className="formlabel">Tool Variable Name:</div>
                        <div className="formvalue">
                          {item.localVariableName}
                        </div>
                      </div>
                      <div className="form-group d-flex align-items-center mb-2">
                        <div className="formlabel">
                          {item.inputType == "File"
                            ? "File:"
                            : "Standard Value"}
                        </div>
                        <div className="formvalue">
                          {item.inputType == "File"
                            ? item?.fileValue?.fileName
                            : item.variableValue}
                        </div>
                      </div>
                    </div>
                    <div className="actionicons d-flex">
                      <div
                        onClick={() => handleEdit(index)}
                        className="addicon"
                      ></div>
                      {inputData.length > 1 && (
                        <div
                          onClick={() => handleremoveInput(index)}
                          className="deleteicon"
                        ></div>
                      )}
                    </div>
                    {item.inputType === "File" && (
                      <div className="col-xs-12 col-md-12">
                        <div className="fv-plugins-icon-container">
                          <FileVariableLookUp
                            singleSelectVariables={singleSelectVariables}
                            useFileVariable={item.fileVariableSelection}
                            setFileVariable={(value) => {
                              handleFileInput(value, index);
                            }}
                            isEdit={item?.isEdit}
                            selectedValue={item.fileValue}
                            nodeType={"lookUpNode"}
                            fileData={formData.inputs[index]}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {item?.isEdit == true && (
                  <div className="node-inutbdata-box">
                    <div className="actionicons">
                      {inputData.length > 1 && (
                        <div
                          onClick={() => handleremoveInput(index)}
                          className="deleteicon"
                        ></div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <div className="form-group fv-plugins-icon-container">
                          <label className={"form-label"}>
                            Node Display Name
                          </label>
                          <input
                            placeholder="Enter Label"
                            type="text"
                            value={item.variableLabel}
                            onChange={(e) => handleInputChange(index, e)}
                            className="form-control form-control-solid h-auto py-5 px-6 "
                            name={"variableLabel"}
                          />
                          {errorValidationArray[index]?.variableLabel !==
                            "" && (
                            <span className="text-danger">
                              {errorValidationArray[index]?.variableLabel}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <div className="form-group fv-plugins-icon-container">
                          <label className={"form-label"}>
                            Tool Variable Name
                          </label>
                          <input
                            placeholder="Enter Tool Variable Name"
                            type="text"
                            value={item.localVariableName}
                            onChange={(e) => handleInputChange(index, e)}
                            className="form-control form-control-solid h-auto py-5 px-6 "
                            name={"localVariableName"}
                          />
                          {errorValidationArray[index]?.localVariableName !==
                            "" && (
                            <span className="text-danger">
                              {errorValidationArray[index]?.localVariableName}
                            </span>
                          )}

                          {showFilterVariable &&
                            item.showFilter &&
                            filterVariable.length > 0 && (
                              <ul className="suggestion__block">
                                {filterVariable.map((v) => (
                                  <li
                                    onClick={() =>
                                      handleSelectVariable(v, index)
                                    }
                                    key={v._id}
                                  >
                                    {v.name}
                                  </li>
                                ))}
                              </ul>
                            )}
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-12">
                        <div className="form-group fv-plugins-icon-container">
                          <label className={"form-label"}>
                            Select Initial Value
                          </label>

                          <select
                            className="form-control form-control-solid h-auto py-5 px-6"
                            name="inputType"
                            value={item.inputType}
                            onChange={(e) => handleInputChange(index, e)}
                          >
                            <option value="String">Text</option>
                            <option value="File">File</option>
                          </select>
                        </div>
                      </div>
                      {item.inputType !== "File" && (
                        <div className="col-xs-12 col-md-12">
                          <div className="form-group fv-plugins-icon-container">
                            <label className={"form-label"}>
                              Standard Value
                            </label>
                            <input
                              placeholder="Enter Variable Value"
                              type="text"
                              name="variableValue"
                              value={item.variableValue}
                              onChange={(e) => handleInputChange(index, e)}
                              className="form-control form-control-solid h-auto py-5 px-6 "
                            />
                          </div>
                        </div>
                      )}
                      {item.inputType === "File" && (
                        <div className="col-xs-12 col-md-12">
                          <div className="fv-plugins-icon-container">
                            <FileVariableLookUp
                              singleSelectVariables={singleSelectVariables}
                              useFileVariable={item.fileVariableSelection}
                              setFileVariable={(value) => {
                                handleFileInput(value, index);
                              }}
                              isEdit={item?.isEdit}
                              selectedValue={item.fileValue}
                              nodeType={"lookUpNode"}
                              fileData={formData.inputs[index]}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          <div className="btn-block text-right mt-6">
            <button
              type="button"
              className="btn btn-primary mr-4"
              onClick={() => {
                const oldInput = [...inputData];

                const updatedInput = oldInput.map((obj, index) => {
                  return { ...obj, isEdit: false };
                });

                setInputData([
                  ...updatedInput,
                  {
                    variableLabel: "",
                    variableValue: "",
                    fileVariableSelection: false,
                    localVariableName: "",
                    showFilter: false,
                    inputType: "String",
                    fileValue: "",
                    isEdit: true,
                  },
                ]);
              }}
            >
              Add More Lookup Variable
            </button>
            <button
              type="button"
              className="btn btn-success w-100px"
              onClick={() => saveForm()}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default LookUpNodeInput;
