import React, {  useState } from "react";
import { Button, Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";


import { setToast } from "../../toast";
import { Services } from "../../../../_service/Services";

const Steps = [
  {
    key: "email",
    header: "Please, Enter valid User Name",
    buttonLabel: "Send OTP",
    placeholder: "Enter User Name...",
    type: "text",
  },
  {
    inputs: [
      {
        key: "otp",
        header: "Please, Enter OTP received from Email",
        placeholder: "Enter otp...",
        type: "number",
      },
      {
        key: "newPassword",
        header: "Please, Enter New Password",
        placeholder: "Enter new password...",
        type: "password",
      },
      {
        key: "reNewPassword",
        header: "Please, Re-Enter New Password",
        placeholder: "Enter new password...",
        type: "password",
      },
    ],
    buttonLabel: "Submit",
  },
];

export function ForgotPassword({ onHide, ...rest }) {
  const [step, setStep] = useState(0);
  const [forgotPassword, setForgotPassword] = useState({
    email: "",
    otp: "",
    newPassword: "",
    reNewPassword: "",
  });
  const [error, setError] = useState("");
  
  

  const handleInputChange = (key, event) => {
    const { value } = event.target;
    setError("");
    setForgotPassword((oldVal) => ({ ...oldVal, [key]: value }));
  };
  const handleStep = (key) => {
    if (step === 0) {
      const value = forgotPassword[key];
      if (!value) {
        setError("Email id is required!");
        return;
      }
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (regex.test(value)) {
        Services({
          url: `/user/send-otp`,
          method: "POST",
          data: {
            email: forgotPassword.email,
            otpFor: "resetPassword",
          },
        })
          .then((res) => {
            setToast(
              `OTP sent to ${forgotPassword.email} successfully!`,
              "success"
            );
            setStep((oldStep) => ++oldStep);
          })
          .catch((errorAPI) => {
            setToast("Failed to sent OTP!", "error");
          });
      } else {
        setError("Email id is not valid!");
      }
    }
    if (step === 1) {
      const { newPassword, reNewPassword, otp } = forgotPassword;
      if (!newPassword || !reNewPassword || !otp) {
        setError("All fields are required!");
        return;
      }

      if (newPassword !== reNewPassword) {
        setError("New password and re-enter password should be the same!");
        return;
      }

      if (newPassword?.length > 8 && newPassword?.length <= 15) {
        Services({
          url: `/user/reset-password`,
          method: "POST",
          data: {
            email: forgotPassword.email,
            otpCode: Number(forgotPassword.otp),
            newPassword,
          },
        })
          .then((res) => {
            setToast("Password reset successfully!", "success");
            onHide();
          })
          .catch((errorAPI) => {
            
            let message = "Password reset failed!";
            if (errorAPI?.response?.data?.message) {
              message = errorAPI.response.data.message;
              setError(message);
            }
            setToast(message, "error");
          });
      } else {
        setError(
          "Password length must be greater than 8 and less than or equal to 15 characters!"
        );
      }
    }
  };

  const handleHide = () => {
    setForgotPassword({
      email: "",
      otp: "",
      newPassword: "",
      reNewPassword: "",
    });
    setStep(0);
    onHide();
  };

  const stepDetails = Steps[step];

  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleHide}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">Forgot Password</h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={handleHide}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        {error && (
          <div
            style={{
              textAlign: "center",
              fontSize: 12,
              marginBottom: 5,
              color: "#F64E60",
            }}
            className="error"
          >
            {error}
          </div>
        )}
        {stepDetails?.inputs ? (
          stepDetails.inputs.map((inputDetails) => (
            <div className="form-group fv-plugins-icon-container mb-5">
              <label className="form-label text-dark-50 font-weight-bolder">
                {inputDetails?.header}
              </label>
              <input
                placeholder={inputDetails?.placeholder}
                type={inputDetails?.type}
                className={`form-control form-control-solid h-auto py-5 px-6`}
                name="text"
                value={forgotPassword[inputDetails?.key]}
                onChange={handleInputChange.bind(this, inputDetails?.key)}
              />
            </div>
          ))
        ) : (
          <div className="form-group fv-plugins-icon-container mb-5">
            <label className="form-label text-dark-50 font-weight-bolder">
              {stepDetails?.header}
            </label>
            <input
              placeholder={stepDetails?.placeholder}
              type={stepDetails?.type}
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="text"
              value={forgotPassword[stepDetails?.key]}
              onChange={handleInputChange.bind(this, stepDetails?.key)}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-0 mb-8">
        <Button
          className="w-100px btn-primary"
          onClick={handleStep.bind(this, stepDetails?.key)}
        >
          {stepDetails?.buttonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
