import React from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";
import Tooltip from "@material-ui/core/Tooltip";

import allStates from "./data/allstates.json";
import "./UsaMapChart.css";


const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

const UsaMapChart = ({ state = [], onStateChange = () => {} }) => {
  const handleClick = (selectedState, e) => {
    onStateChange(selectedState?.id);
  };

  return (
    <ComposableMap projection="geoAlbersUsa">
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <>
            {geographies.map((geo) => {
              const cur = allStates.find((s) => s.val === geo.id);
              const hasSelected = !!state.find(
                (o_state) =>
                  o_state?.StateName?.toLowerCase?.() === cur?.id?.toLowerCase?.()
              );
              return (
                <Tooltip
                  key={geo.rsmKey}
                  title={cur?.id || ""}
                  PopperProps={{
                    className: "state-view-tooltip",
                  }}
                  color="#a1326de6"
                >
                  <Geography
                    key={geo.rsmKey}
                    className="state-view"
                    stroke="#fff"
                    strokeWidth={1.5}
                    geography={geo}
                    fill={hasSelected ? "#0072B8" : "#D2D4DB"}
                    onClick={handleClick.bind(this, cur)}
                    
                  />
                </Tooltip>
              );
            })}
            {geographies.map((geo) => {
              const centroid = geoCentroid(geo);
              const cur = allStates.find((s) => s.val === geo.id);
              
              return (
                <g
                  key={geo.rsmKey + "-name"}
                  className="state-text-view"
                  // fill={hasSelected ? "#0072B8" : "#D2D4DB"}
                  // color={hasSelected ? "#fff" : "#333"}
                >
                  {cur &&
                    centroid[0] > -160 &&
                    centroid[0] < -67 &&
                    (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                      <Marker coordinates={centroid}>
                        <text
                          y="2"
                          style={{ fontSize: 8, color: "#333" }}
                          textAnchor="middle"
                        >
                          {cur.code}
                        </text>
                      </Marker>
                    ) : (
                      <Annotation
                        subject={centroid}
                        dx={offsets[cur.id][0]}
                        dy={offsets[cur.id][1]}
                      >
                        <text
                          x={4}
                          style={{ fontSize: 8, color: "#333" }}
                          alignmentBaseline="middle"
                        >
                          {cur.code}
                        </text>
                      </Annotation>
                    ))}
                </g>
              );
            })}
          </>
        )}
      </Geographies>
    </ComposableMap>
  );
};

export default UsaMapChart;
