import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";

import * as FILE_UPLOAD_SERVICES from "../../../../_service/fileUpload/fileUpload.service";

export const actionTypes = {
  ADD: "[FILE_UPLOAD_ADD] Action",
  LOADING: "[FILE_UPLOAD_LOADING] Action",
  ERROR: "[FILE_UPLOAD_ERROR] Action",
  REDIRECT: "[FILE_UPLOAD_REDIRECT] Action",
  GET_FILE_LIST_API: "[GET_FILE_LIST_API] Action",
  GET_FILE_COLUMN_LIST_API: "[GET_FILE_COLUMN_LIST_API] Action",
  GET_FILE_COLUMN_ROW_LIST_API: "[GET_FILE_COLUMN_ROW_LIST_API] Action",
  GET_FILE_COLUMN_LIST: "[GET_FILE_COLUMN_LIST] Action",
  GET_FILE_COLUMN_ROW_LIST: "[GET_FILE_COLUMN_ROW_LIST] Action",
  LIST: "[FILE_UPLOAD_LIST] Action",
  GET_FILE_NAME_LIST: "[GET_FILE_NAME_LIST] Action",
  SET_FILE_NAME_LIST: "[SET_FILE_NAME_LIST] Action",
  GET_DATA_TYPE_LIST_FOR_HEADER: "[GET_DATA_TYPE_LIST_FOR_HEADER] Action",
  SET_DATA_TYPE_LIST_FOR_HEADER: "[SET_DATA_TYPE_LIST_FOR_HEADER] Action",
  GET_DATE_FORMATE: "[GET_DATE_FORMATE] Action",
  SET_DATE_FORMATE: "[SET_DATE_FORMATE] Action",
  DELETE: "[FILE_DELETE] Action",
};

const initialFileUploadState = {
  isLoading: false,
  error: "",
  redirect: false,
  fileList: [],
  columnList: [],
  columnRowList: [],
  fileNameList: [],
  dataTypeHeaderList: [],
  dateFormateList: [],
};

export const reducer = persistReducer(
  { storage, key: "fileUpload" },
  (state = initialFileUploadState, action) => {
    switch (action.type) {
      case actionTypes.LOADING: {
        const { isLoading } = action.payload;

        return { ...state, isLoading };
      }

      case actionTypes.ERROR: {
        const { error } = action.payload;

        return { ...state, error };
      }

      case actionTypes.LIST: {
        const { fileList } = action.payload;

        return { ...state, fileList };
      }
      case actionTypes.GET_FILE_COLUMN_LIST: {
        const { columnList } = action.payload;

        return { ...state, columnList };
      }
      case actionTypes.SET_FILE_NAME_LIST: {
        const { fileNameList } = action.payload;

        return { ...state, fileNameList };
      }
      case actionTypes.GET_FILE_COLUMN_ROW_LIST: {
        const { columnRowList } = action.payload;
        let newColumnRowList = [...new Set(columnRowList)];
        return { ...state, columnRowList: newColumnRowList };
      }
      case actionTypes.SET_DATA_TYPE_LIST_FOR_HEADER: {
        const { dataTypeHeaderList } = action.payload;
        return { ...state, dataTypeHeaderList };
      }
      case actionTypes.SET_DATE_FORMATE: {
        const { dateFormateList } = action.payload;
        return { ...state, dateFormateList };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  add: (fileData) => ({ type: actionTypes.ADD, payload: { fileData } }),
  delete: (fileId) => ({ type: actionTypes.DELETE, payload: { fileId } }),
  isLoading: (isLoading) => ({
    type: actionTypes.LOADING,
    payload: { isLoading },
  }),
  redirect: (isTrue) => ({
    type: actionTypes.REDIRECT,
    payload: { isTrue },
  }),
  error: (error) => ({ type: actionTypes.ERROR, payload: { error } }),
  list: (fileList) => ({ type: actionTypes.LIST, payload: { fileList } }),
  columnList: (columnList) => ({
    type: actionTypes.GET_FILE_COLUMN_LIST,
    payload: { columnList },
  }),
  columnRowList: (columnRowList) => ({
    type: actionTypes.GET_FILE_COLUMN_ROW_LIST,
    payload: { columnRowList },
  }),
  getFileList: () => ({ type: actionTypes.GET_FILE_LIST_API }),
  getFileColumnList: (fileName) => ({
    type: actionTypes.GET_FILE_COLUMN_LIST_API,
    payload: { fileName },
  }),
  getFileColumnRowList: (fileData) => ({
    type: actionTypes.GET_FILE_COLUMN_ROW_LIST_API,
    payload: { fileData },
  }),
  getFileNameList: () => ({
    type: actionTypes.GET_FILE_NAME_LIST,
    payload: {},
  }),
  setFileNameList: (fileNameList) => ({
    type: actionTypes.SET_FILE_NAME_LIST,
    payload: { fileNameList },
  }),

  getDataTypeListForHeader: () => ({
    type: actionTypes.GET_DATA_TYPE_LIST_FOR_HEADER,
    payload: {},
  }),
  setDataTypeListForHeader: (dataTypeHeaderList) => ({
    type: actionTypes.SET_DATA_TYPE_LIST_FOR_HEADER,
    payload: { dataTypeHeaderList },
  }),
  getDateFormateList: () => ({
    type: actionTypes.GET_DATE_FORMATE,
    payload: {},
  }),
  setDateFormateList: (dateFormateList) => ({
    type: actionTypes.SET_DATE_FORMATE,
    payload: { dateFormateList },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.ADD, function* addSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { fileData } = action.payload;

      yield call(FILE_UPLOAD_SERVICES.uploadFile, fileData);
      yield put(actions.getFileList());
      yield put(actions.error(""));
    } catch (error) {
      yield put(actions.error("File Upload Fails!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.GET_FILE_LIST_API, function* listSaga() {
    yield put(actions.isLoading(true));
    try {
      const response = yield call(FILE_UPLOAD_SERVICES.getFileList);
      yield put(
        actions.list(response.data?.data || initialFileUploadState.fileList)
      );
      yield put(actions.error(""));
    } catch (error) {
      yield put(actions.error("Failed File list fetch!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(
    actionTypes.GET_DATA_TYPE_LIST_FOR_HEADER,
    function* getDataTypeListForHeaderSaga() {
      yield put(actions.isLoading(true));
      try {
        const response = yield call(
          FILE_UPLOAD_SERVICES.getDataTypeListForHeader
        );
        yield put(
          actions.setDataTypeListForHeader(
            response.data?.data || initialFileUploadState.dataTypeHeaderList
          )
        );
      } catch (error) {
        yield put(actions.error("Failed File Name List fetch!"));
      }
      yield put(actions.isLoading(false));
    }
  );
  yield takeLatest(
    actionTypes.GET_DATE_FORMATE,
    function* getDateFormateListSaga() {
      yield put(actions.isLoading(true));
      try {
        const response = yield call(
          FILE_UPLOAD_SERVICES.getDateFormateListService
        );
        yield put(
          actions.setDateFormateList(
            response.data?.data || initialFileUploadState.dateFormateList
          )
        );
      } catch (error) {
        yield put(actions.error("Failed File Name List fetch!"));
      }
      yield put(actions.isLoading(false));
    }
  );

  yield takeLatest(
    actionTypes.GET_FILE_NAME_LIST,
    function* getFileNameListSaga() {
      yield put(actions.isLoading(true));
      try {
        const response = yield call(FILE_UPLOAD_SERVICES.getFileNameList);
        yield put(
          actions.setFileNameList(
            response.data?.data || initialFileUploadState.fileList
          )
        );
        yield put(actions.error(""));
      } catch (error) {
        console.log("error", error);
        yield put(actions.error("Failed File Name List fetch!"));
      }
      yield put(actions.isLoading(false));
    }
  );

  yield takeLatest(
    actionTypes.GET_FILE_COLUMN_LIST_API,
    function* getFileColumnListSaga(action) {
      yield put(actions.isLoading(true));
      try {
        const { fileName } = action.payload;
        const response = yield call(
          FILE_UPLOAD_SERVICES.getFileColumnList,
          fileName
        );
        yield put(
          actions.columnList(
            response.data?.data || initialFileUploadState.fileList
          )
        );
        yield put(actions.error(""));
      } catch (error) {
        yield put(actions.error("Failed"));
      }
      yield put(actions.isLoading(false));
    }
  );

  yield takeLatest(
    actionTypes.GET_FILE_COLUMN_ROW_LIST_API,
    function* getFileColumnRowListSaga(action) {
      yield put(actions.isLoading(true));
      try {
        const { fileData } = action.payload;
        const response = yield call(
          FILE_UPLOAD_SERVICES.getFileColumnRowList,
          fileData
        );
        yield put(
          actions.columnRowList(
            response.data?.data || initialFileUploadState.fileList
          )
        );
        yield put(actions.error(""));
      } catch (error) {
        yield put(actions.error("Failed"));
      }
      yield put(actions.isLoading(false));
    }
  );

  yield takeLatest(actionTypes.DELETE, function* addSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { fileId } = action.payload;
      yield call(FILE_UPLOAD_SERVICES.deleteFile, fileId);
      yield put(actions.getFileList());
      yield put(actions.error(""));
    } catch (error) {
      yield put(actions.error("File Delete Fails!"));
    }
    yield put(actions.isLoading(false));
  });
}
