import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";

export const addSubtopic = (subtopic, parentTopic) => {
  return Services({
    url: "/admin/subTopic/",
    method: "POST",
    data: {
      ...subtopic,
      parentTopic: parentTopic,
    },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const updateSubtopic = (id, subtopic) => {
  return Services({
    url: `/admin/subTopic/${id}`,
    method: "PUT",
    data: {
      ...subtopic,
    },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const deleteSubtopic = (id) => {
  return Services({
    url: `/admin/subTopic/${id}`,
    method: "DELETE",
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const getTopicDetails = (id) => {
  let url = "/admin/topic/details";
  if (id != "" && id != undefined) {
    url = url + "?topicId=" + id;
  }
  return Services({
    url: url,
    method: "GET",
  });
};
