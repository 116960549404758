import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { actions as QUESTION_ACTIONS } from "../../../../app/modules/Question/_redux/questionsRedux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export function AddTopicQuestionDialog({
  onHide,
  isUpdate,
  id,
  name,
  parentSubtopic,
  ...rest
}) {
  const [input, setInput] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setInput(name);
  }, [name]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    const regex = /^[a-zA-Z0-9_?\s+]*$/g;
    if (regex.test(value)) {
      setInput(value);
    }
  };
  const handleSubmit = () => {
    if (!input) {
      return;
    }
    if (isUpdate) {
      dispatch(QUESTION_ACTIONS.updateQuestion(id, parentSubtopic, input));
    } else {
      dispatch(QUESTION_ACTIONS.addQuestion(input, parentSubtopic));
    }
    setInput("");
    onHide();
  };

  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">Add Question</h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={onHide}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="form-group fv-plugins-icon-container mb-0">
          <label className="form-label text-dark-50 font-weight-bolder">
            Question
          </label>
          <textarea
            placeholder="Enter question..."
            type="text"
            className={`form-control form-control-solid h-100px py-5 px-6`}
            name="text"
            value={input}
            onChange={handleInputChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-0 mb-8">
        <Button className="w-100px btn-primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
