import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";

export const savePredefinedLogic = (processData) => {
  return Services({
    url: "/admin/process/create",
    method: "POST",
    data: processData,
  })
    .then((response) => {
      response.data?.message &&
        setToast(response.data?.message, "success", "top-center");
      return response;
    })
    .catch((error) => {
      setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const updatePredefinedLogic = (processData) => {
  return Services({
    url: "/admin/process/update",
    method: "PUT",
    data: processData,
  })
    .then((response) => {
      response.data?.message &&
        setToast(response.data?.message, "success", "top-center");
      return response;
    })
    .catch((error) => {
      setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const deletePredefinedLogic = (id) => {
  return Services({
    url: `/admin/process/delete/${id}`,
    method: "DELETE",
  })
    .then((response) => {
      response.data?.message &&
        setToast(response.data?.message, "success", "top-center");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error", "top-center");
      return Promise.reject(error);
    });
};
export const savePredefinedLogicDiagram = (processData) => {
  return Services({
    url: "/admin/process",
    method: "POST",
    data: processData,
  })
    .then((response) => {
      response.data?.message &&
        setToast(response.data?.message, "success", "top-center");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const updatePredefinedLogicDiagram = (diagramData) => {
  return Services({
    url: "/admin/process",
    method: "PUT",
    data: diagramData,
  })
    .then((response) => {
      response.data?.message &&
        setToast(response.data?.message, "success", "top-center");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const deleteDiagram = (id) =>
  Services({
    url: `/admin/decisionTree/${id}`,
    method: "DELETE",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error", "top-center");
      return Promise.reject(error);
    });
export const getDiagram = (questionId) => {
  return Services({
    url: "/admin/decisionTree/" + questionId,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getPredefinedLogicDiagram = (predefinedogicId) => {
  return Services({
    url: "/admin/process/details/" + predefinedogicId,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getPredefinedLogicList = () => {
  return Services({
    url: "/admin/process/list",
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getStaticLoop = () => {
  return Services({
    url: "admin/reportTemplate/loopTypes",
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const getStaticLoopVariables = (loopId) => {
  return Services({
    url: "/admin/process/loop_variables/" + loopId,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const getPredefinedLogicVariables = (predefinedLogic) => {
  return Services({
    url: "/admin/variable/list/" + predefinedLogic,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getVariableList = (nodeType) => {
  return Services({
    url: "/admin/variable?nodeType=" + nodeType,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getOperatorList = (nodeType) => {
  return Services({
    url: "/admin/variable/operators?nodeType=" + nodeType,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
