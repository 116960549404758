import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as DIAGRAM_ACTIONS } from "../../../../../app/modules/Diagram/_redux/DiagramRedux";
import { setToast } from "../../../../../app/modules/toast";

const PredefinedLogic = ({
  formData,
  save,
  nodeKey,
  localVariableArray,
  linkNodeArray,
}) => {
  const dispatch = useDispatch();
  const predefinedLogicData = useSelector(
    (store) => store.diagram.predefinedLogicData
  );
  const predefinedLogicVariables = useSelector(
    (store) => store.diagram.predefinedLogicVariables
  );
  const [procedure, setProcedure] = useState("");
  const [label, setLabel] = useState("");
  const [processId, setProcessId] = useState("");
  const [usedVariables, setUsedVariables] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [variableMapping, setVariableMapping] = useState([]);
  const [localVariableArrayFilters, setLocalVariableArrayFilters] = useState(
    []
  );
  const [errorSelected, setErrorSelected] = useState([]);
  const [nodeDisplayLabel, setNodeDisplayLabel] = useState("");
  const [nodeDisplayLabelLength, setNodeDisplayLabelLength] = useState(0);

  useEffect(() => {
    dispatch(DIAGRAM_ACTIONS.getPredefinedLogicListAPI());
  }, []);

  useEffect(() => {
    setProcedure(formData.procedure);
    setProcessId(formData.processId);
    setLabel(formData.label);
    setNodeDisplayLabel(formData?.nodeDisplayLabel);
    setNodeDisplayLabelLength(formData?.nodeDisplayLabel?.length);
    if (formData?.variableMapping?.length > 0) {
      setVariableMapping(formData?.variableMapping);
    } else {
      setVariableMapping([]);
    }
  }, [formData]);
  useEffect(() => {
    let newLocalVaraibleFilter = [];
    localVariableArray.length > 0 &&
      localVariableArray.forEach((localVariable, index) => {
        let currentNodeId = linkNodeArray.find((item) => item.key == nodeKey)
          ?.nodeId;

        let variableNodeId = linkNodeArray.find(
          (item) => item.key == localVariable.key
        );

        if (
          variableNodeId != undefined &&
          variableNodeId.nodeId < currentNodeId
        ) {
          newLocalVaraibleFilter.push(localVariable);
        }
      });
    if (newLocalVaraibleFilter.length > 0) {
      newLocalVaraibleFilter.sort((variableFirst, variableSecond) => {
        const variableLabelFirst = variableFirst?.variableLabel?.toLowerCase(); // Convert names to lowercase for case-insensitive sorting
        const variableLabelSecond = variableSecond?.variableLabel?.toLowerCase();

        if (variableLabelFirst < variableLabelSecond) return -1;
        if (variableLabelFirst > variableLabelSecond) return 1;
        return 0;
      });
    }
    setLocalVariableArrayFilters(newLocalVaraibleFilter);
  }, [localVariableArray]);
  useEffect(() => {
    if (processId !== "" && processId !== undefined) {
      dispatch(DIAGRAM_ACTIONS.getPredefinedLogicVariablesAPI(processId));
    }
  }, [processId]);

  useEffect(() => {
    let newUsedVariables = [];
    let newselectedVariables = [];
    predefinedLogicVariables.length > 0 &&
      predefinedLogicVariables.map((variable) => {
        if (formData?.variableMapping?.length > 0) {
          let index = formData.variableMapping.findIndex(
            (item) => item.variableName === variable.name
          );
          newUsedVariables[variable.name] =
            formData.variableMapping[index] !== undefined
              ? formData.variableMapping[index].localVariableName
              : "";
          newselectedVariables.push({
            variableName: variable.name,
            localVariableName:
              formData.variableMapping[index] !== undefined
                ? formData.variableMapping[index].localVariableName
                : "",
          });
        }
        // oldUsedVariables[variable.name] = oldUsedVariables[variable.name] === undefined ? "" : oldUsedVariables[variable.name]
      });
    setUsedVariables(newUsedVariables);
    setSelectedVariables(newselectedVariables);
  }, [predefinedLogicVariables]);

  const saveForm = () => {
    if (procedure === "") {
      setToast("Please fill values in form", "error", "top-center");
      return;
    }
    if (nodeDisplayLabel == "" || nodeDisplayLabel == undefined) {
      setToast("Please add Node Display Label", "error", "top-center");
      return;
    }
    if (nodeDisplayLabel.length > 25) {
      setToast(
        "Please Enter Node Display Label less then 25 characters",
        "error",
        "top-center"
      );
      return;
    }
    let notSelectedVariables = []; //oldUsedVariables.filter(item => item === '')

    predefinedLogicVariables.map((item, index) => {
      if (
        usedVariables[item.name] == "" ||
        usedVariables[item.name] == undefined
      ) {
        notSelectedVariables.push(item.name);
      }
    });
    setErrorSelected(notSelectedVariables);
    if (notSelectedVariables.length > 0) {
      setToast("Please Select Variables", "error", "top-center");
    } else {
      save({ procedure, processId, label, variableMapping, nodeDisplayLabel });
    }
  };
  return (
    <form className="py-5 px-5 form-col modal-form predefined-logic">
      <div className="form-group fv-plugins-icon-container">
        <label className={"form-label text-dark-50 font-weight-bolder"}>
          Node Display Label{" "}
          <small>
            {" "}
            <span className={nodeDisplayLabelLength > 25 ? "error" : "info"}>
              ({nodeDisplayLabelLength} / 25 charaters)
            </span>
          </small>
          :
        </label>

        <input
          placeholder="Node Display Label"
          type="text"
          value={nodeDisplayLabel}
          onChange={(e) => {
            setNodeDisplayLabel(e.target.value);
            setNodeDisplayLabelLength(e.target.value.length);
          }}
          className="form-control form-control-solid h-auto py-5 px-6"
          name={"nodeDisplayLabel"}
        />
      </div>
      <div className="form-group fv-plugins-icon-container">
        <label className="form-label text-dark-50 font-weight-bolder">
          Procedure :
        </label>
        <select
          className="form-control form-control-solid h-auto py-5 px-6"
          name="predefinedLogic"
          onChange={(e) => {
            let value = e.target.value;
            setProcedure(value);
            let index = e.nativeEvent.target.selectedIndex;
            setLabel(e.nativeEvent.target[index].text);
            setProcessId(e.target.options[index].dataset.key);
          }}
          value={procedure}
        >
          <option value="">Select Procedure</option>

          {predefinedLogicData.length > 0 &&
            predefinedLogicData.map((predefinedLogic) => (
              <option
                data-key={predefinedLogic._id}
                key={predefinedLogic._id}
                value={predefinedLogic.logic}
              >
                {predefinedLogic.displayLabel != undefined
                  ? predefinedLogic.displayLabel
                  : predefinedLogic.label}
              </option>
            ))}
        </select>
      </div>
      <div className="row">
        {procedure != "" &&
          predefinedLogicVariables.length > 0 &&
          predefinedLogicVariables.map((variable, index) => {
            let errorCheck = errorSelected.findIndex(
              (item) => item === variable.name
            );

            return (
              <div
                key={variable.name + "_" + variable.variableLabel + "_" + index}
                className={`col-xs-12 col-md-6`}
              >
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label text-dark-50 font-weight-bolder">
                    Select Variable For {variable.variableLabel} :
                  </label>
                  <select
                    className={`form-control form-control-solid h-auto py-5 px-6 ${
                      errorCheck < 0 ? "" : "error"
                    }`}
                    name={variable.name}
                    onChange={(e) => {
                      let variableName = e.target.name;
                      let value = e.target.value;
                      let oldSelected = [...variableMapping];

                      let selectedIndex = oldSelected.findIndex(
                        (item) => item.variableName === variableName
                      );
                      if (selectedIndex < 0) {
                        oldSelected.push({
                          variableName: variableName,
                          localVariableName: value,
                        });
                      } else {
                        oldSelected[selectedIndex] = {
                          variableName: variableName,
                          localVariableName: value,
                        };
                      }
                      setVariableMapping(oldSelected);
                      let oldSelectedVariables = selectedVariables;
                      let selectedVariableIndex = oldSelectedVariables.findIndex(
                        (item) => item.variableName === variableName
                      );
                      if (selectedVariableIndex < 0) {
                        oldSelectedVariables.push({
                          variableName: variableName,
                          localVariableName: value,
                        });
                      } else {
                        oldSelectedVariables[
                          selectedVariableIndex
                        ].localVariableName = value;
                      }
                      setSelectedVariables(oldSelectedVariables);

                      let oldUsedVariables = usedVariables;

                      oldUsedVariables[variableName] = value;
                      setUsedVariables(oldUsedVariables);
                    }}
                    value={usedVariables[variable.name]}
                  >
                    <option value="">Select Local Variable</option>
                    {localVariableArrayFilters.length > 0 &&
                      localVariableArrayFilters.map((localVariable, index) => {
                        let disabledOption = selectedVariables.findIndex(
                          (item) =>
                            item.localVariableName ===
                            localVariable.variableName
                        );

                        return (
                          <option
                            disabled={
                              disabledOption > -1 &&
                              selectedVariables[disabledOption].variableName !==
                                variable.name
                                ? true
                                : null
                            }
                            key={localVariable.key + "_" + index}
                            value={localVariable.variableName}
                          >
                            {localVariable.variableLabel}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            );
          })}
      </div>
      <div className="btn-block">
        <button
          type="button"
          className="btn btn-success w-100px"
          onClick={() => saveForm()}
        >
          {" "}
          Save
        </button>
      </div>
    </form>
  );
};

export default PredefinedLogic;
