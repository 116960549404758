import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import "../header.css";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/"
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/">
            <span className="menu-text">Administrator</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/toolbox")}`}>
                <NavLink className="menu-link" to="/toolbox">
                  <span className="menu-text">Toolbox</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive("/users")}`}>
                <NavLink className="menu-link" to="/users">
                  <span className="menu-text">Users</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive("/organization")}`}>
                <NavLink className="menu-link" to="/organization">
                  <span className="menu-text">Organization</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/predefined-logic"
                )}`}
              >
                <NavLink className="menu-link" to="/predefined-logic">
                  <span className="menu-text">Predefined Logic</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/predefined-logic"
                )}`}
              ></li>
              <li className={`menu-item ${getMenuItemActive("/upload")}`}>
                <NavLink className="menu-link" to="/upload">
                  <span className="menu-text">File Upload</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/report-template")}`}
              >
                <NavLink className="menu-link" to="/report-template">
                  <span className="menu-text">Report Template</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive("/help-section")}`}>
                <NavLink className="menu-link" to="/help-section">
                  <span className="menu-text">Help Setting</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
}
