import React, { useState, useEffect } from "react";
import { uniq } from "lodash";


function StepTwo({ className, organization, onFieldChange }) {
  const [allProducts, setAllProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  

  const handleCategoryCheck = (category, isChecked) => {
    let selectedProducts = organization?.products;
    if (isChecked) {
      selectedProducts = selectedProducts?.map((s_prd) => {
        if (s_prd?.Category === category) {
          s_prd.isSelected = false;
        }
        return s_prd;
      });
    } else {
      selectedProducts = selectedProducts?.map((s_prd) => {
        if (s_prd?.Category === category) {
          s_prd.isSelected = true;
        }
        return s_prd;
      });
    }
    onFieldChange("products", selectedProducts);
  };

  const handleProductCheck = (isChecked, product) => {
    if (isChecked) {
      const organizationProducts = organization?.products?.map((prd) => {
        if (prd._id === product._id) {
          prd.isSelected = false;
        }
        return prd;
      });
      onFieldChange("products", organizationProducts);
    } else {
      const organizationProducts = organization?.products?.map((prd) => {
        if (prd._id === product._id) {
          prd.isSelected = true;
        }
        return prd;
      });
      onFieldChange("products", organizationProducts);
    }
  };

  const getChecked = (category) => {
    return organization?.products?.reduce((isChecked, product) => {
      if (product?.Category === category && !product?.isSelected) {
        isChecked = false;
      }
      return isChecked;
    }, true);
  };

  const getHasSelected = (category) => {
    return organization?.products?.reduce((isChecked, product) => {
      if (product?.Category === category && product?.isSelected) {
        isChecked = true;
      }
      return isChecked;
    }, false);
  };

  const onCategoryClicked = (category) => {
    setSelectedCategory(category);
    const categoryProducts = allProducts.filter((prd) => prd.Category === category);
    setProducts(categoryProducts);
  };

  useEffect(() => {
    const categoriesFromProduct = uniq(
      (organization?.products || [])?.map((prd) => prd?.Category)
    );
    setCategories(categoriesFromProduct);
    setAllProducts(organization?.products);
  }, []);

  useEffect(() => {
    onCategoryClicked(categories[0]);
  }, [allProducts]);

  return (
    <>
      <div className="card-header border-0 py-8 custom-header">
        <h3 className="card-title flex-column mb-0">
          <span className="card-label font-weight-bolder text-dark">
            Select Product Sold
          </span>
        </h3>
      </div>

      <div className="card-body pt-0">
        <div className={`card card-custom ${className}`}>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="form-group">
                  <h4 className="card-label font-weight-bolder text-dark mb-6">
                    Categories
                  </h4>
                  <div className="checkbox-block">
                    {categories.map((category) => {
                      const isChecked = getChecked(category);
                      const hasSelectedProduct = !isChecked
                        ? getHasSelected(category)
                        : false;
                      return (
                        <div className="checkbox-inline">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name={category}
                              onClick={handleCategoryCheck.bind(
                                this,
                                category,
                                isChecked
                              )}
                              checked={isChecked}
                            />
                            <span
                              className={
                                hasSelectedProduct
                                  ? "has-product-selected"
                                  : ""
                              }
                              mr-0
                            >
                              {hasSelectedProduct ? "-" : ""}
                            </span>
                            <div
                              style={
                                selectedCategory === category
                                  ? { color: "#E57F0E" }
                                  : {}
                              }
                              className="pl-3 font-weight-bolder"
                            >
                              {category}
                            </div>
                          </label>
                          <div
                            className="arrowright"
                            onClick={onCategoryClicked.bind(this, category)}
                          ></div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {!!products?.length && (
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <h4 className="card-label font-weight-bolder text-dark mb-6">
                      Products
                    </h4>
                    <div className="checkbox-block">
                      {products.map((prd) => {
                        const isChecked = prd?.isSelected;
                        return (
                          <label className="checkbox mb-3">
                            <input
                              onClick={handleProductCheck.bind(
                                this,
                                isChecked,
                                prd
                              )}
                              type="checkbox"
                              checked={isChecked}
                              name={prd?.Name}
                            />
                            <span mr-0></span>
                            <div className="pl-3 font-weight-bolder">
                              {prd?.Name}
                            </div>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default StepTwo;
