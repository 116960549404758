import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";

export const uploadFile = (fileData) => {
  let bodyFormData = new FormData();
  bodyFormData.append("helpFile", fileData.file);
  bodyFormData.append("pageName", fileData.pageName);
  bodyFormData.append("label", fileData.label);

  return Services({
    url: "/admin/help/" + fileData.id,
    method: "PUT",
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      response?.data?.message && setToast(response?.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const getPageList = () => {
  return Services({
    url: "/admin/help",
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getSinglePageData = (pageName) => {
  return Services({
    url: "/admin/help/" + pageName,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const deleteFile = (fileId) => {
  return Services({
    url: `/admin/excelData/${fileId}`,
    method: "DELETE",
  })
    .then((response) => {
      setToast(response?.data?.message || "File has been deleted!");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
