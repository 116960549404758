import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";

//backend fetch
export const addTopic = (topicData) =>
  Services({
    url: "/admin/topic",
    method: "POST",
    data: { name: topicData },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

export const updateTopic = (id, topicData) =>
  Services({
    url: `/admin/topic/${id}`,
    method: "PUT",
    data: { name: topicData },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

export const getTopic = () =>
  Services({
    url: "/admin/topic",
    method: "GET",
  });

export const getTopicDetails = (id) =>
  Services({
    url: `/admin/topic/details/${id}`,
    method: "GET",
  });

export const deleteTopic = (id) =>
  Services({
    url: `/admin/topic/${id}`,
    method: "DELETE",
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

//mock
let topics = [
];
export const topic = { topics };
