import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";

export const MultiSelect = ({
  inputClass = "",
  formik,
  name,
  placeholder = "",
  options = [],
  isDisabled = false,
  ...rest
}) => {
  const handleChange = (e) => {
    formik.setFieldValue(name, e?.target?.value);
  };
  return (
    <Select
      labelId="demo-multiple-checkbox-label"
      multiple
      style={{ width: "100%", padding: 0 }}
      value={formik.values[name] || []}
      onChange={handleChange}
      inputProps={{
        className: inputClass,
      }}
      renderValue={(selected) => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {selected.map((item) => (
            <Chip key={item} label={item} />
          ))}
        </Box>
      )}
      disableUnderline={true}
      {...rest}
    >
      {options.map((option) => (
        <MenuItem key={option?.value} value={option?.value}>
          <Checkbox
            checked={formik.values[name]?.indexOf?.(option?.value) > -1}
          />
          <ListItemText primary={option?.label} />
        </MenuItem>
      ))}
    </Select>
  );
};
