import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Data from "../../data/response.json";
import { setToast } from "../../../../../app/modules/toast";
const Parallelogram = ({ formData, save }) => {
  const { frequencies, servers } = Data.data;
  const [inputs, setInputs] = useState({
    fileName: "",
    server: "",
    frequency: "",
  });

  useEffect(() => {
    setInputs(formData);
  }, [formData]);

  const saveForm = () => {
    if (
      inputs.fileName === "" ||
      inputs.server === "" ||
      inputs.frequency === ""
    ) {
      setToast('Please fill values in form', 'error','top-center')
      return;
    }
    save(inputs);
  };

  return (
    <form className="py-5 px-5 form-col modal-form">
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Name :
            </label>
            <input
              type="text"
              placeholder="Name"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="text"
              value={inputs?.fileName}
              onChange={(e) => {
                let fileName = e.target.value;
                setInputs((state) => ({ ...state, fileName }));
              }}
            />
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Server :
            </label>
            <select
              className="form-control form-control-solid h-auto py-5 px-6"
              name="server"
              onChange={(e) => {
                let server = e.target.value;
                setInputs((state) => ({ ...state, server }));
              }}
              value={inputs?.server}
            >
              <option value="">Select Server</option>
              {Object.keys(servers).map((v) => (
                <option key={v} value={servers[v]}>
                  {servers[v]}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div> 
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Frequency :
            </label>
            <select
              className="form-control form-control-solid h-auto py-5 px-6"
              name="frequency"
              onChange={(e) => {
                let frequency = e.target.value;
                setInputs((state) => ({
                  ...state,
                  frequency,
                }));
              }}
              value={inputs?.frequency}
            >
              <option value="">Select Operator</option>
              {Object.keys(frequencies).map((v) => (
                <option key={v} value={frequencies[v]}>
                  {frequencies[v]}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Equation :
            </label>
            <input
              type="text"
              placeholder="Equation"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="text"
            />
          </div>  
        </div>
      </div>  
      <div className="btn-block">
        <Link>
          <button type="button" class="btn btn-primary w-100px" onClick={() => saveForm()}>{" "} Save</button>
        </Link>
      </div>
    </form>
  );
};

export default Parallelogram;
