import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";
import * as ORGANIZATION_SERVICES from "../../../../_service/organizations/organization.service";

export const actionTypes = {
  ADD: "[O_ADD] Action",
  LIST: "[O_LIST] Action",
  LOADING: "[O_LOADING] Action",
  ERROR: "[O_ERROR] Action",
  GET_ORGANIZATIONS_LIST: "[GET_ORGANIZATIONS_LIST] Action",
};

const initialAuthState = {
  data: [],
  isLoading: false,
  error: "",
};

export const reducer = persistReducer(
  { storage, key: "organizations" },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.LIST: {
        const { organizations } = action.payload;
        return { ...state, data: organizations };
      }

      case actionTypes.LOADING: {
        const { isLoading } = action.payload;

        return { ...state, isLoading };
      }

      case actionTypes.ERROR: {
        const { error } = action.payload;

        return { ...state, error };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  add: (organization) => ({
    type: actionTypes.ADD,
    payload: { organization },
  }),
  list: (organizations) => ({
    type: actionTypes.LIST,
    payload: { organizations },
  }),
  isLoading: (isLoading) => ({
    type: actionTypes.LOADING,
    payload: { isLoading },
  }),
  error: (error) => ({ type: actionTypes.ERROR, payload: { error } }),
  getOrganizationsList: () => ({
    type: actionTypes.GET_ORGANIZATIONS_LIST,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.ADD, function* addSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { organization } = action.payload;
      yield call(ORGANIZATION_SERVICES._updateOrganization, organization);

      const response = yield call(ORGANIZATION_SERVICES.getOrganizations);
      yield put(actions.list(response?.data?.data));
    } catch (error) {
      yield put(actions.error("Failed organization add!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.GET_ORGANIZATIONS_LIST, function* listSaga() {
    yield put(actions.isLoading(true));
    try {
      const response = yield call(ORGANIZATION_SERVICES.getOrganizations);
      yield put(actions.list(response?.data?.data));
    } catch (error) {
      yield put(actions.error("Failed organization fetch!"));
    }
    yield put(actions.isLoading(false));
  });
}
