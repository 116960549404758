import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";

export const createDiagram = (diagramData) => {
  return Services({
    url: "/admin/decisionTree",
    method: "POST",
    data: diagramData,
  })
    .then((response) => {
      response.data?.message &&
        setToast(response.data?.message, "success", "top-center");
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
export const updateDiagram = (diagramData) => {
  return Services({
    url: "/admin/decisionTree",
    method: "PUT",
    data: diagramData,
  })
    .then((response) => {
      response.data?.message &&
        setToast(response.data?.message, "success", "top-center");
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteDiagram = (id) =>
  Services({
    url: `/admin/decisionTree/${id}`,
    method: "DELETE",
  })
    .then((response) => {
      response.data?.message &&
        setToast(response.data?.message, "success", "top-center");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error", "top-center");
      return Promise.reject(error);
    });
export const getDiagram = (questionId) => {
  return Services({
    url: "/admin/decisionTree/" + questionId,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getPredefinedLogicDiagram = (predefinedogicId) => {
  return Services({
    url: "/admin/decisionTree/process/" + predefinedogicId,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getPredefinedLogicList = (questionId) => {
  return Services({
    url: "admin/process/tree/list",
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getPredefinedLogicVariables = (predefinedLogic) => {
  return Services({
    url: "/admin/process/variables/" + predefinedLogic,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getVariableList = (nodeType) => {
  return Services({
    url: "/admin/variable?nodeType=" + nodeType,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getOperatorList = (nodeType) => {
  return Services({
    url: "/admin//variable/operators?nodeType=" + nodeType,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
