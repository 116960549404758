import React from "react";

const Text = ({
  label = "",
  labelClass = "",
  inputClass = "",
  formik,
  name,
  placeholder = "",
  type = "text",
  maxLength,
  hideError,
  children,
}) => {
  return (
    <>
      {label !== "" && (
        <label className={labelClass}>
          {label} {children && <small>({children})</small>}
        </label>
      )}
      <input
        placeholder={placeholder}
        type={type}
        className={inputClass}
        name={name}
        onChange={formik.handleChange}
        value={formik.values[name]}
        maxLength={maxLength}
      />
      {!hideError && formik?.errors?.[name] && formik?.touched?.[name] && (
        <span className="text-danger">{formik?.errors?.[name]}</span>
      )}
    </>
  );
};

export default Text;
