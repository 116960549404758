import * as Yup from "yup";
import { formValidations } from "./constants";
export const getUserSchema = (isUpdate = false) =>
  Yup.object().shape({
    firstName: Yup.string().required(formValidations.usersForm.firstName),
    // .matches(/^[aA-zZ\s]+$/, formValidations.usersForm.invalidFirstName),
    lastName: Yup.string().required(formValidations.usersForm.lastName),
    // .matches(/^[aA-zZ\s]+$/, formValidations.usersForm.invalidLastName),
    email: Yup.string()
      .email(formValidations.usersForm.invalidEmail)
      .required(formValidations.usersForm.email),
    countryCode: Yup.string().required(formValidations.usersForm.countryCode),
    phone: Yup.string()
      .min(10, formValidations.usersForm.invalidPhone)
      .max(12, formValidations.usersForm.invalidPhone)
      .matches("\\d", formValidations.usersForm.invalidPhone)
      .required(formValidations.usersForm.phone),
    mobile: Yup.string()
      .min(10, formValidations.usersForm.invalidPhone)
      .max(15, formValidations.usersForm.invalidPhone),
    designation: Yup.string()
      .min(4, formValidations.usersForm.invalidDesignation)
      .required(formValidations.usersForm.designation),
    // .matches(
    //   /^[a-zA-Z0-9\s]+$/,
    //   formValidations.usersForm.invalidDesignationString
    // ),
    organizationId: isUpdate
      ? Yup.string()
      : Yup.string().required(formValidations.usersForm.organizationId),
    userRole: Yup.array().required(formValidations.usersForm.userRole),
    password: isUpdate
      ? Yup.string()
      : Yup.string()
          .required(formValidations.usersForm.passwordValidation)
          .min(9, formValidations.usersForm.minPassword)
          .max(64, formValidations.usersForm.maxPassword),
  });

export const getDiagramSchema = (isUpdate = false) =>
  Yup.object().shape({
    label: Yup.string().required(formValidations.diagramForm.question),
    localVariableName: Yup.string()
      .required(formValidations.diagramForm.variableName)
      .matches(
        /^[A-Za-z0-9]*$/,
        formValidations.diagramForm.invalidVariableName
      ),
  });

Yup.addMethod(Yup.string, "notOnlyCharacter", function(message) {
  // eslint-disable-next-line no-invalid-this
  return this.test("not-only-number", message, (value) => isNaN(value));
});
export const getQuestionSchema = (isUpdate = false) =>
  Yup.object().shape({
    text: Yup.string()
      .required(formValidations.questionForm.text)
      .notOnlyCharacter(formValidations.questionForm.validText),
    // .matches(
    //   /^[aA-zZ0-9\s]+$/,
    //   "Only alphabets and numbers are allowed for this field "
    // ),
    assumptions: Yup.string().required(
      formValidations.questionForm.assumptions
    ),
    screen_tagline: Yup.string().required(
      formValidations.questionForm.screen_tagline
    ),
    // .matches(
    //   /^[aA-zZ0-9\s]+$/,
    //   "Only  alphabets and numbers are allowed for this field "
    // ),
    icon_logo: Yup.mixed(),
    // summery_report: Yup.string()
    //   .required(formValidations.questionForm.summery_report)
    //   .matches(
    //     /^[aA-zZ0-9\s]+$/,
    //     "Only  alphabets and numbers are allowed for this field "
    //   ),
    description: Yup.string().required(
      formValidations.questionForm.description
    ),
  });

export const ORGANIZATIONS_SCHEMA = Yup.object().shape({
  companyName: Yup.string().required(
    formValidations.organizationsForm.companyName
  ),
  geographicScope: Yup.string().required(
    formValidations.organizationsForm.geographicScope
  ),
  city: Yup.string().required(formValidations.organizationsForm.city),
  state: Yup.string().required(formValidations.organizationsForm.state),
  productSold: Yup.string().required(
    formValidations.organizationsForm.productSold
  ),
  salesChannelUtilized: Yup.string().required(
    formValidations.organizationsForm.salesChannelUtilized
  ),
});
export const getToolBoxSchema = (isUpdate = false) =>
  Yup.object().shape({
    name: Yup.string()
      .required(formValidations.toolBoxForm.toolName)
      .notOnlyCharacter(formValidations.toolBoxForm.validText),
    tool_image: Yup.mixed(),
    tagLine: Yup.string()
      .required(formValidations.toolBoxForm.tagLine)
      .max(80, formValidations.toolBoxForm.tagLineLength),
    solution: Yup.string()
      .required(formValidations.toolBoxForm.solution)
      .max(100, formValidations.toolBoxForm.solutionLength),
    question: Yup.string()
      .required(formValidations.toolBoxForm.question)
      .max(50, formValidations.toolBoxForm.questionLength),
  });
