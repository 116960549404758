import React from "react";

const Dropdown = ({
  inputClass = "",
  formik,
  name,
  placeholder = "",
  options = [],
  isDisabled = false,
  hideError,
  ...rest
}) => {
  return (
    <>
      <select
        className={inputClass}
        name={name}
        onChange={formik.handleChange}
        value={formik.values[name]}
        placeholder={placeholder}
        disabled={isDisabled}
        {...rest}
      >
        <option value="">{placeholder}</option>
        {options.map((op) => (
          <option value={op.value} key={op.value+'_'+op.label}>
            {op.label}
          </option>
        ))}
      </select>
      {!hideError && formik?.errors?.[name] && formik?.touched?.[name] && (
        <span className="text-danger">{formik?.errors?.[name]}</span>
      )}
    </>
  );
};

export default Dropdown;
