import React from "react";
import { MixedWidget1 } from "../widgets";
// import UsaMapChart from "../organization/UsaMapChart";

export function Demo1Dashboard({ className }) {
  return (
    <>
      <div className="dashboard__bg"></div>
      <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>
      </div>
    </>
  );
}
