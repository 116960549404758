import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";

export const uploadFile = (fileData) => {
  let bodyFormData = new FormData();
  bodyFormData.append("excelFile", fileData.excelFile);
  bodyFormData.append("selectFileName", fileData.selectFileName);
  bodyFormData.append("fileName", fileData.fileName);
  bodyFormData.append("uploadBehavior", fileData.uploadBehavior);
  bodyFormData.append("uniqueKeyColumns", fileData.uniqueKeyColumns);
  bodyFormData.append("headersTypes", JSON.stringify(fileData.headersTypes));
  bodyFormData.append("isModifiableFields", fileData.isModifiableFields);
  bodyFormData.append("isCompositeUnique", fileData.isCompositeUnique);
  bodyFormData.append("compositeKeyName", fileData.compositeKeyName);
  bodyFormData.append("isCompositive", fileData.isCompositive);
  bodyFormData.append("isHavingDisplayName", fileData.isHavingDisplayName);
  bodyFormData.append("compositeKeyOptions", fileData.compositeKeyOptions);
  return Services({
    url: "/admin/excelData",
    method: "POST",
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      response?.data?.message && setToast(response?.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message?.message &&
        setToast(error?.response?.data?.message?.message, "error");
      return Promise.reject(error);
    });
};

export const getFileList = () => {
  return Services({
    url: "/admin/excelData",
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getFileNameList = () => {
  return Services({
    url: "/admin/excelData/getStaticFileList",
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
export const getDataTypeListForHeader = () => {
  return Services({
    url: "/admin/excelData/dataType",
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const getDateFormateListService = () => {
  return Services({
    url: "/admin/excelData/dateFormats",
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const getFileColumnList = (fileName) => {
  return Services({
    url: "/admin/excelData/fileColumns?fileName=" + fileName,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const getFileColumnRowList = (fileData) => {
  return Services({
    url:
      "/admin/excelData/fileColumnsData?fileName=" +
      fileData.fileName +
      "&identityCol=" +
      fileData.identityColumn,
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const deleteFile = (fileId) => {
  return Services({
    url: `/admin/excelData/${fileId}`,
    method: "DELETE",
  })
    .then((response) => {
      setToast(response?.data?.message || "File has been deleted!");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};
