import React, { useState, useEffect } from "react";
import RingLoader from "react-spinners/RingLoader";
import { Button, Modal } from "react-bootstrap";
import { read, utils } from "xlsx";

import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { actions as UPLOAD_FILE_ACTIONS } from "../../../../app/modules/UploadFile/_redux/UploadFileRedux";
import { Services } from "../../../../_service/Services";
import { setToast } from "../../../../app/modules/toast";
import {
  getFileNameList,
  getFileUploadError,
  getFileUploadIsLoading,
  getDataTypeList,
  getDateFormateList,
} from "../../../../app/modules/UploadFile/_redux/UploadFileSelector";
const fileType = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
export function FileUploadDialog({
  toggleModal,
  editMode = false,
  fileId = "",
  showModal,
  ...props
}) {
  const dispatch = useDispatch();
  const [defaultHeaderValidation, setDefaultHeaderValidation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCompositive, setIsCompositive] = useState(false);
  const [isCompositeUnique, setIsCompositeUnique] = useState(false);
  const [isHavingDisplayName, setIsHavingDisplayName] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [compositeKeyName, setCompositeKeyName] = useState("");
  const [selectFileName, setSelectFileName] = useState("");
  const [uploadBehavior, setSelectUploadBehavior] = useState("");
  const [uniqKeyColumns, setUniqKeyColumns] = useState([]);
  const [compositeKeyOptions, setCompositeKeyOptions] = useState([]);
  const [sheetHeaders, setSheetHeaders] = useState([]);
  const fileNameList = useSelector(getFileNameList);
  const fileUploadError = useSelector(getFileUploadError);
  const fileUploadIsLoading = useSelector(getFileUploadIsLoading);
  const dataTypeList = useSelector(getDataTypeList);
  const dateFormateList = useSelector(getDateFormateList);
  const [data, setData] = useState({});

  const [errorValidationArray, setErrorValidation] = useState([]);
  const [error, setError] = useState({
    file: "",
    fileName: "",
    uniqKeyColumns: "",
    uploadBehavior: "",
  });

  useEffect(() => {
    if (showModal) {
      dispatch(UPLOAD_FILE_ACTIONS.getDataTypeListForHeader());
      dispatch(UPLOAD_FILE_ACTIONS.getDateFormateList());
      if (!editMode) {
        dispatch(UPLOAD_FILE_ACTIONS.getFileNameList());
      }
    }
    return () => {
      setSheetHeaders([]);
      setUniqKeyColumns([]);
      setDefaultHeaderValidation([]);
      setIsSubmit(false);
      setIsHavingDisplayName(false);
      setCompositeKeyName("");
      setError({
        file: "",
        fileName: "",
        uniqKeyColumns: "",
        uploadBehavior: "",
      });
    };
  }, [showModal]);
  useEffect(() => {
    if (!fileUploadIsLoading && fileUploadError == "" && isSubmit) {
      resetAll();
      toggleModal(false);
      setIsLoading(false);
    }
    if (!fileUploadIsLoading) {
      setIsLoading(false);
    }
  }, [fileUploadError, fileUploadIsLoading]);
  useEffect(() => {
    if (fileId && editMode) {
      setIsLoading(true);
      Services({
        url: `/admin/excelData/fileData?id=${fileId}`,
        method: "GET",
      })
        .then((response) => {
          setData(response?.data?.data);
          setSheetHeaders(
            response?.data?.data?.fileHeaders.filter?.(
              (header) => header !== "_id"
            )
          );
          setFileName(response?.data?.data?.fileName);
          setCompositeKeyOptions(response?.data?.data?.compositeKeyOptions);
          setCompositeKeyName(response?.data?.data?.compositeKeyName);
          setIsCompositeUnique(response?.data?.data?.isCompositeUnique);
          setIsCompositive(response?.data?.data?.isCompositive);
          setUniqKeyColumns(response?.data?.data?.uniqueKeyColumns || []);
        })
        .catch((apiError) => {
          apiError?.response?.data?.message &&
            setToast(apiError?.response?.data?.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [fileId, editMode]);

  const onFileChange = (event) => {
    resetError();
    setSheetHeaders([]);
    if (event.target.files.length > 0) {
      setFile(event.target.files[0]);
      const file = event.target.files[0];
      const reader = new FileReader();
      let fileHeaderUpload = [];
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          for (const element of sheets) {
            const rows = utils.sheet_to_json(wb.Sheets[element], {
              header: 1,
            });
            const header = rows.shift();
            fileHeaderUpload = [...fileHeaderUpload, ...header].filter(function(
              x,
              i,
              a
            ) {
              return a.indexOf(x) == i;
            });
          }
          setSheetHeaders(fileHeaderUpload);
          let uniqHeaders = uniqKeyColumns.filter(
            (item) => fileHeaderUpload.indexOf(item) != -1
          );

          if (isCompositeUnique) {
            uniqHeaders.push(compositeKeyName);
          }
          setUniqKeyColumns(uniqHeaders);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleSubmit = () => {
    let isModifiableFields = true;
    let oldDefaultHeaderValidation = [...defaultHeaderValidation];
    let errorValidation = [];
    let error = false;
    oldDefaultHeaderValidation.forEach((headerValidation, index) => {
      let validation = {};
      if (headerValidation.columnName == "") {
        error = true;
        validation.columnName = "Please Select Header Name.";
      }
      if (headerValidation.columnType == "") {
        error = true;
        validation.columnType = "Please Select data type.";
      }
      if (headerValidation.columnType == "Date") {
        if (headerValidation.dateFormat == "") {
          error = true;
          validation.dateFormat = "Please Enter Date Formate.";
        }
      }
      if (headerValidation.isSecureField) {
        isModifiableFields = false;
      }
      errorValidation.push(validation);
    });

    if (error) {
      setErrorValidation(errorValidation);
      return true;
    }

    if (editMode) {
      setErrorValidation([]);
      // if (!(uniqKeyColumns || [])?.length) {
      //   setError({
      //     uniqKeyColumns: !(uniqKeyColumns || [])?.length
      //       ? "Unique Key Column/s is/are required!"
      //       : "",
      //   });
      // } else {
      if (!file) {
        setError({
          file: !file ? "File is required!" : "",
        });

        return;
      }
      if (uploadBehavior == "") {
        setError({
          uploadBehavior: "Please Upload Behaviour",
        });

        return;
      }
      if (isCompositive) {
        let compositeError = {};
        if (compositeKeyName == "") {
          compositeError.compositeKeyName = "Composite Key Name is required";
        }
        if (compositeKeyOptions.length == 0) {
          compositeError.compositeKeyOptions =
            "Please Select Composite Key Options";
        }

        if (Object.keys(compositeError).length > 0) {
          setError(compositeError);
          return;
        }
      }
      setIsLoading(true);
      let bodyFormData = new FormData();

      bodyFormData.append("fileName", fileName);
      bodyFormData.append("uploadBehavior", uploadBehavior);
      bodyFormData.append("excelFile", file || "");
      bodyFormData.append("uniqueKeyColumns", uniqKeyColumns.join(","));
      bodyFormData.append(
        "headersTypes",
        JSON.stringify(defaultHeaderValidation)
      );
      bodyFormData.append("isModifiableFields", isModifiableFields);
      bodyFormData.append("isCompositeUnique", isCompositeUnique);
      bodyFormData.append("compositeKeyName", compositeKeyName);
      bodyFormData.append("isCompositive", isCompositive);
      bodyFormData.append("isHavingDisplayName", isHavingDisplayName);
      bodyFormData.append("compositeKeyOptions", compositeKeyOptions.join(","));
      Services({
        url: `/admin/excelData/${data?._id}`,
        method: "PUT",
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          setToast(response?.message || "File updated successfully!");
          resetAll();
          UPLOAD_FILE_ACTIONS.getFileList();
          toggleModal(false);
          setIsLoading(false);
        })
        .catch((apiError) => {
          apiError?.response?.data?.message?.message &&
            setToast(apiError?.response?.data?.message?.message, "error");
          setIsLoading(false);
        });
      // }
    } else {
      setErrorValidation([]);
      if (!selectFileName || !file) {
        setError({
          file: !file ? "File is required!" : "",
          selectFileName: !selectFileName ? "Please Select File Name!" : "",
        });
      } else {
        const regex = /^[a-zA-Z][a-zA-Z0-9_\s+.()]*$/g;
        if (selectFileName == "other" && !regex.test(fileName)) {
          setError({
            file: !file ? "File is required!" : "",
            fileName: "File Name is not valid!",
          });
          return;
        }
        if (isCompositive) {
          let compositeError = {};
          if (compositeKeyName == "") {
            compositeError.compositeKeyName = "Composite Key Name is required";
          }
          if (compositeKeyOptions.length == 0) {
            compositeError.compositeKeyOptions =
              "Please Select Composite Key Options";
          }
          console.log("compositeError", compositeError);
          if (Object.keys(compositeError).length > 0) {
            setError(compositeError);
            return;
          }
        }
        if (fileType.includes(file.type)) {
          setIsLoading(true);
          dispatch(
            UPLOAD_FILE_ACTIONS.add({
              fileName,
              selectFileName,
              excelFile: file,
              uploadBehavior,
              uniqueKeyColumns: uniqKeyColumns.join(","),
              headersTypes: defaultHeaderValidation,
              isModifiableFields: isModifiableFields,
              isCompositeUnique,
              compositeKeyName,
              compositeKeyOptions: compositeKeyOptions.join(","),
              isCompositive,
              isHavingDisplayName,
            })
          );
          setIsSubmit(true);
        } else {
          setError({
            file: "File is not valid, Please select xlsx file",
            fileName: "",
            selectFileName: "",
          });
          setIsLoading(false);
          return;
        }
      }
    }
  };

  const resetError = () => {
    setError({
      file: "",
      fileName: "",
    });
  };

  const resetAll = () => {
    resetError();
    setFile(null);
    setFileName("");
    setCompositeKeyOptions([]);
    setCompositeKeyName("");
    setIsCompositeUnique(false);
    setIsCompositive(false);
  };

  const handleHeaderValidation = () => {
    let headerInput = {
      columnName: "",
      columnType: "",
      dateFormat: "",
      isSecureField: false,
    };
    setDefaultHeaderValidation([headerInput]);
  };
  const addHeaderValidation = () => {
    let headerInput = {
      columnName: "",
      columnType: "",
      dateFormat: "",
      isSecureField: false,
    };
    setDefaultHeaderValidation([...defaultHeaderValidation, headerInput]);
  };

  const handleremoveInput = (index) => {
    const oldDefaultHeaderValidation = [...defaultHeaderValidation];
    oldDefaultHeaderValidation.splice(index, 1);
    setDefaultHeaderValidation(oldDefaultHeaderValidation);
  };

  const handleInputChange = (index, event) => {
    let name = event.target.name;
    let value = event.target.value;
    const oldDefaultHeaderValidation = [...defaultHeaderValidation];
    Object.freeze(oldDefaultHeaderValidation[index]);
    let newObject = { ...oldDefaultHeaderValidation[index] };
    newObject[name] = value;
    oldDefaultHeaderValidation[index] = newObject;
    setDefaultHeaderValidation(oldDefaultHeaderValidation);
  };
  const handleHeadingCheckBox = (index, checked) => {
    const oldDefaultHeaderValidation = [...defaultHeaderValidation];
    Object.freeze(oldDefaultHeaderValidation[index]);
    let newObject = { ...oldDefaultHeaderValidation[index] };
    newObject.isSecureField = checked;
    oldDefaultHeaderValidation[index] = newObject;
    setDefaultHeaderValidation(oldDefaultHeaderValidation);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        resetAll();
        toggleModal(false);
      }}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">{editMode ? "Edit File" : "Upload File"}</h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={() => {
            resetAll();
            toggleModal(false);
          }}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className={`card card-custom `}>
          {isLoading && (
            <div className="loader_wrapper">
              <RingLoader color={"darkcyan"} loading={true} size={40} />
            </div>
          )}
          <div className="card-body">
            <div className="  row manual-input-box">
              {!editMode && (
                <>
                  <div className="form-group fv-plugins-icon-container col-6">
                    <div className="form-group fv-plugins-icon-container">
                      <label
                        className={"form-label text-dark-50 font-weight-bolder"}
                      >
                        Select Data File
                      </label>
                      <select
                        name="selectfilename"
                        className="form-control form-control-solid h-auto py-5 px-6"
                        onChange={(e) => setSelectFileName(e.target.value)}
                      >
                        <option value="">Select</option>
                        {fileNameList.map((fileName, index) => {
                          return (
                            <option
                              key={fileName._id}
                              value={fileName.fileName}
                            >
                              {fileName.fileName}
                            </option>
                          );
                        })}
                        <option value="other">Other</option>
                      </select>
                      {error?.selectfilename && (
                        <span className="text-danger">
                          {error?.selectfilename}
                        </span>
                      )}
                    </div>
                  </div>
                  {selectFileName == "other" && (
                    <div className="form-group fv-plugins-icon-container col-6">
                      <div className="form-group fv-plugins-icon-container">
                        <label
                          className={
                            "form-label text-dark-50 font-weight-bolder"
                          }
                        >
                          File Upload Template
                        </label>
                        <input
                          style={
                            error.fileName ? { border: "1px solid red" } : {}
                          }
                          className={`form-control form-control-solid h-auto py-5 px-6`}
                          name="fileName"
                          onChange={(e) => {
                            resetError();
                            setFileName(e.target.value);
                          }}
                          value={fileName}
                          placeholder="Enter file name..."
                          disabled={editMode}
                        />
                        {error?.fileName && (
                          <span className="text-danger">{error?.fileName}</span>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
              {editMode && (
                <>
                  <div className="form-group fv-plugins-icon-container col-6">
                    <div className="form-group fv-plugins-icon-container">
                      <label
                        className={"form-label text-dark-50 font-weight-bolder"}
                      >
                        File Name
                      </label>
                      <input
                        style={
                          error.fileName ? { border: "1px solid red" } : {}
                        }
                        className={`form-control form-control-solid h-auto py-5 px-6`}
                        name="fileName"
                        value={fileName}
                        readOnly={true}
                        placeholder="Enter file name..."
                        disabled={editMode}
                      />
                      {error?.fileName && (
                        <span className="text-danger">{error?.fileName}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group fv-plugins-icon-container col-6">
                    <div className="form-group fv-plugins-icon-container">
                      <label
                        className={"form-label text-dark-50 font-weight-bolder"}
                      >
                        Select Upload Behavior
                      </label>
                      <select
                        name="selectUploadBehavior"
                        className="form-control form-control-solid h-auto py-5 px-6"
                        onChange={(e) =>
                          setSelectUploadBehavior(e.target.value)
                        }
                        value={uploadBehavior}
                      >
                        <option value="">Select</option>
                        <option value="1">Replace All Data</option>
                        <option value="2">Appending Additional data</option>
                        <option
                          value="3"
                          disabled={
                            uniqKeyColumns.length == 0 && !isCompositeUnique
                          }
                        >
                          Modify With Existing data
                        </option>
                      </select>
                      {error?.uploadBehavior && (
                        <span className="text-danger">
                          {error?.uploadBehavior}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="form-group fv-plugins-icon-container col-6">
                <div className="form-group fv-plugins-icon-container">
                  <label
                    className={"form-label text-dark-50 font-weight-bolder"}
                  >
                    Select File
                  </label>
                  <span
                    className=""
                    style={{ fontSize: 10, marginLeft: 5, color: "gray" }}
                  >
                    (Note: File size should not be exceed 5Mb)
                  </span>
                  <input
                    style={{
                      padding: 13.5,
                      border: error.file ? "1px solid red" : "inherit",
                    }}
                    type="file"
                    className={`form-control form-control-solid h-auto`}
                    name="files"
                    accept={fileType.join(",")}
                    onChange={onFileChange}
                  />
                  {error?.file && (
                    <span className="text-danger">{error?.file}</span>
                  )}
                </div>
              </div>

              <div className="form-group fv-plugins-icon-container col-6">
                <label className="form-label text-dark-50 font-weight-bolder">
                  Unique Data Items
                </label>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  multiple
                  style={
                    error.uniqKeyColumns
                      ? {
                          border: "1px solid red",
                          width: "100%",
                          padding: 0,
                        }
                      : { width: "100%", padding: 0 }
                  }
                  value={uniqKeyColumns}
                  onChange={(e) => {
                    setUniqKeyColumns(e.target.value);
                    if (e.target.value.length == 0 && uploadBehavior == 3) {
                      setSelectUploadBehavior("");
                    }
                  }}
                  inputProps={{
                    className:
                      "form-control form-control-solid h-auto py-2 px-2 br-2 abc",
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                      {selected.map((item) => (
                        <Chip key={item} label={item} />
                      ))}
                    </Box>
                  )}
                  disableUnderline={true}
                  placeholder="Select unique key columns..."
                  MenuProps={{
                    style: {
                      maxWidth: 300,
                    },
                  }}
                >
                  {sheetHeaders.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox
                        checked={uniqKeyColumns?.indexOf?.(option) > -1}
                      />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
                {error?.uniqKeyColumns != undefined &&
                  error?.uniqKeyColumns != "" && (
                    <span className="text-danger">{error?.uniqKeyColumns}</span>
                  )}
              </div>
              <div className="row col-12">
                <div className="form-group fv-plugins-icon-container col-4">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isCompositive}
                        onChange={() => {
                          if (isCompositive) {
                            setIsCompositeUnique(false);
                          }
                          setIsCompositive(!isCompositive);
                        }}
                        value="checkedA"
                      />
                    }
                    label="Add Compositive Key"
                  />
                </div>
                <div className="form-group fv-plugins-icon-container col-4">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isHavingDisplayName}
                        onChange={() => {
                          setIsHavingDisplayName(!isHavingDisplayName);
                        }}
                        value=""
                      />
                    }
                    label="Is Having Display Name"
                  />
                </div>
                {isCompositive && (
                  <div className="form-group fv-plugins-icon-container col-4">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isCompositeUnique}
                          onChange={() => {
                            setIsCompositeUnique(!isCompositeUnique);
                          }}
                          value="checkedA"
                        />
                      }
                      label="Is Compositive Key is Unique"
                    />
                  </div>
                )}
              </div>

              {isCompositive && (
                <div className="col-12 row">
                  <div className="form-group fv-plugins-icon-container col-6">
                    <div className="form-group fv-plugins-icon-container">
                      <label
                        className={"form-label text-dark-50 font-weight-bolder"}
                      >
                        Composite Key Name
                      </label>
                      <input
                        style={
                          error.compositeKeyName
                            ? { border: "1px solid red" }
                            : {}
                        }
                        className={`form-control form-control-solid h-auto py-5 px-6`}
                        name="compositeKeyName"
                        value={compositeKeyName}
                        readOnly={editMode && data.compositeKeyName != ""}
                        placeholder="Enter Composite Key Name..."
                        onChange={(e) => setCompositeKeyName(e.target.value)}
                      />
                      {error?.compositeKeyName && (
                        <span className="text-danger">
                          {error?.compositeKeyName}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group fv-plugins-icon-container col-6">
                    <label className="form-label text-dark-50 font-weight-bolder">
                      Composite Key Options
                    </label>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      multiple
                      readOnly={editMode && data.compositeKeyOptions.length > 0}
                      style={
                        error.compositeKeyOptions
                          ? {
                              border: "1px solid red",
                              width: "100%",
                              padding: 0,
                            }
                          : { width: "100%", padding: 0 }
                      }
                      value={compositeKeyOptions}
                      onChange={(e) => {
                        setCompositeKeyOptions(e.target.value);
                      }}
                      inputProps={{
                        className:
                          "form-control form-control-solid h-auto py-2 px-2 br-2 abc",
                      }}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                          {selected.map((item) => (
                            <Chip key={item} label={item} />
                          ))}
                        </Box>
                      )}
                      disableUnderline={true}
                      placeholder="Select Composite Key Options..."
                      MenuProps={{
                        style: {
                          maxWidth: 300,
                        },
                      }}
                    >
                      {sheetHeaders.map((option) => (
                        <MenuItem key={option} value={option}>
                          <Checkbox
                            checked={
                              compositeKeyOptions?.indexOf?.(option) > -1
                            }
                          />
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.compositeKeyOptions != undefined &&
                      error?.compositeKeyOptions != "" && (
                        <span className="text-danger">
                          {error?.compositeKeyOptions}
                        </span>
                      )}
                  </div>
                </div>
              )}
            </div>

            {defaultHeaderValidation.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className=" row manual-input-box">
                    {defaultHeaderValidation.length > 0 && (
                      <span
                        className="inputclose_btn"
                        onClick={() => handleremoveInput(index)}
                      >
                        <CloseIcon />
                      </span>
                    )}

                    <div className="col-xs-12 col-md-6 form-group fv-plugins-icon-container">
                      <label
                        className={"form-label text-dark-50 font-weight-bolder"}
                      >
                        Select Header Name
                      </label>
                      <select
                        className="form-control form-control-solid h-auto py-5 px-6"
                        name="columnName"
                        value={item.columnName}
                        onChange={(e) => handleInputChange(index, e)}
                      >
                        <option value="">Select</option>
                        {sheetHeaders.map((header) => {
                          return (
                            <option key={header} value={header}>
                              {header}
                            </option>
                          );
                        })}
                      </select>
                      {errorValidationArray[index]?.columnName !== "" && (
                        <span className="text-danger">
                          {errorValidationArray[index]?.columnName}
                        </span>
                      )}
                    </div>
                    <div className="col-xs-12 col-md-6 form-group fv-plugins-icon-container">
                      <label
                        className={"form-label text-dark-50 font-weight-bolder"}
                      >
                        Select Data Type
                      </label>
                      <select
                        className="form-control form-control-solid h-auto py-5 px-6"
                        name="columnType"
                        value={item.columnType}
                        onChange={(e) => handleInputChange(index, e)}
                      >
                        <option value=""> Select</option>
                        {dataTypeList.map((typeList) => (
                          <option
                            key={typeList.dataType}
                            value={typeList.dataType}
                          >
                            {typeList.dataType}
                          </option>
                        ))}
                      </select>
                      {errorValidationArray[index]?.columnType !== "" && (
                        <span className="text-danger">
                          {errorValidationArray[index]?.columnType}
                        </span>
                      )}
                    </div>
                    {item.columnType == "Date" && (
                      <div className="col-xs-12 col-md-6 form-group fv-plugins-icon-container">
                        <label
                          className={
                            "form-label text-dark-50 font-weight-bolder"
                          }
                        >
                          Enter Formate
                        </label>

                        <select
                          className="form-control form-control-solid h-auto py-5 px-6"
                          name="dateFormat"
                          value={item.dateFormat}
                          onChange={(e) => handleInputChange(index, e)}
                        >
                          <option value=""> Select</option>
                          {dateFormateList.map((dateFormate) => (
                            <option key={dateFormate} value={dateFormate}>
                              {dateFormate}
                            </option>
                          ))}
                        </select>
                        {/* <input
                          placeholder="Enter Formate "
                          type="text"
                          value={item.dateFormat}
                          onChange={(e) => handleInputChange(index, e)}
                          className="form-control form-control-solid h-auto py-5 px-6 "
                          name={"dateFormat"}
                        /> */}
                        {errorValidationArray[index]?.dateFormat !== "" && (
                          <span className="text-danger">
                            {errorValidationArray[index]?.dateFormat}
                          </span>
                        )}
                      </div>
                    )}
                    <div className="col-xs-12 col-md-6 form-group fv-plugins-icon-container">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={item.isSecureField}
                            onChange={() =>
                              handleHeadingCheckBox(index, !item.isSecureField)
                            }
                            value="checkedA"
                          />
                        }
                        label="Use As Default "
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-0 mb-8">
        {defaultHeaderValidation.length == 0 && (
          <Button
            disabled={sheetHeaders.length == 0 || isLoading}
            onClick={handleHeaderValidation}
          >
            Add Header Validation
          </Button>
        )}
        {defaultHeaderValidation.length > 0 && (
          <Button onClick={addHeaderValidation} disabled={isLoading}>
            Add More Header Validation
          </Button>
        )}
        <Button
          className="w-100px btn-primary"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {editMode ? "Update" : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
