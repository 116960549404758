import React, { useState, useEffect } from "react";
import { RingLoader } from "react-spinners";
import UsaMapChart from "../../_metronic/_partials/organization/UsaMapChart";

import { uniq } from "lodash";
import Checkbox from "@material-ui/core/Checkbox";
import "./EditState.css";
import { IconButton, Tooltip } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";

const COUNTRY_REGION_MAP = {
  all: "all",
  northeast: [
    "Connecticut",
    "Maine",
    "Massachusetts",
    "New Hampshire",
    "Rhode Island",
    "Vermont",
    "New Jersey",
    "New York",
    "Pennsylvania",
  ],
  southeast: [
    "Delaware",
    "Florida",
    "Georgia",
    "Maryland",
    "North Carolina",
    "South Carolina",
    "Virginia",
    "West Virginia",
    "Alabama",
    "Kentucky",
    "Mississippi",
    "Tennessee",
    "Arkansas",
    "Louisiana",
    "Oklahoma",
    "Texas",
  ],
  midwest: [
    "Illinois",
    "Indiana",
    "Michigan",
    "Ohio",
    "Wisconsin",
    "Iowa",
    "Kansas",
    "Minnesota",
    "Missouri",
    "Nebraska",
    "North Dakota",
    "South Dakota",
  ],
  west: [
    "Alaska",
    "Arizona",
    "California",
    "Colorado",
    "District of Columbia",
    "Hawaii",
    "Idaho",
    "Montana",
    "Nevada",
    "New Mexico",
    "Oregon",
    "Puerto Rico",
    "Utah",
    "Wyoming",
    "Washington",
  ],
};

export const EditState = ({
  state,
  allState,
  onStateChange,
  manualSelectedStates,
  setManualSelectedStates,
  manualUnselectedStates,
  setManualUnselectedStates,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [checkStatus, setCheckStatus] = useState({
    all: false,
  });
  const [listGroupBy, setListGroupBy] = useState([]);

  useEffect(() => {
    const stateGroups = uniq(allState.map((prd) => prd?.Region));
    let newCheckStatus = { all: false };
    stateGroups.map((item) => {
      newCheckStatus[item.toLowerCase()] = false;
    });
    setListGroupBy(stateGroups);
    setCheckStatus(newCheckStatus);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (checkStatus.all) {
      onStateChange(allState, false);
    } else if (Object.values(checkStatus).find((item) => item == true)) {
      const selectedStates = Object.keys(checkStatus).reduce(
        (o_selected_state, key) => {
          if (key !== "all") {
            if (checkStatus[key]) {
              const a_state = allState.filter(
                (o_state) =>
                  o_state?.Region.toLowerCase() == key &&
                  !manualUnselectedStates.find(
                    (u_state) => u_state?.StateName === o_state?.StateName
                  )
              );
              o_selected_state = [...o_selected_state, ...a_state];
            }
          }
          return o_selected_state;
        },
        []
      );
      onStateChange([...selectedStates, ...manualSelectedStates], false);
    } else {
      let selectedState = [];
      allState.map((item) => {
        if (item.isSelected) {
          selectedState.push({
            StateName: item?.StateName,
            _id: item._id,
          });
        }
      });
      onStateChange(selectedState, true);
    }
  }, [checkStatus]);

  const handleStateChange = (stateName) => {
    
    if (!stateName) {
      return;
    }
    if (
      !state?.find(
        (o_state) =>
          o_state?.StateName?.toLowerCase() === stateName?.toLowerCase()
      )
    ) {
      const newState = allState.find(
        (o_state) => o_state.StateName.toLowerCase() === stateName.toLowerCase()
      );
      if (newState) {
        const updatedState = [...state, newState];
        setManualSelectedStates([...manualSelectedStates, newState]);
        manualUnselectedStates = manualUnselectedStates.filter(
          (o_state) =>
            o_state.StateName.toLowerCase() !== stateName.toLowerCase()
        );
        setManualUnselectedStates([...manualUnselectedStates]);
        onStateChange(updatedState);
      }
    } else {
      manualSelectedStates = manualSelectedStates.filter(
        (o_state) => o_state.StateName.toLowerCase() !== stateName.toLowerCase()
      );
      setManualSelectedStates([...manualSelectedStates]);
      const newState = allState.find(
        (o_state) => o_state.StateName.toLowerCase() === stateName.toLowerCase()
      );
      if (newState) {
        setManualUnselectedStates((oldState) => [...oldState, newState]);
      }
      state = state.filter(
        (o_state) => o_state.StateName.toLowerCase() !== stateName.toLowerCase()
      );
      onStateChange(state);
    }
  };

  const handleChange = (id, e) => {

    setCheckStatus((oldValue) => {
      if (id === "all" && e.target.checked) {
        let newCheckStatus = { all: true };

        Object.keys(checkStatus).forEach((item) => {
          newCheckStatus[item.toLowerCase()] = true;
          return true
        });

        return newCheckStatus;
      }

      if (id === "all" && !e.target.checked) {
        let newCheckStatus = { all: false };
        Object.keys(checkStatus).forEach((item) => {
          newCheckStatus[item.toLowerCase()] = false;
          return true;
        });

        return newCheckStatus;
      }

      if (e.target.checked) {
        const hasAll = Object.keys(checkStatus).reduce((b_has_all, key) => {
          if (key === "all" || key.toLowerCase() === id.toLowerCase()) {
            return b_has_all;
          }
          if (!checkStatus[key]) {
            b_has_all = false;
          }
          return b_has_all;
        }, true);

        if (hasAll) {
          return { ...oldValue, [id]: e.target.checked, all: true };
        }
      } else if (id !== "all" && checkStatus.all) {
        return { ...oldValue, [id]: e.target.checked, all: false };
      }
      return { ...oldValue, [id]: e.target.checked };
    });
  };

  return isLoading ? (
    <div className="loader_wrapper">
      <RingLoader color={"darkcyan"} loading={true} size={40} />
    </div>
  ) : (
    // <div className={`card card-custom`}>
    //   <div className="card-header border-0 py-8 custom-header">
    //     <h3 className="card-title flex-column ">
    //       <span className="card-label font-weight-bolder text-dark">
    //         Please update selling state
    //       </span>
    //     </h3>
    //   </div>
    <>
      <div className="custom-map-checkbox">
        <div>
          {listGroupBy.length > 0 && (
            <>
              <Checkbox
                id="all"
                checked={checkStatus?.all}
                onChange={handleChange.bind(this, "all")}
              />
              <label htmlFor="all" className="all-country-label">
                All
              </label>
              {listGroupBy.map((item, index) => {
                return (
                  <React.Fragment key={item + "_" + index}>
                    <Checkbox
                      id={item + "_" + index}
                      checked={checkStatus[item.toLowerCase()]}
                      onChange={handleChange.bind(this, item.toLowerCase())}
                    />
                    <label htmlFor={item + "_" + index}>{item}</label>
                  </React.Fragment>
                );
              })}
            </>
          )}
        </div>
        <div style={{ marginLeft: 5, marginTop: -5 }}>
          <Tooltip
            title="Reset state"
            PopperProps={{
              className: "refresh-view-tooltip",
            }}
          >
            <IconButton
              className="btn btn-icon btn-light btn-hover-primary btn-sm"
              onClick={() => {
                setCheckStatus({
                  all: false,
                  northeast: false,
                  southeast: false,
                  midwest: false,
                  west: false,
                });
                setManualSelectedStates([]);
                setManualUnselectedStates([]);
                onStateChange([]);
              }}
              aria-label="detail"
              style={{
                marginLeft: 3,
                marginTop: 7,
                marginRight: 10,
                marginBottom: 26,
                height: 34,
                width: 34,
                borderRadius: 5,
                color: "#3699FF",
              }}
            >
              <Refresh className="reset" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="usa_map">
        <div className="card-body py-0" style={{ maxWidth: 820 }}>
          <UsaMapChart state={state || []} onStateChange={handleStateChange} />
        </div>
      </div>
    </>
    // </div>
  );
};
