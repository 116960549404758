import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { RingLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_helpers";
import { AddUserDialog } from "../../modals/adduser-dialog/AddUserDialog";
import { ConfirmDeleteDialog } from "../../modals/confirmdelete-dialog/ConfirmDeleteDialog";
import { ResetPassword } from "../../modals/ResetPassword/ResetPassword";
import HelpIcon from "../../Help/HelpIcon";
import {
  getUserIsLoading,
  getUsersList,
} from "../../../../app/modules/Users/_redux/usersSelectors";
import { actions as Users } from "../../../../app/modules/Users/_redux/usersRedux";

export function UsersTableWidget1({ className }) {
  const dispatch = useDispatch();

  const users = useSelector(getUsersList);
  const isLoading = useSelector(getUserIsLoading);

  const [modalDelete, setModalDelete] = useState({
    visiblity: false,
    id: "",
  });
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const handleHide = () => {
    setSelectedUser({});
    setShowResetPassword(false);
  };

  /**
   * To Fetch Users
   */
  useEffect(() => {
    dispatch(Users.getUsersList());
  }, []);

  /**
   * To Set Add Update Modal Data
   */
  const toggleModalFn = (show, data) => {
    setModalShow(show);
    setModalData(data);
  };

  /**
   * To Delete User
   */
  const deleteUserFn = (id) => {
    dispatch(Users.delete(id));
  };

  return (
    <div className={`card card-custom ${className}`}>
      {/* begin::Header */}
      {isLoading && (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      )}
      <div className="card-header border-0 py-5 custom-header align-items-center">
        <h3 className="card-title flex-column m-0">
          <span className="card-label font-weight-bolder text-dark">Users</span>
        </h3>
        <div className="card-toolbar">
          <a
            onClick={() => toggleModalFn(true, {})}
            className="btn btn-success font-weight-bolder font-size-sm"
          >
            <span className="svg-icon svg-icon-md svg-icon-white">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
                className="h-50 align-self-center"
              ></SVG>
            </span>
            Add New User
          </a>
          <AddUserDialog
            show={modalShow}
            toggleModal={(value) => setModalShow(value)}
            data={modalData}
          />
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-0">
        {/* begin::Table */}
        <div className="table-responsive">
          <table
            className="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr className="text-left">
                <th className="pl-0" style={{ minWidth: "200px" }}>
                  Name
                </th>
                <th style={{ minWidth: "250px" }}>email</th>
                <th>User Type</th>
                <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                  action
                </th>
              </tr>
            </thead>
            <tbody>
              {users?.length > 0 ? (
                users?.map((user) => (
                  <tr key={`${user?.firstName?.toLowerCase()}-${user?._id}`}>
                    <td className="pl-0 py-2">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <span className="svg-icon h-75 align-self-end">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/User.svg"
                                )}
                              />
                            </span>
                          </span>
                        </div>
                        <div>
                          <Link to={`/users/${user._id}`}>
                            <a className="text-dark-75 font-weight-bolder text-capitalize text-hover-primary d-block mb-1 font-size-lg">
                              {`${user?.firstName} ${user?.lastName}`}
                            </a>
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {user?.email}
                      </span>
                    </td>
                    <td>
                      <span
                        style={{ textTransform: "capitalize" }}
                        className="text-dark-50 font-weight-bolder d-block font-size-lg"
                      >
                        {user?.userRole?.join?.(",")}
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      {/* <Link
                        to={`/travers-history/${user?._id}`}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Settings-1.svg"
                            )}
                          ></SVG>
                        </span>
                      </Link> */}
                      <a
                        onClick={() => {
                          setSelectedUser(user);
                          setShowResetPassword(true);
                        }}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm lock-icon"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/ico_lock.svg")}
                          ></SVG>
                        </span>
                      </a>
                      <a
                        onClick={() => {
                          let { _id: id, createdAt, updatedAt, ...rest } = user;
                          toggleModalFn(true, { ...rest, id });
                        }}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>
                      <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                        onClick={() =>
                          setModalDelete((state) => ({
                            ...state,
                            visiblity: true,
                            id: user?._id,
                          }))
                        }
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="pl-0 py-2 text-center" colSpan={3}>
                    <span className="svg-icon d-block p-3 nodata__icon">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/ico_nodata.svg")}
                      ></SVG>
                    </span>
                    <h3 className="text-dark font-weight-bolder d-block mb-0">
                      No Record Found
                    </h3>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* end::Table */}
        {/* Delete Modal */}
        <ConfirmDeleteDialog
          show={modalDelete.visiblity}
          toggleModal={(value) =>
            setModalDelete((state) => ({ ...state, visiblity: value }))
          }
          deleteFn={() => deleteUserFn(modalDelete.id)}
        />
        <ResetPassword
          show={showResetPassword}
          onHide={handleHide}
          user={selectedUser}
        />
      </div>
      {/* end::Body */}

      <HelpIcon pageName="UserSection" />
    </div>
  );
}
