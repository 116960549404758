import React, { useEffect, useState } from "react";
import { FileVariable } from "../FileVariable";
import { useDispatch, useSelector } from "react-redux";
import { Switch, FormControlLabel } from "@material-ui/core";
import { actions as DIAGRAM_ACTIONS } from "../../../../../app/modules/Diagram/_redux/DiagramRedux";
import { setToast } from "../../../../../app/modules/toast";

const Conditional = ({ formData, save, localVariableArray }) => {
  const dispatch = useDispatch();
  const [shortLabelCount, setShortLabelCount] = useState(0);
  const serverProcedureVariables = useSelector(
    (store) => store.diagram.procedureVariables
  );
  const operators = useSelector((store) => store.diagram.operators);
  const [variables, setVariableArray] = useState([]);
  const [errors, setError] = useState({});
  useEffect(() => {
    dispatch(DIAGRAM_ACTIONS.getProcedureVariableListAPI("Procedure"));
    dispatch(DIAGRAM_ACTIONS.getOperatorListAPI("Conditional"));
  }, []);

  useEffect(() => {
    let newVariablesArray = serverProcedureVariables;
    localVariableArray.map((variable) => {
      let findIndex = serverProcedureVariables.findIndex(
        (item, index) => item.name === variable.variableName
      );

      if (findIndex < 0 && variable.category !== "MultiOption") {
        newVariablesArray.push({
          key: variable.key,
          name: variable.variableName,
          label: variable.variableName,
        });
      }
    });
    if (newVariablesArray.length > 0) {
      newVariablesArray.sort((variableFirst, variableSecond) => {
        const variableLabelFirst = variableFirst?.label?.toLowerCase(); // Convert names to lowercase for case-insensitive sorting
        const variableLabelSecond = variableSecond?.label?.toLowerCase();

        if (variableLabelFirst < variableLabelSecond) return -1;
        if (variableLabelFirst > variableLabelSecond) return 1;
        return 0;
      });
    }

    setVariableArray(newVariablesArray);
  }, [serverProcedureVariables]);
  const [inputs, setInputs] = useState({
    selectInputOne: { variableName: "", fileInput: "" },
    selectInputTwo: { variableName: "", fileInput: "", textValue: "" },
    operator: "",
    type: "question",
    label: "",
    equation: "",
    shortLabel: "",
  });

  useEffect(() => {
    if (formData !== undefined) {
      setInputs({ ...formData });
      if (formData.shortLabel != "") {
        setShortLabelCount(formData?.shortLabel?.length);
      }
    }
  }, [formData]);

  const [filterVariable, setFilterVariable] = useState([]);
  const [showFilterVariable, setShowFilterVariable] = useState(false);
  const handleVariableSelection = (event) => {
    let value = event.target.value;
    let oldInput = inputs;
    Object.freeze(oldInput?.selectInputTwo);
    let twoInput = { ...oldInput?.selectInputTwo };
    twoInput.textValue = value;
    oldInput.selectInputTwo = twoInput;
    setInputs(oldInput);

    if (value != "") {
      setShowFilterVariable(true);
      let newfilterVariable = serverProcedureVariables.filter((variable) => {
        return variable?.name?.toLowerCase()?.includes(value?.toLowerCase());
      });
      setFilterVariable(newfilterVariable);
    } else {
      setShowFilterVariable(false);
    }
  };
  const handleSelectVariable = (variable) => {
    setShowFilterVariable(false);
    setInputs((state) => ({ ...state, value: variable.name }));
  };

  const saveForm = () => {
    if (inputs.type === "equation") {
      let inputOne = "";
      let inputTwo = "";
      let operatorsValue = inputs.operator;
      if (inputs.selectInputOne.variableName != "useFileVariable") {
        inputOne = inputs.selectInputOne.variableName;
        if (inputOne == "") {
          setToast("Please Select All Fields", "error", "top-center");
          return;
        }
      } else {
        inputOne = inputs.selectInputOne.fileInput;
        let inputOneArray = inputOne.split(":");
        if (inputOneArray.length != 4 || inputOneArray.includes("")) {
          setToast("Please Select all File Fields", "error", "top-center");
          return;
        }
      }
      if (inputs.selectInputTwo.variableName == "useFileVariable") {
        inputTwo = inputs.selectInputTwo.fileInput;
        let inputTwoArray = inputTwo.split(":");
        if (inputTwoArray.length != 4 || inputTwoArray.includes("")) {
          setToast("Please Select all File Fields", "error", "top-center");
          return;
        }
      } else if (inputs.selectInputTwo.variableName == "useText") {
        inputTwo = inputs.selectInputTwo.textValue;
        if (inputTwo == "") {
          setToast("Please Add Text Value", "error", "top-center");
          return;
        }
      } else {
        inputTwo = inputs.selectInputTwo.variableName;
        if (inputTwo == "") {
          setToast("Please Select All Fields", "error", "top-center");
          return;
        }
      }
      inputs.equation = `${inputOne}${operatorsValue}${inputTwo}`;
    } else if (
      inputs.type === "question" &&
      (inputs.label === "" || inputs.shortLabel === "")
    ) {
      setToast("Please fill values in form", "error", "top-center");
      return;
    }
    if (inputs.shortLabel == "" || inputs.shortLabel.length > 25) {
      setToast(
        "Please Provide Short Label only with 25 characters",
        "error",
        "top-center"
      );
      return;
    }
    save(inputs);
  };

  const handleChangeSelection = () => {
    if (inputs.type === "question") {
      setInputs({ ...inputs, label: "", type: "equation" });
    } else {
      setInputs({
        ...inputs,
        variableName: "",
        equation: "",
        value: "",
        function: "",
        type: "question",
      });
    }
  };
  return (
    <form className="py-5 px-5 form-col modal-form">
      <div className="form-group fv-plugins-icon-container">
        <label className="form-label text-dark-50 font-weight-bolder">
          Short Label
          <small>
            {" "}
            <span className={shortLabelCount > 25 ? "error" : "info"}>
              ({shortLabelCount} / 25 charaters)
            </span>
          </small>
        </label>
        <input
          type="text"
          placeholder="Short Label"
          className={`form-control form-control-solid h-auto py-5 px-6`}
          name="text"
          value={inputs?.shortLabel}
          onChange={(e) => {
            let shortLabel = e.target.value;
            setInputs((state) => ({ ...state, shortLabel }));
            setShortLabelCount(shortLabel.length);
          }}
        />
      </div>
      <FormControlLabel
        control={
          <Switch
            checked={inputs.type === "question"}
            onChange={handleChangeSelection}
            value="checkedA"
          />
        }
        label="Question"
      />
      {(inputs.type === "equation" || inputs.type === "") && (
        <>
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Select Input :
            </label>
            <select
              className="form-control form-control-solid h-auto py-5 px-6"
              name="variable"
              onChange={(e) => {
                let variableName = e.target.value;
                setInputs((state) => ({
                  ...state,
                  selectInputOne: { variableName, fileInput: "" },
                }));
              }}
              value={inputs?.selectInputOne?.variableName}
            >
              <option value="">Select Variable</option>
              {variables.map((variable) => {
                return (
                  <option
                    key={variable.key + "_" + variable.name}
                    value={variable.name}
                  >
                    {variable.label} {variable.key != undefined ? "" : "*"}
                  </option>
                );
              })}
              <option value="useFileVariable">Use File Variable</option>
            </select>
          </div>
          {inputs?.selectInputOne?.variableName == "useFileVariable" && (
            <>
              <FileVariable
                singleSelectVariables={[]}
                useFileVariable={true}
                selectedValue={inputs?.selectInputOne?.fileInput.split(":")}
                setFileVariable={(data) => {
                  let oldInput = inputs;
                  Object.freeze(oldInput?.selectInputOne);
                  let oneFileInput = { ...oldInput?.selectInputOne };
                  oneFileInput.fileInput = data;
                  oldInput.selectInputOne = oneFileInput;
                  setInputs(oldInput);
                }}
              />
            </>
          )}
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Operators :
            </label>
            <select
              className="form-control form-control-solid h-auto py-5 px-6"
              name="function"
              onChange={(e) => {
                let operatorsValue = e.target.value;
                setInputs((state) => ({ ...state, operator: operatorsValue }));
              }}
              value={inputs?.operator}
            >
              <option value="">Select Operator</option>
              {operators.map((v) => (
                <option key={v} value={v}>
                  {v}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Select Input :
            </label>
            <select
              className="form-control form-control-solid h-auto py-5 px-6"
              name="variable"
              onChange={(e) => {
                let variableName = e.target.value;
                setInputs((state) => ({
                  ...state,
                  selectInputTwo: {
                    variableName,
                    fileInput: "",
                    textValue: "",
                  },
                }));
              }}
              value={inputs?.selectInputTwo?.variableName}
            >
              <option value="">Select Variable</option>
              {variables.map((variable) => {
                return (
                  <option
                    key={variable.key + "_" + variable.name}
                    value={variable.name}
                  >
                    {variable.label} {variable.key != undefined ? "" : "*"}
                  </option>
                );
              })}
              <option value="useFileVariable">Use File Variable</option>
              <option value="useText">Use Text</option>
            </select>
            {inputs?.selectInputTwo?.variableName == "useFileVariable" && (
              <>
                <FileVariable
                  singleSelectVariables={[]}
                  useFileVariable={true}
                  selectedValue={inputs?.selectInputTwo?.fileInput.split(":")}
                  setFileVariable={(data) => {
                    let oldInput = inputs;
                    Object.freeze(oldInput?.selectInputTwo);
                    let oneFileInput = { ...oldInput?.selectInputTwo };
                    oneFileInput.fileInput = data;
                    oldInput.selectInputTwo = oneFileInput;
                    setInputs(oldInput);
                  }}
                />
              </>
            )}
            {inputs?.selectInputTwo?.variableName == "useText" && (
              <div className="mt-5">
                <label className="form-label text-dark-50 font-weight-bolder">
                  Enter Value :
                </label>
                <input
                  type="text"
                  placeholder="Value"
                  className={`form-control form-control-solid h-auto py-5 px-6 `}
                  name="text"
                  value={inputs?.selectInputTwo.textValue}
                  onChange={(e) => handleVariableSelection(e)}
                />
              </div>
            )}
            {errors?.valueValidationError && (
              <span class="text-danger">Please enter valid value</span>
            )}
            {showFilterVariable && filterVariable.length > 0 && (
              <ul className="suggestion__block">
                {filterVariable.map((v) => (
                  <li onClick={() => handleSelectVariable(v)} key={v._id}>
                    {v.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
      )}

      {inputs.type === "question" && (
        <>
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Question :
            </label>
            <input
              type="text"
              placeholder="Question"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="text"
              value={inputs?.label}
              onChange={(e) => {
                let label = e.target.value;

                setInputs((state) => ({ ...state, label }));
              }}
            />
          </div>
        </>
      )}
      <div className="btn-block">
        <button
          type="button"
          className="btn btn-primary w-100px"
          onClick={() => saveForm()}
        >
          {" "}
          Save
        </button>
      </div>
    </form>
  );
};

export default Conditional;
