import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RingLoader from "react-spinners/RingLoader";
import { actions as Questions } from "../../../app/modules/Question/_redux/questionsRedux";

export function ToolboxDetails({ className }) {
  const dispatch = useDispatch();
  let history = useHistory();
  const { id } = useParams();
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openCopyBox, setOpenCopyBox] = useState(false);
  const redirect = useSelector((store) => store.questions.redirect);
  const loader = useSelector((store) => store.questions.isLoading);
  const questionDetails = useSelector(
    (store) => store.questions.questionDetails
  );
  const [topicId, setTopicId] = useState(questionDetails?.topicId);
  const subTopicDetails = useSelector((store) => store.subtopics.topicDetails);

  const toolBoxLists = useSelector(
    (store) => store.subtopics.topicDetails.subTopics
  );
  const [copyToolBoxName, setCopyToolBoxName] = useState(
    "Copy " + questionDetails?.text
  );
  useEffect(() => {
    if (redirect) {
      history.push(`/toolbox`);
      dispatch(Questions.isRedirect(false));
    }
  }, [redirect]);

  useEffect(() => {
    if (id != questionDetails?._id) {
      setCopyToolBoxName("Copy " + questionDetails?.text);
      setTopicId(questionDetails?.topicId);
      history.push({
        pathname: `/toolbox/${questionDetails?._id}`,
        state: {
          subtopicId: questionDetails?.topicId,
        },
      });
    }
  }, [questionDetails]);

  const deleteToolConfirmation = () => {
    setOpenDeleteAlert(false);
    dispatch(Questions.deleteQuestion(id, questionDetails?.topicId));
  };

  const handleSaveCopy = () => {
    let copyDetails = {
      text: copyToolBoxName,
      toolId: id,
      topicId: topicId,
    };
    dispatch(Questions.copyTool(copyDetails));
    setOpenCopyBox(false);
  };
  return (
    <div className={`card card-custom ${className}`}>
      {loader && (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      )}
      <div className="card-header py-2 custom-header align-items-center">
        <h3 className="card-title flex-column m-0">
          <span className="card-label font-weight-bolder text-dark">
            {questionDetails?.text}
          </span>
        </h3>
        <div className="card-toolbar">
          <Button
            onClick={(e) => {
              setOpenCopyBox(true);
            }}
            className="btn btn-danger font-weight-bolder font-size-sm "
          >
            Make a Copy
          </Button>
          <Link
            onClick={(e) => {
              dispatch(Questions.isRedirect(false));
            }}
            // to={`/toolbox/edit/${id}`}
            to={{
              pathname: `/toolbox/edit/${id}`,
              state: {
                subtopicId: questionDetails.topicId,
                topicId: subTopicDetails?._id,
              },
            }}
            className="btn btn-success font-weight-bolder font-size-sm w-100px ml-5"
          >
            Edit
          </Link>
          <Button
            onClick={(e) => {
              setOpenDeleteAlert(true);
            }}
            className="btn btn-danger font-weight-bolder font-size-sm w-100px ml-5"
          >
            Delete
          </Button>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-2 ToolboxDetails__img">
            <img
              src={
                questionDetails?.icon_logo != "" &&
                questionDetails?.icon_logo != undefined
                  ? questionDetails?.icon_logo
                  : "/media/svg/upload_icon.svg"
              }
              alt="uploadimg"
            />
          </div>
          <div className="col-12 col-md-10 ToolboxDetails__contant__col">
            <div className="detail__contant d-flex align-items-center py-2">
              <span className="text-dark-75 font-weight-bolder mr-3 font-size-lg">
                Screen Tag line:
              </span>
              <span className="text-muted">
                {questionDetails?.screen_tagline}
              </span>
            </div>
            <div className="detail__contant d-flex align-items-center py-2">
              <span className="text-dark-75 font-weight-bolder mr-3 font-size-lg">
                Assumption:
              </span>
              <span
                className="text-muted"
                dangerouslySetInnerHTML={{
                  __html: questionDetails?.assumptions,
                }}
              ></span>
            </div>
            <div className="detail__contant d-flex align-items-center py-2">
              <span className="text-dark-75 font-weight-bolder mr-3 font-size-lg">
                Description:
              </span>
              <span
                className="text-muted"
                dangerouslySetInnerHTML={{
                  __html: questionDetails?.description,
                }}
              ></span>
            </div>

            <Link
              to={`/diagram/${id}`}
              className="btn btn-primary font-weight-bolder font-size-lg mt-4"
            >
              Design Studio
            </Link>
          </div>
        </div>
      </div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openDeleteAlert}
        onHide={() => {
          setOpenDeleteAlert(false);
        }}
      >
        <Modal.Body>
          <h5 className="mt-2 pb-4 text-center">
            Are you sure want to Delete Tool?
          </h5>
          <div className="mb-0 p-0 d-flex justify-content-center">
            <Button
              variant="secondary"
              className="w-100px mr-2"
              onClick={() => {
                setOpenDeleteAlert(false);
              }}
            >
              Close
            </Button>
            <Button
              variant="danger"
              className="w-100px"
              onClick={() => {
                deleteToolConfirmation();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openCopyBox}
        onHide={() => {
          setOpenCopyBox(false);
        }}
      >
        <Modal.Body>
          <h3 className="mt-2 pb-6">Make a Copy</h3>

          <IconButton
            position="top right"
            aria-label="close"
            onClick={() => {
              setOpenCopyBox(false);
            }}
            style={{
              position: "absolute",
              zIndex: 1111,
              right: 22,
              top: 17,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="form-group fv-plugins-icon-container ">
            <label className="form-label text-dark-50 font-weight-bolder">
              Select ToolBox
            </label>

            <select
              className="form-control form-control-solid h-auto py-5 px-6"
              name="topic"
              onChange={(e) => {
                let variableName = e.target.value;
                setTopicId(variableName);
              }}
              value={topicId}
            >
              <option value="">Select ToolBox</option>
              {toolBoxLists.map((toolBox) => {
                return (
                  <option key={toolBox._id} value={toolBox._id}>
                    {toolBox.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group fv-plugins-icon-container mb-0">
            <label className="form-label text-dark-50 font-weight-bolder">
              Give Copied Tool New Name
            </label>
            <input
              placeholder="Give Copied Tool New Name..."
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="label"
              onChange={(event) => {
                setCopyToolBoxName(event.target.value);
              }}
              value={copyToolBoxName}
              maxLength="100"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center pt-0 mb-8">
          <Button className="w-100px btn-primary" onClick={handleSaveCopy}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
