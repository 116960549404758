import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import RingLoader from "react-spinners/RingLoader";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { actions as SUBTOPIC_ACTIONS } from "../../../../app/modules/SubTopic/_redux/subtopicsRedux";
import { getToolBoxSchema } from "../../../_utils";
import { Text } from "../../../_partials/formik-form-fields";

export function AddSubTopicDialog({
  onHide,
  isUpdate,
  id,
  data,
  parentTopic,
  ...rest
}) {
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const isLoading = useSelector((store) => store.subtopics.isLoading);
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      Object.keys(data).forEach((field) => {
        formik.setFieldValue(field, data[field] != null ? data[field] : "");
      });
    }
    return () => {
      Object.keys(initialValues).forEach((field) => {
        formik.setFieldValue(
          field,
          initialValues[field] != null ? initialValues[field] : ""
        );
      });
    };
  }, [data]);

  useEffect(() => {
    if (!isLoading && isSubmit) {
      onHide();
    }
  }, [isLoading]);
  const initialValues = {
    name: "",
    tagLine: "",
    question: "",
    solution: "",
    tool_image: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getToolBoxSchema(data),
    onSubmit: ({ name, tagLine, question, solution, tool_image }) => {
      setIsSubmit(true);
      if (isUpdate) {
        dispatch(
          SUBTOPIC_ACTIONS.updateSubtopic(id, parentTopic, {
            name,
            tagLine,
            question,
            solution,
            tool_image,
          })
        );
      } else {
        dispatch(
          SUBTOPIC_ACTIONS.addSubtopic(
            { name, tagLine, question, solution, tool_image },
            parentTopic
          )
        );
      }
    },
  });

  const handleSubmit = () => {
    formik.validateForm();
    formik.handleSubmit();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">{`${
          isUpdate ? "Update" : "Add"
        } Toolbox`}</h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={onHide}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          encType="multipart/form-data"
        >
          {isLoading && (
            <div className="loader_wrapper">
              <RingLoader color={"darkcyan"} loading={true} size={40} />
            </div>
          )}
          <div className="form-group fv-plugins-icon-container ">
            <Text
              label="Toolbox name"
              labelClass="form-label text-dark-50 font-weight-bolder"
              name="name"
              inputClass="form-control form-control-solid h-auto py-5 px-6"
              formik={formik}
              placeholder="Enter Toolbox Name"
            />
          </div>
          <div className="form-group fv-plugins-icon-container ">
            <Text
              label="Tag Line"
              labelClass="form-label text-dark-50 font-weight-bolder"
              name="tagLine"
              inputClass="form-control form-control-solid h-auto py-5 px-6"
              formik={formik}
              placeholder="Enter tagLine"
            >
              <span
                className={
                  formik.values["tagLine"].length > 80 ? "error" : "info"
                }
              >
                {formik.values["tagLine"].length} / 80 characters
              </span>
            </Text>
          </div>
          <div className="form-group fv-plugins-icon-container ">
            <Text
              label="Question"
              labelClass="form-label text-dark-50 font-weight-bolder"
              name="question"
              inputClass="form-control form-control-solid h-auto py-5 px-6"
              formik={formik}
              placeholder="Enter Question"
            >
              <span
                className={
                  formik.values["question"].length > 50 ? "error" : "info"
                }
              >
                {formik.values["question"].length} / 50 characters
              </span>
            </Text>
          </div>
          <div className="form-group fv-plugins-icon-container ">
            <Text
              label="Solution"
              labelClass="form-label text-dark-50 font-weight-bolder"
              name="solution"
              inputClass="form-control form-control-solid h-auto py-5 px-6"
              formik={formik}
              placeholder="Enter solution"
            >
              <span
                className={
                  formik.values["solution"].length > 100 ? "error" : "info"
                }
              >
                {formik.values["solution"].length} / 100 characters
              </span>
            </Text>
          </div>

          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Upload Icon
            </label>
            <div className="toolbox__uploadfile">
              {/* <span className="uploadicon"></span> */}
              <div className="uploadimg">
                {formik.values["tool_image"] != "" &&
                formik.values["tool_image"] != null ? (
                  <img src={formik.values["tool_image"]} alt="uploadimg" />
                ) : (
                  <img src="/media/svg/upload_icon.svg" alt="uploadimg" />
                )}
              </div>
              <label className="mb-0 w-100" htmlFor="actual-btn">
                <input
                  className={`form-control form-control-solid h-auto py-5 px-6`}
                  id="actual-btn"
                  accept="image/png, image/jpeg"
                  name="tool_image"
                  type="file"
                  onChange={(event) => {
                    getBase64(event.currentTarget.files[0]).then((result) => {
                      formik.setFieldValue("tool_image", result);
                    });
                  }}
                />
                <span className="file__browse" htmlFor="actual-btn">
                  Browse
                </span>
              </label>
            </div>
            {formik?.errors?.["tool_image"] &&
              formik?.touched?.["tool_image"] && (
                <span className="text-danger">
                  {formik?.errors?.["tool_image"]}
                </span>
              )}
          </div>
          {/*<label className="form-label text-dark-50 font-weight-bolder">
              Toolbox Name
            </label> <input
              placeholder="Toolbox name..."
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="text"
              value={input}
              onChange={handleInputChange}
              maxLength="100"
            /> {errors?.noInput && (
              <span class="text-danger">Please enter Toolbox name</span>
            )}
            {errors?.valueValidationError && (
              <span class="text-danger">Please enter valid value</span>
            )}*/}

          <Button className="w-100px btn-primary" type="submit">
            Submit
          </Button>
        </form>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-0 mb-8"></Modal.Footer>
    </Modal>
  );
}
