import React, { useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";
import "./WizardStep.scss";

const ActionButtons = (props) => {
  const handleBack = () => {
    props.previousStep();
  };

  const handleNext = () => {
    props.nextStep();
  };

  const handleFinish = () => {
    props.lastStep();
  };

  return (
    <div className="step__btns__block">
      {props.currentStep > 0 && (
        <div className="action__btn">
          <button className="btn__back" onClick={handleBack}></button>
        </div>
      )}
      <div className="action__btn">
        {props.currentStep < props.totalSteps && (
          <button className="btn__next" onClick={handleNext}></button>
        )}
        {props.currentStep === props.totalSteps && (
          <button
            className="btn btn-primary btn__update"
            onClick={handleFinish}
          >
            Update
          </button>
        )}
      </div>
    </div>
  );
};

const One = (props) => {
  return (
    <>
      <ActionButtons {...props} />
    </>
  );
};

const Two = (props) => {
  const handleLastStep = () => {
    props.onUpdate();
  };

  return (
    <div className="StepTwo">
      <ActionButtons {...props} lastStep={handleLastStep} />
    </div>
  );
};

const WizardStep = ({ activeStep, onStepChange, onUpdate }) => {
  const [stepWizard, setStepWizard] = useState(null);
  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  const handleStepChange = (e) => {
    onStepChange(e.activeStep - 1);
  };

  return (
    <div className="WizardStep__com__main">
      <div className="WizardStep__container">
        <Stepper activeStep={activeStep}>
          <Step label="Step 1" />
          <Step label="Step 2" />
        </Stepper>
        <StepWizard
          className="step__arrows"
          instance={assignStepWizard}
          onStepChange={handleStepChange}
        >
          <One />
          <Two onUpdate={onUpdate} />
        </StepWizard>
      </div>
    </div>
  );
};

export default WizardStep;
