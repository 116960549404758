import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";
import * as SUBTOPIC_SERVICES from "../../../../_service/subtopics/subtopics.service";


export const actionTypes = {
  ADD_SUBTOPIC: "[ADD_SUBTOPIC] Action",
  UPDATE_SUBTOPIC: "[UPDATE_SUBTOPIC] Action",
  DELETE_SUBTOPIC: "[DELETE_SUBTOPIC] Action",
  LIST_SUBTOPIC: "[LIST_SUBTOPIC] Action",
  LOADING_SUBTOPIC: "[LOADING_SUBTOPIC] Action",
  ERROR_SUBTOPIC: "[ERROR_SUBTOPIC] Action",
  GET_TOPIC_DETAILS: "[GET_TOPIC_DETAILS] Action",
  FETCH_TOPIC_DETAILS: "[FETCH_TOPIC_DETAILS] Action",
};

const initialState = {
  topicDetails: {
    subTopics: [],
  },
  isLoading: false,
  error: "",
};

export const reducer = persistReducer(
  { storage, key: "subtopic" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FETCH_TOPIC_DETAILS: {
        const { topicDetails } = action.payload;

        return { ...state, topicDetails };
      }

      case actionTypes.LOADING_SUBTOPIC: {
        const { isLoading } = action.payload;

        return { ...state, isLoading };
      }

      case actionTypes.ERROR_SUBTOPIC: {
        const { error } = action.payload;

        return { ...state, error };
      }
      case actionTypes.Logout: {
        return initialState;
      }
      default:
        return state;
    }
  }
);

export const actions = {
  addSubtopic: (subtopic, parentTopic) => ({
    type: actionTypes.ADD_SUBTOPIC,
    payload: { subtopic, parentTopic },
  }),
  updateSubtopic: (id, parentTopic, subtopic) => ({
    type: actionTypes.UPDATE_SUBTOPIC,
    payload: { id, parentTopic, subtopic },
  }),
  deleteSubtopic: (id, parentTopic) => ({
    type: actionTypes.DELETE_SUBTOPIC,
    payload: { id, parentTopic },
  }),
  getTopicDetails: (parentTopic) => ({
    type: actionTypes.GET_TOPIC_DETAILS,
    payload: { parentTopic },
  }),
  isLoading: (isLoading) => ({
    type: actionTypes.LOADING_SUBTOPIC,
    payload: { isLoading },
  }),
  error: (error) => ({ type: actionTypes.ERROR_SUBTOPIC, payload: { error } }),
  fetchTopicDetails: (topicDetails) => ({
    type: actionTypes.FETCH_TOPIC_DETAILS,
    payload: { topicDetails },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.ADD_SUBTOPIC, function* addSubtopicSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { subtopic, parentTopic } = action.payload;
      yield call(SUBTOPIC_SERVICES.addSubtopic, subtopic, parentTopic);
      const response = yield call(
        SUBTOPIC_SERVICES.getTopicDetails,
        parentTopic
      );
      const [topicDetails] = response.data?.data;
      yield put(
        actions.fetchTopicDetails(topicDetails || initialState.topicDetails)
      );
    } catch (error) {
      yield put(actions.error("Failed sub-topic add!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.UPDATE_SUBTOPIC, function* updateSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { id, parentTopic, subtopic } = action.payload;
      yield call(SUBTOPIC_SERVICES.updateSubtopic, id, subtopic);
      const response = yield call(
        SUBTOPIC_SERVICES.getTopicDetails,
        parentTopic
      );
      const [topicDetails] = response.data?.data;
      yield put(
        actions.fetchTopicDetails(topicDetails || initialState.topicDetails)
      );
    } catch (error) {
      yield put(actions.error("Failed topic update!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.DELETE_SUBTOPIC, function* deleteSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { id, parentTopic } = action.payload;
      yield call(SUBTOPIC_SERVICES.deleteSubtopic, id);
      const response = yield call(
        SUBTOPIC_SERVICES.getTopicDetails,
        parentTopic
      );
      const [topicDetails] = response.data?.data;
      yield put(
        actions.fetchTopicDetails(topicDetails || initialState.topicDetails)
      );
    } catch (error) {
      yield put(actions.error("Failed topic delete!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.GET_TOPIC_DETAILS, function* getTopicDetailsSaga(
    action
  ) {
    yield put(actions.isLoading(true));
    
    try {
      const { parentTopic } = action.payload;
      const response = yield call(
        SUBTOPIC_SERVICES.getTopicDetails,
        parentTopic
      );
      const [topicDetails] = response.data?.data;
      yield put(
        actions.fetchTopicDetails(topicDetails || initialState.topicDetails)
      );
    } catch (error) {
      yield put(actions.error("Failed topic fetch!"));
    }
    yield put(actions.isLoading(false));
  });
}
