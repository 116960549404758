import { setToast } from "../../app/modules/toast";
import { Services } from "../Services";

export const addQuestion = (questionText, parentSubtopic) => {
  let bodyFormData = new FormData();
  bodyFormData.append("text", questionText.text);
  bodyFormData.append("assumptions", questionText.assumptions);
  bodyFormData.append("screen_tagline", questionText.screen_tagline);
  bodyFormData.append("description", questionText.description);
  // bodyFormData.append("summery_report", questionText.summery_report);
  bodyFormData.append("icon_logo", questionText.icon_logo);
  bodyFormData.append("topicId", questionText.topicId);
  bodyFormData.append("helpFile", questionText.helpFile);
  return Services({
    url: `/admin/question`,
    method: "POST",
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const updateQuestion = (id, questionText) => {
  let bodyFormData = new FormData();
  bodyFormData.append("text", questionText.text);
  bodyFormData.append("assumptions", questionText.assumptions);
  bodyFormData.append("screen_tagline", questionText.screen_tagline);
  bodyFormData.append("description", questionText.description);
  // bodyFormData.append("summery_report", questionText.summery_report);
  bodyFormData.append("icon_logo", questionText.icon_logo);
  bodyFormData.append("helpFile", questionText.helpFile);
  return Services({
    url: `/admin/question/${id}`,
    method: "PUT",
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const copyTool = (copyToolDetails) => {
  return Services({
    url: `/admin/question/clone`,
    method: "POST",
    data: copyToolDetails,
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const updateReportTemplateApi = (reportData) => {
  return Services({
    url: `/admin/reportTemplate`,
    method: "PUT",
    data: reportData,
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const deleteQuestion = (id) => {
  return Services({
    url: `/admin/question/${id}`,
    method: "DELETE",
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });
};

export const getSubtopicDetails = (id) => {
  return Services({
    url: `/admin/subTopic/${id}`,
    method: "GET",
  });
};
export const getTemplateListApi = () => {
  return Services({
    url: `/admin/reportTemplate`,
    method: "GET",
  });
};

let questions = [
  {
    _id: "62a9cf2af912b33c147293d6",
    text: "Do you have  clear process on how to user/implement the software?",
    updatedAt: "2022-06-15T12:23:06.339Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    parentSubtopic: "62a8243346e2bc3c641f8f6d",
    modifiedBy: {
      firstName: "Intech",
      lastName: "solution",
    },
  },
  {
    _id: "62a9cf30f912b33c147293d8",
    text: "question 3",
    updatedAt: "2022-06-15T12:23:12.710Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    parentSubtopic: "62a8243346e2bc3c641f8f6d",
    modifiedBy: {
      firstName: "Intech",
      lastName: "solution",
    },
  },
  {
    _id: "62a9cf35f912b33c147293da",
    text: "question 4",
    updatedAt: "2022-06-15T12:23:17.944Z",
    createdAt: "2022-06-14T07:22:28.777Z",
    parentSubtopic: "62a8245146e2bc3c641f8f71",
    modifiedBy: {
      firstName: "Intech",
      lastName: "solution",
    },
  },
];

export const question = { questions };
