import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import UsaMapChart from "../../organization/UsaMapChart";
import { Services } from "../../../../_service/Services";
import { setToast } from "../../../../app/modules/toast";

const KeyLabelMap = {
  eCommerceRevenuePercentage: "E Commerce Revenue(%)",
  traditionalRetailRevenuePercentage: "Traditional Retail Revenue(%)",
  iconLogo: "Logo",
};

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function OrganizationDetailDialog({ toggleModal, orgId, ...props }) {
  const [data, setData] = useState({});

  useEffect(() => {
    if (orgId) {
      Services({
        url: `/admin/organization/edit/${orgId}`,
        method: "GET",
      })
        .then((response) => {
          setData(response.data?.data);
        })
        .catch((error) => {
          error?.response?.data?.message &&
            setToast(error?.response?.data?.message);
        });
    }
    
  }, [orgId]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        toggleModal(false);
      }}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">Organization Detail</h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={() => {
            toggleModal(false);
          }}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* <pre>{JSON.stringify(data, 0, 2)}</pre> */}
        <div style={{ height: "75vh" }} className="table-responsive">
          <table
            className="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            {[
              // "companyName",
              // "geographicScope",
              // "overAllRevenue",
              // "city",
              // "eCommerceRevenuePercentage",
              // "traditionalRetailRevenuePercentage",
              // "eCommerceUnit",
              // "traditionalRetailUnit",
              // "salesChannelUtilized",
              // "state",
              // "products",
              // "iconLogo",
              "companyName",
              "overAllRevenue",
              "eCommerceRevenuePercentage",
              "traditionalRetailRevenuePercentage",
              "eCommerceUnit",
              "traditionalRetailUnit",
              "products",
            ].map((key) => {
              if (key === "_id") {
                return null;
              }
              if (!data[key]) {
                return null;
              }
              if (key === "iconLogo") {
                return (
                  <tr>
                    <th>{KeyLabelMap[key]}</th>
                    <td>
                      <img src={data[key]} style={{ height: 50 }} />
                    </td>
                  </tr>
                );
              }
              if (typeof data[key] !== "object") {
                const result = key.replace(/([A-Z])/g, " $1");
                const header = result.charAt(0).toUpperCase() + result.slice(1);
                let tableValue = data[key];
                if (key === "overAllRevenue") {
                  tableValue = formatter.format(tableValue);
                }
                if (
                  [
                    "traditionalRetailRevenuePercentage",
                    "eCommerceRevenuePercentage",
                  ].indexOf(key) > -1
                ) {
                  tableValue = `${tableValue}%`;
                }
                return (
                  <tr>
                    <th>{KeyLabelMap[key] || header}</th>
                    <td>{tableValue}</td>
                  </tr>
                );
              } else {
                const tableData =
                  key === "products"
                    ? data[key]
                        ?.filter?.((prd) => prd?.isSelected)
                        ?.map?.(({ Category, Name }) => ({
                          Category,
                          Name,
                        }))
                    : data[key];
                if (!tableData.length) {
                  return null;
                }

                const [header] = tableData;
                const value = (
                  <table>
                    <thead>
                      <tr>
                        {Object.keys(header).map((headerKey) => {
                          if (headerKey === "_id") {
                            return null;
                          }
                          const resultHeaderKey = headerKey.replace(/([A-Z])/g, " $1");
                          const titleHeader =
                          resultHeaderKey.charAt(0).toUpperCase() + resultHeaderKey.slice(1);
                          return <th>{titleHeader}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row) => (
                        <tr>
                          {Object.keys(row).map((rowKey) => {
                            if (rowKey === "_id") {
                              return null;
                            }
                            return <td>{row[rowKey]}</td>;
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );

                const result = key.replace(/([A-Z])/g, " $1");
                const title = result.charAt(0).toUpperCase() + result.slice(1);
                return (
                  <tr>
                    <th>{title}</th>
                    <td>{value}</td>
                  </tr>
                );
              }
            })}
            <tr>
              <th>State</th>
              <td>
                <div style={{ pointerEvents: "none" }}>
                  <UsaMapChart
                    allState={data?.state}
                    state={data?.state?.filter?.(
                      (o_state) => o_state?.isSelected
                    )}
                  />
                </div>
              </td>
            </tr>
            {/* <tr>
            </tr> */}
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}
