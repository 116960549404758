import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";


export function ProfileOverviewTableWidget1({ className }) {
  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body py-0 pt-4">
        <div className="table-responsive">
          <table
            className="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr className="text-left">
                <th className="pl-0" style={{ minWidth: "400px" }}>
                  name
                </th>
                <th style={{ minWidth: "300px" }}>Analytic name</th>
                <th className="pr-0 text-right" style={{ minWidth: "300px" }}>
                  modified name
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pl-0 py-4">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-50 symbol-light mr-4">
                      <span className="symbol-label">
                        <span className="svg-icon h-75 align-self-end">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/User.svg"
                            )}
                          />
                        </span>
                      </span>
                    </div>
                    <div>
                      <a className="text-dark-75 font-weight-bolder text-hover-primary d-block mb-1 font-size-lg">
                        Do you have clear process on how to user/implement the
                        software?
                      </a>
                      <span className="text-muted font-weight-bold d-block">
                        Modified on Mar 14 2022
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                    Analytic Tools
                  </span>
                </td>
                <td className="pr-0 text-right">
                  <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                    Modified by Jack
                  </span>
                </td>
              </tr>
              <tr>
                <td className="pl-0 py-4">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-50 symbol-light mr-4">
                      <span className="symbol-label">
                        <span className="svg-icon h-75 align-self-end">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/User.svg"
                            )}
                          />
                        </span>
                      </span>
                    </div>
                    <div>
                      <a className="text-dark-75 font-weight-bolder text-hover-primary d-block mb-1 font-size-lg">
                        How you are tracking users on your page?
                      </a>
                      <span className="text-muted font-weight-bold d-block">
                        Modified on Mar 14 2022
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                    Analytic Tools
                  </span>
                </td>
                <td className="pr-0 text-right">
                  <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                    Modified by Jack
                  </span>
                </td>
              </tr>
              <tr>
                <td className="pl-0 py-4">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-50 symbol-light mr-4">
                      <span className="symbol-label">
                        <span className="svg-icon h-75 align-self-end">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/User.svg"
                            )}
                          />
                        </span>
                      </span>
                    </div>
                    <div>
                      <a className="text-dark-75 font-weight-bolder text-hover-primary d-block mb-1 font-size-lg">
                        Are you tracking users via google?
                      </a>
                      <span className="text-muted font-weight-bold d-block">
                        Modified on Mar 14 2022
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                    Analytic Tools
                  </span>
                </td>
                <td className="pr-0 text-right">
                  <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                    Modified by Jack
                  </span>
                </td>
              </tr>
              <tr>
                <td className="pl-0 py-4">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-50 symbol-light mr-4">
                      <span className="symbol-label">
                        <span className="svg-icon h-75 align-self-end">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/User.svg"
                            )}
                          />
                        </span>
                      </span>
                    </div>
                    <div>
                      <a className="text-dark-75 font-weight-bolder text-hover-primary d-block mb-1 font-size-lg">
                        How you are tracking users on your page?
                      </a>
                      <span className="text-muted font-weight-bold d-block">
                        Modified on Mar 14 2022
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                    Analytic Tools
                  </span>
                </td>
                <td className="pr-0 text-right">
                  <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                    Modified by Jack
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
