import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import { actions as AUTH_ACTIONS } from "../_redux/authRedux";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getError } from "../_redux/authSelector";
import { ForgotPassword } from "./ForgotPassword";
import { Toaster } from "react-hot-toast";

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const loading = useSelector(getLoading);
  const error = useSelector(getError);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong username format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,

    onSubmit: (values, { setStatus, setSubmitting }) => {
      dispatch(AUTH_ACTIONS.login(values.email, values.password));
    },
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(AUTH_ACTIONS.error(""));
      }, 3000);
    }
  }, [error]);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-left mb-7 mb-lg-14">
        <h3 className="font-size-h1">
          {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
          Admin Login
        </h3>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {error ? (
          <div className="alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{error}</div>
          </div>
        ) : (
          <div></div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <label className="form-label text-dark-50 font-weight-bolder">
            User Name
          </label>
          <input
            placeholder="User Name"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label className="form-label text-dark-50 font-weight-bolder">
            Password
          </label>
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={loading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Login</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShow(true);
            }}
            className={`btn btn-secondary font-weight-bold px-9 py-4 my-3 ml-5`}
          >
            <span>Forgot Password</span>
          </button>
        </div>
      </form>
      <ForgotPassword show={show} onHide={() => setShow(false)} />
      <Toaster />
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(Login);
