import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setToast } from "../../../../../app/modules/toast";
import { actions as QUESTION_ACTIONS } from "../../../../../app/modules/Question/_redux/questionsRedux";

const MultiDocument = ({ formData, save, nodeCategory }) => {
  const dispatch = useDispatch();

  const optionDetails = useSelector((store) => store.questions.templateList);

  const [category, setCategory] = useState("");
  const [label, setLabel] = useState("");
  const [isSingle, setIsSingle] = useState(false);
  const [nodeDisplayLabel, setNodeDisplayLabel] = useState("");
  const [nodeDisplayLabelLength, setNodeDisplayLabelLength] = useState(0);
  useEffect(() => {
    dispatch(QUESTION_ACTIONS.getTemplateListApi());
  }, []);
  useEffect(() => {
    setCategory(formData.localVariableName);
    setLabel(formData.label);
    setIsSingle(formData.isSingle);
    setNodeDisplayLabel(formData?.nodeDisplayLabel);
    setNodeDisplayLabelLength(formData?.nodeDisplayLabel?.length);
  }, [formData]);

  const saveForm = () => {
    if (category === "") {
      setToast("Please fill values in form", "error", "top-center");
      return;
    }

    if (nodeDisplayLabel == "" || nodeDisplayLabel == undefined) {
      setToast("Please Enter Node Display Label ", "error", "top-center");
      return;
    }
    if (nodeDisplayLabelLength > 15) {
      setToast(
        "Please Enter Node Display Label less then 15 characters",
        "error",
        "top-center"
      );
      return;
    }
    save({
      templateId: category,
      localVariableName: category,
      variableName: label,
      label,
      isSingle,
      nodeDisplayLabel,
    });
  };

  return (
    <form className="py-5 px-5 form-col modal-form">
      <div className="form-group fv-plugins-icon-container">
        <label className={"form-label text-dark-50 font-weight-bolder"}>
          Node Display Label{" "}
          <small>
            {" "}
            <span className={nodeDisplayLabelLength > 15 ? "error" : "info"}>
              ({nodeDisplayLabelLength} / 15 charaters)
            </span>
          </small>
          :
        </label>

        <input
          placeholder="Node Display Label"
          type="text"
          value={nodeDisplayLabel}
          onChange={(e) => {
            setNodeDisplayLabel(e.target.value);
            setNodeDisplayLabelLength(e.target.value.length);
          }}
          className="form-control form-control-solid h-auto py-5 px-6"
          name={"nodeDisplayLabel"}
        />
      </div>
      <div className="form-group fv-plugins-icon-container">
        <label className="form-label text-dark-50 font-weight-bolder">
          Select Template :
        </label>
        <select
          className="form-control form-control-solid h-auto py-5 px-6"
          name="multiOption"
          onChange={(e) => {
            var index = e.nativeEvent.target.selectedIndex;
            setLabel(optionDetails[index - 1]?.displayLabel);
            setCategory(optionDetails[index - 1]?._id);
          }}
          value={category}
        >
          <option value="">Select Option</option>
          {optionDetails.map((option, index) => (
            <option key={option._id} value={option._id}>
              {option.displayLabel}
            </option>
          ))}
        </select>
      </div>
      <div className="btn-block">
        <button
          type="button"
          className="btn btn-success w-100px"
          onClick={() => saveForm()}
        >
          {" "}
          Save
        </button>
      </div>
    </form>
  );
};

export default MultiDocument;
