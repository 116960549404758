import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { actions as PREDEFINED_LOGIC_ACTIONS } from "../../../../app/modules/PredefinedLogic/_redux/PredefinedLogicRedux";
import { DeleteButtonWithConfirm } from "../../../_partials/widgets/advance-tables/DeleteButtonWithConfirm";
import { toAbsoluteUrl } from "../../../_helpers";
import { AddPredefinedProcess } from "../../modals/addPredefinedProcess-dialog/addPredefinedProcess";
import RingLoader from "react-spinners/RingLoader";
import "./common.css";
import HelpIcon from "../../Help/HelpIcon";

export function PredefinedLogicTableWidget({ className }) {
  const dispatch = useDispatch();
  const isLoading = false;
  const predefinedLogicData = useSelector(
    (store) => store.predefinedLogic.predefinedLogicData
  );

  const [modalShow, setModalShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    dispatch(PREDEFINED_LOGIC_ACTIONS.getListOfPredefinedLogicAPI());
  }, []);

  /**
   * To Set Add Update Modal Data
   */
  const toggleModalFn = (show, data, isEdit) => {
    setModalShow(show);
    setModalData(data);

    setIsUpdate(isEdit);
  };

  const handleHide = () => {
    console.log("here");
    setIsUpdate(false);
    setModalShow(false);
    setModalData({});
  };
  const handleDelete = (id) => {
    dispatch(PREDEFINED_LOGIC_ACTIONS.delete(id));
  };

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        {isLoading && (
          <div className="loader_wrapper">
            <RingLoader color={"darkcyan"} loading={true} size={40} />
          </div>
        )}
        <div className="card-header border-0 py-5 custom-header align-items-center">
          <h3 className="card-title flex-column m-0">
            <span className="card-label font-weight-bolder text-dark">
              Predefined Process
            </span>
          </h3>
          <div className="card-toolbar">
            <a
              onClick={() => toggleModalFn(true, {}, false)}
              className="btn btn-success font-weight-bolder font-size-sm"
            >
              <span className="svg-icon svg-icon-md svg-icon-white">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Add-user.svg"
                  )}
                  className="h-50 align-self-center"
                ></SVG>
              </span>
              Add New Predefined Process
            </a>
            <AddPredefinedProcess
              show={modalShow}
              isUpdate={isUpdate}
              modalData={modalData}
              onHide={handleHide}
            />
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pl-0" style={{ minWidth: "400px" }}>
                    Logic
                  </th>
                  <th className="pl-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {(predefinedLogicData || []).map((procedureDetail, index) => (
                  <tr key={`procedureDetail_${index}`}>
                    <td className="pl-0 py-4">
                      <div className="d-flex align-items-center">
                        <div>
                          <Link
                            className="text-dark-75 font-weight-bolder text-hover-primary d-block mb-1 font-size-lg"
                            to={`/predefined-logic/${procedureDetail._id}`}
                          >
                            {procedureDetail.displayLabel != undefined
                              ? procedureDetail.displayLabel
                              : procedureDetail.label}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="pl-0 py-4">
                      <a
                        onClick={() => {
                          let {
                            _id: id,
                            createdAt,
                            updatedAt,
                            ...rest
                          } = procedureDetail;
                          toggleModalFn(true, { ...rest, id }, true);
                        }}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>
                      <DeleteButtonWithConfirm
                        parentClassName="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onDelete={() => handleDelete(procedureDetail._id)}
                        iconClassName="svg-icon svg-icon-md svg-icon-primary"
                      />
                    </td>
                  </tr>
                ))}
                {!(predefinedLogicData || []).length && !isLoading && (
                  <tr>
                    <td className="pl-0 text-center" colSpan={8}>
                      <span className="svg-icon d-block p-3 nodata__icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/ico_nodata.svg")}
                        ></SVG>
                      </span>
                      <h3 className="text-dark font-weight-bolder d-block mb-0">
                        No Record Found
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>

      <HelpIcon pageName="PredefindLogicListSection" />
    </>
  );
}
