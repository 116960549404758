/** @format */
// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ArrowBackIos } from "@material-ui/icons";
import RingLoader from "react-spinners/RingLoader";
import SVG from "react-inlinesvg";
import * as go from "gojs";
import { ReactDiagram, ReactPalette } from "gojs-react";
import { useImmer } from "use-immer";
import { toAbsoluteUrl } from "../../_helpers";

import HelpIcon from "../Help/HelpIcon";
import { Form } from "./component";
import { actions as DIAGRAM_ACTIONS } from "../../../app/modules/Diagram/_redux/DiagramRedux";
import "./Diagram.scss";
import { setToast } from "../../../app/modules/toast";
import { CustomToolTip } from "../customComponents/customTooltip/CustomTooltip";
import ZoomSlider from "./ZoomSlider";
let _initialScale = 1;
export function Diagram() {
  const diagramRef = useRef(null);

  const dispatch = useDispatch();
  const { id } = useParams();
  const diagramDetails = useSelector((store) => store.diagram.diagramData);
  const questionDetails = useSelector(
    (store) => store.questions.questionDetails
  );
  const subTopicDetails = useSelector((store) => store.subtopics.topicDetails);
  const question = useSelector((store) => store.diagram.question);
  const diagramError = useSelector((store) => store.diagram.diagramError);

  const isEdit = diagramDetails?.nodeDataArray.length > 0 ? true : false;
  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [openUpdateAlert, setOpenUpdateAlert] = React.useState(false);
  const isLoading = useSelector((store) => store.diagram.isLoading);
  const [formToShow, setFormToShow] = useState(null);
  const [formChange, setFormChange] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [diagramErrorModal, setDiagramErrorModal] = useState(false);
  const [toolTipText, setToolTipText] = useState("");
  const [toolTipPosition, setToolTipPosition] = useState({ x: 0, y: 0 });
  const [selectedNode, setSelectedNode] = useState({});
  const [localVariableArray, setLocalVariableArray] = useState([]);
  const [linkNodeArray, setLinkNodeArray] = useState([]);

  //Set redux and component loader together
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  // Get Diagram Detail Api
  useEffect(() => {
    dispatch(DIAGRAM_ACTIONS.getDiagramDetail(id));
    return () => {
      dispatch(
        DIAGRAM_ACTIONS.getDiagramData({
          nodeDataArray: [],
          linkDataArray: [],
        })
      );
    };
  }, []);

  // DiagramData
  const [diagramData, updateDiagramData] = useImmer({
    nodeDataArray: diagramDetails.nodeDataArray || [],
    linkDataArray: diagramDetails.linkDataArray || [],
    paletteDataArray: [
      { category: "Start", text: "Start", name: "Start" },
      // { text: "Step", name: "Step" },

      { category: "End", text: "End", name: "End" },
      // { category: "Triangle", text: "Triangle", name: "Triangle" },
      { category: "MultiAns", text: "MultiAns", name: "MultiAns" },
      { category: "MultiOption", text: "MultiOption", name: "MultiOption" },

      {
        category: "Procedure",
        text: "Process",
        name: "ProcessData",
      },
      {
        category: "PredefinedLogic",
        text: "Predefined Process",
        name: "PredefinedLogic",
      },
      { category: "PassData", text: "PassData", name: "PassData" },

      // { category: "Parallelogram", text: "Data Load", name: "FileInput" },
      { category: "ManualInput", text: "Manual Input", name: "ManualInput" },
      // { category: "Cylinder1", text: "Store Data", name: "StoreData" },
      { category: "MultiDocument", text: "Report", name: "Report" },
      // { category: "Document", text: "Final Read Out", name: "FinalReadOut" },
      // { category: "MultiProcess", text: "MultiProcess", name: "MultiProcess" },
      { category: "Comment", text: "Comment", name: "Comment" },
      { category: "Conditional", text: "???", name: "Decision" },
      { category: "LookUp", text: "LookUp", name: "LookUp" },
    ],
    modelData: { canRelink: true },
    selectedData: null,
    skipsDiagramUpdate: false,
    mapNodeKeyIdx: {},
    mapLinkKeyIdx: {},
  });
  //Update Diagrame detail to component state
  useEffect(() => {
    let localVariableArrayNew = [];

    updateDiagramData((draft) => {
      draft.nodeDataArray = diagramDetails.nodeDataArray;
      draft.linkDataArray = diagramDetails.linkDataArray;
      let mapLinkKeyIdx = draft.mapLinkKeyIdx;
      diagramDetails.linkDataArray.map((item) => {
        mapLinkKeyIdx[item.key] = diagramDetails.linkDataArray.length;
      });
      let mapNodeKeyIdx = draft.mapNodeKeyIdx;
      diagramDetails.nodeDataArray.map((item) => {
        mapNodeKeyIdx[item.key] = diagramDetails.nodeDataArray.length;
      });
    });

    diagramDetails.nodeDataArray.map((node) => {
      if (node.category === "MultiOption" || node.category === "Procedure") {
        let variable = node.metaData?.isSingle
          ? node.metaData?.singleVariableName
          : node.metaData.localVariableName != undefined
          ? node.metaData.localVariableName
          : node.metaData.variableName;
        if (node.metaData?.isFetchFromLookup) {
          variable = node.metaData?.singleVariableName;
        }

        let fileName = node.metaData.localVariableName;
        let variableLabel = node.metaData.variableLabel;
        if (node.category === "MultiOption") {
          let toKey = node.key;
          let fromKey = diagramDetails.linkDataArray.find(
            (item) => item.to == toKey
          )?.from;
          if (
            diagramDetails.nodeDataArray.find((item) => item.key == fromKey)
              ?.category == "Conditional"
          ) {
            let toKeyForNextNode = diagramDetails.linkDataArray.find(
              (item) => item.to != toKey && item.from == fromKey
            )?.to;
            let otherNode = diagramDetails.nodeDataArray.find(
              (item) => item.key == toKeyForNextNode
            );
            if (otherNode.metaData.localVariableName != "") {
              variable = variable + "," + otherNode.metaData.localVariableName;
              fileName = fileName + "," + otherNode.metaData.localVariableName;
              variableLabel =
                variableLabel + "," + otherNode.metaData.variableLabel;
            }
          }
        }
        localVariableArrayNew.push({
          key: node.key,
          variableName: variable,
          category: node.category,
          variableLabel: variableLabel,
          isSingle:
            node.metaData?.isSingle == undefined
              ? false
              : node.metaData?.isSingle,
          fileName: fileName,
        });
      }

      if (node.category === "ManualInput" || node.category === "LookUp") {
        if (node.metaData?.inputs?.length > 0) {
          let changes = node.metaData?.inputs?.map((item) => {
            localVariableArrayNew.push({
              key: node.key,
              variableName: item.localVariableName,
              variableLabel: item.variableLabel,
              category: node.category,
              isSingle:
                node.metaData?.isSingle == undefined
                  ? false
                  : node.metaData?.isSingle,
              fileName: item.localVariableName,
              fileIdentityValue: item?.fileValue?.identityValue,
            });
            return true;
          });
        }
      }
    });

    setLocalVariableArray(localVariableArrayNew);
  }, [diagramDetails]);

  useEffect(() => {
    let linkDataArray = diagramData.linkDataArray;

    let nodeDataArray = diagramData.nodeDataArray;
    let linkNode = [];
    let i = 0;
    let startNode = nodeDataArray.find((item) => item.category === "Start");
    if (startNode != undefined) {
      linkNode.push({ key: startNode.key, nodeId: i });
      let nextNode = linkDataArray.find((item) => item.from == startNode.key);
      if (nextNode != undefined) {
        i++;
        linkNode.push({ key: nextNode.to, nodeId: i });
      }
    }

    nodeDataArray.map((node) => {
      if (
        node.category != "Start" &&
        node.category != "Conditional" &&
        node.category != "MultiAns"
      ) {
        let nodeIdfromNewNode = linkNode.find((item) => item.key == node.key);
        let linkOfNode = linkDataArray.find((item) => item.from == node.key);

        let nodeIdData = { key: node.key };
        if (nodeIdfromNewNode != undefined && linkOfNode != undefined) {
          nodeIdData = { key: linkOfNode.to };
          if (linkNode.indexOf((item) => item.key == linkOfNode.to) == -1) {
            nodeIdData = {
              key: linkOfNode.to,
              nodeId: nodeIdfromNewNode.nodeId + 1,
            };
          }
        }
        let linkToNode = {};
        let nodeIDToNode = {};
        if (nodeIdfromNewNode == undefined) {
          linkToNode = linkDataArray.find((item) => item.to == node.key);
          if (linkToNode != undefined) {
            let linkFromMultipleCheck = linkDataArray.filter(
              (item) => item.from == linkToNode.from
            );
            let linkToMultipleCheck = linkDataArray.filter(
              (item) => item.to == linkToNode.to
            );
            nodeIDToNode = linkNode.find((item) => item.key == linkToNode.from);
            if (nodeIDToNode != undefined) {
              if (nodeIDToNode.nodeId != undefined) {
                let nodeIdForMultipleFrom = nodeIDToNode.nodeId + 1;
                if (linkFromMultipleCheck.length > 0) {
                  linkFromMultipleCheck.map((link) => {
                    let newNodeIDforMiltipleTo = linkNode.find(
                      (item) => item.key == link.to
                    );
                    if (newNodeIDforMiltipleTo != undefined) {
                      if (
                        nodeIdForMultipleFrom <
                        newNodeIDforMiltipleTo.nodeId + 1
                      ) {
                        nodeIdForMultipleFrom =
                          newNodeIDforMiltipleTo.nodeId + 1;
                      }
                    }
                  });
                }
                if (linkToMultipleCheck.length > 0) {
                  linkToMultipleCheck.map((link) => {
                    let newNodeIDforMiltipleTo = linkNode.find(
                      (item) => item.key == link.from
                    );
                    if (newNodeIDforMiltipleTo != undefined) {
                      if (
                        nodeIdForMultipleFrom <
                        newNodeIDforMiltipleTo.nodeId + 1
                      ) {
                        nodeIdForMultipleFrom =
                          newNodeIDforMiltipleTo.nodeId + 1;
                      }
                    }
                  });
                }

                nodeIdData = { key: node.key, nodeId: nodeIdForMultipleFrom };
              }
            }
          }
        }
        linkNode.push(nodeIdData);
        return true;
      }

      if (node.category == "Conditional" || node.category == "MultiAns") {
        let nodeIdfromNewNode = linkNode.find((item) => item.key == node.key);

        if (nodeIdfromNewNode != undefined) {
          let linkOfNode = linkDataArray.filter(
            (item) => item.from == node.key
          );

          let nodeId = nodeIdfromNewNode.nodeId;
          linkOfNode.map((linkData) => {
            nodeId++;
            linkNode.push({ key: linkData.to, nodeId: nodeId });
          });
        } else {
          let linkToNode = linkDataArray.filter((item) => item.to == node.key);
          linkToNode.map((linkData) => {
            let nodeIdfromNewNodeLink = linkNode.find(
              (item) => item.key == linkData.from
            );
            if (nodeIdfromNewNodeLink != undefined) {
              if (nodeIdfromNewNodeLink.nodeId != undefined) {
                let newNodeId = nodeIdfromNewNodeLink.nodeId + 1;
                linkNode.push({ key: node.key, nodeId: newNodeId });
              } else {
                linkNode.push({ key: node.key });
              }
            } else {
              linkNode.push({ key: node.key });
            }
          });
        }
      }
    });

    let linkNodeNew = linkNode.filter((lnode) => lnode.nodeId !== undefined);
    let noteNodeIdArray = linkNode.filter(
      ({ key: id1 }) => !linkNodeNew.some(({ key: id2 }) => id2 === id1)
    );
    if (noteNodeIdArray.length > 0) {
      noteNodeIdArray.forEach((nodeKey) => {
        let key = nodeKey.key;

        let linkFromNode = linkDataArray.find((item) => item.from == key);
        let linkToNode = linkDataArray.find((item) => item.to == key);
        let nodeId = "";

        let nodeIdFromNode = linkNodeNew.find(
          (linkData) => linkData.key == key
        );
        if (nodeIdFromNode != undefined) {
          let toNodeNodeIDAvailableIndex = linkNodeNew.find(
            (linkData) => linkData.key == linkFromNode?.to
          );
          let nodeCategory = nodeDataArray.find(
            (item) => item.key === linkFromNode?.to
          )?.category;
          if (nodeCategory != "Start") {
            if (
              toNodeNodeIDAvailableIndex == undefined &&
              linkFromNode != undefined
            ) {
              linkNodeNew.push({
                key: linkFromNode.to,
                nodeId: nodeIdFromNode.nodeId + 1,
              });
            } else {
              if (toNodeNodeIDAvailableIndex != undefined) {
                if (
                  toNodeNodeIDAvailableIndex.nodeId <
                  nodeIdFromNode.nodeId + 1
                ) {
                  toNodeNodeIDAvailableIndex.nodeId = nodeIdFromNode.nodeId + 1;
                }
              }
            }
          }
        } else {
          if (linkToNode != undefined) {
            let nodeIdToNode = linkNodeNew.find(
              (linkData) => linkData.key == linkToNode.from
            );
            if (nodeIdToNode != undefined) {
              linkNodeNew.push({ key: key, nodeId: nodeIdToNode.nodeId + 1 });
            }
          }
        }
        let findNode = nodeDataArray.find((item) => item.key == key);
        if (nodeId > -1) {
          let linkFrom = linkDataArray.filter((item) => item.from == key);
          let nodeIdfromNewNode = linkNodeNew.find((item) => item.key == key);
          if (nodeIdfromNewNode != undefined) {
            let nodeIdFrom = nodeIdfromNewNode.nodeId;
            linkFrom.map((linkData) => {
              nodeIdFrom++;
              linkNodeNew.push({ key: linkData.to, nodeId: nodeIdFrom });
            });
          }
        }
        return true;
      });
    }

    const differenceNodeId = noteNodeIdArray.filter(
      (obj1) => !linkNodeNew.some((obj2) => obj1.key === obj2.key)
    );
    if (differenceNodeId.length > 0) {
      differenceNodeId.forEach((nodeKey) => {
        let key = nodeKey.key;
        let linkFrom = linkDataArray.find((item) => item.to == key);
        if (linkFrom != undefined) {
          let nodeIdfromNewNode = linkNodeNew.find(
            (item) => item.key == linkFrom.from
          );
          if (nodeIdfromNewNode != undefined) {
            let nodeIdFrom = nodeIdfromNewNode.nodeId;
            nodeIdFrom++;
            linkNodeNew.push({ key: key, nodeId: nodeIdFrom });
          }
        }
      });
    }
    let uniqueNode = linkNodeNew.filter(
      (a, k) => linkNodeNew.findIndex((s) => a.key === s.key) === k
    );
    setLinkNodeArray(uniqueNode);
  }, [diagramData.linkDataArray]);
  const showLinkLabel = (e) => {
    const label = e.subject.findObject("LABEL");
    if (label !== null)
      label.visible =
        e.subject.fromNode.data.category === "Conditional" ||
        e.subject.fromNode.data.category === "MultiAns";
  };
  // Create Graph Object
  const $ = go.GraphObject.make;
  go.Diagram.licenseKey =
    "288647e1b3644dc702d90776423d68f919a175639d8418a30c0311f3ed0d3a06329ce07155d3839380ac1bfc1d7d9489d5c26a7ac04a553fe63180db45e1d2a9e53376e6130b4389f60b2fcbcaff78f1a87c71e0d2b322f2dc78dea2eabad39f5debf1cb4ad50dbe";
  const diagram = $(go.Diagram, {
    "undoManager.isEnabled": true,
    LinkDrawn: showLinkLabel,
    LinkRelinked: showLinkLabel,
    model: $(go.GraphLinksModel, {
      linkKeyProperty: "key",
      linkFromPortIdProperty: "fromPort",
      linkToPortIdProperty: "toPort",
    }),
  });

  // Scroll Mode Define
  diagram.scrollMode = go.Diagram.DocumentScroll;

  // Create event for opening model for get more information
  const formArray = [
    "Conditional",
    "PredefinedLogic",
    "Procedure",
    "Parallelogram",
    "MultiOption",
    "ManualInput",
    "MultiAns",
    "PassData",
    "LookUp",
    "MultiDocument",
    "Comment",
  ];

  diagram.addDiagramListener("ObjectDoubleClicked", function(e) {
    setShowToolTip(false);

    let part = e.subject.part;
    if (!(part instanceof go.Link)) {
      if (formArray.includes(part.data.category)) {
        //set selected node
        setSelectedNode(part.data);
        //set form visible
        setFormChange(true);
        // set key of diagram which form is to be shown
        setFormToShow(part.data.key);
      }
    }
  });

  // Event for change selection
  diagram.addDiagramListener("ChangedSelection", function(e) {
    //   to hide form
    setFormChange(false);
    // set null for not to show form
    setFormToShow(null);
    //refresh Link Index
    // refreshLinkIndex(diagramData.linkDataArray);
    setShowToolTip(false);
  });

  // Initiate Diagram Function
  const initDiagram = () => {
    // add mathmatic value for create diagram nodes
    let KAPPA = 4 * ((Math.sqrt(2) - 1) / 3);
    function FigureParameter(name, def, min, max) {
      if (min === undefined /*notpresent*/) min = 0.0;
      if (max === undefined /*notpresent*/) max = Infinity;
      /** @type {string} */
      this._name = name;
      /** @type {number} */
      this._defaultValue = def;
      /** @type {number} */
      this._minimum = min;
      /** @type {number} */
      this._maximum = max;
    }
    // Adding different Figure(Shape)
    go.Shape.defineFigureGenerator("Cylinder1", function(shape, w, h) {
      let param1 = shape ? shape.parameter1 : NaN; // half the height of the ellipse
      if (isNaN(param1)) param1 = 5; // default value
      param1 = Math.min(param1, h / 3);

      let geo = new go.Geometry();
      let cpxOffset = KAPPA * 0.5;
      let fig = new go.PathFigure(0, param1, true);
      geo.add(fig);
      // The base (top)
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          0,
          0,
          KAPPA * param1,
          (0.5 - cpxOffset) * w,
          0
        )
      );
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          1.0 * w,
          param1,
          (0.5 + cpxOffset) * w,
          0,
          1.0 * w,
          KAPPA * param1
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h - param1));
      // Bottom curve
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          1.0 * h,
          1.0 * w,
          h - KAPPA * param1,
          (0.5 + cpxOffset) * w,
          1.0 * h
        )
      );
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          h - param1,
          (0.5 - cpxOffset) * w,
          1.0 * h,
          0,
          h - KAPPA * param1
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, param1));

      let fig2 = new go.PathFigure(w, param1, false);
      geo.add(fig2);
      fig2.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          2 * param1,
          1.0 * w,
          2 * param1 - KAPPA * param1,
          (0.5 + cpxOffset) * w,
          2 * param1
        )
      );
      fig2.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          param1,
          (0.5 - cpxOffset) * w,
          2 * param1,
          0,
          2 * param1 - KAPPA * param1
        )
      );

      geo.spot1 = new go.Spot(0, 0, 0, 2 * param1);
      geo.spot2 = new go.Spot(1, 1);
      return geo;
    });

    go.Shape.defineFigureGenerator("MultiAns", function(shape, w, h) {
      let geo = new go.Geometry();
      let fig = new go.PathFigure(0.1 * w, 0, true);
      geo.add(fig);

      // Body
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.1 * w, h).close());
      let fig2 = new go.PathFigure(0.2 * w, 0.2 * h, false);
      geo.add(fig2);
      // Inside lines
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.2 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Move, 0.2 * w, 0.4 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.4 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Move, 0.2 * w, 0.6 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.6 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Move, 0.2 * w, 0.8 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.8 * h));
      return geo;
    });

    go.Shape.defineFigureGenerator("PassData", function(shape, w, h) {
      let param1 = shape ? shape.parameter1 : NaN; // half the width of the ellipse
      if (isNaN(param1)) param1 = 5; // default value
      param1 = Math.min(param1, w / 3);

      let geo = new go.Geometry();
      let cpyOffset = KAPPA * 0.5;
      let fig = new go.PathFigure(w - param1, 0, true);
      geo.add(fig);
      // The body, starting and ending top right
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          w,
          0.5 * h,
          w - KAPPA * param1,
          0,
          w,
          (0.5 - cpyOffset) * h
        )
      );
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          w - param1,
          h,
          w,
          (0.5 + cpyOffset) * h,
          w - KAPPA * param1,
          h
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, param1, h));
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          0.5 * h,
          KAPPA * param1,
          h,
          0,
          (0.5 + cpyOffset) * h
        )
      );
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          param1,
          0,
          0,
          (0.5 - cpyOffset) * h,
          KAPPA * param1,
          0
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, w - param1, 0));

      let fig2 = new go.PathFigure(w - param1, 0, false);
      geo.add(fig2);
      // Cylinder line (right)
      fig2.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          w - 2 * param1,
          0.5 * h,
          w - param1 - KAPPA * param1,
          0,
          w - 2 * param1,
          (0.5 - cpyOffset) * h
        )
      );
      fig2.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          w - param1,
          h,
          w - 2 * param1,
          (0.5 + cpyOffset) * h,
          w - param1 - KAPPA * param1,
          h
        )
      );

      geo.spot1 = new go.Spot(0, 0);
      geo.spot2 = new go.Spot(1, 1, -2 * param1, 0);
      return geo;
    });
    go.Shape.defineFigureGenerator("LookUp", function(shape, w, h) {
      var part = 1 / (Math.SQRT2 + 2);
      return new go.Geometry()
        .add(
          new go.PathFigure(part * w, 0, true)
            .add(new go.PathSegment(go.PathSegment.Line, (1 - part) * w, 0))
            .add(new go.PathSegment(go.PathSegment.Line, w, part * h))
            .add(new go.PathSegment(go.PathSegment.Line, w, (1 - part) * h))
            .add(new go.PathSegment(go.PathSegment.Line, (1 - part) * w, h))
            .add(new go.PathSegment(go.PathSegment.Line, part * w, h))
            .add(new go.PathSegment(go.PathSegment.Line, 0, (1 - part) * h))
            .add(new go.PathSegment(go.PathSegment.Line, 0, part * h).close())
        )
        .setSpots(part / 2, part / 2, 1 - part / 2, 1 - part / 2);
    });
    go.Shape.defineFigureGenerator("MultiDocument", function(shape, w, h) {
      let geo = new go.Geometry();
      h = h / 0.8;
      let fig = new go.PathFigure(w, 0, true);
      geo.add(fig);

      // Outline
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.5 * h));
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.9 * w,
          0.44 * h,
          0.96 * w,
          0.47 * h,
          0.93 * w,
          0.45 * h
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.6 * h));
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0.8 * w,
          0.54 * h,
          0.86 * w,
          0.57 * h,
          0.83 * w,
          0.55 * h
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.7 * h));
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          0.7 * h,
          0.4 * w,
          0.4 * h,
          0.4 * w,
          h
        )
      );
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.2 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.1 * w, 0.2 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.1 * w, 0.1 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.2 * w, 0.1 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.2 * w, 0).close());
      let fig2 = new go.PathFigure(0.1 * w, 0.2 * h, false);
      geo.add(fig2);
      // Inside lines
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.2 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.54 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Move, 0.2 * w, 0.1 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.1 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.44 * h));
      geo.spot1 = new go.Spot(0, 0.25);
      geo.spot2 = new go.Spot(0.8, 0.77);
      return geo;
    });

    go.Shape.defineFigureGenerator("MultiProcess", function(shape, w, h) {
      let geo = new go.Geometry();
      let fig = new go.PathFigure(0.1 * w, 0.1 * h, true);
      geo.add(fig);

      fig.add(new go.PathSegment(go.PathSegment.Line, 0.2 * w, 0.1 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.2 * w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.8 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.8 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.9 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.9 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.2 * h));
      fig.add(
        new go.PathSegment(go.PathSegment.Line, 0.1 * w, 0.2 * h).close()
      );
      let fig2 = new go.PathFigure(0.2 * w, 0.1 * h, false);
      geo.add(fig2);
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.1 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0.8 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Move, 0.1 * w, 0.2 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.2 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.9 * h));
      geo.spot1 = new go.Spot(0, 0.2);
      geo.spot2 = new go.Spot(0.8, 1);
      return geo;
    });

    go.Shape.defineFigureGenerator("Document", function(shape, w, h) {
      let geo = new go.Geometry();
      h = h / 0.8;
      let fig = new go.PathFigure(0, 0.7 * h, true);
      geo.add(fig);

      fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.7 * h));
      fig.add(
        new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          0.7 * h,
          0.5 * w,
          0.4 * h,
          0.5 * w,
          h
        ).close()
      );
      geo.spot1 = go.Spot.TopLeft;
      geo.spot2 = new go.Spot(1, 0.6);
      return geo;
    });

    go.Shape.defineFigureGenerator("ManualInput", function(shape, w, h) {
      let geo = new go.Geometry();
      let fig = new go.PathFigure(w, 0, true);
      geo.add(fig);

      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.25 * h).close());
      geo.spot1 = new go.Spot(0, 0.25);
      geo.spot2 = go.Spot.BottomRight;
      return geo;
    });
    go.Shape.getFigureParameter = function(figurename, index) {
      let arr = go.Shape._FigureParameters[figurename];
      if (!arr) return null;
      return /** @type {FigureParmeter} */ (arr[index]);
    };
    go.Shape.setFigureParameter = function(figurename, index, figparam) {
      if (!(figparam instanceof FigureParameter))
        throw new Error(
          "Third argument to Shape.setFigureParameter is not FigureParameter: " +
            figparam
        );
      if (
        figparam.defaultValue < figparam.minimum ||
        figparam.defaultValue > figparam.maximum
      )
        throw new Error(
          "defaultValue must be between minimum and maximum, not: " +
            figparam.defaultValue
        );
      let arr = go.Shape._FigureParameters[figurename];
      if (!arr) {
        arr = [];
        go.Shape._FigureParameters[figurename] = arr;
      }
      arr[index] = figparam;
    };
    go.Shape.defineFigureGenerator("Procedure", function(shape, w, h) {
      let geo = new go.Geometry();
      let param1 = shape ? shape.parameter1 : NaN;
      // Distance of left  and right lines from edge
      if (isNaN(param1)) param1 = 0.1;
      let fig = new go.PathFigure(0, 0, true);
      geo.add(fig);

      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close());
      let fig2 = new go.PathFigure((1 - param1) * w, 0, false);
      geo.add(fig2);
      fig2.add(new go.PathSegment(go.PathSegment.Line, (1 - param1) * w, h));
      fig2.add(new go.PathSegment(go.PathSegment.Move, param1 * w, 0));
      fig2.add(new go.PathSegment(go.PathSegment.Line, param1 * w, h));
      //??? geo.spot1 = new go.Spot(param1, 0);
      //??? geo.spot2 = new go.Spot(1 - param1, 1);
      return geo;
    });
    go.Shape.defineFigureGenerator("PredefinedLogic", function(shape, w, h) {
      let geo = new go.Geometry();
      let param1 = shape ? shape.parameter1 : NaN;
      if (isNaN(param1)) param1 = 0.1;
      let fig = new go.PathFigure(0, 0, true);
      geo.add(fig);

      // Body
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close());
      let fig2 = new go.PathFigure(0, param1 * h, false);
      geo.add(fig2);
      // Inside lines
      fig2.add(new go.PathSegment(go.PathSegment.Line, w, param1 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Move, 0, (1 - param1) * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, w, (1 - param1) * h));
      //??? geo.spot1 = new go.Spot(0, param1);
      //??? geo.spot2 = new go.Spot(1, 1 - param1);
      return geo;
    });

    go.Shape.defineFigureGenerator("MultiOption", function(shape, w, h) {
      let geo = new go.Geometry();
      let fig = new go.PathFigure(0, 0, true);
      geo.add(fig);
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close());
      return geo;
    });
    go.Shape._FigureParameters = {};
    go.Shape.setFigureParameter(
      "Parallelogram",
      0,
      new FigureParameter("Indent", 10, -Infinity, Infinity)
    );
    go.Shape.defineFigureGenerator("Parallelogram", function(shape, w, h) {
      let param1 = shape ? shape.parameter1 : NaN; // indent's x distance
      if (isNaN(param1)) param1 = 10;
      else if (param1 < -w) param1 = -w;
      else if (param1 > w) param1 = w;
      let indent = Math.abs(param1);
      let geo = new go.Geometry();
      if (param1 === 0) {
        geo = new go.Geometry(go.Geometry.Rectangle);
        geo.startX = 0;
        geo.startY = 0;
        geo.endX = w;
        geo.endY = h;
        return geo;
      } else {
        if (param1 > 0) {
          geo.add(
            new go.PathFigure(indent, 0)
              .add(new go.PathSegment(go.PathSegment.Line, w, 0))
              .add(new go.PathSegment(go.PathSegment.Line, w - indent, h))
              .add(new go.PathSegment(go.PathSegment.Line, 0, h).close())
          );
        } else {
          // param1 < 0
          geo.add(
            new go.PathFigure(0, 0)
              .add(new go.PathSegment(go.PathSegment.Line, w - indent, 0))
              .add(new go.PathSegment(go.PathSegment.Line, w, h))
              .add(new go.PathSegment(go.PathSegment.Line, indent, h).close())
          );
        }
        if (indent < w / 2) {
          geo.setSpots(indent / w, 0, (w - indent) / w, 1);
        }
        return geo;
      }
    });

    // Adding Node Template to diagram

    // define a simple Node template
    diagram.nodeTemplate = $(
      go.Node,
      "Auto",

      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      $(
        go.Shape,
        "RoundedRectangle",
        {
          name: "SHAPE",
          fill: "white",
          strokeWidth: 0,
          // set the port properties:
          portId: "",
          fromLinkable: true,
          toLinkable: true,
          cursor: "pointer",
        },
        // Shape.fill is bound to Node.data.color
        new go.Binding("fill", "color"),
        new go.Binding("fill", "error", (error, shape) =>
          error ? "red" : shape.node.data.color || "white"
        )
        // new go.Binding("fill", "error", function(error) {
        //   return error ? "red" : "";
        // })
      ),

      $(
        go.TextBlock,
        { margin: 8, editable: true, font: "400 .875rem Roboto, sans-serif" }, // some room around the text
        new go.Binding("text").makeTwoWay()
      )
    );

    const nodeStyle = () => [
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      {
        locationSpot: go.Spot.Center,
      },
    ];

    const makePort = (name, align, spot, output, input) => {
      const horizontal =
        align.equals(go.Spot.Top) || align.equals(go.Spot.Bottom);
      return $(go.Shape, {
        fill: "transparent",
        strokeWidth: 0,
        width: horizontal ? NaN : 8,
        height: !horizontal ? NaN : 8,
        alignment: align,
        stretch: horizontal
          ? go.GraphObject.Horizontal
          : go.GraphObject.Vertical,
        portId: name,
        fromSpot: spot,
        fromLinkable: output,
        toSpot: spot,
        toLinkable: input,
        fromLinkableDuplicates: true,
        toLinkableDuplicates: true,
        cursor: "pointer",
        mouseEnter(e, port) {
          if (!e.diagram.isReadOnly) port.fill = "rgba(255,0,255,0.5)";
        },
        mouseLeave(e, port) {
          port.fill = "transparent";
        },
      });
    };

    const textStyle = () => ({
      font: "bold 12px Lato, Helvetica, Arial, sans-serif",
      stroke: "#000000",
    });

    diagram.nodeTemplateMap.add(
      "Conditional",
      $(
        go.Node,
        "Table",
        nodeStyle(),
        $(
          go.Panel,
          "Auto",
          $(
            go.Shape,
            "Diamond",
            {
              fill: "#eaeaea",
              stroke: "#000000",
              strokeWidth: 1,
              width: 160,
              height: 100,
              portId: "",
            },
            new go.Binding("figure", "figure"),
            new go.Binding("fill", "color")
          ),
          $(
            go.TextBlock,
            textStyle(),
            {
              margin: 8,
              maxSize: new go.Size(105, NaN),
              alignment: go.Spot.Center,
              editable: false,
              isMultiline: true,
            },

            new go.Binding("text").makeTwoWay()
          )
        ),
        makePort("T", go.Spot.Top, go.Spot.Top, true, true),
        makePort("L", go.Spot.Left, go.Spot.Left, true, true),
        makePort("R", go.Spot.Right, go.Spot.Right, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.Bottom, true, true)
      )
    );

    diagram.nodeTemplateMap.add(
      "Start",
      $(
        go.Node,
        "Table",
        nodeStyle(),
        $(
          go.Panel,
          "Spot",
          $(
            go.Shape,
            "Circle",
            {
              desiredSize: new go.Size(75, NaN),
              fill: "#eaeaea",
              stroke: "#000000",
              strokeWidth: 1,
              width: 75,
              height: 75,
            },
            new go.Binding("fill", "color")
          ),
          $(go.TextBlock, "Start", textStyle(), new go.Binding("text"))
        ),
        makePort("L", go.Spot.Left, go.Spot.Left, true, true),
        makePort("R", go.Spot.Right, go.Spot.Right, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.Bottom, true, true)
      )
    );

    diagram.nodeTemplateMap.add(
      "End",
      $(
        go.Node,
        "Table",
        nodeStyle(),
        $(
          go.Panel,
          "Spot",
          $(
            go.Shape,
            "RoundedRectangle",
            {
              desiredSize: new go.Size(75, NaN),
              fill: "#eaeaea",
              stroke: "#000000",
              strokeWidth: 1,
              width: 75,
              height: 75,
            },
            new go.Binding("fill", "color")
          ),
          $(go.TextBlock, "End", textStyle(), new go.Binding("text"))
        ),
        makePort("T", go.Spot.Top, go.Spot.Top, false, true),
        makePort("L", go.Spot.Left, go.Spot.Left, false, true),
        makePort("R", go.Spot.Right, go.Spot.Right, false, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );

    diagram.nodeTemplateMap.add(
      "Triangle",
      $(
        go.Node,
        "Table",
        nodeStyle(),
        $(
          go.Panel,
          "Spot",
          $(
            go.Shape,
            "Triangle",
            {
              desiredSize: new go.Size(105, NaN),
              fill: "#eaeaea",
              stroke: "#000000",
              strokeWidth: 1,
              width: 105,
              height: 90,
            },
            new go.Binding("fill", "color")
          ),
          $(go.TextBlock, "End", textStyle(), new go.Binding("text"))
        ),
        makePort("T", go.Spot.Top, go.Spot.Top, false, true),
        makePort("L", go.Spot.Left, go.Spot.Left, true, true),
        makePort("R", go.Spot.Right, go.Spot.Right, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.Bottom, true, false)
      )
    );

    go.Shape.defineFigureGenerator("File", (shape, w, h) => {
      const geo = new go.Geometry();
      const fig = new go.PathFigure(0, 0, true);
      geo.add(fig);
      fig.add(new go.PathSegment(go.PathSegment.Line, 0.75 * w, 0));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.25 * h));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close());
      const fig2 = new go.PathFigure(0.75 * w, 0, false);
      geo.add(fig2);
      fig2.add(new go.PathSegment(go.PathSegment.Line, 0.75 * w, 0.25 * h));
      fig2.add(new go.PathSegment(go.PathSegment.Line, w, 0.25 * h));
      geo.spot1 = new go.Spot(0, 0.25);
      geo.spot2 = go.Spot.BottomRight;
      return geo;
    });

    diagram.nodeTemplateMap.add(
      "Comment",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Panel,
          "Auto",
          { width: 105, height: 90 },
          $(
            go.Shape,
            "File",
            {
              fill: "#eaeaea",
              stroke: "#000000",
              strokeWidth: 1,
            },
            new go.Binding("fill", "color")
          ),
          $(
            go.TextBlock,
            textStyle(),
            {
              margin: 8,
              maxSize: new go.Size(100, NaN),
              formatting: go.TextBlock.OverflowEllipsis,
              textAlign: "center",
              editable: false,
            },
            new go.Binding("text", "text", function(label) {
              return label.substring(0, 22);
            })
          )
        ),
        makePort("T", go.Spot.Top, go.Spot.Top, true, true),
        makePort("L", go.Spot.Left, go.Spot.Left, true, true),
        makePort("R", go.Spot.Right, go.Spot.Right, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );
    diagram.nodeTemplateMap.add(
      "LookUp",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Panel,
          "Auto",
          { width: 105, height: 90 },
          $(
            go.Shape,
            "LookUp",
            {
              fill: "#eaeaea",
              stroke: "#000000",
              strokeWidth: 1,
            },
            new go.Binding("fill", "color")
          ),
          $(
            go.TextBlock,
            textStyle(),
            {
              margin: 8,
              maxSize: new go.Size(100, NaN),
              formatting: go.TextBlock.OverflowEllipsis,
              textAlign: "center",
              editable: false,
            },
            new go.Binding("text", "text", function(label) {
              return label.substring(0, 22);
            })
          )
        ),
        makePort("T", go.Spot.Top, go.Spot.Top, true, true),
        makePort("L", go.Spot.Left, go.Spot.Left, true, true),
        makePort("R", go.Spot.Right, go.Spot.Right, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );

    diagram.nodeTemplateMap.add(
      "Procedure",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Shape,
          "Procedure",
          {
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 105,
            height: 90,
          },
          new go.Binding("fill", "color")
        ),

        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );

    diagram.nodeTemplateMap.add(
      "MultiAns",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Shape,
          "MultiAns",
          {
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 105,
            height: 90,
          },
          new go.Binding("fill", "color")
        ),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(95, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );
    diagram.nodeTemplateMap.add(
      "PassData",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Shape,
          "PassData",
          {
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 105,
          },
          new go.Binding("fill", "color")
        ),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 18,
            maxSize: new go.Size(105, NaN),
            alignment: go.Spot.Center,
            editable: false,
            isMultiline: true,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );

    diagram.nodeTemplateMap.add(
      "PredefinedLogic",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Shape,
          "PredefinedLogic",
          {
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 105,
            height: 90,
          },
          new go.Binding("fill", "color")
        ),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );
    diagram.nodeTemplateMap.add(
      "MultiOption",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Shape,
          "MultiOption",
          {
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 105,
            minSize: new go.Size(105, 95),
            maxSize: new go.Size(105, NaN),
          },
          new go.Binding("fill", "color")
        ),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: new go.Margin(10, 0, 0, 0),
            maxSize: new go.Size(105, NaN),
            alignment: go.Spot.Top,
            editable: false,
            isMultiline: true,
          },
          new go.Binding("text").makeTwoWay()
        ),
        $(
          go.Picture,
          "../../../media/svg/multioptionv2.svg",
          { alignment: go.Spot.Bottom, margin: 10 },
          { width: 54 }
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );
    diagram.nodeTemplateMap.add(
      "Parallelogram",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Shape,
          "Parallelogram",
          {
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 105,
            height: 90,
          },
          new go.Binding("fill", "color")
        ),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 10,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );
    diagram.nodeTemplateMap.add(
      "ManualInput",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Shape,
          "ManualInput",
          {
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 105,
            height: 90,
          },
          new go.Binding("fill", "color")
        ),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );

    diagram.nodeTemplateMap.add(
      "Cylinder1",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Shape,
          "Cylinder1",
          {
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 105,
            height: 90,
          },
          new go.Binding("fill", "color")
        ),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: true,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );
    diagram.nodeTemplateMap.add(
      "MultiDocument",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Panel,
          "Auto",
          { width: 105, height: 90 },
          $(
            go.Shape,
            "MultiDocument",
            {
              fill: "#eaeaea",
              stroke: "#000000",
              strokeWidth: 1,
              width: 105,
              height: 90,
            },
            new go.Binding("fill", "color")
          ),
          $(
            go.TextBlock,
            textStyle(),
            {
              margin: 8,
              maxSize: new go.Size(105, NaN),
              alignment: go.Spot.Center,
              editable: false,
              isMultiline: true,
            },
            new go.Binding("text", "text", function(label) {
              return label.substring(0, 22);
            })
          )
        ),

        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );

    diagram.nodeTemplateMap.add(
      "Document",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Shape,
          "Document",
          {
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 105,
            height: 90,
          },
          new go.Binding("fill", "color")
        ),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, false, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, false)
      )
    );

    diagram.nodeTemplateMap.add(
      "MultiProcess",
      $(
        go.Node,
        "Auto",
        nodeStyle(),
        $(
          go.Shape,
          "MultiProcess",
          {
            fill: "#eaeaea",
            stroke: "#000000",
            strokeWidth: 1,
            width: 105,
            height: 90,
          },
          new go.Binding("fill", "color")
        ),
        $(
          go.TextBlock,
          textStyle(),
          {
            margin: 8,
            maxSize: new go.Size(105, NaN),
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: false,
          },
          new go.Binding("text").makeTwoWay()
        ),
        makePort("T", go.Spot.Top, go.Spot.TopSide, true, true),
        makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
        makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
        makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, true)
      )
    );

    // 连接线处理
    diagram.linkTemplate = $(
      go.Link,
      {
        routing: go.Link.AvoidsNodes,
        curve: go.Link.JumpOver,
        corner: 5,
        toShortLength: 4,
        relinkableFrom: true,
        relinkableTo: true,
        reshapable: false,
        resegmentable: false,
        fromLinkableDuplicates: true,
        toLinkableDuplicates: true,
        mouseEnter(e, link) {
          link.findObject("HIGHLIGHT").stroke = "rgba(30,144,255,0.2)";
        },
        mouseLeave(e, link) {
          link.findObject("HIGHLIGHT").stroke = "transparent";
        },
        selectionAdorned: false,
      },
      new go.Binding("points").makeTwoWay(),
      $(go.Shape, {
        isPanelMain: true,
        strokeWidth: 8,
        stroke: "transparent",
        name: "HIGHLIGHT",
      }),

      $(
        go.Shape,
        {
          isPanelMain: true,
          stroke: "gray",
          strokeWidth: 2,
        },
        new go.Binding("stroke", "error", (sel) => (sel ? "red" : "gray"))
      ),
      $(go.Shape, {
        toArrow: "standard",
        strokeWidth: 0,
        fill: "gray",
      }),
      $(
        go.Panel,
        "Auto",
        {
          visible: false,
          name: "LABEL",
          segmentIndex: 2,
          segmentFraction: 0.5,
        },
        new go.Binding("visible", "visible").makeTwoWay(),
        $(go.Shape, "RoundedRectangle", {
          fill: "#F8F8F8",
          strokeWidth: 0,
        }),
        $(
          go.TextBlock,
          {
            textAlign: "center",
            font: "10pt helvetica, arial, sans-serif",
            stroke: "#000000",
            editable: true,
          },
          new go.Binding("text").makeTwoWay()
        )
      )
    );

    diagram.toolManager.linkingTool.temporaryLink.routing = go.Link.Orthogonal;
    diagram.toolManager.relinkingTool.temporaryLink.routing =
      go.Link.Orthogonal;

    return diagram;
  };

  diagram.addDiagramListener("ObjectSingleClicked", function(e) {
    const part = e.subject.part;
    if (!(part instanceof go.Link)) {
      const j = diagram.lastInput;
      const x =
        (diagramRef.current.getDiagram()?.div?.offsetLeft ?? 0) +
        j.viewPoint.x +
        420;
      const y =
        (diagramRef.current.getDiagram()?.div?.offsetTop ?? 0) +
        j.viewPoint.y +
        150;
      setToolTipPosition({ x: x, y: y });
      let textTool = "";
      if (part.data.metaData.type == "question") {
        textTool = part.data.metaData.label;
      } else if (part.data.category == "Comment") {
        textTool = part.data.metaData.comment;
      } else if (part.data.category != "Procedure") {
        textTool = part.data.text;
      } else if (part.data.metaData.equation != "") {
        textTool = part.data.metaData.equation;
      }
      if (textTool == "") {
        textTool = part.data.text;
      }
      setToolTipText(textTool);
      setShowToolTip(true);
    }
  });

  const initPalette = () => {
    const myPalette = $(
      go.Palette, // create a new Palette in the HTML DIV element
      {
        // share the template map with the Palette
        nodeTemplateMap: diagram.nodeTemplateMap,
        autoScale: go.Diagram.Uniform, // everything always fits in viewport
      }
    );

    myPalette.nodeTemplate = $(
      go.Node,
      "Vertical",
      $(go.Shape, { fill: "red" }, new go.Binding("fill", "color")),
      $(go.TextBlock, { stroke: "black" }, new go.Binding("text").makeTwoWay())
    );

    return myPalette;
  };

  // To create a form object of added diagram
  const addDiagramForm = (category) => {
    switch (category) {
      case "Conditional":
        return {
          selectInputOne: { variableName: "", fileInput: "" },
          selectInputTwo: { variableName: "", fileInput: "", textValue: "" },
          operator: "",
          type: "question",
          label: "",
          equation: "",
          shortLabel: "",
        };

      case "PredefinedLogic":
        return {
          procedure: "",
          label: "",
          variableMapping: [],
          nodeDisplayLabel: "",
        };

      case "Procedure":
        return {
          data: [],
          equation: "",
          name: "",
          variableLabel: "",
          localVariableName: "",
          nodeDisplayLabel: "",
          equationArrayFunction: [],
        };

      case "LookUp":
        return {
          inputs: [
            {
              variableLabel: "",
              variableValue: "",
              fileVariableSelection: false,
              localVariableName: "",
              showFilter: false,
              inputType: "String",
              fileValue: "",
              isEdit: true,
            },
          ],
          nodeDisplayLabel: "",
        };

      case "MultiOption":
      case "PassData":
        return {
          groupByColumn: "",
          displayColumnName: "",
          localVariableName: "",
          label: "",
          isSingle: false,
          inputType: "Array",
          variableLabel: "",
          hasGroupBy: false,
          nodeDisplayLabel: "",
          singleVariableName: "",
          isDistinct: false,
          distinctColumn: "",
        };

      case "MultiDocument":
        return {
          templateId: "",
          localVariableName: "",
          variableName: "",
          label: "",
          nodeDisplayLabel: "",
        };
      case "Comment":
        return {
          comment: "",
          nodeDisplayLabel: "",
        };

      case "Parallelogram":
        return {
          fileName: "",
          server: "",
          frequency: "",
          nodeDisplayLabel: "",
        };

      case "ManualInput":
        return {
          inputs: [
            {
              label: "",
              localVariableName: "",
              showFilter: false,
              variableLabel: "",
              inputType: "Number",
              constraints: "not_null",
            },
          ],
          heading: "",
          headingCheckBox: false,
          nodeDisplayLabel: "",
          isGridLayout: false,
        };

      case "MultiAns":
        return {
          question: "",
          nodeDisplayLabel: "",
        };
      default:
        return {};
    }
  };

  const modelChangeHandler = (obj) => {
    const insertedNodeKeys = obj.insertedNodeKeys;
    const modifiedNodeData = obj.modifiedNodeData;
    const removedNodeKeys = obj.removedNodeKeys;
    const insertedLinkKeys = obj.insertedLinkKeys;
    const modifiedLinkData = obj.modifiedLinkData;
    const removedLinkKeys = obj.removedLinkKeys;
    const modifiedModelData = obj.modelData;

    // maintain maps of modified data so insertions don't need slow LookUps
    const modifiedNodeMap = new Map();
    const modifiedLinkMap = new Map();
    updateDiagramData((draft) => {
      let checkForLink = false;

      let narr = draft.nodeDataArray;
      let mapNodeKeyIdx = draft.mapNodeKeyIdx;

      if (insertedNodeKeys) {
        insertedNodeKeys.forEach((key) => {
          let nd = modifiedNodeMap.get(key);
          const idx = Object.keys(mapNodeKeyIdx).find((v) => v === key);
          let index = narr.findIndex((item) => item.key === key);

          if (nd === undefined && idx === undefined && index < 0) {
            // nodes won't be added if they already exist
            nd = modifiedNodeData[0];
            mapNodeKeyIdx[key] = narr.length;

            // to add form fields in node
            nd.metaData = addDiagramForm(nd.category);
            narr.push(nd);
          }
        });
      }

      if (modifiedNodeData) {
        checkForLink = true;
        modifiedNodeData.forEach((nd) => {
          modifiedNodeMap.set(nd.key, nd);
          const idx = Object.keys(mapNodeKeyIdx).findIndex(
            (v) => parseInt(v) === nd.key
          );
          if (
            idx !== undefined &&
            idx >= 0 &&
            JSON.stringify(nd) !== JSON.stringify(narr[idx])
          ) {
            narr[idx] = nd;
            if (draft.selectedData && draft.selectedData.key === nd.key) {
              draft.selectedData = nd;
            }
          }
        });
      }

      if (removedNodeKeys) {
        narr = narr.filter((nd) => {
          if (removedNodeKeys.includes(nd.key)) {
            return false;
          }
          return true;
        });
        removedNodeKeys.forEach((key) => delete mapNodeKeyIdx[key]);
        draft.nodeDataArray = narr;
      }

      let larr = draft.linkDataArray;
      let mapLinkKeyIdx = draft.mapLinkKeyIdx;
      if (insertedLinkKeys) {
        insertedLinkKeys.forEach((key) => {
          let ld = modifiedLinkMap.get(key);

          const idx = Object.keys(mapLinkKeyIdx).find((v) => v === key);

          let index = larr.findIndex((item) => item.key === key);

          let linkData = modifiedLinkData.find((item) => item.key == key);

          if (ld === undefined && idx === undefined && index < 0) {
            // links won't be added if they already exist

            ld = linkData;
            mapLinkKeyIdx[key] = larr.length;
            larr.push(ld);
          }
        });
      }

      if (modifiedLinkData) {
        modifiedLinkData.forEach((ld) => {
          modifiedLinkMap.set(ld.key, ld);
          const idx = Object.keys(mapLinkKeyIdx).findIndex(
            (v) => parseInt(v) === ld.key
          );

          // to check if a text is added between link
          if (
            ld?.text !== undefined &&
            larr[idx]?.text !== ld?.text &&
            checkForLink === false
          ) {
            checkForLink = true;
          }

          if (idx !== undefined && idx >= 0 && checkForLink === true) {
            larr[idx] = ld;
            if (draft.selectedData && draft.selectedData.key == ld.key) {
              draft.selectedData = ld;
            }
          }
        });
      }

      if (removedLinkKeys) {
        larr = larr.filter((ld) => {
          if (removedLinkKeys.includes(ld.key)) {
            return false;
          }
          return true;
        });
        removedLinkKeys.forEach((key) => delete mapLinkKeyIdx[key]);
        draft.linkDataArray = larr;
      }

      // handle model data changes, for now just replacing with the supplied object
      if (modifiedModelData) {
        draft.modelData = modifiedModelData;
      }
      draft.skipsDiagramUpdate = true; // the GoJS model already knows about these updates
    });
  };
  const handleSaveData = (key, metaData) => {
    setSelectedNode((state) => ({ ...state, metaData }));
    updateDiagramData((draft) => {
      let narr = draft.nodeDataArray;
      let index = narr.findIndex((item) => item.key === key);
      let variableName = "";

      // Form Data save to node according to category

      switch (narr[index].category) {
        case "Conditional":
          narr[index].text = metaData.shortLabel;

          break;
        case "Procedure":
          narr[index].text = metaData.nodeDisplayLabel;
          variableName = metaData.name;
          break;
        case "LookUp":
          narr[index].text = metaData.nodeDisplayLabel;
          // variableName = metaData.name;
          break;
        case "PredefinedLogic":
          narr[index].text = metaData.nodeDisplayLabel;
          break;
        case "Parallelogram":
          narr[index].text = metaData.fileName;
          break;
        case "MultiOption":
          variableName = metaData?.isSingle
            ? metaData?.singleVariableName
            : metaData?.localVariableName;
          narr[index].text = metaData.nodeDisplayLabel;
          // narr[index].text = "Select " + metaData.label;
          break;
        case "MultiDocument":
          narr[index].text = metaData.nodeDisplayLabel;
          break;
        case "PassData":
          narr[index].text = metaData.nodeDisplayLabel;
          break;
        case "MultiAns":
          narr[index].metaData = metaData;
          narr[index].text = metaData.nodeDisplayLabel;
          break;
        case "Comment":
          narr[index].text =
            metaData.nodeDisplayLabel != ""
              ? metaData.nodeDisplayLabel
              : metaData.comment;
          break;
        default:
          break;
      }

      if (variableName !== "") {
        if (metaData?.isFetchFromLookup) {
          variableName = metaData?.singleVariableName;
        }
        let localVariableArrayOld = localVariableArray;
        let localVarialeIndex = localVariableArray.findIndex(
          (item) => item.key === key
        );

        let fileName = metaData.localVariableName;
        let variableLabel = metaData.variableLabel;
        if (narr[index].category === "MultiOption") {
          let toKey = key;
          let fromKey = diagramData.linkDataArray.find(
            (item) => item.to == toKey
          )?.from;
          if (
            diagramData.nodeDataArray.find((item) => item.key == fromKey)
              ?.category == "Conditional"
          ) {
            let toKeyForNextNode = diagramData.linkDataArray.find(
              (item) => item.to != toKey && item.from == fromKey
            )?.to;
            let otherNode = diagramData.nodeDataArray.find(
              (item) => item.key == toKeyForNextNode
            );
            if (otherNode.metaData.localVariableName != "") {
              variableName =
                variableName + "," + otherNode.metaData.localVariableName;
              fileName = fileName + "," + otherNode.metaData.localVariableName;
              variableLabel =
                variableLabel + "," + otherNode.metaData.variableLabel;
            }
          }
        }
        if (localVarialeIndex < 0) {
          localVariableArrayOld.push({
            key: key,
            variableName: variableName,
            category: narr[index].category,
            variableLabel: variableLabel,
            isSingle:
              metaData?.isSingle == undefined ? false : metaData?.isSingle,
            fileName: fileName,
          });
          // setLocalVariableArray([...localVariableArray,])
        } else {
          localVariableArrayOld[localVarialeIndex].variableName = variableName;
          localVariableArrayOld[
            localVarialeIndex
          ].variableLabel = variableLabel;
          localVariableArrayOld[localVarialeIndex].isSingle =
            metaData?.isSingle == undefined ? false : metaData?.isSingle;
        }
        setLocalVariableArray(localVariableArrayOld);
      }
      narr[index].metaData = metaData;
      if (
        narr[index].category === "ManualInput" ||
        narr[index].category === "LookUp"
      ) {
        let localVariableArrayFilters = localVariableArray.filter(
          (item) => item.key !== key
        );
        let text = "";
        metaData.inputs.map((item) => {
          text =
            text != ""
              ? text + "," + item.localVariableName
              : item.localVariableName;
          localVariableArrayFilters.push({
            key: key,
            variableName: item.localVariableName,
            category: narr[index].category,
            variableLabel: item.variableLabel,
            isSingle:
              metaData?.isSingle == undefined ? false : metaData?.isSingle,
            fileName: metaData?.localVariableName,
            fileIdentityValue: item?.fileValue?.identityValue,
          });
        });
        narr[index].metaData = metaData;
        narr[index].text = metaData.nodeDisplayLabel;
        setLocalVariableArray(localVariableArrayFilters);
      }

      if (narr[index].category === "PredefinedLogic") {
        localVariableArray.map((item) => {
          let localVariableIndex = narr.findIndex(
            (node) => node.key === item.key
          );

          let serverVariableIndex = metaData.variableMapping.findIndex(
            (variable) => variable.localVariableName == item.variableName
          );
        });
      }

      draft.nodeDataArray = narr;
    });
    handleClose();
  };
  const handleSave = (dataForSave) => {
    let data = {
      questionId: id,
      linkDataArray: dataForSave.linkDataArray,
      nodeDataArray: dataForSave.nodeDataArray,
    };

    dispatch(DIAGRAM_ACTIONS.add(data));
  };
  const validateDiagram = (status) => {
    if (diagramData.linkDataArray.length > 0) {
      let diagramNodeData = diagramData.nodeDataArray;
      let reportNodeCount = diagramNodeData.filter(
        (nodeData) => nodeData.category == "MultiDocument"
      ).length;

      let startNodeCount = diagramNodeData.filter(
        (nodeData) => nodeData.category == "Start"
      ).length;
      let endNodeCount = diagramNodeData.filter(
        (nodeData) => nodeData.category == "End"
      ).length;

      let diagramLinkData = [];
      diagramData.linkDataArray.map((linkData) => {
        diagramLinkData.push(linkData.from);
        diagramLinkData.push(linkData.to);
        return true;
      });
      let comparision = diagramNodeData.filter((nodeData) => {
        return diagramLinkData.indexOf(nodeData.key) === -1;
      });
      if (comparision.length > 0) {
        setToast("Please connect remaining nodes", "error", "top-center");
        setLoader(false);
      } else if (startNodeCount > 1) {
        setToast("Please add only one Start node", "error", "top-center");
        setLoader(false);
      } else if (startNodeCount == 0) {
        setToast("Please add Start node", "error", "top-center");
        setLoader(false);
      } else if (endNodeCount > 1) {
        setToast("Please add only one End node", "error", "top-center");
        setLoader(false);
      } else if (endNodeCount == 0) {
        setToast("Please add End node", "error", "top-center");
        setLoader(false);
      } else if (reportNodeCount > 1) {
        setToast("Please add only one report node", "error", "top-center");
        setLoader(false);
      } else {
        let startLinkValidation = [];
        let nodeLinkValidation = [];
        let linkArray = [];
        let nodeTextValidationForArray = [];
        let nodeTextVarificationMultiOption = [];
        let nodeTextVarificationPredefinedLogic = [];
        let nodeTextVarificationReport = [];
        let nodeTextVarificationMannualInput = [];
        let nodeTextVarificationLookUp = [];
        let conditionalYesNovalidation = [];
        const connectLinkForCondition = diagramData.nodeDataArray.map(
          (node, nodeIndex) => {
            let isConditionAllConnect = true;
            //Conditional Node Link Validation
            if (node.category === "Conditional") {
              // In link validation
              let toLinkFor = diagramData.linkDataArray.filter(
                (link) => link.to === node.key
              );

              if (toLinkFor.length < 1) {
                isConditionAllConnect = false;
                let conditionlLinkDataIndex = diagramData.linkDataArray.reduce(
                  function(a, link, i) {
                    if (link.to === node.key) a.push(i);
                    return a;
                  },
                  []
                );
                conditionlLinkDataIndex.map((index) => {
                  linkArray[index] = !isConditionAllConnect;
                });
              }
              // From link count validation
              let filteredLink = diagramData.linkDataArray.filter(
                (link) => link.from === node.key
              );
              if (filteredLink.length != 2) {
                isConditionAllConnect = false;
              }
              // From Yes and link validation
              let filteredLinkYes = diagramData.linkDataArray.filter(
                (link) =>
                  link.from === node.key &&
                  link.text != undefined &&
                  link.text.toLowerCase() === "yes"
              );
              let filteredLinkNo = diagramData.linkDataArray.filter(
                (link) =>
                  link.from === node.key &&
                  link.text != undefined &&
                  link.text.toLowerCase() === "no"
              );
              if (
                (filteredLink.length === 2 && filteredLinkNo.length !== 1) ||
                filteredLinkYes.length !== 1
              ) {
                isConditionAllConnect = false;
                conditionalYesNovalidation.push(false);
              } else {
                conditionalYesNovalidation.push(true);
              }
              let conditionlLinkDataIndexLINK = diagramData.linkDataArray.reduce(
                function(a, link, i) {
                  if (link.from === node.key) a.push(i);
                  return a;
                },
                []
              );
              conditionlLinkDataIndexLINK.map((index) => {
                linkArray[index] = !isConditionAllConnect;
              });
            }

            //Start Node Link Validation
            if (node.category === "Start") {
              let startLink = true;
              //Out Link Validation
              let fromLinkFor = diagramData.linkDataArray.filter(
                (link) => link.from === node.key
              );
              let startLinkDataIndex = diagramData.linkDataArray.reduce(
                function(a, link, i) {
                  if (link.from === node.key) a.push(i);
                  return a;
                },
                []
              );
              if (fromLinkFor.length != 1) {
                startLink = false;
              }
              startLinkDataIndex.map((index) => {
                linkArray[index] = !startLink;
              });
              startLinkValidation.push(startLink);
            }
            //Multioption, Predefined Process, predefined Login, Manual Input , Report Validation
            if (
              node.category === "MultiOption" ||
              node.category === "PassData" ||
              node.category === "Procedure" ||
              node.category === "PredefinedLogic" ||
              node.category === "ManualInput" ||
              node.category === "ManualInput" ||
              node.category === "LookUp" ||
              node.category === "Comment"
            ) {
              let nodeTextValidation = false;
              if (
                node.category === "MultiOption" ||
                node.category === "PassData"
              ) {
                if (node.metaData.label === "") {
                  nodeTextValidationForArray[nodeIndex] = !nodeTextValidation;
                } else {
                  nodeTextValidationForArray[nodeIndex] = nodeTextValidation;
                  nodeTextValidation = true;
                }
                nodeTextVarificationMultiOption.push(nodeTextValidation);
              }
              if (node.category === "PredefinedLogic") {
                if (node.metaData.label === "") {
                  nodeTextValidationForArray[nodeIndex] = !nodeTextValidation;
                } else {
                  nodeTextValidationForArray[nodeIndex] = nodeTextValidation;
                  nodeTextValidation = true;
                }
                nodeTextVarificationPredefinedLogic.push(nodeTextValidation);
              }
              if (node.category === "ManualInput") {
                if (node.metaData.label === "") {
                  nodeTextValidationForArray[nodeIndex] = !nodeTextValidation;
                } else {
                  nodeTextValidationForArray[nodeIndex] = nodeTextValidation;
                  nodeTextValidation = true;
                }
                nodeTextVarificationMannualInput.push(nodeTextValidation);
              }

              if (node.category === "LookUp") {
                if (node.metaData.inputs[0].localVariableName === "") {
                  nodeTextValidationForArray[nodeIndex] = !nodeTextValidation;
                } else {
                  nodeTextValidationForArray[nodeIndex] = nodeTextValidation;
                  nodeTextValidation = true;
                }
                nodeTextVarificationLookUp.push(nodeTextValidation);
              }
              if (node.category === "MultiDocument") {
                if (node.metaData.templateId === "") {
                  nodeTextValidationForArray[nodeIndex] = !nodeTextValidation;
                } else {
                  nodeTextValidationForArray[nodeIndex] = nodeTextValidation;
                  nodeTextValidation = true;
                }
                nodeTextVarificationReport.push(nodeTextValidation);
              }
              let linkValidation = true;
              let fromLinkFor = diagramData.linkDataArray.filter(
                (link) => link.from === node.key
              );

              if (fromLinkFor.length != 1) {
                linkValidation = false;
                let linkValidationDataIndex = diagramData.linkDataArray.reduce(
                  function(a, link, i) {
                    if (link.from === node.key) a.push(i);
                    return a;
                  },
                  []
                );
                linkValidationDataIndex.map((index) => {
                  linkArray[index] = !linkValidation;
                });
              }
              let toLinkFor = diagramData.linkDataArray.filter(
                (link) => link.to === node.key
              );

              if (toLinkFor.length === 0) {
                linkValidation = false;
                let linkValidationDataIndex = diagramData.linkDataArray.reduce(
                  function(a, link, i) {
                    if (link.to === node.key) a.push(i);
                    return a;
                  },
                  []
                );
                linkValidationDataIndex.map((index) => {
                  linkArray[index] = !linkValidation;
                });
              }
              nodeLinkValidation.push(linkValidation);
            }
            return isConditionAllConnect;
          }
        );

        let filterFalseForLinkCondition = connectLinkForCondition.filter(
          (item) => item === false
        );
        let filterFalseForLinkStart = startLinkValidation.filter(
          (item) => item === false
        );
        let filterFalseForNodeLinkValidation = nodeLinkValidation.filter(
          (item) => item === false
        );
        let filterConditionalYesNoValidation = conditionalYesNovalidation.filter(
          (item) => item === false
        );
        let filterNodeTextVarificationMultiOption = nodeTextVarificationMultiOption.filter(
          (item) => item === false
        );
        let filterNodeTextVarificationMannualInput = nodeTextVarificationMannualInput.filter(
          (item) => item === false
        );
        let filterNodeTextVarificationLookUp = nodeTextVarificationLookUp.filter(
          (item) => item === false
        );
        let filterNodeTextVarificationPredefinedLogic = nodeTextVarificationPredefinedLogic.filter(
          (item) => item === false
        );
        let filterNodeTextVarificationReport = nodeTextVarificationReport.filter(
          (item) => item === false
        );
        if (
          filterNodeTextVarificationMultiOption.length > 0 ||
          filterNodeTextVarificationPredefinedLogic.length > 0 ||
          filterNodeTextVarificationMannualInput.length > 0 ||
          filterNodeTextVarificationLookUp.length > 0 ||
          filterNodeTextVarificationReport.length > 0
        ) {
          updateDiagramData((draft) => {
            let narr = draft.nodeDataArray;

            nodeTextValidationForArray.map((value, index) => {
              narr[index].color = value ? "red" : "#eaeaea";
              narr[index].error = true;
              return true;
            });
            draft.nodeDataArray = narr;
          });
          if (filterNodeTextVarificationMultiOption.length > 0) {
            setLoader(false);
            setToast(
              "Please make Selection for MultiOption",
              "error",
              "top-center"
            );
          } else if (filterNodeTextVarificationPredefinedLogic.length > 0) {
            setLoader(false);
            setToast(
              "Please make Selection for Predefined Logic",
              "error",
              "top-center"
            );
          } else if (filterNodeTextVarificationMannualInput.length > 0) {
            setLoader(false);
            setToast(
              "Please Enter Values in Mannual Input",
              "error",
              "top-center"
            );
          } else if (filterNodeTextVarificationLookUp.length > 0) {
            setLoader(false);
            setToast("Please Enter Values in Look Up", "error", "top-center");
          } else if (filterNodeTextVarificationReport.length > 0) {
            setLoader(false);
            setToast("Please Select Valid Report", "error", "top-center");
          }
        } else if (
          filterFalseForLinkStart.length > 0 ||
          filterFalseForLinkCondition.length > 0 ||
          filterFalseForNodeLinkValidation.length > 0 ||
          filterConditionalYesNoValidation.length > 0
        ) {
          updateDiagramData((draft) => {
            let larr = draft.linkDataArray;

            linkArray.map((value, index) => {
              larr[index].error = value;
              return true;
            });
            draft.linkDataArray = larr;
          });

          if (filterFalseForLinkStart.length > 0) {
            setLoader(false);
            setToast("Please Check Start Node", "error", "top-center");
          } else if (
            filterFalseForLinkCondition.length > 0 &&
            filterConditionalYesNoValidation.length === 0
          ) {
            setLoader(false);
            setToast(
              "Please Check Conditional Node Links(Diamond Shape)",
              "error",
              "top-center"
            );
          } else if (filterConditionalYesNoValidation.length > 0) {
            setLoader(false);
            setToast(
              "Please Check Conditional Node Link for Label",
              "error",
              "top-center"
            );
          } else if (filterFalseForNodeLinkValidation.length > 0) {
            setLoader(false);
            setToast("Please Connect node properly", "error", "top-center");
          }
        } else {
          let dataLoadCopyLinkDataArray = [...diagramData.linkDataArray];

          let linkValidationDataIndex = dataLoadCopyLinkDataArray.reduce(
            function(a, link, i) {
              if (link.error === true) a.push(i);
              return a;
            },
            []
          );

          linkValidationDataIndex.map((index) => {
            Object.freeze(dataLoadCopyLinkDataArray[index]);
            let newObject = { ...dataLoadCopyLinkDataArray[index] };
            newObject.error = false;
            dataLoadCopyLinkDataArray[index] = newObject;
          });
          let dataLoadCopyNodeDataArray = [...diagramData.nodeDataArray];

          let nodeValidationDataIndex = dataLoadCopyNodeDataArray.reduce(
            function(a, node, i) {
              if (node.error === true) a.push(i);
              return a;
            },
            []
          );

          nodeValidationDataIndex.map((index) => {
            Object.freeze(dataLoadCopyNodeDataArray[index]);
            let newObject = { ...dataLoadCopyNodeDataArray[index] };
            newObject.error = false;
            newObject.color = "#eaeaea";
            dataLoadCopyNodeDataArray[index] = newObject;
          });

          if (status == "edit") {
            setTimeout(() => {
              let dataLoad = {
                linkDataArray: dataLoadCopyLinkDataArray,
                nodeDataArray: dataLoadCopyNodeDataArray,
              };
              handleUpdate(dataLoad);
            }, 3000);
          } else {
            let dataLoad = {
              linkDataArray: dataLoadCopyLinkDataArray,
              nodeDataArray: dataLoadCopyNodeDataArray,
            };
            handleSave(dataLoad);
          }
        }
      }
    } else {
      setLoader(false);
      setToast("Please connect all nodes", "error", "top-center");
    }
  };
  const handleEditSave = () => {
    setOpenUpdateAlert(false);
    setLoader(true);
    validateDiagram("edit");
  };
  const handleUpdate = (dataLoad) => {
    let data = {
      questionId: id,
      linkDataArray: dataLoad.linkDataArray,
      nodeDataArray: dataLoad.nodeDataArray,
    };

    dispatch(DIAGRAM_ACTIONS.update(data));
  };

  const handleClose = () => {
    setFormChange(!formChange);
  };

  const handleDelete = () => {
    setOpenDeleteAlert(true);
    // setFormChange(!formChange)
  };
  const deleteDiagramConfirmation = () => {
    dispatch(DIAGRAM_ACTIONS.delete(id));
    setOpenDeleteAlert(false);
  };
  useEffect(() => {
    if (!diagramRef.current) return;
    const diagram = diagramRef.current.getDiagram();
    if (diagram instanceof go.Diagram) {
      // ZoomSlider state/events
      _initialScale = diagram.scale;
      diagram.addDiagramListener("ViewportBoundsChanged", scaleToValue);
    }
  }, []);
  const [zoomValue, setZoomValue] = useState(1);

  const valueToScale = (val) => {
    if (!diagramRef.current) return;
    const diagram = diagramRef.current.getDiagram();
    if (diagram instanceof go.Diagram) {
      const A = _initialScale;
      const B = diagram.commandHandler.zoomFactor;
      diagram.scale = A * Math.pow(B, val);
    }
  };

  const scaleToValue = (e) => {
    const diagram = e.diagram;
    if (diagram instanceof go.Diagram) {
      const A = _initialScale;
      const B = diagram.commandHandler.zoomFactor;
      const y1 = diagram.scale;
      setZoomValue(Math.round(Math.log(y1 / A) / Math.log(B)));
    }
  };

  useEffect(() => {
    if (diagramError.hasError) {
      setDiagramErrorModal(true);
      updateDiagramData((draft) => {
        let narr = draft.nodeDataArray;
        const arrayOfObjects = diagramError?.errors?.errorFields;

        arrayOfObjects.forEach((obj) => {
          let errorNodeIndex = narr.findIndex(
            (item) => item.key == obj.errorNode.key
          );

          narr[errorNodeIndex].color = "red";
          narr[errorNodeIndex].error = true;
        });

        draft.nodeDataArray = narr;
      });
    }
  }, [diagramError]);

  return (
    <div className={`card-custom diagram-card`}>
      {loader && (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      )}
      <div className="card-header border-0 py-5 custom-header d-flex align-items-center justify-content-between">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link
            to={{
              pathname: `/toolbox/${id}`,
              state: {
                subtopicId: questionDetails.topicId,
                topicId: subTopicDetails?._id,
              },
            }}
          >
            <IconButton
              className="btn btn-icon btn-light btn-hover-primary btn-sm"
              aria-label="detail"
              style={{
                marginRight: 10,
                height: 34,
                width: 34,
                borderRadius: 5,
                color: "#3699FF",
              }}
            >
              <ArrowBackIos className="back" />
            </IconButton>
          </Link>
          <h3 className="card-title flex-column m-0">
            <span className="card-label font-weight-bolder text-dark ">
              {question !== "" ? question : ""}
            </span>
          </h3>
        </div>
        <div>
          <h1>Design Studio</h1>
        </div>
        <div className="card-toolbar">
          {isEdit ? (
            <>
              <a
                onClick={() => {
                  setOpenUpdateAlert(true);
                }}
                className="btn btn-success font-weight-bolder font-size-sm mr-5"
              >
                <span className="svg-icon svg-icon-md svg-icon-white">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Save.svg")}
                    className="h-50 align-self-center"
                  ></SVG>
                </span>
                Update
              </a>
              <a
                onClick={() => {
                  handleDelete();
                }}
                className="btn btn-success font-weight-bolder font-size-sm"
              >
                <span className="svg-icon svg-icon-md svg-icon-white">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                    className="h-50 align-self-center"
                  ></SVG>
                </span>
                Delete
              </a>
            </>
          ) : (
            <a
              onClick={() => {
                validateDiagram();
              }}
              className="btn btn-success font-weight-bolder font-size-sm"
            >
              <span className="svg-icon svg-icon-md svg-icon-white">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Save.svg")}
                  className="h-50 align-self-center"
                ></SVG>
              </span>
              Save
            </a>
          )}
        </div>
      </div>
      <div className="pt-6">
        <div className="diagram-row">
          <ReactPalette
            initPalette={initPalette}
            divClassName="palette-col"
            nodeDataArray={diagramData.paletteDataArray}
            skipsDiagramUpdate={true}
          />
          <div style={{ position: "relative" }}>
            <ReactDiagram
              initDiagram={initDiagram}
              ref={diagramRef}
              divClassName="diagram-col"
              linkFromPortIdProperty="fromPort"
              linkToPortIdProperty="toPort"
              nodeDataArray={diagramData.nodeDataArray}
              linkDataArray={diagramData.linkDataArray}
              onModelChange={modelChangeHandler}
            />
            <ZoomSlider value={zoomValue} valueToScale={valueToScale} />
          </div>
        </div>
        <div>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={formChange}
            onHide={() => {
              handleClose();
            }}
          >
            <Modal.Body>
              <label className="mt-2 pb-3">&nbsp;</label>
              <IconButton
                position="top right"
                aria-label="close"
                onClick={() => {
                  handleClose();
                }}
                style={{
                  position: "absolute",
                  zIndex: 1111,
                  right: 22,
                  top: 17,
                  color: "black",
                }}
              >
                <CloseIcon />
              </IconButton>
              <Form
                save={handleSaveData}
                nodeDataArray={selectedNode}
                formToShow={formToShow}
                localVariableArray={localVariableArray}
                linkNodeArray={linkNodeArray}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openDeleteAlert}
        onHide={() => {
          setOpenDeleteAlert(false);
        }}
      >
        <Modal.Body>
          <h5 className="mt-2 pb-4 text-center">
            Are you sure want to Delete Diagram?
          </h5>
          <div className="mb-0 p-0 d-flex justify-content-center">
            <Button
              variant="secondary"
              className="w-100px mr-2"
              onClick={() => {
                setOpenDeleteAlert(false);
              }}
            >
              Close
            </Button>
            <Button
              variant="danger"
              className="w-100px"
              onClick={() => {
                deleteDiagramConfirmation();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openUpdateAlert}
        onHide={() => {
          setOpenUpdateAlert(false);
        }}
      >
        <Modal.Body>
          <h5 className="mt-2 pb-4 text-center">
            Are you sure want to Update Diagram?
          </h5>
          <div className="mb-0 p-0 d-flex justify-content-center">
            <Button
              variant="secondary"
              className="w-100px mr-2"
              onClick={() => {
                setOpenUpdateAlert(false);
              }}
            >
              Close
            </Button>
            <Button
              variant="danger"
              className="w-100px"
              onClick={() => {
                handleEditSave();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={diagramErrorModal}
        onHide={() => {
          setDiagramErrorModal(false);
        }}
      >
        <Modal.Body>
          {diagramError?.errors?.errorFields.length > 0 && (
            <>
              <ul>
                {diagramError?.errors?.errorFields.map((item) => {
                  return (
                    <li key={item.message} style={{ color: "red" }}>
                      {item.message}
                    </li>
                  );
                })}
              </ul>
            </>
          )}

          <div className="mb-0 p-0 d-flex justify-content-center">
            <Button
              variant="secondary"
              className="w-100px mr-2"
              onClick={() => {
                setDiagramErrorModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {showToolTip && (
        <>
          <CustomToolTip
            toolTipPosition={toolTipPosition}
            toolTipText={toolTipText}
          />
        </>
      )}

      <HelpIcon pageName="ToolBoxDesignStudioSection" />
    </div>
  );
}
