import React, {  useState } from "react";
import { Button, Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Services } from "../../../../_service/Services";
import { setToast } from "../../../../app/modules/toast";


export function ResetPassword({ onHide, user, ...rest }) {
  const [passwordDetails, setPasswordDetails] = useState({
    old: "",
    new1: "",
    new2: "",
  });
  const [error, setError] = useState("");
  

  const handleInputChange = (key, event) => {
    const { value } = event.target;
    setError("");
    setPasswordDetails((oldVal) => ({ ...oldVal, [key]: value }));
  };
  const handleSubmit = () => {
    
    if (passwordDetails.new1) {
      if (
        passwordDetails.new1.length > 8 &&
        passwordDetails.new1.length <= 15
      ) {
        Services({
          url: `/admin/user/${user._id}/password`,
          method: "PUT",
          data: {
            password: passwordDetails.new1,
          },
        })
          .then((res) => {
            setToast(res?.data?.message, "success");
            onHide();
          })
          .catch((apiError) => {
            apiError?.response?.data?.message &&
              setToast(apiError?.response?.data?.message, "error");
            return Promise.reject(apiError);
          });
      } else {
        setError(
          "Error: Length must be greater than 8 and less than or equal to 15 characters!"
        );
      }
    } else {
      setError("field value should not be empty");
    }
  };

  const handleHide = () => {
    setPasswordDetails({
      old: "",
      new1: "",
      new2: "",
    });
    onHide();
  };

  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleHide}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">Reset Password</h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={handleHide}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        {error && (
          <div
            style={{
              textAlign: "center",
              fontSize: 12,
              marginBottom: 5,
            }}
            className="error"
          >
            {error}
          </div>
        )}
        
        <div className="form-group fv-plugins-icon-container mb-5">
          <label className="form-label text-dark-50 font-weight-bolder">
            {`${user?.firstName} ${user?.lastName} (${user.email})`}
          </label>
          <input
            placeholder="Enter new password..."
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="text"
            value={passwordDetails.new1}
            onChange={handleInputChange.bind(this, "new1")}
          />
        </div>
        
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-0 mb-8">
        <Button className="w-100px btn-primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
