import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";
import * as TRAVERS_HISTORY_SERVICES from "../../../../_service/traversHistory/traversHistory.service";

export const actionTypes = {
  LOADING: "[TRAVERS_HISTORY_LOADING] Action",
  GET_TRAVERS_HISTORY_DATA: "[GET_TRAVERS_HISTORY_DATA] Action",
  GET_TRAVERS_HISTORY_DATA_API_CALL: "[GET_TRAVERS_HISTORY_DATA_API_CALL] Action",
  GET_TRAVERS_INPUT_DATA: "[GET_TRAVERS_INPUT_DATA] Action",
  GET_TRAVERS_INPUT_DATA_API_CALL: "[GET_TRAVERS_INPUT_DATA_API_CALL] Action",
  ERROR: "[TRAVERS_HISTORY_ERROR] Action",

};

const initialDaigramState = {
  isLoading: false,
  error: "",
  redirect: false,
  diagramData: {
    nodeDataArray: [],
    linkDataArray: [],
    userId: "",
    firstName: "",
    lastName: "",
  },
  traversInputs : {
    item:[],
    displayColumn:[]
  }

};

export const reducer = persistReducer(

  {
    storage, key: "travers-history"
  },
  (state = initialDaigramState, action) => {
    switch (action.type) {
      case actionTypes.GET_TRAVERS_HISTORY_DATA: {
        const { diagramData } = action.payload;

        return { ...state, diagramData };
      }
      case actionTypes.GET_TRAVERS_INPUT_DATA: {
        const { inputDetails } = action.payload;

        return { ...state, traversInputs:inputDetails };
      }

      case actionTypes.LOADING: {
        const { isLoading } = action.payload;

        return { ...state, isLoading };
      }

      case actionTypes.ERROR: {
        const { error } = action.payload;

        return { ...state, error };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  isLoading: (isLoading) => ({
    type: actionTypes.LOADING,
    payload: { isLoading },
  }),
  redirect: (isTrue) => ({
    type: actionTypes.REDIRECT,
    payload: { isTrue },
  }),
  error: (error) => ({ type: actionTypes.ERROR, payload: { error } }),
  getDiagramTraversHistoryDetail: (traversId) => ({ type: actionTypes.GET_TRAVERS_HISTORY_DATA_API_CALL, payload: { traversId } }),
  getDiagramTraversHistoryData: (diagramData) => ({ type: actionTypes.GET_TRAVERS_HISTORY_DATA, payload: { diagramData } }),
  getTraversHistoryInputDetailsApi: (traversId) => ({ type: actionTypes.GET_TRAVERS_INPUT_DATA_API_CALL, payload: { traversId } }),
  getTraversHistoryInputDetails: (inputDetails) => ({ type: actionTypes.GET_TRAVERS_INPUT_DATA, payload: { inputDetails } }),

};

export function* saga() {

  yield takeLatest(actionTypes.GET_TRAVERS_HISTORY_DATA_API_CALL, function* getDiagramTraversHistoryDetailSaga(action) {
    yield put(actions.isLoading(true));
    
    try {
      const { traversId } = action.payload;
      const response = yield call(TRAVERS_HISTORY_SERVICES.getDiagramDetailForTraversHistory, traversId);

      const diagramDetails = response.data?.data;
      
      yield put(
        actions.getDiagramTraversHistoryData({
          nodeDataArray: diagramDetails.nodeDataArray,
          linkDataArray: diagramDetails.linkDataArray,
          userId: diagramDetails.userId,
          firstName: diagramDetails.firstName,
          lastName: diagramDetails.lastName,
          question:diagramDetails.question
        } || initialDaigramState.diagramData)
      );
    } catch (error) {
      yield put(actions.error("Failed Get Diagram!"));
      yield put(
        actions.getDiagramTraversHistoryData(initialDaigramState.diagramData)
      );
    }
    yield put(actions.isLoading(false));
  });

 yield takeLatest(actionTypes.GET_TRAVERS_INPUT_DATA_API_CALL, function* getDiagramTraversHistoryDetailSaga(action) {
    yield put(actions.isLoading(true));
    actions.getDiagramTraversHistoryData([])
    try {
      const { traversId } = action.payload;
      const response = yield call(TRAVERS_HISTORY_SERVICES.getInputDetailForTraversHistory, traversId);
      const inputDetails = response.data?.data;
      
      yield put(
        actions.getTraversHistoryInputDetails(inputDetails ||[])
      );
    } catch (error) {
      yield put(actions.error("Failed Get Input Variables!"));
      yield put(
        actions.getDiagramTraversHistoryData(initialDaigramState.diagramData)
      );
    }
    yield put(actions.isLoading(false));
  });
}
