import React, { useEffect, useState } from "react";
import { Switch, FormControlLabel } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../../../../app/modules/toast";
import { actions as DIAGRAM_ACTIONS } from "../../../../../app/modules/Diagram/_redux/DiagramRedux";
const ManualInput = ({ formData, localVariableArray, nodeKey, save }) => {
  const dispatch = useDispatch();
  const [inputData, setInputData] = useState(
    formData.inputs || [
      {
        label: "",
        localVariableName: "",
        showFilter: false,
        inputType: "Number",
        constraints: "not_null",
      },
    ]
  );

  const [heading, setHeading] = useState(formData.heading);
  const [headingCheckBox, handleHeadingCheckBox] = useState(
    formData.headingCheckBox
  );
  const [nodeDisplayLabel, setNodeDisplayLabel] = useState(
    formData?.nodeDisplayLabel
  );
  const [nodeDisplayLabelLength, setNodeDisplayLabelLength] = useState(
    formData?.nodeDisplayLabel?.length
  );
  const handleNodeLabel = (value) => {
    setNodeDisplayLabel(value);
    setNodeDisplayLabelLength(value.length);
  };

  const [isGridLayout, handleIsGridLayout] = useState(formData.isGridLayout);

  const manualInputData = useSelector((store) => store.diagram.manualInputData);
  useEffect(() => {
    dispatch(DIAGRAM_ACTIONS.getManualInputVariablesListAPI("ManualInput"));
    if (localVariableArray.length > 0) {
      localVariableArray.sort((variableFirst, variableSecond) => {
        const variableLabelFirst = variableFirst?.variableLabel?.toLowerCase(); // Convert names to lowercase for case-insensitive sorting
        const variableLabelSecond = variableSecond?.variableLabel?.toLowerCase();

        if (variableLabelFirst < variableLabelSecond) return -1;
        if (variableLabelFirst > variableLabelSecond) return 1;
        return 0;
      });
    }
  }, []);
  const [filterVariable, setFilterVariable] = useState([]);
  const [errorValidationArray, setErrorValidation] = useState([]);

  const [showFilterVariable, setShowFilterVariable] = useState(false);
  const handleInputChange = (index, event) => {
    let name = event.target.name;
    let value = event.target.value;
    const oldInput = [...inputData];
    Object.freeze(oldInput[index]);
    let newObject = { ...oldInput[index] };

    if (name === "localVariableName") {
      handleVariableSelection(value);
      newObject.showFilter = true;
    }
    newObject[name] = value;
    oldInput[index] = newObject;
    setInputData(oldInput);
  };
  const handleVariableSelection = (value, index) => {
    let fullVal = value;
    if (fullVal != "") {
      setShowFilterVariable(true);
      let newfilterVariable = manualInputData.filter((variable) => {
        return variable?.name?.toLowerCase().includes(fullVal?.toLowerCase());
      });
      setFilterVariable(newfilterVariable);
    } else {
      setShowFilterVariable(false);
    }
  };

  const handleSelectVariable = (variable, dataIndex) => {
    setShowFilterVariable(false);
    const oldInput = [...inputData];
    Object.freeze(oldInput[dataIndex]);
    let newObject = { ...oldInput[dataIndex] };
    newObject.showFilter = false;
    newObject.localVariableName = variable.name;
    oldInput[dataIndex] = newObject;
    setInputData(oldInput);
  };

  const saveForm = () => {
    if (nodeDisplayLabel == "" || nodeDisplayLabel == undefined) {
      setToast("Please Enter Node Display Label ", "error", "top-center");
      return;
    }
    if (nodeDisplayLabelLength > 25) {
      setToast(
        "Please Enter Node Display Label less then 25 characters",
        "error",
        "top-center"
      );
      return;
    }
    let oldInput = [...inputData];
    let errorValidation = [];
    let error = false;
    oldInput.forEach((input, index) => {
      let validation = {};
      let findIndexForUsedVariable = localVariableArray.findIndex(
        (item) =>
          item.variableName == input.localVariableName && item.key != nodeKey
      );
      let checkUsedVariableIndex = oldInput.findIndex(
        (item) => item.localVariableName === input.localVariableName
      );
      const regexForVariable = /^[a-zA-Z0-9_]*$/g;
      if (findIndexForUsedVariable > -1) {
        error = true;
        validation.localVariableName = "Please use unique variable name ";
      } else if (
        !isNaN(input.localVariableName) ||
        !regexForVariable.test(input.localVariableName)
      ) {
        error = true;
        validation.localVariableName = "Please enter valid tool variable name";
      } else if (
        checkUsedVariableIndex > -1 &&
        index != checkUsedVariableIndex
      ) {
        error = true;
        validation.localVariableName = "Please enter unique tool variable name";
      }
      let checkUsedQuestionIndex = oldInput.findIndex(
        (item) => item.label === input.label
      );
      if (input.label === "") {
        error = true;
        validation.label = "Please enter question ";
      } else if (
        checkUsedQuestionIndex > -1 &&
        index != checkUsedQuestionIndex
      ) {
        error = true;
        validation.label = "Please enter unique question ";
      }

      if (input.variableLabel === "" || input.variableLabel === undefined) {
        error = true;
        validation.variableLabel = "Please enter Label";
      }

      if (!error) {
        Object.freeze(input);
        let newObject = { ...input };
        let filterServerVariable = manualInputData.filter(
          (item) => item.name === input.localVariableName
        );
        if (filterServerVariable.length > 0) {
          newObject.variableName = input.localVariableName;
        } else {
          newObject.variableName = "";
        }
        newObject.showFilter = false;
        oldInput[index] = newObject;
      }
      errorValidation.push(validation);
      return true;
    });
    if (error) {
      setErrorValidation(errorValidation);
    } else {
      save({
        inputs: oldInput,
        heading,
        headingCheckBox,
        nodeDisplayLabel,
        isGridLayout,
      });
    }
  };

  const handleremoveInput = (index) => {
    const oldInput = [...inputData];
    oldInput.splice(index, 1);
    setInputData(oldInput);
    const errorValidation = [...errorValidationArray];
    errorValidation.splice(index, 1);
    setErrorValidation(errorValidation);
  };
  return (
    <>
      <form className="py-5 px-5 form-col" onSubmit={(e) => e.preventDefault()}>
        <div className="row manual-input-box">
          <div className="col-xs-12 col-md-12">
            <div className="form-group fv-plugins-icon-container">
              <label className={"form-label text-dark-50 font-weight-bolder"}>
                Node Display Label{" "}
                <small>
                  {" "}
                  <span
                    className={nodeDisplayLabelLength > 25 ? "error" : "info"}
                  >
                    ({nodeDisplayLabelLength} / 25 charaters)
                  </span>
                </small>
                :
              </label>

              <input
                placeholder="Node Display Label"
                type="text"
                value={nodeDisplayLabel}
                onChange={(e) => {
                  handleNodeLabel(e.target.value);
                }}
                className="form-control form-control-solid h-auto py-5 px-6"
                name={"nodeDisplayLabel"}
              />
            </div>
          </div>
          <div className="col-xs-12 col-md-12">
            <FormControlLabel
              control={
                <Switch
                  checked={headingCheckBox}
                  onChange={() => handleHeadingCheckBox(!headingCheckBox)}
                  value="checkedA"
                />
              }
              label="Add Heading"
            />
          </div>
          {headingCheckBox && (
            <div className="col-xs-12 col-md-12">
              <div className="form-group fv-plugins-icon-container">
                <label className={"form-label text-dark-50 font-weight-bolder"}>
                  Heading
                </label>
                <input
                  placeholder="heading"
                  type="text"
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                  className="form-control form-control-solid h-auto py-5 px-6"
                  name={"heading"}
                />
              </div>
            </div>
          )}
          <div className="col-xs-12 col-md-12">
            <FormControlLabel
              control={
                <Switch
                  checked={isGridLayout}
                  onChange={() => handleIsGridLayout(!isGridLayout)}
                  value="checkedA"
                />
              }
              label="Use Grid Layout"
            />
          </div>
        </div>
        {inputData.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className="row manual-input-box">
                {inputData.length > 1 && (
                  <span
                    className="inputclose_btn"
                    onClick={() => handleremoveInput(index)}
                  >
                    <CloseIcon />
                  </span>
                )}
                <div className="col-xs-12 col-md-12">
                  <div className="form-group fv-plugins-icon-container">
                    <label
                      className={"form-label text-dark-50 font-weight-bolder"}
                    >
                      Question
                    </label>
                    <input
                      placeholder="Question"
                      type="text"
                      value={item.label}
                      onChange={(e) => handleInputChange(index, e)}
                      className="form-control form-control-solid h-auto py-5 px-6"
                      name={"label"}
                    />
                    {errorValidationArray[index]?.label !== "" && (
                      <span className="text-danger">
                        {errorValidationArray[index]?.label}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="form-group fv-plugins-icon-container">
                    <label
                      className={"form-label text-dark-50 font-weight-bolder"}
                    >
                      Label
                    </label>
                    <input
                      placeholder="Enter Label"
                      type="text"
                      value={item.variableLabel}
                      onChange={(e) => handleInputChange(index, e)}
                      className="form-control form-control-solid h-auto py-5 px-6 "
                      name={"variableLabel"}
                    />
                    {errorValidationArray[index]?.variableLabel !== "" && (
                      <span className="text-danger">
                        {errorValidationArray[index]?.variableLabel}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="form-group fv-plugins-icon-container">
                    <label
                      className={"form-label text-dark-50 font-weight-bolder"}
                    >
                      Tool Variable Name
                    </label>
                    <input
                      placeholder="Enter Tool Variable Name"
                      type="text"
                      value={item.localVariableName}
                      onChange={(e) => handleInputChange(index, e)}
                      className="form-control form-control-solid h-auto py-5 px-6 "
                      name={"localVariableName"}
                    />
                    {errorValidationArray[index]?.localVariableName !== "" && (
                      <span className="text-danger">
                        {errorValidationArray[index]?.localVariableName}
                      </span>
                    )}

                    {showFilterVariable &&
                      item.showFilter &&
                      filterVariable.length > 0 && (
                        <ul className="suggestion__block">
                          {filterVariable.map((v) => (
                            <li
                              onClick={() => handleSelectVariable(v, index)}
                              key={v._id}
                            >
                              {v.name}
                            </li>
                          ))}
                        </ul>
                      )}
                  </div>
                </div>
                <div className="col-xs-10 col-md-6">
                  <div className="form-group fv-plugins-icon-container">
                    <label
                      className={"form-label text-dark-50 font-weight-bolder"}
                    >
                      Select Input Validation
                    </label>
                    <select
                      className="form-control form-control-solid h-auto py-5 px-6"
                      name="inputType"
                      value={item.inputType}
                      onChange={(e) => handleInputChange(index, e)}
                    >
                      <option value="Number">Numeric</option>
                      <option value="String">Text</option>
                      <option value="Date">Date</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-10 col-md-6">
                  <div className="form-group fv-plugins-icon-container">
                    <label
                      className={"form-label text-dark-50 font-weight-bolder"}
                    >
                      Select Input
                    </label>
                    <select
                      className="form-control form-control-solid h-auto py-5 px-6"
                      name="constraints"
                      value={item.constraints}
                      onChange={(e) => handleInputChange(index, e)}
                    >
                      <option value="not_null">Required</option>
                      <option value="any">Optional</option>
                    </select>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}

        <div className="btn-block mt-5">
          <button
            type="button"
            className="btn btn-primary mr-4"
            onClick={() =>
              setInputData([
                ...inputData,
                {
                  label: "",
                  localVariableName: "",
                  variableName: "",
                  showFilter: false,
                  inputType: "Number",
                  constraints: "not_null",
                },
              ])
            }
          >
            Add More Input
          </button>
          <button
            type="button"
            className="btn btn-success w-100px"
            onClick={() => saveForm()}
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default ManualInput;
