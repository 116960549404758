import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { actions as QUESTION_ACTIONS } from "../../../../app/modules/Question/_redux/questionsRedux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export function EditReportTemplate({ onHide, isUpdate, id, name, modalData, ...rest }) {
  const [input, setInput] = useState({
    "reportTemplateId" : "",
    "displayLabel" : ""
  });
  useEffect(() => {
    setInput({ reportTemplateId: modalData?.id, displayLabel:modalData.displayLabel})
  }, [modalData])
  const dispatch = useDispatch();
  const [errors, setError] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);

  const handleInputChange = (value, inputName) => {

    if (value !== '') {
      let data = {
        [inputName]: { noInput: false }
      }
      setError(data)
      setInput(value);
      const regex = /^[a-zA-Z0-9_\s+]*$/g;
      if (!isNaN(value)) {
        let errorData = {
          [inputName]: { valueValidationError: true }
        }
        setError(errorData)
        setFormSubmit(false)
      } else if (!(regex.test(value))) {
        let errorRegexData = {
          [inputName]: { valueValidationError: true }
        }
        setError(errorRegexData)
        setFormSubmit(false)
      } else {
        setFormSubmit(true)
        let noErrorData = {
          [inputName]: { valueValidationError: false }
        }
        setError(noErrorData)
        Object.freeze(input)
        let inputChanges = { ...input }
        inputChanges[inputName] = value
        setInput(inputChanges);
      }
    } else {
      let inputChanges = input
      inputChanges[inputName] = value
      setInput(inputChanges);

      let noInput = {
        [inputName]: { noInput: true }
      }
      setError(noInput)
    }
  };

  const handleSubmit = () => {

    if (input.displayLabel == '') {
      handleInputChange(input.displayLabel, 'displayLabel')
    } else {
      if (formSubmit) {
        if (isUpdate) {
          dispatch(QUESTION_ACTIONS.updateReportTemplateApi(input));
        } else {
          // dispatch(QUESTION_ACTIONS.add(input));
        }
        onHide();
        setInput({
          description: '',
          label: ''
        });
        // dispatch(QUESTION_ACTIONS.getListOfPredefinedLogicAPI());
      }

    }
  };

  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">{isUpdate ? "Update" : "Add"} Report Template</h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={onHide}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="form-group fv-plugins-icon-container mb-0">
          <label className="form-label text-dark-50 font-weight-bolder">
            Report Template
          </label>
          <input
            placeholder="Report Template..."
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="displayLabel"
            onChange={(event) => handleInputChange(event.target.value, event.target.name)}
            value={input['displayLabel']}
            maxLength="100"
          />
          {errors?.label?.noInput && <span class="text-danger">Please enter Predefined Process</span>}
          {errors?.label?.valueValidationError && <span class="text-danger">Please enter valid value</span>}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-0 mb-8">
        <Button className="w-100px btn-primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
