import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import StepOne from "../../_metronic/_partials/organization/WizardStep/StepOne";
import { Services } from "../../_service/Services";
import StepTwo from "../../_metronic/_partials/organization/WizardStep/StepTwo";
import WizardStep from "../../_metronic/_partials/organization/WizardStep/WizardStep";

import { actions } from "../modules/Organizations/_redux/organizationsRedux";
import _unionBy from "lodash/unionBy";

import { setToast } from "../modules/toast";
import HelpIcon from "../../_metronic/_partials/Help/HelpIcon";
export const EditOrganization = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [organization, setOrganization] = React.useState({});
  const [currentStep, setCurrentStep] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);

  const [manualSelectedStates, setManualSelectedStates] = React.useState([]);
  const [selectedStates, setSelectedStates] = React.useState([]);
  const [manualUnselectedStates, setManualUnselectedStates] = React.useState(
    []
  );

  useEffect(() => {
    Services({
      url: `/admin/organization/edit/${id}`,
      method: "GET",
    })
      .then((response) => {
        const selectedState = response.data?.data?.state?.filter(
          (o_state) => o_state?.isSelected
        );
        setManualSelectedStates(selectedState);
        setOrganization(response.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        error?.response?.data?.message &&
          setToast(error?.response?.data?.message);
      });
  }, []);

  const handleStateChange = (states, appliedFilters) => {
    if (appliedFilters) {
      states = _unionBy(states, manualSelectedStates, "StateName");
    }
    setSelectedStates(states);
  };

  const handleFieldChange = (field, value) => {
    if (typeof field === "object") {
      const fields = field;
      setOrganization({ ...organization, ...fields });
    } else {
      organization[field] = value;
      setOrganization({ ...organization });
    }
  };

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  const handleUpdate = () => {
    let updateOrganization = Object.keys(organization).reduce(
      (new_org, key) => {
        if (["isActive", "isDeleted", "__v"].indexOf(key) === -1) {
          new_org[key] = organization[key];
        }
        return new_org;
      },
      {}
    );
    updateOrganization.state = selectedStates.map(function(el) {
      return el._id;
    });

    updateOrganization.products = organization?.products
      ?.filter?.((o_product) => o_product?.isSelected)
      ?.map?.((o_product) => o_product?._id);

    dispatch(actions.add(updateOrganization));
    history.push("/organization");
  };

  return (
    <>
      {isLoading && (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      )}
      {!isLoading && (
        <>
          <div className={`card card-custom`}>
            <div className="Step__block">
              {currentStep === 0 && (
                <StepOne
                  onFieldChange={handleFieldChange}
                  state={organization?.state || []}
                  organization={organization}
                  onStateChange={handleStateChange}
                  selectedStates={selectedStates}
                  manualSelectedStates={manualSelectedStates}
                  setManualSelectedStates={setManualSelectedStates}
                  manualUnselectedStates={manualUnselectedStates}
                  setManualUnselectedStates={setManualUnselectedStates}
                />
              )}
              {currentStep === 1 && (
                <StepTwo
                  organization={organization}
                  onFieldChange={handleFieldChange}
                />
              )}
            </div>
            <div className="WizardStep__block">
              <WizardStep
                activeStep={currentStep}
                onStepChange={handleStepChange}
                onUpdate={handleUpdate}
              />
            </div>
          </div>
        </>
      )}
      <HelpIcon pageName="OrganizationAddOrUpdateSection" />
    </>
  );
};
