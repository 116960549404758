import React, { useState, useEffect } from "react";
import RingLoader from "react-spinners/RingLoader";
import { Button, Modal } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Services } from "../../../../_service/Services";
import { setToast } from "../../../../app/modules/toast";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import InfiniteScroll from "react-infinite-scroll-component";

export function FileDetailDialog({
  toggleModal,
  editMode = false,
  fileName = "",
  fileId = "",
  showModal,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [data, setData] = useState({});
  const [headers, setHeaders] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [
    isDisplayNameHeadersAvailable,
    setIsDisplayNameHeadersAvailable,
  ] = useState(false);
  const [showdisplayNameHeaders, setDisplayNameHeaders] = useState({});
  const [filter, setFilter] = useState([{ headerName: "", value: "" }]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (fileName && showModal) {
      setIsLoading(true);
      fetchData(currentPage);
    }
    return () => {
      setFilter([{ headerName: "", value: "" }]);
    };
  }, [fileName]);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = (newpage) => {
    if (fileName && newpage) {
      setIsLoading(true);
      let url = `/admin/excelData/fileData?id=${fileId}&pageNo=${newpage}`;
      url = url + `&filter=` + JSON.stringify(filter);

      Services({
        url: url,
        method: "GET",
      })
        .then((response) => {
          const {
            fileHeaders,
            fileData,
            displayNameHeaders,
          } = response?.data?.data;
          setData(response?.data?.data);
          setHeaders(fileHeaders);
          if (displayNameHeaders != undefined) {
            setIsDisplayNameHeadersAvailable(true);
            setDisplayNameHeaders(displayNameHeaders);
          } else {
            setIsDisplayNameHeadersAvailable(false);
            setDisplayNameHeaders({});
          }

          if (newpage > 1) {
            setRowData((old) => [...old, ...fileData]);
          } else {
            setRowData(fileData);
          }
        })
        .catch((error) => {
          error?.response?.data?.message &&
            setToast(error?.response?.data?.message);
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        });
    }
  };

  const nextPage = () => {
    setCurrentPage((old) => {
      return old + 1;
    });
  };

  const closeDialog = () => {
    setData({});
    setHeaders([]);
    setRowData([]);
    setCurrentPage(1);
    setDisplayNameHeaders({});
    toggleModal(false);
  };

  const lowercaseKeys = (obj) => {
    const newObject = {};

    Object.keys(obj).forEach((key) => {
      newObject[key.toLowerCase()] = obj[key];
    });

    return newObject;
  };

  const handleremoveInput = (index) => {
    const oldFilter = [...filter];
    oldFilter.splice(index, 1);
    setFilter(oldFilter);
  };

  const handleInputChange = (index, event) => {
    let name = event.target.name;
    let value = event.target.value;
    const oldFilter = [...filter];
    Object.freeze(oldFilter[index]);
    let newObject = { ...oldFilter[index] };
    newObject[name] = value;
    oldFilter[index] = newObject;
    setFilter(oldFilter);
  };

  const addHeaderValidation = () => {
    let headerInput = {
      headerName: "",
      value: "",
    };
    setFilter([...filter, headerInput]);
  };

  const handleSearch = () => {
    setSearch(true);
    setCurrentPage(1);
    fetchData(1);
  };
  const handleFilter = () => {
    setFilter([{ headerName: "", value: "" }]);
    setClearSearch(true);
  };
  useEffect(() => {
    if (clearSearch) {
      setCurrentPage(1);
      fetchData(1);
      setClearSearch(false);
    }
  }, [clearSearch, filter]);
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={closeDialog}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">{data?.fileName}</h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={closeDialog}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className={`card card-custom `}>
          {
            <div className="card-body py-0">
              {/* begin::Table */}
              <div className="row file-filter-box">
                {filter.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="file-filter-loop-box col-6">
                        {filter.length > 1 && (
                          <span
                            className="inputclose_btn"
                            onClick={() => handleremoveInput(index)}
                          >
                            <CloseIcon />
                          </span>
                        )}
                        <div className="row mt-4">
                          <div className="col-xs-12 col-md-5 form-group fv-plugins-icon-container">
                            <label
                              className={
                                "form-label text-dark-50 font-weight-bolder"
                              }
                            >
                              Select Header
                            </label>
                            <select
                              className="form-control form-control-solid h-auto py-5 px-6"
                              name="headerName"
                              value={item.headerName}
                              onChange={(e) => handleInputChange(index, e)}
                            >
                              <option value="">Select</option>
                              {headers.map((header) => {
                                let headerIndex = filter.findIndex(
                                  (item) => item.headerName == header
                                );
                                return (
                                  <option
                                    disabled={headerIndex > -1}
                                    key={header}
                                    value={header}
                                  >
                                    {header}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-xs-12 col-md-6 form-group fv-plugins-icon-container">
                            <label
                              className={
                                "form-label text-dark-50 font-weight-bolder"
                              }
                            >
                              Enter Text
                            </label>
                            <input
                              className={`form-control form-control-solid h-auto py-5 px-6`}
                              name="value"
                              onChange={(e) => handleInputChange(index, e)}
                              value={item.value}
                              placeholder="Enter Text"
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
                <div className="col-12 py-0 ">
                  <Button
                    className="mr-4"
                    onClick={() => addHeaderValidation()}
                  >
                    Add More filter
                  </Button>
                  <Button
                    onClick={() => {
                      handleSearch();
                    }}
                  >
                    Search
                  </Button>
                  {search && !clearSearch && (
                    <Button
                      className="ml-4"
                      onClick={() => {
                        handleFilter();
                      }}
                    >
                      Clear Filter
                    </Button>
                  )}
                </div>
              </div>
              <div className="table-responsive">
                {isLoading && (
                  <div className="loader_wrapper file-upload-loader">
                    <RingLoader color={"darkcyan"} loading={true} size={40} />
                  </div>
                )}
                <InfiniteScroll
                  dataLength={rowData?.length} //This is important field to render the next data
                  next={nextPage}
                  hasMore={currentPage < data?.totalPages}
                  height={"400px"}
                  loader={
                    // <div style={{ textAlign: "center" }}>Fetching data...</div>
                    <div style={{ textAlign: "center" }}>
                      <RingLoader color={"darkcyan"} loading={true} size={40} />
                    </div>
                  }
                  endMessage={
                    data?.totalPages > 0 ? (
                      <div style={{ textAlign: "center" }}>
                        All records are fetched!
                      </div>
                    ) : null
                  }
                >
                  <table
                    className="table table-head-custom table-vertical-center"
                    id="kt_advance_table_widget_1"
                  >
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <tr className="text-left ">
                        {headers
                          ?.filter?.((header) => header !== "_id")
                          ?.map?.((header) => (
                            <th
                              className={`pr-0 text-center header-files ${
                                header == "sheetname" ? "file-header-hide" : ""
                              }`}
                              style={{ minWidth: "150px" }}
                              key={header}
                            >
                              {header}{" "}
                              {isDisplayNameHeadersAvailable &&
                                "(" + showdisplayNameHeaders[header] + ") "}
                            </th>
                          ))}
                      </tr>
                    </thead>

                    {rowData?.length > 0 ? (
                      <tbody>
                        {rowData?.map((file) => {
                          // const fileLowerCase = lowercaseKeys(file);
                          return (
                            <tr
                              key={
                                Math.floor(Math.random() * 100) +
                                "_" +
                                Math.floor(Math.random() * 100) +
                                "_" +
                                `${file?._id}`
                              }
                            >
                              {headers
                                ?.filter?.((header) => header !== "_id")
                                ?.map?.((header, index) => (
                                  <td
                                    className={`pr-0 text-center  ${
                                      header == "sheetname"
                                        ? "file-header-hide"
                                        : ""
                                    }`}
                                    key={
                                      Math.floor(Math.random() * 100) +
                                      "_" +
                                      Math.floor(Math.random() * 100) +
                                      "_" +
                                      file[header]
                                    }
                                  >
                                    {file[header]}
                                  </td>
                                ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="pl-0 py-2 text-center" colSpan={3}>
                            <span className="svg-icon d-block p-3 nodata__icon">
                              <SVG
                                src={toAbsoluteUrl("/media/svg/ico_nodata.svg")}
                              ></SVG>
                            </span>
                            <h3 className="text-dark font-weight-bolder d-block mb-0">
                              No Record Found
                            </h3>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </InfiniteScroll>
              </div>
              {/* end::Table */}
              {/* Delete Modal */}
            </div>
          }
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end pt-0 mb-1">
        {/* <Button className="w-100px btn-primary" onClick={closeDialog}>
          Ok
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}
