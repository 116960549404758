import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Switch, FormControlLabel } from "@material-ui/core";
import { setToast } from "../../../../../app/modules/toast";
import { actions as DIAGRAM_ACTIONS } from "../../../../../app/modules/Diagram/_redux/DiagramRedux";
import Checkbox from "@material-ui/core/Checkbox";
import { Services } from "../../../../../_service/Services";

const MultiOption = ({
  formData,
  nodeKey,
  save,
  nodeCategory,
  localVariableArray,
  linkNodeArray,
}) => {
  const dispatch = useDispatch();
  const optionDetails = useSelector((store) => store.diagram.optionData);
  const [category, setCategory] = useState("");
  const [fileId, setFileId] = useState("");
  const [selectionType, setSelectionType] = useState("");
  const [label, setLabel] = useState("");
  const [isSingle, setIsSingle] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isDistinct, setIsDistinct] = useState(false);
  const [sameAsNodeLabel, setSameAsNodeLabel] = useState(false);
  const [columnList, setColumnList] = useState([]);
  const [localVariableArrayForNode, setLocalVariableArrayForNode] = useState(
    []
  );
  const [lookUpIdentityValue, setLookUpIdentityValue] = useState([]);
  const [groupByColumn, setGroupByColumn] = useState("");
  const [variableLabel, setVariableLabel] = useState("");
  const [columnName, setColumnName] = useState("");
  const [singleVariableName, setSingleVariableName] = useState("");
  const [nodeDisplayLabel, setNodeDisplayLabel] = useState("");
  const [nodeDisplayLabelLength, setNodeDisplayLabelLength] = useState(0);

  useEffect(() => {
    dispatch(DIAGRAM_ACTIONS.getMultipleOptionListAPI("MultiOption"));
  }, []);

  useEffect(() => {
    setCategory(formData.localVariableName);
    setLabel(formData.label);
    setFileId(formData.fileId);
    setChecked(formData.hasGroupBy);
    setIsSingle(formData.isSingle);
    setSingleVariableName(formData.singleVariableName);
    setGroupByColumn(formData.groupByColumn);
    setColumnName(formData.columnName);
    setVariableLabel(formData.variableLabel);
    setNodeDisplayLabel(formData?.nodeDisplayLabel);
    setNodeDisplayLabelLength(formData?.nodeDisplayLabel?.length);
    setIsDistinct(formData?.isDistinct);

    let newVariablesArray = [];

    let currentNodeId = linkNodeArray.find((item) => item.key == nodeKey)
      ?.nodeId;
    let newData = [];
    localVariableArray.map((variable) => {
      let variableNodeId = linkNodeArray.find(
        (item) => item.key == variable.key
      );
      if (
        nodeKey !== variable.key &&
        variable.category !== "MultiOption" &&
        variable.category === "LookUp" &&
        variableNodeId != undefined &&
        variableNodeId.nodeId < currentNodeId
      ) {
        newVariablesArray.push({
          key: variable.key,
          name: variable.variableName,
          label: variable.variableLabel,
          identityValue: variable?.fileIdentityValue,
        });
        if (formData.localVariableName == "{" + variable.variableName + "}") {
          newData = variable?.fileIdentityValue;
        }
      }
    });
    if (formData.isFetchFromLookup) {
      setSelectionType("lookup");

      if (newData?.identityValue != undefined) {
        setLookUpIdentityValue(newData?.identityValue);
      }
    }
    if (newVariablesArray.length > 0) {
      newVariablesArray.sort((variableFirst, variableSecond) => {
        const variableLabelFirst = variableFirst?.label?.toLowerCase(); // Convert names to lowercase for case-insensitive sorting
        const variableLabelSecond = variableSecond?.label?.toLowerCase();

        if (variableLabelFirst < variableLabelSecond) return -1;
        if (variableLabelFirst > variableLabelSecond) return 1;
        return 0;
      });
    }

    setLocalVariableArrayForNode(newVariablesArray);
  }, [formData]);

  useEffect(() => {
    if (category && selectionType != "lookup") {
      setColumnList([]);
      Services({
        url: `/admin/excelData/fileColumns?fileName=${category}`,
        method: "GET",
      })
        .then((response) => {
          const list = response?.data?.data?.filter?.(
            (val) => val?.colName !== "_id"
          );
          setColumnList(list);
        })
        .catch((error) => {
          error?.response?.data?.message &&
            setToast(error?.response?.data?.message);
        });
    } else {
      let newData = localVariableArrayForNode.find(
        (item) => "{" + item?.name + "}" == category
      );
      if (newData?.identityValue != undefined) {
        setLookUpIdentityValue(newData?.identityValue);
      }

      setColumnList([]);
    }
  }, [category]);

  const saveForm = () => {
    if (category === "") {
      setToast("Please fill values in form", "error", "top-center");
      return;
    }
    if (variableLabel === "" || variableLabel === undefined) {
      setToast("Please fill values in form", "error", "top-center");
      return;
    }

    if (columnName === "" || columnName === undefined) {
      setToast("Please fill values in form", "error", "top-center");
      return;
    }
    if (nodeDisplayLabel == "" || nodeDisplayLabel == undefined) {
      setToast("Please Enter Node Display Label ", "error", "top-center");
      return;
    }
    if (isSingle) {
      const regexForVariable = /^[a-zA-Z0-9_]*$/g;
      if (
        !isNaN(singleVariableName) ||
        !regexForVariable.test(singleVariableName)
      ) {
        setToast(
          "Please enter valid tool variable name",
          "error",
          "top-center"
        );
        return;
      }
    }

    if (nodeDisplayLabelLength > 25) {
      setToast(
        "Please Enter Node Display Label less then 25 characters",
        "error",
        "top-center"
      );
      return;
    }

    let variableGroup =
      groupByColumn != "" && groupByColumn != undefined
        ? " " + groupByColumn
        : "";
    let displayName = columnName + variableGroup;
    let dataObject = {
      localVariableName: category,
      label,
      isSingle,
      hasGroupBy: checked,
      groupByColumn,
      columnName,
      displayColumnName: displayName,
      inputType: "Array",
      variableLabel,
      fileId,
      nodeDisplayLabel,
      singleVariableName,
      lookupVariableName: "",
      isFetchFromLookup: false,
      isDistinct: isDistinct,
      distinctColumn: "",
    };
    if (selectionType == "lookup") {
      dataObject.lookupVariableName = category;
      dataObject.isFetchFromLookup = true;
    }
    if (isDistinct) {
      dataObject.distinctColumn = columnName;
    }

    save(dataObject);
  };
  return (
    <form className="py-5 px-5 form-col modal-form">
      <div className="form-group fv-plugins-icon-container">
        <label className="form-label text-dark-50 font-weight-bolder">
          Node Display Label{" "}
          <small>
            {" "}
            <span className={nodeDisplayLabelLength > 25 ? "error" : "info"}>
              ({nodeDisplayLabelLength} / 25 charaters)
            </span>
          </small>
          :
        </label>
        <input
          placeholder="Node Display Label"
          type="text"
          value={nodeDisplayLabel}
          onChange={(e) => {
            setNodeDisplayLabel(e.target.value);
            setNodeDisplayLabelLength(e.target.value.length);
          }}
          className="form-control form-control-solid h-auto py-5 px-6"
          name={"nodeDisplayLabel"}
        />
      </div>
      <div className="form-group fv-plugins-icon-container">
        <span className="multiOption-displayName">
          <label className="form-label text-dark-50 font-weight-bolder">
            Label :
          </label>
          <div className="form-icon">
            <Checkbox
              checked={sameAsNodeLabel}
              onChange={(e) => {
                setSameAsNodeLabel(!sameAsNodeLabel);
                setVariableLabel(nodeDisplayLabel);
              }}
            />
            <label>Same as Node Label</label>
          </div>
        </span>
        <input
          placeholder="Label"
          type="text"
          value={variableLabel}
          onChange={(e) => {
            setVariableLabel(e.target.value);
          }}
          className="form-control form-control-solid h-auto py-5 px-6"
          name={"label"}
        />
      </div>
      <div className="form-group fv-plugins-icon-container">
        <label className="form-label text-dark-50 font-weight-bolder">
          Select File Name or Variable:
        </label>
        <select
          className="form-control form-control-solid h-auto py-5 px-6"
          name="multiOption"
          onChange={(e) => {
            let option = e.target.value;
            const targetElement = e.nativeEvent.target;
            let index = targetElement.selectedIndex;
            const selectedOption = targetElement.options[index];
            const dataValue = selectedOption.getAttribute("data-value");
            const fileId = selectedOption.getAttribute("data-id");
            setSelectionType(dataValue);
            setFileId(fileId);
            setLabel(selectedOption.text);
            setCategory(option);
          }}
          value={label}
        >
          <option value="">Select File</option>
          {optionDetails.map((option) => (
            <option
              key={option._id}
              data-id={option._id}
              data-value="file"
              value={option?.variableName}
            >
              {option?.variableName}
            </option>
          ))}
          {localVariableArrayForNode.map((option) => (
            <option
              key={option.name}
              data-value="lookup"
              value={"{" + option?.name + "}"}
              className="lookup-variable-option"
            >
              {"{" + option?.name + "}"}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group fv-plugins-icon-container">
        <div
          style={{
            display: "flex",
            gap: "20px",
          }}
        >
          {nodeCategory !== "PassData" && (
            <>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSingle}
                    onChange={() => {
                      if (isSingle) {
                        setSingleVariableName("");
                      }
                      setIsSingle(!isSingle);
                    }}
                    value="checkedA"
                  />
                }
                label="Is Single Select"
              />
            </>
          )}
          <div className="form-icon">
            <Checkbox
              checked={checked}
              onChange={(e) => {
                setChecked(e?.target?.checked);

                if (!e?.target?.checked) {
                  setGroupByColumn("");
                }
              }}
            />
            <label>Show Group By File Column</label>
          </div>
        </div>
        {(isSingle || selectionType == "lookup") && (
          <div>
            <label className="form-label text-dark-50 font-weight-bolder mt-5">
              Tool Variable Name
            </label>
            <input
              placeholder="Variable Name"
              type="text"
              value={singleVariableName}
              onChange={(e) => {
                setSingleVariableName(e.target.value);
              }}
              className="form-control form-control-solid h-auto py-5 px-6"
              name={"variableName"}
            />
          </div>
        )}
        {checked && (!!columnList?.length || selectionType == "lookup") && (
          <div>
            <label className="form-label text-dark-50 font-weight-bolder mt-5">
              Group By
            </label>
            <select
              className="form-control form-control-solid h-auto py-5 px-6"
              name="category"
              onChange={(e) => {
                let option = e.target.value;
                setGroupByColumn(option);
              }}
              value={groupByColumn}
            >
              <option value="">Select Option</option>
              {columnList.map((option) => (
                <option key={option?.colName} value={option?.colName}>
                  {option?.colName}
                </option>
              ))}
              {selectionType == "lookup" &&
                lookUpIdentityValue.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
            </select>
          </div>
        )}

        {(!!columnList?.length || selectionType == "lookup") && (
          <div>
            <span className="multiOption-displayName">
              <label className="form-label text-dark-50 font-weight-bolder mt-5">
                Display Name
              </label>
              {nodeCategory !== "PassData" && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={isDistinct}
                      onChange={() => {
                        setIsDistinct(!isDistinct);
                      }}
                      value="checkedA"
                    />
                  }
                  label="Is Unique Column"
                />
              )}
            </span>
            <select
              className="form-control form-control-solid h-auto py-5 px-6"
              name="multiOption"
              onChange={(e) => {
                let option = e.target.value;
                setColumnName(option);
              }}
              value={columnName}
            >
              <option value="">Select Option</option>

              {selectionType !== "lookup" && (
                <>
                  {columnList.map((option) => (
                    <option key={option?.colName} value={option?.colName}>
                      {option?.colName}
                    </option>
                  ))}
                </>
              )}

              {selectionType == "lookup" &&
                lookUpIdentityValue.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
            </select>
          </div>
        )}
      </div>

      <div className="btn-block">
        <button
          type="button"
          className="btn btn-success w-100px"
          onClick={() => saveForm()}
        >
          {" "}
          Save
        </button>
      </div>
    </form>
  );
};

export default MultiOption;
