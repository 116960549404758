import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

import { actions as SubTopics } from "../../../../../app/modules/SubTopic/_redux/subtopicsRedux";
import { getTopicDetails } from "../../../../../app/modules/SubTopic/_redux/subtopicsSelectors";
import { actions as Questions } from "../../../../../app/modules/Question/_redux/questionsRedux";
import { getSubtopicDetails } from "../../../../../app/modules/Question/_redux/questionsSelectors";
import { AddTopicDialog } from "../../../../_partials/modals/addtopic-dialog/AddTopicDialog";
import { AddSubTopicDialog } from "../../../../_partials/modals/addsubtopic-dialog/AddSubTopicDialog";
import SVG from "react-inlinesvg";
import { DeleteButtonWithConfirm } from "../../../../_partials/widgets/advance-tables/DeleteButtonWithConfirm";

export function AsideMenuList({ layoutProps }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const topicDetails = useSelector(getTopicDetails);
  const subtopicDetails = useSelector(getSubtopicDetails);

  const [subTopicSelected, setSubTopicSelected] = useState("");
  const [questionSelected, setQuestionSelected] = useState("");
  const location = useLocation();
  const getMenuItemActiveQuestion = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  useEffect(() => {
    if (id != "" && location?.state !== undefined) {
      setSubTopicSelected(location?.state?.subtopicId);
    }
  }, [location]);

  const getMenuItemActive = (selectId, hasSubmenu = false) => {
    return selectId === subTopicSelected || selectId === questionSelected
      ? ` ${hasSubmenu &&
          "menu-item-active"}  menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const returnOpen = (selectId) => {
    return selectId === subTopicSelected || selectId === questionSelected
      ? "menu-item-open"
      : "";
  };
  useEffect(() => {
    dispatch(SubTopics.getTopicDetails(""));
  }, []);

  useEffect(() => {
    if (subTopicSelected != "" && subTopicSelected != undefined) {
      dispatch(Questions.getSubtopicDetails(subTopicSelected));
    }
  }, [subTopicSelected]);

  const [modalTopicShow, setModalTopicShow] = useState(false);

  const [subtopicId, setSubtopicId] = useState("");
  const [data, setData] = useState({
    name: "",
    tagLine: "",
    question: "",
    solution: "",
    tool_image: "",
  });
  const [isUpdate, setIsUpdate] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const handleHide = () => {
    setSubtopicId("");
    setIsUpdate(false);
    setModalShow(false);
    setSubTopicSelected("");
    // setIsUpdate(false);
    // setModalShow(false);
  };

  const handleUpdate = (subtopicIdUpdate, subtopicNameUpdate) => {
    setSubtopicId(subtopicIdUpdate);
    setData({
      name: subtopicDetails.name,
      tagLine: subtopicDetails.tagLine,
      question: subtopicDetails.question,
      solution: subtopicDetails.solution,
      tool_image: subtopicDetails.tool_image,
    });
    setIsUpdate(true);
    setModalShow(true);
  };

  const handleDelete = (subtopicIdDelete) => {
    dispatch(SubTopics.deleteSubtopic(subtopicIdDelete, id));
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* <li className={`menu-item menu-item-submenu`} onClick={() => {
          setModalTopicShow(true)
        }}>
          <span className="menu-link menu-text">Add New Toolbox</span>
          <label title="Add New Toolbox" className="sidemenu__plusicon orangeIcon" ></label>
        </li> */}
        {/* {topics?.map((topic) => {
          return <li key={topic._id + '_' + topic.name}
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              topic?._id,
              topic?.subTopicsCount > 0
            )} ${returnOpen(topic._id)}  `}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <Link className="menu-link menu-toggle" onClick={(e) => {
              // e.preventDefault()
              if (topicSelected !== topic?._id) {
                dispatch(SubTopics.fetchTopicDetails({
                  subTopics: [],
                }))
                setTopicSelected(topic?._id)
              } else {
                setTopicSelected('')
              }

            }}>
              <span className="menu-text" title={topic?.name}>{topic?.name?.length > 25 ? topic?.name?.substring(0, 24) + "..." : topic?.name}</span>

              <i className="menu-arrow" />

            </Link> */}

        {/* <div className="menu-item menu-item-submenu menu-submenu "> */}
        {/* <i className="menu-arrow" />
              <ul className="menu-subnav"> */}
        {/* <li key={topic?._id + '_' + topic?.name + '_subMenuOpen'} className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text" title={topic?.name}>{topic?.name?.length > 25 ? topic?.name?.substring(0, 24) + "..." : topic?.name}</span>
                  </span>
                </li> */}

        <li
          className={`menu-item menu-item-submenu`}
          onClick={() => {
            setSubtopicId("");
            setData({
              name: "",
              tagLine: "",
              question: "",
              solution: "",
              tool_image: "",
            });
            setIsUpdate(false);
            setModalShow(true);
          }}
        >
          <span className="menu-link menu-text">Add New Toolbox</span>
          <label
            title="Add New Sub Toolbox"
            className="sidemenu__plusicon blueIcon"
          ></label>
        </li>
        {topicDetails?.subTopics?.length > 0 &&
          topicDetails?.subTopics?.map((subTopic) => {
            return (
              <li
                key={subTopic?._id + "_" + subTopic?.name + "_subMenuOpen"}
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  subTopic?._id,
                  subTopic?.questionCount > 0
                )} ${returnOpen(subTopic?._id)} `}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <span className="menu-link">
                  <Link
                    className="menu-toggle link-for-menu"
                    onClick={(e) => {
                      // e.preventDefault()
                      if (subTopicSelected !== subTopic?._id) {
                        dispatch(
                          Questions.fetchSubtopicDetails({
                            questions: [],
                          })
                        );
                        setSubTopicSelected(subTopic?._id);
                      } else {
                        setSubTopicSelected("");
                      }
                      // setSubTopicSelected(subTopic?._id)
                    }}
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text" title={subTopic?.name}>
                      {subTopic?.name?.length > 20
                        ? subTopic?.name?.substring(0, 18) + "..."
                        : subTopic?.name}
                    </span>
                  </Link>
                  <div className="menu-action-icons">
                    <a
                      onClick={handleUpdate.bind(
                        this,
                        subTopic?._id,
                        subTopic?.name
                      )}
                      className="btn-sm"
                    >
                      <span className="svg-icon svg-icon-md">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/ico_edit.svg")}
                        ></SVG>
                      </span>
                    </a>
                    <DeleteButtonWithConfirm
                      parentClassName="btn-sm"
                      onDelete={handleDelete.bind(this, subTopic._id)}
                      deleteIcon="/media/svg/ico_delete.svg"
                      iconClassName="svg-icon svg-icon-md"
                    />
                    {/* <a
                      onDelete={handleDelete.bind(this, subTopic._id)} className="btn-sm">
                      <span className="svg-icon svg-icon-md">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/ico_delete.svg"
                          )}
                        ></SVG>
                      </span>
                    </a> */}
                  </div>
                  <i style={{}} className="menu-arrow" />
                </span>
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li className={`menu-item `}>
                      <Link
                        title="Add New Tool"
                        className="menu-link"
                        onClick={(e) => {
                          dispatch(Questions.isRedirect(false));
                          dispatch(Questions.questionDetails({}));
                        }}
                        // to={`/toolbox/add/${subTopic?._id}`}
                        to={{
                          pathname: `/toolbox/add/${subTopic?._id}`,
                          state: {
                            subtopicId: subtopicDetails._id,
                          },
                        }}
                      >
                        <span className="menu-link menu-text">
                          Add New Tool
                        </span>
                        <label className="sidemenu__plusicon"></label>
                      </Link>
                    </li>
                    {subtopicDetails?.questions?.length > 0 &&
                      subtopicDetails?.questions.map((question) => {
                        return (
                          <li
                            className={`menu-item  ${getMenuItemActiveQuestion(
                              question?._id,
                              checkIsActive(location, question?._id)
                            )}`}
                            key={
                              question?._id + "_" + question?.text + "_question"
                            }
                            aria-haspopup="true"
                          >
                            <NavLink
                              className="menu-link"
                              onClick={() => {
                                const newDataForQuestion = {
                                  ...question,
                                  topic_id: subtopicDetails._id,
                                };
                                dispatch(Questions.isRedirect(false));
                                dispatch(
                                  Questions.questionDetails(newDataForQuestion)
                                );
                              }}
                              to={{
                                pathname: `/toolbox/${question?._id}`,
                                state: {
                                  subtopicId: subtopicDetails._id,
                                },
                              }}
                              // to={`/toolbox/${question?._id}`}
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span
                                className="menu-text"
                                title={question?.text}
                              >
                                {question?.text?.length > 21
                                  ? question?.text?.substring(0, 20) + "..."
                                  : question?.text}
                              </span>
                            </NavLink>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </li>
            );
          })}

        {/* </ul>
            </div> */}
        {/* 
            
          </li>

        })
        } */}
      </ul>
      <AddSubTopicDialog
        parentTopic={topicDetails?._id}
        show={modalShow}
        id={subtopicId}
        data={data}
        isUpdate={isUpdate}
        onHide={handleHide}
      />
      <AddTopicDialog
        show={modalTopicShow}
        isUpdate={false}
        id={""}
        name={""}
        onHide={() => setModalTopicShow(false)}
      />
    </>
  );
}
