import React, { useState } from "react";

const MultiInput = ({ formData, localVariableArray, nodeKey, save }) => {
  const [inputData, setInputData] = useState(
    formData || {
      question: "",
      nodeDisplayLabel: "",
    }
  );

  const [errorValidationObject, setErrorValidation] = useState({
    question: "",
    nodeDisplayLabel: "",
  });

  const saveForm = () => {
    let oldInput = { ...inputData };
    let errorValidation = {
      question: "",
      nodeDisplayLabel: "",
      answers: [],
    };
    let error = false;
    if (!oldInput.question) {
      error = true;
      errorValidation.question = "Please enter question.";
    }
    if (
      oldInput.nodeDisplayLabel == "" ||
      oldInput.nodeDisplayLabel == undefined
    ) {
      error = true;
      errorValidation.nodeDisplayLabel = "Please Enter Node Display Label ";
    }
    if (oldInput.nodeDisplayLabel.length > 25) {
      error = true;
      errorValidation.nodeDisplayLabel =
        "Please Enter Node Display Label less then 15 characters";
    }
    if (error) {
      setErrorValidation(errorValidation);
    } else {
      save(oldInput);
    }
  };

  return (
    <>
      <form className="py-5 px-5 form-col">
        <div className="col-12">
          <div className="form-group fv-plugins-icon-container">
            <label className={"form-label text-dark-50 font-weight-bolder"}>
              Node Display Label{" "}
              <small>
                {" "}
                <span
                  className={
                    inputData.nodeDisplayLabel.length > 25 ? "error" : "info"
                  }
                >
                  ({inputData.nodeDisplayLabel.length} / 25 charaters)
                </span>
              </small>
              :
            </label>

            <input
              placeholder="Node Display Label"
              type="text"
              value={inputData.nodeDisplayLabel}
              onChange={(e) => {
                const nodeDisplayLabel = e.target.value;
                setInputData((oldVal) => ({
                  ...oldVal,
                  nodeDisplayLabel,
                }));
              }}
              className="form-control form-control-solid h-auto py-5 px-6"
              name={"nodeDisplayLabel"}
            />
            {errorValidationObject.nodeDisplayLabel !== "" && (
              <span className="text-danger">
                {errorValidationObject.nodeDisplayLabel}
              </span>
            )}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <label className={"form-label text-dark-50 font-weight-bolder"}>
              Question
            </label>
            <input
              placeholder="Question"
              type="text"
              value={inputData.question}
              onChange={(e) => {
                const question = e.target.value;
                setInputData((oldVal) => ({
                  ...oldVal,
                  question,
                }));
              }}
              className="form-control form-control-solid h-auto py-5 px-6"
              name={"label"}
            />
            {errorValidationObject.question !== "" && (
              <span className="text-danger">
                {errorValidationObject.question}
              </span>
            )}
          </div>
        </div>

        <div className="btn-block">
          <button
            type="button"
            className="btn btn-success w-100px"
            onClick={() => saveForm()}
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default MultiInput;
