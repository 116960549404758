import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";

import { useFormik } from "formik";
import { Text } from "../formik-form-fields";
import RingLoader from "react-spinners/RingLoader";
import { getQuestionSchema } from "../../_utils";
import { actions as QUESTION_ACTIONS } from "../../../app/modules/Question/_redux/questionsRedux";
import HelpIcon from "../Help/HelpIcon";

export function Toolbox({ className }) {
  let history = useHistory();
  const { subtopic } = useParams();

  const dispatch = useDispatch();
  const questionDetails = useSelector(
    (store) => store.questions.questionDetails
  );

  const subTopicDetails = useSelector((store) => store.subtopics.topicDetails);

  const redirect = useSelector((store) => store.questions.redirect);
  const loader = useSelector((store) => store.questions.isLoading);

  const initialValues = {
    id: "",
    text: "",
    assumptions: "",
    screen_tagline: "",
    description: "",
    // summery_report: "",
    topicId: "",
    icon_logo: "",
    helpFile: "",
  };

  useEffect(() => {
    if (redirect) {
      history.push({
        pathname: `/toolbox/${questionDetails._id}`,
        state: {
          subtopicId: questionDetails.topicId,
          topicId: subTopicDetails?._id,
        },
      });
      dispatch(QUESTION_ACTIONS.isRedirect(false));
    }
  }, [redirect]);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getQuestionSchema(questionDetails && questionDetails?.id),
    onSubmit: ({
      id,
      text,
      assumptions,
      screen_tagline,
      description,
      // summery_report,
      topicId,
      icon_logo,
      helpFile,
    }) => {
      dispatch(
        QUESTION_ACTIONS.addQuestion({
          id,
          text,
          assumptions,
          screen_tagline,
          description,
          // summery_report,
          topicId,
          icon_logo,
          helpFile,
        })
      );
    },
  });
  useEffect(() => {
    if (subtopic === "" || subtopic === undefined) {
      if (Object.keys(questionDetails).length > 0) {
        Object.keys(questionDetails).forEach((field) => {
          formik.setFieldValue(field, questionDetails[field]);
        });
        formik.setFieldValue("id", questionDetails["_id"]);
      }
    } else {
      Object.keys(initialValues).forEach((field) => {
        formik.setFieldValue(field, initialValues[field]);
      });
      formik.setFieldValue("topicId", subtopic);
    }
  }, [questionDetails]);
  const handleSubmit = () => {
    formik.validateForm();
    formik.handleSubmit();
  };

  return (
    <div className={`card card-custom ${className}`}>
      {loader && (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      )}
      <div className="card-header border-0 py-8 custom-header">
        <h3 className="card-title flex-column ">
          <span className="card-label font-weight-bolder text-dark">Tool</span>
        </h3>
      </div>
      <div className="card-body py-0">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          encType="multipart/form-data"
        >
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="form-group fv-plugins-icon-container">
                <Text
                  label="Tool Name"
                  labelClass="form-label text-dark-50 font-weight-bolder"
                  name="text"
                  inputClass="form-control form-control-solid h-auto py-5 px-6"
                  formik={formik}
                  placeholder="Enter Tool Name"
                />
                {/* <div className="error">{formik.errors["text"]}</div> */}
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="form-group fv-plugins-icon-container">
                <label className="form-label text-dark-50 font-weight-bolder">
                  Upload Icon
                </label>
                <div className="toolbox__uploadfile">
                  {/* <span className="uploadicon"></span> */}
                  <div className="uploadimg">
                    {formik.values["icon_logo"] != "" ? (
                      <img src={formik.values["icon_logo"]} alt="uploadimg" />
                    ) : (
                      <img src="/media/svg/upload_icon.svg" alt="uploadimg" />
                    )}
                  </div>
                  <label className="mb-0 w-100" htmlFor="actual-btn">
                    <input
                      className={`form-control form-control-solid h-auto py-5 px-6`}
                      id="actual-btn"
                      accept="image/png, image/jpeg"
                      name="icon_logo"
                      type="file"
                      onChange={(event) => {
                        getBase64(event.currentTarget.files[0]).then(
                          (result) => {
                            formik.setFieldValue("icon_logo", result);
                          }
                        );
                      }}
                    />
                    <span className="file__browse" htmlFor="actual-btn">
                      Browse
                    </span>
                  </label>
                </div>
                {formik?.errors?.["icon_logo"] &&
                  formik?.touched?.["icon_logo"] && (
                    <span className="text-danger">
                      {formik?.errors?.["icon_logo"]}
                    </span>
                  )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="form-group fv-plugins-icon-container">
                <Text
                  label="Screen Tag line"
                  labelClass="form-label text-dark-50 font-weight-bolder"
                  name="screen_tagline"
                  inputClass="form-control form-control-solid h-auto py-5 px-6"
                  formik={formik}
                  placeholder="Enter Screen Tag line"
                  maxLength="100"
                />
                {/* <div className="error">{formik.errors["screen_tagline"]}</div> */}
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="form-group fv-plugins-icon-container">
                <label className="form-label text-dark-50 font-weight-bolder">
                  Assumptions
                </label>
                <ReactQuill
                  className="form-control form-control-solid h-auto "
                  theme="snow"
                  value={formik.values["assumptions"] || ""}
                  onChange={(value) => {
                    formik.setFieldValue("assumptions", value);
                  }}
                />

                {formik?.errors?.["assumptions"] &&
                  formik?.touched?.["assumptions"] && (
                    <span className="text-danger">
                      {formik?.errors?.["assumptions"]}
                    </span>
                  )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="form-group fv-plugins-icon-container">
                <label className="form-label text-dark-50 font-weight-bolder">
                  Description
                </label>
                <ReactQuill
                  className="form-control form-control-solid h-auto "
                  theme="snow"
                  value={formik.values["description"] || ""}
                  onChange={(value) => {
                    formik.setFieldValue("description", value);
                  }}
                />

                {formik?.errors?.["description"] &&
                  formik?.touched?.["description"] && (
                    <span className="text-danger">
                      {formik?.errors?.["description"]}
                    </span>
                  )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="form-group fv-plugins-icon-container">
                <label className="form-label text-dark-50 font-weight-bolder">
                  Upload Help PDF
                </label>
                <div className="toolbox__uploadfile">
                  {/* <span className="uploadicon"></span> */}
                  {/* <div className="uploadimg">
                    {formik.values["helpFile"] != "" ? (
                      <img src={formik.values["icon_logo"]} alt="uploadimg" />
                    ) : (
                      <img src="/media/svg/upload_icon.svg" alt="uploadimg" />
                    )}
                  </div> */}
                  <label className="mb-0 w-100" htmlFor="actual-btn-pdf-upload">
                    <input
                      className={`form-control form-control-solid h-auto py-5 px-6`}
                      id="actual-btn-pdf-upload"
                      accept={"application/pdf"}
                      name="helpFile"
                      type="file"
                      onChange={(event) => {
                        formik.setFieldValue("helpFile", event.target.files[0]);
                      }}
                    />
                    <span
                      className="file__browse"
                      htmlFor="actual-btn-pdf-upload"
                    >
                      Browse
                    </span>
                  </label>
                </div>
                {formik?.errors?.["icon_logo"] &&
                  formik?.touched?.["icon_logo"] && (
                    <span className="text-danger">
                      {formik?.errors?.["icon_logo"]}
                    </span>
                  )}
              </div>
            </div>
          </div>
          <div className="border-0 justify-content-center pt-2 mb-8">
            <Link
              to={`/toolbox${
                questionDetails._id != undefined
                  ? "/" + questionDetails._id
                  : ""
              }`}
              className="w-100px btn btn-secondary mr-3"
            >
              Cancel
            </Link>
            <Button className="w-100px" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>

      <HelpIcon pageName="ToolBoxAddUpdateSection" />
    </div>
  );
}
