import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { setToast } from "../../../../../app/modules/toast";
import { actions as PREDEFINED_LOGIC_ACTIONS } from "../../../../../app/modules/PredefinedLogic/_redux/PredefinedLogicRedux";

const StaticLoop = ({
  formData,
  save,
  localVariableArray,
  nodeKey,
  linkNodeArray,
  nodeCategory,
}) => {
  const dispatch = useDispatch();

  const optionDetails = useSelector(
    (store) => store.predefinedLogic.staticLoop
  );
  const staticLoopVariables = useSelector(
    (store) => store.predefinedLogic.staticLoopVariables
  );

  const [category, setCategory] = useState("");
  const [associateLoopId, setAssociateLoopId] = useState("");
  const [label, setLabel] = useState("");

  const [usedVariables, setUsedVariables] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [variableMapping, setVariableMapping] = useState([]);

  const [errorSelected, setErrorSelected] = useState([]);

  useEffect(() => {
    if (associateLoopId !== "" && associateLoopId != undefined) {
      dispatch(
        PREDEFINED_LOGIC_ACTIONS.getStaticLoopVariablesAPI(associateLoopId)
      );
    }
  }, [associateLoopId]);

  useEffect(() => {
    let newUsedVariables = [];
    let newselectedVariables = [];

    staticLoopVariables.length > 0 &&
      staticLoopVariables.map((variable) => {
        if (formData?.variableMapping?.length > 0) {
          let index = formData.variableMapping.findIndex(
            (item) => item.variableName === variable.name
          );
          newUsedVariables[variable.name] =
            formData.variableMapping[index] !== undefined
              ? formData.variableMapping[index].localVariableName
              : "";
          newselectedVariables.push({
            variableName: variable.name,
            localVariableName:
              formData.variableMapping[index] !== undefined
                ? formData.variableMapping[index].localVariableName
                : "",
          });
        }
        // oldUsedVariables[variable.name] = oldUsedVariables[variable.name] === undefined ? "" : oldUsedVariables[variable.name]
      });
    setUsedVariables(newUsedVariables);
    setSelectedVariables(newselectedVariables);
  }, [staticLoopVariables]);

  useEffect(() => {
    dispatch(PREDEFINED_LOGIC_ACTIONS.getListOfStaticLoopAPI());
    if (localVariableArray.length > 0) {
      localVariableArray.sort((variableFirst, variableSecond) => {
        const variableLabelFirst = variableFirst?.variableLabel?.toLowerCase(); // Convert names to lowercase for case-insensitive sorting
        const variableLabelSecond = variableSecond?.variableLabel?.toLowerCase();

        if (variableLabelFirst < variableLabelSecond) return -1;
        if (variableLabelFirst > variableLabelSecond) return 1;
        return 0;
      });
    }
  }, []);
  useEffect(() => {
    setCategory(formData.localVariableName);
    setLabel(formData.label);
    setAssociateLoopId(formData.associateLoopId);
    if (formData?.variableMapping?.length > 0) {
      setVariableMapping(formData?.variableMapping);
    } else {
      setVariableMapping([]);
    }
  }, [formData]);

  const saveForm = () => {
    if (category === "") {
      setToast("Please fill values in form", "error", "top-center");
      return;
    }
    let notSelectedVariables = []; //oldUsedVariables.filter(item => item === '')

    staticLoopVariables.map((item, index) => {
      if (
        usedVariables[item.name] == "" ||
        usedVariables[item.name] == undefined
      ) {
        notSelectedVariables.push(item.name);
      }
    });
    setErrorSelected(notSelectedVariables);
    if (notSelectedVariables.length > 0) {
      setToast("Please Select Variables", "error", "top-center");
    } else {
      save({
        localVariableName: category,
        variableName: category,
        associateLoopId,
        label,
        variableMapping,
      });
    }
  };

  return (
    <form className="py-5 px-5 form-col modal-form">
      <div className="form-group fv-plugins-icon-container">
        <label className="form-label text-dark-50 font-weight-bolder">
          Select Static Loop :
        </label>
        <select
          className="form-control form-control-solid h-auto py-5 px-6"
          name="multiOption"
          onChange={(e) => {
            let option = e.target.value;
            setCategory(option);
            var index = e.nativeEvent.target.selectedIndex - 1;
            setLabel(optionDetails[index].name);
            setAssociateLoopId(optionDetails[index]._id);
          }}
          value={category}
        >
          <option value="">Select Option</option>
          {optionDetails.map((option) => (
            <option key={option._id} value={option.name}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
      <div className="row">
        {associateLoopId != "" &&
          staticLoopVariables.length > 0 &&
          staticLoopVariables.map((variable) => {
            let errorCheck = errorSelected.findIndex(
              (item) => item === variable.name
            );

            return (
              <div key={variable._id} className={`col-xs-12 col-md-6`}>
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label text-dark-50 font-weight-bolder">
                    Select Variable For {variable.variableLabel} :
                  </label>
                  <select
                    className={`form-control form-control-solid h-auto py-5 px-6 ${
                      errorCheck < 0 ? "" : "error"
                    }`}
                    name={variable.name}
                    onChange={(e) => {
                      let variableName = e.target.name;
                      let value = e.target.value;
                      let oldSelected = [...variableMapping];

                      let selectedIndex = oldSelected.findIndex(
                        (item) => item.variableName === variableName
                      );
                      if (selectedIndex < 0) {
                        oldSelected.push({
                          variableName: variableName,
                          localVariableName: value,
                        });
                      } else {
                        oldSelected[selectedIndex] = {
                          variableName: variableName,
                          localVariableName: value,
                        };
                      }
                      setVariableMapping(oldSelected);
                      let oldSelectedVariables = selectedVariables;
                      let selectedVariableIndex = oldSelectedVariables.findIndex(
                        (item) => item.variableName === variableName
                      );
                      if (selectedVariableIndex < 0) {
                        oldSelectedVariables.push({
                          variableName: variableName,
                          localVariableName: value,
                        });
                      } else {
                        oldSelectedVariables[
                          selectedVariableIndex
                        ].localVariableName = value;
                      }
                      setSelectedVariables(oldSelectedVariables);

                      let oldUsedVariables = usedVariables;

                      oldUsedVariables[variableName] = value;
                      setUsedVariables(oldUsedVariables);
                    }}
                    value={usedVariables[variable.name]}
                  >
                    <option value="">Select Local Variable</option>
                    {localVariableArray.length > 0 &&
                      localVariableArray.map((localVariable, index) => {
                        let disabledOption = selectedVariables.findIndex(
                          (item) =>
                            item.localVariableName ===
                            localVariable.variableName
                        );
                        let currentNodeId = linkNodeArray.find(
                          (item) => item.key == nodeKey
                        )?.nodeId;
                        let variableNodeId = linkNodeArray.find(
                          (item) => item.key == localVariable.key
                        );
                        if (
                          variableNodeId != undefined &&
                          variableNodeId.nodeId < currentNodeId
                        ) {
                          return (
                            <option
                              disabled={
                                disabledOption > -1 &&
                                selectedVariables[disabledOption]
                                  .variableName !== variable.name
                                  ? true
                                  : null
                              }
                              key={localVariable.key + "_" + index}
                              value={localVariable.variableName}
                            >
                              {localVariable.variableLabel}
                            </option>
                          );
                        }
                        // }else{
                        //   return '';
                        // }
                      })}
                  </select>
                </div>
              </div>
            );
          })}
      </div>
      <div className="btn-block">
        <button
          type="button"
          className="btn btn-success w-100px"
          onClick={() => saveForm()}
        >
          {" "}
          Save
        </button>
      </div>
    </form>
  );
};

export default StaticLoop;
