import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { Undo } from "@material-ui/icons";
import { actions as DIAGRAM_ACTIONS } from "../../../../../app/modules/Diagram/_redux/DiagramRedux";
import { FileVariable } from "../FileVariable";
import { updatePredefinedLogicDiagram } from "../../../../../_service/predefinedLogic/predefined.service";
const Procedure = ({
  formData,
  nodeKey,
  save,
  localVariableArray,
  linkNodeArray,
}) => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    variable: "",
    operator: "",
    localVariableName: "",
    nodeDisplayLabel: "",
  });

  const operators = useSelector((store) => store.diagram.operators);
  const [data, setData] = useState([]);
  const [equationArrayFunction, setEquationArrayFunction] = useState([]);
  const [equation, setEquation] = useState("");
  const [variableValue, setVariableValues] = useState("");
  const [errors, setError] = useState({});
  const [equationName, setEquationName] = useState("");
  const [variableLabel, setVariableLabel] = useState("");
  const [nodeDisplayLabel, setNodeDisplayLabel] = useState("");
  const [nodeDisplayLabelLength, setNodeDisplayLabelLength] = useState(0);
  const [useFileVariable, setUseFileVariable] = useState(false);
  const [textFileVariable, setTextFileVariable] = useState(false);
  const [fileVariable, setFileVariable] = useState("");
  const [singleSelectVariables, setSingleSelectVariables] = useState([]);
  const serverProcedureVariables = useSelector(
    (store) => store.diagram.procedureVariables
  );
  const [variables, setVariableArray] = useState([]);
  const [sameAsNodeLabel, setSameAsNodeLabel] = useState(false);
  useEffect(() => {
    dispatch(DIAGRAM_ACTIONS.getProcedureVariableListAPI("Procedure"));
    dispatch(DIAGRAM_ACTIONS.getOperatorListAPI("Procedure"));
    return () => {
      dispatch(DIAGRAM_ACTIONS.getProcedureVariableData([]));
      setVariableArray([]);
    };
  }, []);

  useEffect(() => {
    let newVariablesArray = [];
    newVariablesArray = serverProcedureVariables;
    let currentNodeId = linkNodeArray.find((item) => item.key == nodeKey)
      ?.nodeId;
    let singleVariables = [];
    localVariableArray.map((variable) => {
      let findIndex = serverProcedureVariables.findIndex(
        (item, index) => item.name === variable.variableName
      );
      let variableNodeId = linkNodeArray.find(
        (item) => item.key == variable.key
      );
      if (
        findIndex < 0 &&
        nodeKey !== variable.key &&
        variable.category !== "MultiOption" &&
        variableNodeId != undefined &&
        variableNodeId.nodeId < currentNodeId
      ) {
        newVariablesArray.push({
          key: variable.key,
          name: variable.variableName,
          label: variable.variableLabel,
        });
      }

      if (
        findIndex < 0 &&
        nodeKey !== variable.key &&
        variable.category === "MultiOption" &&
        variable.isSingle &&
        variableNodeId != undefined &&
        variableNodeId.nodeId < currentNodeId
      ) {
        singleVariables.push(variable);
      }
    });
    if (newVariablesArray.lenght > 0) {
      newVariablesArray.sort((variableFirst, variableSecond) => {
        const variableLabelFirst = variableFirst?.label?.toLowerCase(); // Convert names to lowercase for case-insensitive sorting
        const variableLabelSecond = variableSecond?.label?.toLowerCase();

        if (variableLabelFirst < variableLabelSecond) return -1;
        if (variableLabelFirst > variableLabelSecond) return 1;
        return 0;
      });
    }
    newVariablesArray.push({
      key: "file",
      name: "fileVariable",
      label: "Use File Variable",
    });
    newVariablesArray.push({
      key: "text",
      name: "textVariable",
      label: "Use Text Input",
    });
    setVariableArray(newVariablesArray);
    setSingleSelectVariables(singleVariables);
  }, [serverProcedureVariables]);
  useEffect(() => {
    setData(formData.data);
    let tempInput = {
      variable: "",
      operator: "",
    };
    for (let index = formData.data.length - 1; index >= 0; index--) {
      if (formData.data[index].type == "variable" && tempInput.variable === "")
        tempInput.variable = formData.data[index].value;
      if (formData.data[index].type === "operator" && tempInput.operator === "")
        tempInput.operator = formData.data[index].value;
      if (tempInput.variable !== "" && tempInput.operator !== "") break;
    }

    setInputs(tempInput);
    let equationArray = formData.equation.split("=");
    let oldEquation = equationArray[1] != undefined ? equationArray[1] : "";
    setEquation(oldEquation);
    setEquationName(formData.name);
    setVariableLabel(formData.variableLabel);
    setNodeDisplayLabel(formData?.nodeDisplayLabel);
    setNodeDisplayLabelLength(formData?.nodeDisplayLabel?.length);
    setEquationArrayFunction(
      formData?.equationArrayFunction != undefined
        ? formData?.equationArrayFunction
        : oldEquation.match(/([a-zA-Z0-9]+|[^a-zA-Z0-9])/g)
    );
  }, [formData]);

  const validateParentheses = (str) => {
    let count = 0;

    for (let char of str) {
      if (char === "(") {
        count++;
      } else if (char === ")") {
        count--;
        if (count < 0) {
          return false; // More close parentheses than open parentheses
        }
      }
    }

    return count === 0; // True if open and close parentheses are balanced
  };
  const saveForm = () => {
    if (nodeDisplayLabel === "" || nodeDisplayLabel == undefined) {
      setError({ nodeDisplayLabelError: true });
      return;
    }
    if (nodeDisplayLabelLength > 25) {
      setError({ nodeDisplayLabelLengthError: true });
      return;
    }
    if (equationName === "") {
      setError({ variableError: true });
      return;
    }
    const regex = /^[a-zA-Z][a-zA-Z0-9 !?_\s+.()]*$/g;
    if (variableLabel === "" || variableLabel === undefined) {
      setError({ variableLabelError: true });
      return;
    }

    if (equation === "") {
      setError({ equationError: true });
      return;
    }
    if (!/^[a-zA-Z0-9_]*$/g.test(equationName)) {
      setError({ variableValidationError: true });
      return;
    }
    if (!isNaN(equationName)) {
      setError({ variableValidationError: true });
      return;
    }
    if (!validateParentheses(equation)) {
      setError({ equationValidationError: true });
      return;
    }
    if (
      (operators.includes(equation.charAt(equation.length - 1)) &&
        !["(", ")"].includes(equation.charAt(equation.length - 1))) ||
      (operators.includes(equation.charAt(0)) &&
        !["(", ")"].includes(equation.charAt(0)))
    ) {
      setError({ equationValidationError: true });
      return;
    }
    let findIndexForUsedVariable = localVariableArray.findIndex(
      (item) => item.variableName == equationName
    );
    if (
      findIndexForUsedVariable > -1 &&
      localVariableArray[findIndexForUsedVariable].key !== nodeKey
    ) {
      setError({ variableDuplicateValidationError: true });
      return;
    }

    save({
      data,
      equation: equationName + "=" + equation,
      name: equationName,
      localVariableName: equationName,
      variableLabel,
      nodeDisplayLabel,
      equationArrayFunction,
    });
  };
  useEffect(() => {
    if (equationArrayFunction != undefined) {
      setEquation(equationArrayFunction.join(""));
    }
  }, [equationArrayFunction]);
  return (
    <form className="py-5 px-5 form-col modal-form">
      <div className="row">
        <div className="col-xs-12 col-md-12">
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Node Display Label{" "}
              <small>
                {" "}
                <span
                  className={nodeDisplayLabelLength > 25 ? "error" : "info"}
                >
                  ({nodeDisplayLabelLength} / 25 charaters)
                </span>
              </small>
              :
            </label>
            <input
              placeholder="Node Display Label"
              type="text"
              value={nodeDisplayLabel}
              onChange={(e) => {
                setNodeDisplayLabel(e.target.value);
                setNodeDisplayLabelLength(e.target.value.length);
              }}
              className="form-control form-control-solid h-auto py-5 px-6"
              name={"nodeDisplayLabel"}
            />

            {errors?.nodeDisplayLabelError && (
              <span className="text-danger">Please add Node Display Label</span>
            )}
            {errors?.nodeDisplayLabelLengthError && (
              <span className="text-danger">
                Please Enter Node Display Label less then 25 characters
              </span>
            )}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <span className="multiOption-displayName">
              <label className="form-label text-dark-50 font-weight-bolder">
                History Label :
              </label>
              <div className="form-icon">
                <Checkbox
                  checked={sameAsNodeLabel}
                  onChange={(e) => {
                    setSameAsNodeLabel(!sameAsNodeLabel);
                    setVariableLabel(nodeDisplayLabel);
                  }}
                />
                <label>Same as Node Label</label>
              </div>
            </span>
            <input
              placeholder="Label"
              type="text"
              value={variableLabel}
              onChange={(e) => {
                const label = e.target.value;
                setVariableLabel(label);
              }}
              className="form-control form-control-solid h-auto py-5 px-6"
              name={"label"}
            />
            {errors?.variableLabelError && (
              <span className="text-danger">Please add Label</span>
            )}
            {errors?.variableLabelValidationError && (
              <span className="text-danger">Please enter valid Label</span>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-10 col-md-5">
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Tool Variable Name :
            </label>
            <input
              type="text"
              placeholder="Name"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="text"
              value={equationName}
              onChange={(e) => {
                setEquationName(e.target.value);
              }}
            />
            {errors?.variableError && (
              <span className="text-danger">Please add tool variable name</span>
            )}
            {errors?.variableValidationError && (
              <span className="text-danger">
                Variable name should be valid with Character and Number.
              </span>
            )}
            {errors?.variableDuplicateValidationError && (
              <span className="text-danger">
                Please use unique variable name
              </span>
            )}
          </div>
        </div>
        <div className="col-xs-2 col-md-1">
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              &nbsp;
            </label>
            <label className="h-auto py-5 px-6 form-label text-dark-50 font-weight-bolder">
              =
            </label>
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="d-flex">
            <div className="form-group fv-plugins-icon-container equation-form-group">
              <label className="form-label text-dark-50 font-weight-bolder">
                Equation :
              </label>
              <textarea
                type="text"
                placeholder="Equation"
                className={`form-control form-control-solid h-auto py-3 px-6`}
                name="text"
                value={equation}
                // value="2 Items Selected"
                readOnly
                title={equation}
              />
              {errors?.equationError && (
                <span className="text-danger">Please add equation</span>
              )}
              {errors?.equationValidationError && (
                <span className="text-danger">Please make valid equestion</span>
              )}
              {errors?.operatorError && (
                <span className="text-danger">Please make valid equation</span>
              )}
            </div>
            <div className="equation-action-icons">
              <button
                className="btn btn-primary d-block"
                type="button"
                title="Clear"
                onClick={() => {
                  setEquationArrayFunction([]);
                  setEquation("");
                  setError("");
                }}
              >
                x
              </button>
              <button
                className="btn btn-primary mt-2 d-block"
                type="button"
                title="Undo"
                onClick={() => {
                  const equationArrayFunctionOld = equationArrayFunction;

                  let removedArray = equationArrayFunctionOld.slice(
                    0,
                    equationArrayFunction.length - 1
                  );
                  setEquationArrayFunction(removedArray);
                }}
              >
                <Undo className="undo" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row border-for-equation">
        <div className="col-xs-12 col-md-12 ">
          <label className="form-label text-dark-50 font-weight-bolder expression-chessel">
            Expression Builder
          </label>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Variable :
            </label>
            <select
              className="form-control form-control-solid h-auto py-5 px-6"
              name="state"
              onChange={(e) => {
                let variable = e.target.value;
                setInputs((state) => ({ ...state, variable }));
                setData((state) => [
                  ...state,
                  { type: "variable", value: variable },
                ]);
                if (variable === "fileVariable") {
                  setUseFileVariable(true);
                  setTextFileVariable(false);
                } else if (variable == "textVariable") {
                  setTextFileVariable(true);
                  setUseFileVariable(false);
                } else {
                  setUseFileVariable(false);
                  setTextFileVariable(false);
                }
              }}
              value={inputs?.variable}
            >
              <option value="">Select Variable</option>
              {variables.map((variable) => {
                return (
                  <option
                    key={variable.key + "_" + variable.name}
                    value={variable.name}
                  >
                    {variable.label} {variable.key != undefined ? "" : "*"}
                  </option>
                );
              })}
            </select>
            {useFileVariable && (
              <>
                <FileVariable
                  singleSelectVariables={singleSelectVariables}
                  useFileVariable={useFileVariable}
                  setFileVariable={setFileVariable}
                />
              </>
            )}
            {textFileVariable && (
              <>
                <label className="form-label text-dark-50 font-weight-bolder">
                  Add Values :
                </label>

                <input
                  type="number"
                  value={variableValue}
                  className={`form-control form-control-solid h-auto py-5 px-6`}
                  onChange={(e) => {
                    setVariableValues(e.target.value);
                  }}
                />
              </>
            )}
            <button
              // disabled={
              //   equation != "" &&
              //   !operators.includes(equation.charAt(equation.length))
              // }
              type="button"
              className="btn btn-primary mt-4"
              onClick={() => {
                if (inputs?.variable !== "") {
                  let valueOfEquation = "";
                  if (inputs?.variable === "fileVariable") {
                    valueOfEquation = fileVariable;
                    setEquation(equation + fileVariable);
                    setTextFileVariable(false);
                    setUseFileVariable(false);
                  } else if (inputs?.variable === "textVariable") {
                    valueOfEquation = variableValue.trim();
                    setEquation(equation + variableValue.trim());
                    setTextFileVariable(false);
                    setUseFileVariable(false);
                  } else {
                    valueOfEquation = inputs?.variable;
                    setEquation(equation + inputs?.variable.trim());
                    setTextFileVariable(false);
                    setUseFileVariable(false);
                  }
                  setInputs((state) => ({ ...state, variable: "" }));
                  const equationArrayFunctionOld = equationArrayFunction;
                  setEquationArrayFunction([
                    ...equationArrayFunctionOld,
                    valueOfEquation,
                  ]);
                }
              }}
            >
              Add
            </button>
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label text-dark-50 font-weight-bolder">
              Operators :
            </label>
            <select
              className="form-control form-control-solid h-auto py-5 px-6"
              name="state"
              onChange={(e) => {
                let operatorValue = e.target.value;
                setInputs((state) => ({ ...state, operator: operatorValue }));
                setData((state) => [
                  ...state,
                  { type: "operator", value: operatorValue },
                ]);
              }}
              value={inputs?.operator}
            >
              <option value="">Select Operator</option>
              {operators.map((v) => (
                <option key={v} value={v}>
                  {v}
                </option>
              ))}
            </select>
            <button
              // disabled={
              //   operators.includes(equation.charAt(equation.length - 1)) &&
              //   ["(", ")"].includes(equation.charAt(equation.length - 1))
              // }
              type="button"
              className="btn btn-primary mt-4"
              onClick={() => {
                // if (inputs?.operator !== "") {
                //   if (equation === "") {
                //     setError({ equationError: true });
                //   } else if (
                //     operators.includes(equation.charAt(equation.length - 1))
                //   ) {
                //     setError({ operatorError: true });
                //   } else {
                //     setError({});
                //     setEquation(equation + inputs?.operator);
                //   }
                // }
                const equationArrayFunctionOld = equationArrayFunction;
                // equationArrayFunctionOld.push(inputs?.operator);
                // setEquationArrayFunction(equationArrayFunctionOld);
                // setEquation(equation + inputs?.operator);
                setEquationArrayFunction([
                  ...equationArrayFunctionOld,
                  inputs?.operator,
                ]);
                setInputs((state) => ({ ...state, variable: "" }));
              }}
            >
              Add
            </button>
          </div>
        </div>

        <div className="col-xs-12 col-md-12">
          <label>
            Note : <small>* = Global Variable </small>,
            <small>{"{variable}"} = Single Select Variable </small>
          </label>
        </div>
      </div>
      <div className="btn-block mt-5">
        <button
          type="button"
          className="btn btn-success w-100px"
          onClick={() => saveForm()}
        >
          {" "}
          Save
        </button>
      </div>
    </form>
  );
};

export default Procedure;
