import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { actions as HELP_SERVICE_ACTIONS } from "../../../../app/modules/HelpSection/_redux/HelpSectionRedux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export function HelpEditTemplate({
  onHide,
  isUpdate,
  id,
  name,
  modalData,
  ...rest
}) {
  const [input, setInput] = useState({
    id: "",
    label: "",
    pageName: "",
  });
  const [file, setFile] = useState(null);
  useEffect(() => {
    setInput({
      id: modalData?.id,
      label: modalData.label,
      pageName: modalData.pageName,
    });
    setFile(null);
  }, [modalData]);

  const dispatch = useDispatch();
  const [errors, setError] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);

  const handleInputChange = (value, inputName) => {
    if (value !== "") {
      let data = {
        [inputName]: { noInput: false },
      };
      setError(data);
      setInput(value);
      const regex = /^[a-zA-Z0-9_\s+]*$/g;
      if (!isNaN(value)) {
        let errorData = {
          [inputName]: { valueValidationError: true },
        };
        setError(errorData);
        setFormSubmit(false);
      } else if (!regex.test(value)) {
        let errorRegexData = {
          [inputName]: { valueValidationError: true },
        };
        setError(errorRegexData);
        setFormSubmit(false);
      } else {
        setFormSubmit(true);
        let noErrorData = {
          [inputName]: { valueValidationError: false },
        };
        setError(noErrorData);
        Object.freeze(input);
        let inputChanges = { ...input };
        inputChanges[inputName] = value;
        setInput(inputChanges);
      }
    } else {
      setFormSubmit(true);
      let inputChanges = input;
      inputChanges[inputName] = value;
      setInput(inputChanges);

      let noInput = {
        [inputName]: { noInput: true },
      };
      setError(noInput);
    }
  };

  const handleSubmit = () => {
    if (input.displayLabel == "") {
      handleInputChange(input.label, "label");
    } else {
      if (formSubmit) {
        if (isUpdate) {
          let fileData = {
            ...input,
            file,
          };
          dispatch(HELP_SERVICE_ACTIONS.add(fileData));
        }
        onHide();
        setInput({
          id: "",
          label: "",
        });
        setFile(null);
      }
    }
  };

  const onFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFormSubmit(true);
      setFile(event.target.files[0]);
    }
  };
  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">Add Help Section Data</h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={onHide}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="form-group fv-plugins-icon-container mb-0">
          <label className="form-label text-dark-50 font-weight-bolder">
            Label
          </label>
          <input
            placeholder="Report Template..."
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="label"
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
            value={input["label"]}
            maxLength="100"
          />
          {errors?.label?.noInput && (
            <span class="text-danger">Please enter label</span>
          )}
          {errors?.label?.valueValidationError && (
            <span class="text-danger">Please enter valid value</span>
          )}
        </div>
        {modalData.fileName != "" && (
          <div className="form-group fv-plugins-icon-container mb-0 mt-5">
            <label className="form-label text-dark-50 font-weight-bolder">
              Uploaded Pdf file
            </label>
            <span
              className=""
              style={{ fontSize: 12, marginLeft: 5, color: "gray" }}
            >
              {modalData.fileName}
            </span>
          </div>
        )}
        <div className="form-group fv-plugins-icon-container mb-0 mt-5">
          <label className="form-label text-dark-50 font-weight-bolder">
            Select Pdf file
          </label>
          <span
            className=""
            style={{ fontSize: 10, marginLeft: 5, color: "gray" }}
          >
            (Note: File size should not be exceed 50Mb)
          </span>
          <input
            style={{
              padding: 13.5,
              border: errors.file ? "1px solid red" : "inherit",
            }}
            type="file"
            className={`form-control form-control-solid h-auto`}
            name="files"
            accept={"application/pdf"}
            onChange={onFileChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-0 mb-8">
        <Button className="w-100px btn-primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
