import React, {  useState } from "react";
import { Button, Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Services } from "../../../../_service/Services";
import { setToast } from "../../../../app/modules/toast";
import { useHistory } from "react-router-dom";

export function ChangePassword({ onHide, user, ...rest }) {
  const [passwordDetails, setPasswordDetails] = useState({
    old: "",
    new1: "",
    new2: "",
  });
  const [error, setError] = useState("");
  const history = useHistory();

  const handleInputChange = (key, event) => {
    const { value } = event.target;
    setError("");
    setPasswordDetails((oldVal) => ({ ...oldVal, [key]: value }));
  };
  const handleSubmit = () => {
    if (passwordDetails.old && passwordDetails.new1 && passwordDetails.new2) {
      if (passwordDetails.new1 !== passwordDetails.new2) {
        setError("New password and re-enter new password should be same!");
      } else if (passwordDetails.old === passwordDetails.new1) {
        setError("Old password and new password can not be same!");
      } else {
        Services({
          url: "/admin/profile/change-password",
          method: "PUT",
          data: {
            oldPassword: passwordDetails.old,
            newPassword: passwordDetails.new1,
          },
        })
          .then(() => {
            history.push("/logout");
          })
          .catch((apiError) => {
            apiError?.response?.data?.message &&
              setToast(apiError?.response?.data?.message, "error");
            return Promise.reject(apiError);
          });
        handleHide();
      }
    } else {
      setError("All fields are mandatory!");
    }
  };

  const handleHide = () => {
    setPasswordDetails({
      old: "",
      new1: "",
      new2: "",
    });
    onHide();
  };

  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleHide}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">Change Password</h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={handleHide}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        {error && (
          <div
            style={{
              textAlign: "center",
              fontSize: 12,
              marginBottom: 5,
            }}
            className="error"
          >
            {error}
          </div>
        )}
        <div className="form-group fv-plugins-icon-container mb-5">
          <label className="form-label text-dark-50 font-weight-bolder">
            *Enter old password:
          </label>
          <input
            placeholder="Old password..."
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="text"
            value={passwordDetails.old}
            onChange={handleInputChange.bind(this, "old")}
          />
        </div>
        <div className="form-group fv-plugins-icon-container mb-5">
          <label className="form-label text-dark-50 font-weight-bolder">
            *Enter new password:
          </label>
          <input
            placeholder="New password..."
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="text"
            value={passwordDetails.new1}
            onChange={handleInputChange.bind(this, "new1")}
          />
        </div>
        <div className="form-group fv-plugins-icon-container mb-5">
          <label className="form-label text-dark-50 font-weight-bolder">
            *Re-Enter new password:
          </label>
          <input
            placeholder="Re-Enter new password..."
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="text"
            value={passwordDetails.new2}
            onChange={handleInputChange.bind(this, "new2")}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-0 mb-8">
        <Button className="w-100px btn-primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
