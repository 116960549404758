import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { actions as UPLOAD_FILE_ACTIONS } from "../../../../app/modules/UploadFile/_redux/UploadFileRedux";

export function FileVariable({
  useFileVariable,
  setFileVariable,
  singleSelectVariables,
  selectedValue,
}) {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState("");
  const [identityCol, setIdentityCol] = useState("");
  const [identityColRow, setIdentityColRow] = useState("");
  const [identityValue, setIdentityValue] = useState("");
  const [localVariableList, setLocalVariableList] = useState([]);
  const fileList = useSelector((store) => store.fileUpload.fileList);
  const columnList = useSelector((store) => store.fileUpload.columnList);
  const columnRowList = useSelector((store) => store.fileUpload.columnRowList);
  useEffect(() => {
    if (useFileVariable) {
      dispatch(UPLOAD_FILE_ACTIONS.getFileList());
    }
    return () => {
      setSelectedFile("");
      setIdentityCol("");
      setIdentityColRow("");
      setIdentityValue("");
    };
  }, [useFileVariable]);
  useEffect(() => {
    if (selectedValue != undefined) {
      setSelectedFile(selectedValue[0]);
      setIdentityCol(selectedValue[1]);
      setIdentityColRow(selectedValue[2]);
      setIdentityValue(selectedValue[3]);
    }
  }, [selectedValue]);
  useEffect(() => {
    if (selectedFile != "") {
      dispatch(UPLOAD_FILE_ACTIONS.getFileColumnList(selectedFile));
    }
  }, [selectedFile]);
  useEffect(() => {
    if (identityCol != "" && identityCol != undefined) {
      dispatch(
        UPLOAD_FILE_ACTIONS.getFileColumnRowList({
          fileName: selectedFile,
          identityColumn: identityCol,
        })
      );
    }
  }, [identityCol]);

  return (
    <>
      <div className="form-group">
        {" "}
        <label className="form-label text-dark-50 font-weight-bolder">
          Select File :
        </label>
        <select
          className="form-control form-control-solid h-auto py-5 px-6"
          name="selectFile"
          onChange={(e) => {
            let variable = e.target.value;
            setSelectedFile(variable);
            setLocalVariableList(
              singleSelectVariables.filter(
                (item) => item.fileName === variable && item.isSingle
              )
            );
          }}
          value={selectedFile}
        >
          <option value="">Select File</option>
          {fileList.map((file) => {
            return (
              <option key={file._id} value={file.fileName}>
                {file.fileName}
              </option>
            );
          })}
        </select>
      </div>
      {selectedFile != "" && (
        <div className="form-group">
          <label className="form-label text-dark-50 font-weight-bolder">
            Select Identity Column :
          </label>
          <select
            className="form-control form-control-solid h-auto py-5 px-6"
            name="state"
            onChange={(e) => {
              let variable = e.target.value;
              setIdentityCol(variable);
              setFileVariable(
                selectedFile +
                  ":" +
                  variable +
                  ":" +
                  identityColRow +
                  ":" +
                  variable
              );
            }}
            value={identityCol}
          >
            <option value="">Select Column</option>
            {columnList.map((file) => {
              return file.colName != "_id" ? (
                <option key={file.index} value={file.colName}>
                  {file.colName}
                </option>
              ) : (
                ""
              );
            })}
          </select>
          {identityCol != "" && (
            <div className="pt-5">
              <label className="form-label text-dark-50 font-weight-bolder">
                Select Identity Column Value :
              </label>
              <select
                className="form-control form-control-solid h-auto py-5 px-6"
                name="state"
                onChange={(e) => {
                  let variable = e.target.value;
                  setIdentityColRow(variable);
                  setFileVariable(
                    selectedFile +
                      ":" +
                      identityCol +
                      ":" +
                      variable +
                      ":" +
                      variable
                  );
                }}
                value={identityColRow}
              >
                <option value="">Select Row</option>
                {columnRowList.map((file, key) => {
                  return file != "_id" ? (
                    <option key={file + "_" + key} value={file}>
                      {file}
                    </option>
                  ) : (
                    ""
                  );
                })}
                {localVariableList.map((variable, key) => {
                  return (
                    <option
                      key={variable.variableName + "_" + key}
                      value={"{" + variable.variableName + "}"}
                    >
                      {`{${variable.variableName}}`}
                    </option>
                  );
                })}
              </select>
              {identityColRow != "" && (
                <div className="pt-5">
                  <label className="form-label text-dark-50 font-weight-bolder">
                    Select Identity Value :
                  </label>
                  <select
                    className="form-control form-control-solid h-auto py-5 px-6"
                    name="state"
                    onChange={(e) => {
                      let variable = e.target.value;
                      setIdentityValue(variable);
                      setFileVariable(
                        selectedFile +
                          ":" +
                          identityCol +
                          ":" +
                          identityColRow +
                          ":" +
                          variable
                      );
                    }}
                    value={identityValue}
                  >
                    <option value="">Select Value</option>
                    {columnList.map((file) => {
                      return file.colName != "_id" ? (
                        <option key={file.index} value={file.colName}>
                          {file.colName}
                        </option>
                      ) : (
                        ""
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
