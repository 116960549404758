import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import RingLoader from "react-spinners/RingLoader";
import { actions as TRAVERS_HISTORY_ACTIONS } from "../../../../app/modules/TraversHistory/_redux/TraversHistoryRedux";
const KeyLabelMap = {
  eCommerceRevenuePercentage: "E Commerce Revenue(%)",
  traditionalRetailRevenuePercentage: "Traditional Retail Revenue(%)",
};

export function TraversHistoryDialoge({ toggleModal, traversId, ...props }) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(TRAVERS_HISTORY_ACTIONS.getTraversHistoryInputDetailsApi(traversId))
        return () => {
            dispatch(TRAVERS_HISTORY_ACTIONS.getTraversHistoryInputDetails({
                item:[],
                displayColumn:[]
              }))
        }
    }, []);
    

  const inputDetails = useSelector(
    (store) => store.traversHistory.traversInputs.item
  );
  const displayNames = useSelector(
    (store) => store.traversHistory.traversInputs.displayColumn
  );
  const isLoading = useSelector((store) => store.traversHistory.isLoading);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        toggleModal(false);
      }}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">Travers Detail</h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={() => {
            toggleModal(false);
          }}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        {isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "20vh",
            }}
            className=""
          >
            <RingLoader color={"darkcyan"} loading={true} size={40} />
          </div>
        )}
        {!isLoading && (
          <div style={{ height: "75vh" }} className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <tbody>
                {(inputDetails || [])?.length > 0 &&
                  (inputDetails || [])?.map?.((item, index) => {
                    let key = Object.keys(item)[0];
                    if (
                      typeof item[key] === "string" ||
                      typeof item[key] === "number"
                    ) {
                      return (
                        <tr key={index}>
                          <th>{key}</th>
                          <td>{item[key]}</td>
                        </tr>
                      );
                    }
                    if (typeof item[key] === "object") {
                      let dataValue = item[key];
                      let namesForDisplay = displayNames
                        ?.find((name) => Object.keys(name)[0] == key)
                        ?.[key].split(" ")[0];
                      const header = key;
                      if (key !== "reportSummary") {
                        return (
                          <tr key={index + "_" + header}>
                            <th>{header}</th>
                            <td>
                              {dataValue?.map?.((dataItem) => {
                                if (namesForDisplay != undefined) {
                                  return (
                                    <Chip
                                      key={dataItem?.[namesForDisplay]}
                                      style={{ margin: "4px" }}
                                      label={dataItem?.[namesForDisplay]}
                                      variant="outlined"
                                    />
                                  );
                                } else {
                                  
                                  let value = dataItem;
                                  if (
                                    typeof dataItem === "object" &&
                                    !Array.isArray(dataItem)
                                  ) {
                                    value = Object.keys(dataItem).reduce(
                                      (result, ItemKey) => {
                                        if (
                                          ItemKey?.toLowerCase()?.indexOf("name") >
                                          -1
                                        ) {
                                          result = dataItem[ItemKey];
                                        }
                                        return result;
                                      },
                                      ""
                                    );
                                    if (!value) {
                                      value = dataItem?._id;
                                    }
                                  }
                                  return (
                                    <Chip
                                      key={value}
                                      style={{ margin: "4px" }}
                                      label={value}
                                      variant="outlined"
                                    />
                                  );
                                }
                              })}
                            </td>
                          </tr>
                        );
                      } else {
                        return "";
                      }
                    }
                  })}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
