import React from "react";
import { Button, Modal } from "react-bootstrap";

export function ConfirmDeleteDialog({ toggleModal, deleteFn, ...props }) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        toggleModal(false);
      }}
    >
      <Modal.Body>
        <h5 className="mt-2 pb-4 text-center">
          Are you sure you want to delete?
        </h5>
        <div className="mb-0 p-0 d-flex justify-content-center">
          <Button
            type="button"
            className="w-100px mr-2"
            onClick={() => {
              deleteFn();
              toggleModal(false);
            }}
          >
            Yes
          </Button>
          <Button
            type="button"
            className="w-100px"
            variant="secondary"
            onClick={() => {
              toggleModal(false);
            }}
          >
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
