import React from "react";

import {
  Conditional,
  ManualInput,
  Parallelogram,
  Procedure,
  PredefinedLogic,
  MultiOption,
  StaticLoop,
  MultiDocument,
  MultiInput,
  CommentInput,
  LookUpNodeInput,
} from "./Forms";

const Form = ({
  save,
  nodeDataArray,
  formToShow,
  localVariableArray,
  linkNodeArray,
}) => {
  const saveForm = (data) => {
    save(formToShow, data);
  };

  switch (nodeDataArray.category) {
    case "Conditional":
      return (
        <Conditional
          localVariableArray={localVariableArray}
          formData={nodeDataArray.metaData}
          save={saveForm}
        />
      );
    case "PredefinedLogic":
      return (
        <PredefinedLogic
          localVariableArray={localVariableArray}
          formData={nodeDataArray.metaData}
          save={saveForm}
          nodeKey={nodeDataArray.key}
          linkNodeArray={linkNodeArray}
        />
      );
    case "Procedure":
      return (
        <Procedure
          localVariableArray={localVariableArray}
          nodeKey={nodeDataArray.key}
          formData={nodeDataArray.metaData}
          linkNodeArray={linkNodeArray}
          save={saveForm}
        />
      );
    case "Parallelogram":
      return (
        <Parallelogram formData={nodeDataArray.metaData} save={saveForm} />
      );
    case "MultiOption":
    case "PassData":
      return (
        <MultiOption
          formData={nodeDataArray.metaData}
          nodeKey={nodeDataArray.key}
          nodeCategory={nodeDataArray.category}
          localVariableArray={localVariableArray}
          linkNodeArray={linkNodeArray}
          save={saveForm}
        />
      );
    case "ManualInput":
      return (
        <ManualInput
          nodeKey={nodeDataArray.key}
          localVariableArray={localVariableArray}
          formData={nodeDataArray.metaData}
          save={saveForm}
        />
      );
    case "LookUp":
      return (
        <LookUpNodeInput
          nodeKey={nodeDataArray.key}
          localVariableArray={localVariableArray}
          formData={nodeDataArray.metaData}
          linkNodeArray={linkNodeArray}
          save={saveForm}
        />
      );
    case "MultiAns":
      return (
        <MultiInput
          nodeKey={nodeDataArray.key}
          localVariableArray={localVariableArray}
          formData={nodeDataArray.metaData}
          save={saveForm}
        />
      );

    case "MultiDocument":
      return (
        <MultiDocument
          localVariableArray={localVariableArray}
          formData={nodeDataArray.metaData}
          nodeCategory={nodeDataArray.category}
          save={saveForm}
        />
      );
    case "StaticLoop":
      return (
        <StaticLoop
          localVariableArray={localVariableArray}
          formData={nodeDataArray.metaData}
          nodeCategory={nodeDataArray.category}
          nodeKey={nodeDataArray.key}
          save={saveForm}
          linkNodeArray={linkNodeArray}
        />
      );

    case "Comment":
      return (
        <CommentInput
          formData={nodeDataArray.metaData}
          nodeCategory={nodeDataArray.category}
          save={saveForm}
        />
      );
    default:
      return <></>;
  }
};

export default Form;
