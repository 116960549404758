import { Services } from "../Services";
import { setToast } from "../../app/modules/toast";

export const addUser = (userData) =>
  Services({
    url: "/admin/user",
    method: "POST",
    data: {
      ...userData,
      userRole:
        typeof userData?.userRole === "string"
          ? [userData?.userRole]
          : userData?.userRole,
    },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

export const updateUser = (userData) =>
  Services({
    url: `/admin/user/${userData.id}`,
    method: "PUT",
    data: {
      firstName: userData.firstName,
      lastName: userData.lastName,
      countryCode: userData.countryCode,
      phone: userData.phone,
      designation: userData.designation,
      //userTittle: userData.designation,
      userRole:
        typeof userData?.userRole === "string"
          ? [userData?.userRole]
          : userData?.userRole,
    },
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

export const changePassword = (passwordDetails) =>
  Services({
    url: `/admin/profile/change-password`,
    method: "PUT",
    data: passwordDetails,
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

export const deleteUser = (id) =>
  Services({
    url: `/admin/user/${id}`,
    method: "DELETE",
  })
    .then((response) => {
      response.data?.message && setToast(response.data?.message, "success");
      return response;
    })
    .catch((error) => {
      error?.response?.data?.message &&
        setToast(error?.response?.data?.message, "error");
      return Promise.reject(error);
    });

export const getUsers = (pageNo = 1) =>
  Services({
    url: `/admin/user/?page=${pageNo}`,
    method: "GET",
  });

export const getUserTraverse = (id) =>
  Services({
    url: `/user/history/?userId=${id}`,
    method: "GET",
  });

//mock
let users = [];
export const user = { users };
