import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";
import Data from "../../../../_metronic/_partials/diagram/data/response.json";
import * as DIAGRAM_SERVICES from "../../../../_service/diagram/diagram.service";
import { setToast } from "../../toast";

export const actionTypes = {
  ADD: "[DIGRAM_ADD] Action",
  UPDATE: "[DIGRAM_UPDATE] Action",
  DELETE: "[DIGRAM_DELETE] Action",
  LOADING: "[DIGRAM_LOADING] Action",
  GET_DIAGRAM_DATA: "[GET_DIAGRAM_DATA] Action",
  GET_DIAGRAM_DATA_API_CALL: "[GET_DIAGRAM_DATA_API_CALL] Action",
  ERROR: "[DIGRAM_ERROR] Action",
  REDIRECT: "[DIGRAM_REDIRECT] Action",
  GET_LIST_PREDEFINED_LOGIC_DIAGRAM:
    "[GET_LIST_PREDEFINED_LOGIC_DIAGRAM] Action",
  GET_LIST_PREDEFINED_LOGIC_DIAGRAM_API:
    "[GET_LIST_PREDEFINED_LOGIC_DIAGRAM_API] Action",
  GET_LIST_PREDEFINED_LOGIC_DIAGRAM_VARIABLES_API:
    "[GET_LIST_PREDEFINED_LOGIC_DIAGRAM_VARIABLES_API] Action",
  GET_LIST_PREDEFINED_LOGIC_DIAGRAM_VARIABLES:
    "[GET_LIST_PREDEFINED_LOGIC_DIAGRAM_VARIABLES] Action",
  GET_LIST_MULTIPLE_OPTION: "[GET_LIST_MULTIPLE_OPTION] Action",
  GET_LIST_MULTIPLE_OPTION_API: "[GET_LIST_MULTIPLE_OPTION_API] Action",
  GET_QUESTION_DATA: "[GET_QUESTION_DATA] Action",
  GET_SUB_TOPIC_ID_DATA: "[GET_SUB_TOPIC_ID_DIGRAM_DATA] Action",
  // GET_PREDEFINED_LOGIC_DIAGRAM_DATA: "[GET_PREDEFINED_LOGIC_DIAGRAM_DATA] Action",
  // GET_PREDEFINED_LOGIC_DIAGRAM_DATA_API_CALL: "[GET_PREDEFINED_LOGIC_DIAGRAM_DATA_API_CALL] Action",
  GET_LIST_PROCEDURE_VARIABLE_API: "[GET_LIST_PROCEDURE_VARIABLE_API] Action",
  GET_LIST_PROCEDURE_VARIABLE_DATA: "[GET_LIST_PROCEDURE_VARIABLE_DATA] Action",
  GET_LIST_MANUAL_INPUT: "[GET_LIST_MANUAL_INPUT] Action",
  GET_LIST_MANUAL_INPUT_API: "[GET_LIST_MANUAL_INPUT_API] Action",
  GET_LIST_OPERATOR_API: "[GET_LIST_OPERATOR_API] Action",
  GET_LIST_OPERATOR: "[GET_LIST_OPERATOR] Action",
  SET_DIAGRAM_ERROR: "[SET_DIAGRAM_ERROR] Action",
};

const initialDaigramState = {
  isLoading: false,
  error: "",
  redirect: false,
  question: "",
  subTopicId: "",
  diagramData: {
    nodeDataArray: [],
    linkDataArray: [],
  },
  predefinedLogicData: [],
  predefinedLogicVariables: [],
  optionData: [],
  predefinedLogic: {
    predefinedLogicName: "",
    diagramData: {
      nodeDataArray: [],
      linkDataArray: [],
    },
  },
  procedureVariables: [],
  manualInputData: [],
  operators: [],
  diagramError: {
    hasError: false,
  },
};

export const reducer = persistReducer(
  {
    storage,
    key: "diagram",
    whitelist: ["predefinedLogicData", "optionData"],
  },
  (state = initialDaigramState, action) => {
    switch (action.type) {
      case actionTypes.GET_DIAGRAM_DATA: {
        const { diagramData } = action.payload;
        return { ...state, diagramData };
      }
      case actionTypes.GET_QUESTION_DATA: {
        const { question } = action.payload;
        return { ...state, question: question };
      }
      case actionTypes.GET_SUB_TOPIC_ID_DATA: {
        const { subTopicId } = action.payload;
        return { ...state, subTopicId: subTopicId };
      }
      case actionTypes.GET_LIST_PREDEFINED_LOGIC_DIAGRAM: {
        const predefinedLogicData = action.payload.result;
        return { ...state, predefinedLogicData };
      }
      case actionTypes.GET_LIST_PREDEFINED_LOGIC_DIAGRAM_VARIABLES: {
        const predefinedLogicVariables = action.payload.result;
        return { ...state, predefinedLogicVariables };
      }
      case actionTypes.GET_LIST_MULTIPLE_OPTION: {
        const optionData = action.payload.result;
        return { ...state, optionData };
      }
      case actionTypes.GET_LIST_OPERATOR: {
        const operators = action.payload.result;
        return { ...state, operators };
      }
      case actionTypes.GET_LIST_MANUAL_INPUT: {
        const manualInputData = action.payload.result;
        return { ...state, manualInputData };
      }
      case actionTypes.GET_LIST_PROCEDURE_VARIABLE_DATA: {
        const { procedureVariables } = action.payload;
        return { ...state, procedureVariables };
      }

      case actionTypes.LOADING: {
        const { isLoading } = action.payload;
        return { ...state, isLoading };
      }

      case actionTypes.ERROR: {
        const { error } = action.payload;
        return { ...state, error };
      }
      case actionTypes.SET_DIAGRAM_ERROR: {
        const { error } = action.payload;
        return { ...state, diagramError: error };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  add: (diagramData) => ({ type: actionTypes.ADD, payload: { diagramData } }),
  update: (diagramData) => ({
    type: actionTypes.UPDATE,
    payload: { diagramData },
  }),
  delete: (id) => ({ type: actionTypes.DELETE, payload: { id } }),
  list: (diagramDatas) => ({
    type: actionTypes.LIST,
    payload: { diagramDatas },
  }),
  isLoading: (isLoading) => ({
    type: actionTypes.LOADING,
    payload: { isLoading },
  }),
  redirect: (isTrue) => ({
    type: actionTypes.REDIRECT,
    payload: { isTrue },
  }),
  error: (error) => ({ type: actionTypes.ERROR, payload: { error } }),
  getDiagramDetail: (questionId) => ({
    type: actionTypes.GET_DIAGRAM_DATA_API_CALL,
    payload: { questionId },
  }),
  getDiagramData: (diagramData) => ({
    type: actionTypes.GET_DIAGRAM_DATA,
    payload: { diagramData },
  }),
  getQuestion: (question) => ({
    type: actionTypes.GET_QUESTION_DATA,
    payload: { question },
  }),
  getSubTopicId: (subTopicId) => ({
    type: actionTypes.GET_SUB_TOPIC_ID_DATA,
    payload: { subTopicId },
  }),
  getPredefinedLogicListAPI: () => ({
    type: actionTypes.GET_LIST_PREDEFINED_LOGIC_DIAGRAM_API,
  }),
  getPredefinedLogicVariablesAPI: (predefinedLogic) => ({
    type: actionTypes.GET_LIST_PREDEFINED_LOGIC_DIAGRAM_VARIABLES_API,
    payload: { predefinedLogic },
  }),
  getPredefinedLogicList: (result) => ({
    type: actionTypes.GET_LIST_PREDEFINED_LOGIC_DIAGRAM,
    payload: { result },
  }),
  getPredefinedLogicVariablesList: (result) => ({
    type: actionTypes.GET_LIST_PREDEFINED_LOGIC_DIAGRAM_VARIABLES,
    payload: { result },
  }),
  getMultipleOptionListAPI: (nodeType) => ({
    type: actionTypes.GET_LIST_MULTIPLE_OPTION_API,
    payload: { nodeType },
  }),
  getMultipleOptionList: (result) => ({
    type: actionTypes.GET_LIST_MULTIPLE_OPTION,
    payload: { result },
  }),
  getManualInputVariablesListAPI: (nodeType) => ({
    type: actionTypes.GET_LIST_MANUAL_INPUT_API,
    payload: { nodeType },
  }),
  getManualInputVariablesList: (result) => ({
    type: actionTypes.GET_LIST_MANUAL_INPUT,
    payload: { result },
  }),

  getProcedureVariableData: (procedureVariables) => ({
    type: actionTypes.GET_LIST_PROCEDURE_VARIABLE_DATA,
    payload: { procedureVariables },
  }),
  getProcedureVariableListAPI: (nodeType) => ({
    type: actionTypes.GET_LIST_PROCEDURE_VARIABLE_API,
    payload: { nodeType },
  }),
  getOperatorListAPI: (nodeType) => ({
    type: actionTypes.GET_LIST_OPERATOR_API,
    payload: { nodeType },
  }),
  getOperatorList: (result) => ({
    type: actionTypes.GET_LIST_OPERATOR,
    payload: { result },
  }),
  setDiagramError: (error) => ({
    type: actionTypes.SET_DIAGRAM_ERROR,
    payload: { error },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.ADD, function* addSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { diagramData } = action.payload;
      yield call(DIAGRAM_SERVICES.createDiagram, diagramData);
      yield put(
        actions.getDiagramData(
          {
            nodeDataArray: diagramData.nodeDataArray,
            linkDataArray: diagramData.linkDataArray,
          } || initialDaigramState.diagramData
        )
      );
      yield put(actions.isLoading(false));
      // yield put(actions.redirect(true));
    } catch (error) {
      // console.log(error?.response?.data?.data);
      if (error?.response?.data?.data?.errors?.errorFields.length > 0) {
        yield put(actions.setDiagramError(error?.response?.data?.data));
      } else {
        setToast(error?.response?.data?.message);
      }

      yield put(actions.error("Failed Create Diagram!"));
      yield put(actions.isLoading(false));

      // setToast(error?.response?.data?.message);
    }
  });
  yield takeLatest(actionTypes.UPDATE, function* updateSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { diagramData } = action.payload;
      yield call(DIAGRAM_SERVICES.updateDiagram, diagramData);
      yield put(
        actions.getDiagramData(
          {
            nodeDataArray: diagramData.nodeDataArray,
            linkDataArray: diagramData.linkDataArray,
          } || initialDaigramState.diagramData
        )
      );
    } catch (error) {
      if (error?.response?.data?.data?.errors?.errorFields.length > 0) {
        yield put(actions.setDiagramError(error?.response?.data?.data));
      } else {
        setToast(error?.response?.data?.message);
      }
      yield put(actions.error("Failed Update Diagram!"));
    }
    yield put(actions.isLoading(false));
  });
  yield takeLatest(actionTypes.DELETE, function* deleteSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { id } = action.payload;
      yield call(DIAGRAM_SERVICES.deleteDiagram, id);
      yield put(actions.getDiagramData(initialDaigramState.diagramData));
    } catch (error) {
      yield put(actions.error("Failed Delete Diagram!"));
    }
    yield put(actions.isLoading(false));
  });
  yield takeLatest(
    actionTypes.GET_DIAGRAM_DATA_API_CALL,
    function* getDiagramDetailSaga(action) {
      yield put(actions.isLoading(true));
      actions.getDiagramData(initialDaigramState.diagramData);
      try {
        const { questionId } = action.payload;
        const response = yield call(DIAGRAM_SERVICES.getDiagram, questionId);
        const diagramDetails = response.data?.data?.tree;

        yield put(
          actions.getDiagramData(
            diagramDetails || initialDaigramState.diagramData
          )
        );
        yield put(
          actions.getQuestion(
            response.data?.data?.question || initialDaigramState.question
          )
        );
        yield put(
          actions.getSubTopicId(
            response.data?.data?.topicId || initialDaigramState.subTopicId
          )
        );
      } catch (error) {
        yield put(actions.error("Failed Get Diagram!"));
        yield put(actions.getDiagramData(initialDaigramState.diagramData));
      }
      yield put(actions.isLoading(false));
    }
  );

  yield takeLatest(
    actionTypes.GET_LIST_PROCEDURE_VARIABLE_API,
    function* getListProcedureVariableApiSaga(action) {
      yield put(actions.isLoading(true));
      const { nodeType } = action.payload;
      try {
        const response = yield call(DIAGRAM_SERVICES.getVariableList, nodeType);
        yield put(
          actions.getProcedureVariableData(
            response.data?.data || initialDaigramState.procedureVariables
          )
        );
      } catch (error) {
        yield put(actions.error("Failed Procedure Variable fetch!"));
      }
      yield put(actions.isLoading(false));
    }
  );

  yield takeLatest(
    actionTypes.GET_LIST_PREDEFINED_LOGIC_DIAGRAM_API,
    function* listSaga() {
      yield put(actions.isLoading(true));
      try {
        const response = yield call(DIAGRAM_SERVICES.getPredefinedLogicList);
        yield put(
          actions.getPredefinedLogicList(
            response.data?.data || initialDaigramState.predefinedLogicData
          )
        );
        yield put(
          actions.getPredefinedLogicVariablesList(
            initialDaigramState.predefinedLogicData
          )
        );
      } catch (error) {
        yield put(actions.error("Failed Predefined Logic fetch!"));
      }
      yield put(actions.isLoading(false));
    }
  );

  yield takeLatest(
    actionTypes.GET_LIST_PREDEFINED_LOGIC_DIAGRAM_VARIABLES_API,
    function* predefinedLogicVariablesListSaga(action) {
      yield put(actions.isLoading(true));
      try {
        const { predefinedLogic } = action.payload;

        const response = yield call(
          DIAGRAM_SERVICES.getPredefinedLogicVariables,
          predefinedLogic
        );
        yield put(
          actions.getPredefinedLogicVariablesList(
            response.data?.data || initialDaigramState.predefinedLogicData
          )
        );
        //yield put(actions.getPredefinedLogicVariablesList(Data.data.serverVariables || initialDaigramState.procedureData));
      } catch (error) {
        yield put(actions.error("Failed Variable fetch!"));
        yield put(
          actions.getPredefinedLogicVariablesList(
            Data.data.serverVariables || initialDaigramState.predefinedLogicData
          )
        );
      }
      yield put(actions.isLoading(false));
    }
  );
  yield takeLatest(actionTypes.GET_LIST_MULTIPLE_OPTION_API, function* listSaga(
    action
  ) {
    const { nodeType } = action.payload;
    yield put(actions.isLoading(true));
    try {
      const response = yield call(DIAGRAM_SERVICES.getVariableList, nodeType);

      yield put(
        actions.getMultipleOptionList(
          response.data?.data || initialDaigramState.optionData
        )
      );
      // yield put(actions.getMultipleOptionList(Data.data.category_sample))
    } catch (error) {
      yield put(actions.error("Failed Option fetch!"));
    }
    yield put(actions.isLoading(false));
  });
  yield takeLatest(
    actionTypes.GET_LIST_MANUAL_INPUT_API,
    function* manualInputListSaga(action) {
      const { nodeType } = action.payload;
      yield put(actions.isLoading(true));
      try {
        const response = yield call(DIAGRAM_SERVICES.getVariableList, nodeType);
        yield put(
          actions.getManualInputVariablesList(
            response.data?.data || initialDaigramState.manualInputData
          )
        );
      } catch (error) {
        yield put(actions.error("Failed Option fetch!"));
      }
      yield put(actions.isLoading(false));
    }
  );

  yield takeLatest(
    actionTypes.GET_LIST_OPERATOR_API,
    function* getOperatorsSaga(action) {
      yield put(actions.isLoading(true));
      try {
        const { nodeType } = action.payload;

        const response = yield call(DIAGRAM_SERVICES.getOperatorList, nodeType);
        yield put(
          actions.getOperatorList(
            response.data?.data || initialDaigramState.operators
          )
        );
        // yield put(actions.getOperatorList(Data.data.operators || initialDaigramState.procedureData));
      } catch (error) {
        yield put(actions.error("Failed Variable fetch!"));
        yield put(
          actions.getPredefinedLogicVariablesList(
            Data.data.serverVariables || initialDaigramState.operators
          )
        );
      }
      yield put(actions.isLoading(false));
    }
  );
}
