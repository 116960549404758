import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { cloneDeep } from "lodash";
import {
  Box,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Services } from "../../../../_service/Services";
import { actions as UPLOAD_FILE_ACTIONS } from "../../../../app/modules/UploadFile/_redux/UploadFileRedux";

let validationArray = [
  "Equals To",
  "Greater Than",
  "Greater Than Or Equal To",
  "Less Than",
  "Less Than Or Equal To",
  "Not Equals To",
];
let equationArray = {
  "Equals To": "=",
  "Greater Than": ">",
  "Greater Than Or Equal To": ">=",
  "Less Than": "<",
  "Less Than Or Equal To": "<=",
  "Not Equals To": "!=",
};
let includesValue = ["Not Equals To", "Equals To"];
export function FileVariableLookUp({
  useFileVariable,
  setFileVariable,
  singleSelectVariables,
  selectedValue,
  isEdit,
}) {
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState("");
  const [fileId, setFileId] = useState("");

  const [outerOperation, setOuterOperation] = useState("");

  const [multiIdentityColRow, setMultiIdentityColRow] = useState([
    {
      operation: "",
      operationalFields: [
        {
          identityCol: "",
          identityColRow: "",
          equation: "",
          text: "",
          isAdd: true,
          error: { errorIdentityCol: "", errorIdentityColRow: "" },
        },
      ],
    },
  ]);

  const [identityValue, setIdentityValue] = useState([]);
  const [localVariableList, setLocalVariableList] = useState([]);
  const fileList = useSelector((store) => store.fileUpload.fileList);
  const columnList = useSelector((store) => store.fileUpload.columnList);
  const [columnRowList, setColumnRowList] = useState({});

  useEffect(() => {
    if (useFileVariable) {
      dispatch(UPLOAD_FILE_ACTIONS.getFileList());
    }
    return () => {
      setFileName("");
    };
  }, [useFileVariable]);
  useEffect(() => {
    if (selectedValue != undefined && selectedValue != "") {
      setFileName(selectedValue.fileName);
      setFileId(selectedValue.fileId);

      if (selectedValue?.multiIdentityColRow != undefined) {
        setIdentityValue(selectedValue.identityValue);

        setOuterOperation(selectedValue.outerOperation);
        setMultiIdentityColRow(selectedValue.multiIdentityColRow);
        const desiredValues = [
          ...new Set(
            selectedValue.multiIdentityColRow.flatMap((obj) =>
              obj.operationalFields.map(
                (operationalField) => operationalField.identityCol
              )
            )
          ),
        ];
        let tempColrowList = {};

        desiredValues.forEach(async (item) => {
          tempColrowList[item] = await handleGetColumnRowValue(
            selectedValue.fileName,
            item
          ).then((result) => {
            return result;
          });
        });
        setColumnRowList(tempColrowList);
      }
    }
  }, []);

  useEffect(() => {
    if (fileName != "") {
      setLocalVariableList(
        singleSelectVariables
        // .filter(
        //   (item) =>
        //     // item.fileName === fileName &&
        //     item.isSingle
        // )
      );
      dispatch(UPLOAD_FILE_ACTIONS.getFileColumnList(fileName));
    }
  }, [fileName]);

  useEffect(() => {
    let newFileObject = {
      outerOperation: outerOperation,
      multiIdentityColRow: multiIdentityColRow,
      identityValue: identityValue,
      fileName: fileName,
      fileId: fileId,
    };
    setFileVariable(newFileObject);
  }, [multiIdentityColRow, identityValue, outerOperation]);

  const handleGetColumnRowValue = async (fileName, identityCol) => {
    return await Services({
      url:
        "/admin/excelData/fileColumnsData?fileName=" +
        fileName +
        "&identityCol=" +
        identityCol,
      method: "GET",
    }).then((response) => {
      let listColumnRowValue = [...new Set(response.data?.data)];
      return listColumnRowValue;
    });
  };

  const handleInputChange = async (index, event, dataIndex) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name == "identityCol") {
      let oldValues = cloneDeep(multiIdentityColRow);
      Object.freeze(oldValues[dataIndex]);
      let newObject = { ...oldValues[dataIndex].operationalFields[index] };
      newObject.identityCol = value;
      if (columnRowList[value] != undefined) {
        let findIndex = columnRowList[value].findIndex(
          (item) => item == newObject.value
        );
        if (findIndex == -1) {
          newObject.identityColRow = "";
        }
      }

      oldValues[dataIndex].operationalFields[index] = newObject;

      let columnRowListForIdentityCol = await handleGetColumnRowValue(
        fileName,
        value
      );
      setMultiIdentityColRow(oldValues);
      let oldColumnRowList = { ...columnRowList };
      oldColumnRowList[value] = columnRowListForIdentityCol;
      setColumnRowList(oldColumnRowList);
    } else {
      const oldMultiIdentityColRow = [...multiIdentityColRow];
      Object.freeze(oldMultiIdentityColRow[dataIndex]);
      const oldOperatonFields = { ...oldMultiIdentityColRow[dataIndex] };
      Object.freeze(oldOperatonFields.operationalFields);
      let operationalFields = [...oldOperatonFields.operationalFields];
      Object.freeze(operationalFields[index]);
      let newObject = { ...operationalFields[index] };
      newObject[name] = value;

      operationalFields[index] = newObject;
      oldOperatonFields.operationalFields = operationalFields;
      oldMultiIdentityColRow[dataIndex] = oldOperatonFields;
      setMultiIdentityColRow(oldMultiIdentityColRow);
    }
  };

  const handleremoveInput = (index, dataIndex, field) => {
    const oldmultiIdentityColRow = [...multiIdentityColRow];
    if (field != "") {
      Object.freeze(oldmultiIdentityColRow[dataIndex]);
      const oldOperatonFields = { ...oldmultiIdentityColRow[dataIndex] };
      Object.freeze(oldOperatonFields.operationalFields);
      let operationalFields = [...oldOperatonFields.operationalFields];
      operationalFields.splice(index, 1);
      oldOperatonFields.operationalFields = operationalFields;
      oldmultiIdentityColRow[dataIndex] = oldOperatonFields;
    } else {
      oldmultiIdentityColRow.splice(dataIndex, 1);
    }
    setMultiIdentityColRow(oldmultiIdentityColRow);
  };

  const handleSaveIdentity = (index, dataIndex) => {
    const oldMultiIdentityColRow = [...multiIdentityColRow];
    Object.freeze(oldMultiIdentityColRow[dataIndex]);
    const oldOperatonFields = { ...oldMultiIdentityColRow[dataIndex] };
    Object.freeze(oldOperatonFields.operationalFields);
    let operationalFields = [...oldOperatonFields.operationalFields];
    Object.freeze(operationalFields[index]);
    let newObject = { ...operationalFields[index] };
    newObject.isAdd = false;
    operationalFields[index] = newObject;
    oldOperatonFields.operationalFields = operationalFields;
    oldMultiIdentityColRow[dataIndex] = oldOperatonFields;
    setMultiIdentityColRow(oldMultiIdentityColRow);
  };

  const handleAddIdentity = (index) => {
    const oldMultiIdentityColRow = [...multiIdentityColRow];
    Object.freeze(oldMultiIdentityColRow[index]);
    const oldOperatonFields = { ...oldMultiIdentityColRow[index] };
    Object.freeze(oldOperatonFields.operationalFields);
    let newObject = [...oldOperatonFields.operationalFields];
    newObject.push({
      identityCol: "",
      identityColRow: "",
      equation: "",
      text: "",
      isAdd: true,
      error: { errorIdentityCol: "", errorIdentityColRow: "" },
    });
    oldOperatonFields.operationalFields = newObject;
    oldMultiIdentityColRow[index] = oldOperatonFields;
    setMultiIdentityColRow(oldMultiIdentityColRow);
  };

  const handleSelection = (value, name, index, keyValue) => {
    const oldMultiIdentityColRow = [...multiIdentityColRow];
    Object.freeze(multiIdentityColRow[index]);
    let newObject = { ...multiIdentityColRow[index] };
    newObject[name] = value;
    oldMultiIdentityColRow[index] = newObject;
    setMultiIdentityColRow(oldMultiIdentityColRow);
  };

  return (
    <>
      {fileList.length > 0 && isEdit && (
        <>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="form-group">
                <label className="form-label">Select File :</label>
                <select
                  className="form-control form-control-solid h-auto py-5 px-6"
                  name="selectFile"
                  onChange={(e) => {
                    let variable = e.target.value;
                    var index = e.nativeEvent.target.selectedIndex;
                    setFileName(variable);
                    setIdentityValue([]);
                    setMultiIdentityColRow([]);
                    setFileId(fileList[index - 1]?._id);
                  }}
                  value={fileName}
                >
                  <option value="">Select File</option>
                  {fileList.map((file) => {
                    return (
                      <option key={file._id} value={file.fileName}>
                        {file.fileName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          {fileName != "" && (
            <>
              {multiIdentityColRow.length > 0 &&
                multiIdentityColRow.map((data, dataIndex) => {
                  return (
                    <>
                      {dataIndex == 1 && (
                        <>
                          <label className="form-label">
                            Select Union Operation :
                          </label>
                          <select
                            className="form-control form-control-solid h-auto py-5 px-6"
                            name="firstOperation"
                            onChange={(e) => {
                              setOuterOperation(e.target.value);
                            }}
                            value={outerOperation}
                          >
                            <option value="">Select</option>
                            <option value="OR">OR</option>
                            <option value="AND">AND</option>
                          </select>
                        </>
                      )}
                      <div
                        className="add-expression-box selected-tags-block"
                        key={"identity-col-" + dataIndex}
                      >
                        {multiIdentityColRow.length > 1 && (
                          <span
                            className="inputclose_btn"
                            onClick={() => handleremoveInput("", dataIndex, "")}
                          >
                            <CloseIcon />
                          </span>
                        )}

                        <div className="select-inner-operation">
                          <label className="form-label">
                            Select Criteria :
                          </label>
                          <select
                            className="form-control form-control-solid h-auto py-5 px-6"
                            name="operation"
                            value={data.operation}
                            onChange={(e) => {
                              handleSelection(
                                e.target.value,
                                e.target.name,
                                dataIndex,
                                ""
                              );
                            }}
                          >
                            <option value="">Select</option>
                            <option value="OR">OR</option>
                            <option value="AND">AND</option>
                          </select>
                        </div>
                        <div className="selected-tags-list">
                          {data.operationalFields.map((item, index) => {
                            let disabledValidation =
                              columnRowList[item?.identityCol] != undefined &&
                              isNaN(columnRowList[item?.identityCol][0]);

                            return (
                              <>
                                {!item?.isAdd && item.identityCol != "" && (
                                  <div className="selected-tag">
                                    {item.identityCol}{" "}
                                    {equationArray?.[item.equation]}{" "}
                                    {item.identityColRow != "text"
                                      ? item.identityColRow
                                      : item.text}
                                    <span
                                      onClick={() =>
                                        handleremoveInput(
                                          index,
                                          dataIndex,
                                          "operationalFields"
                                        )
                                      }
                                      className="tag-remove"
                                    >
                                      <CloseIcon />
                                    </span>
                                  </div>
                                )}
                                {item?.isAdd != undefined &&
                                  item?.isAdd != false && (
                                    <>
                                      <div
                                        className="select-column-for-conditions identity-col file-lookup-grid"
                                        key={
                                          "select-column-for-conditions" +
                                          "-" +
                                          index
                                        }
                                      >
                                        {data.operationalFields.length > 1 && (
                                          <span
                                            className="inputclose_btn"
                                            onClick={() =>
                                              handleremoveInput(
                                                index,
                                                dataIndex,
                                                "operationalFields"
                                              )
                                            }
                                          >
                                            <CloseIcon />
                                          </span>
                                        )}
                                        <div>
                                          <label className="form-label">
                                            Select Identity Column :
                                          </label>
                                          <select
                                            className="form-control form-control-solid h-auto py-5 px-6"
                                            name="identityCol"
                                            onChange={(e) => {
                                              handleInputChange(
                                                index,
                                                e,
                                                dataIndex
                                              );
                                            }}
                                            value={item.identityCol}
                                          >
                                            <option value="">Select</option>
                                            {columnList.map((file) => {
                                              return file.colName != "_id" ? (
                                                <option
                                                  key={file.index}
                                                  value={file.colName}
                                                >
                                                  {file.colName}
                                                </option>
                                              ) : (
                                                ""
                                              );
                                            })}
                                          </select>
                                        </div>
                                        <div>
                                          <label className="form-label">
                                            Select Operators :
                                          </label>
                                          <select
                                            className="form-control form-control-solid h-auto py-5 px-6"
                                            name="equation"
                                            onChange={(e) => {
                                              handleInputChange(
                                                index,
                                                e,
                                                dataIndex
                                              );
                                            }}
                                            value={item.equation}
                                          >
                                            <option value="">Select</option>
                                            {validationArray.map((operator) => {
                                              return (
                                                <option
                                                  disabled={
                                                    disabledValidation
                                                      ? includesValue.includes(
                                                          operator
                                                        )
                                                        ? false
                                                        : true
                                                      : false
                                                  }
                                                  key={operator}
                                                  value={operator}
                                                >
                                                  {operator}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                        <div>
                                          <label className="form-label">
                                            Select Value :
                                          </label>
                                          <select
                                            className="form-control form-control-solid h-auto py-5 px-6"
                                            name="identityColRow"
                                            onChange={(e) => {
                                              handleInputChange(
                                                index,
                                                e,
                                                dataIndex
                                              );
                                            }}
                                            value={item.identityColRow}
                                          >
                                            <option value="">Select Row</option>
                                            {columnRowList[item?.identityCol] !=
                                              undefined &&
                                              columnRowList[
                                                item?.identityCol
                                              ].map((file, key) => {
                                                return file != "_id" ? (
                                                  <option
                                                    key={file + "_" + key}
                                                    value={file}
                                                  >
                                                    {file}
                                                  </option>
                                                ) : (
                                                  ""
                                                );
                                              })}
                                            {localVariableList.map(
                                              (variable, key) => {
                                                return (
                                                  <option
                                                    key={
                                                      variable.variableName +
                                                      "_" +
                                                      key
                                                    }
                                                    value={
                                                      "{" +
                                                      variable.variableName +
                                                      "}"
                                                    }
                                                  >
                                                    {`{${variable.variableName}}`}
                                                  </option>
                                                );
                                              }
                                            )}
                                            <option value="text">Text</option>
                                          </select>
                                          {item.identityColRow == "text" && (
                                            <input
                                              type="text"
                                              name="text"
                                              value={item.text}
                                              className="form-control form-control-solid h-auto py-5 px-6 mt-5"
                                              onChange={(e) => {
                                                handleInputChange(
                                                  index,
                                                  e,
                                                  dataIndex
                                                );
                                              }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className="text-right save-button">
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={() =>
                                            handleSaveIdentity(index, dataIndex)
                                          }
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </>
                                  )}
                              </>
                            );
                          })}
                        </div>
                        {data.operationalFields.filter(
                          (item) => item?.isAdd == true
                        ).length == 0 && (
                          <div className="text-right">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => handleAddIdentity(dataIndex)}
                            >
                              Add Expression
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              <div className="mb-5 text-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    let newAddition = {
                      operation: "",
                      operationalFields: [
                        {
                          identityCol: "",
                          identityColRow: "",
                          equation: "",
                          isAdd: true,
                          error: {
                            errorIdentityCol: "",
                            errorIdentityColRow: "",
                          },
                        },
                      ],
                      isOperationEditable: true,
                    };
                    const oldMultiIdentityColRow = [...multiIdentityColRow];

                    const updateMultiIdentityColRow = oldMultiIdentityColRow.map(
                      (obj) => {
                        return { ...obj, isOperationEditable: false };
                      }
                    );

                    setMultiIdentityColRow([
                      ...updateMultiIdentityColRow,
                      newAddition,
                    ]);
                  }}
                >
                  Add Criteria
                </button>
              </div>
            </>
          )}
          {fileName != "" && (
            <>
              <div className="form-group">
                <label className="form-label">Selected Column Crieteria:</label>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  multiple
                  style={{ width: "100%", padding: 0 }}
                  value={identityValue}
                  onChange={(e) => {
                    setIdentityValue(e.target.value);
                  }}
                  inputProps={{
                    className:
                      "form-control form-control-solid h-auto py-2 px-2 br-2 abc",
                  }}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 4,
                        padding: 4,
                      }}
                    >
                      {selected.map((item) => (
                        <Chip
                          key={item}
                          label={item}
                          className="form-select-tag"
                        />
                      ))}
                    </Box>
                  )}
                  disableUnderline={true}
                  placeholder="Select unique key columns..."
                  MenuProps={{
                    style: {
                      maxWidth: 300,
                    },
                  }}
                >
                  {columnList.map((file) => {
                    return file.colName != "_id" ? (
                      <MenuItem key={file.colName} value={file.colName}>
                        <Checkbox
                          checked={identityValue?.indexOf?.(file.colName) > -1}
                        />
                        <ListItemText primary={file.colName} />
                      </MenuItem>
                    ) : (
                      ""
                    );
                  })}
                </Select>
              </div>
            </>
          )}
        </>
      )}
      {!isEdit && (
        <>
          {multiIdentityColRow.length > 0 &&
            multiIdentityColRow.map((data, dataIndex) => {
              return (
                <div
                  key={"expresionIndex_" + dataIndex}
                  className="selected-tags-block mt-4 mb-6"
                >
                  {outerOperation != "" && dataIndex == 1 && (
                    <div className="tagstitle">
                      Expressions{" "}
                      {outerOperation != "" ? "(" + outerOperation + ")" : ""}
                    </div>
                  )}

                  <div className={`tagstitle ${dataIndex > 0 ? "ml-4" : ""}`}>
                    {dataIndex > 0 ? "Sub " : ""}Expressions{" "}
                    {data.operation != "" ? "(" + data.operation + ")" : ""}
                  </div>
                  <div
                    className={`selected-tags-list ${
                      dataIndex > 0 ? "ml-4" : ""
                    }`}
                  >
                    {data.operationalFields.map((item, index) => {
                      return (
                        <div className="selected-tag">
                          {item.identityCol} {equationArray?.[item.equation]}{" "}
                          {item.identityColRow != "text"
                            ? item.identityColRow
                            : item.text}
                          {/* <span className="tag-remove">
                            <CloseIcon />
                          </span> */}
                        </div>
                      );
                    })}
                  </div>
                  {/* ;<a className="morelink-text">See More</a> */}
                </div>
              );
            })}
          <div className="selected-tags-block mt-4 mb-6">
            <div className="tagstitle">Selected Lookup Columns</div>
            <div className="selected-tags-list">
              {identityValue.map((item, index) => {
                return (
                  <div key={"identityValue_" + index} className="selected-tag">
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}
