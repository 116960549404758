import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";

import * as PREDEFINED_LOGIC_SERVICES from "../../../../_service/predefinedLogic/predefined.service";
import { setToast } from "../../toast";

export const actionTypes = {
  ADD: "[PREDEFINED_LOGIC_ADD] Action",
  UPDATE: "[PREDEFINED_LOGIC_UPDATE] Action",
  DELETE: "[PREDEFINED_LOGIC_DELETE] Action",
  ADD_DIAGRAM: "[PREDEFINED_LOGIC_ADD_DIAGRAM] Action",
  UPDATE_DIAGRAM: "[PREDEFINED_LOGIC_UPDATE_DIAGRAM] Action",
  GET_PREDEFINED_LOGIC_DIAGRAM_DATA: "[GET_PREDEFINED_LOGIC_DIAGRAM_DATA] Action",
  GET_PREDEFINED_LOGIC_DIAGRAM_DATA_API_CALL: "[GET_PREDEFINED_LOGIC_DIAGRAM_DATA_API_CALL] Action",
  LOADING: "[PREDEFINED_LOGIC_LOADING] Action",
  GET_LIST_PREDEFINED_LOGIC: "[GET_LIST_PREDEFINED_LOGIC] Action",
  GET_LIST_PREDEFINED_LOGIC_API: "[GET_LIST_PREDEFINED_LOGIC_API] Action",
  GET_LIST_STATIC_LOOP: "[GET_LIST_STATIC_LOOP] Action",
  GET_LIST_STATIC_LOOP_API: "[GET_LIST_STATIC_LOOP_API] Action",
  GET_LIST_STATIC_LOOP_VARIABLES_API: "[GET_LIST_STATIC_LOOP_VARIABLES_API] Action",
  GET_LIST_STATIC_LOOP_VARIABLES: "[GET_LIST_STATIC_LOOP_VARIABLES] Action",
  ERROR: "[PREDEFINED_LOGIC_ERROR] Action",
};

const initialPredefinedLogicState = {
  error: "",
  isLoading: false,
  predefinedLogicData: [],
  predefinedLogicVariables: [],
  staticLoopVariables: [],
  staticLoop: [],
  optionData: [],
  predefinedLogicName: '',
  diagramData: {
    nodeDataArray: [],
    linkDataArray: []
  },


};

export const reducer = persistReducer(
  {
    storage, key: "predefinedLogicData", whitelist: [
      "predefinedLogicData", "optionData"
    ]
  },
  (state = initialPredefinedLogicState, action) => {
    switch (action.type) {
      case actionTypes.GET_PREDEFINED_LOGIC_DIAGRAM_DATA: {
        const { diagramData } = action.payload;
        return {
          ...state,
          predefinedLogicName: diagramData.predefinedLogicName != undefined ? diagramData.predefinedLogicName : state.predefinedLogicName,
          diagramData: {
            nodeDataArray: diagramData.nodeDataArray,
            linkDataArray: diagramData.linkDataArray
          }

        };
      }
      case actionTypes.LOADING: {
        const { isLoading } = action.payload;
        return { ...state, isLoading };
      }


      case actionTypes.GET_LIST_PREDEFINED_LOGIC: {
        const predefinedLogicData = action.payload.result;
        return { ...state, predefinedLogicData };
      }
      case actionTypes.GET_LIST_STATIC_LOOP: {
        const predefinedLogicData = action.payload.result;
        return { ...state, staticLoop: predefinedLogicData };
      }

      case actionTypes.GET_LIST_STATIC_LOOP_VARIABLES: {
        const staticLoopVariables = action.payload.result;
        return { ...state, staticLoopVariables };
      }

      case actionTypes.ERROR: {
        const { error } = action.payload;
        return { ...state, error };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  add: (diagramData) => ({ type: actionTypes.ADD, payload: { diagramData } }),
  update: (diagramData) => ({ type: actionTypes.UPDATE, payload: { diagramData } }),
  delete: (id) => ({ type: actionTypes.DELETE, payload: { id } }),
  addDiagram: (diagramData) => ({ type: actionTypes.ADD_DIAGRAM, payload: { diagramData } }),
  getPredefinedLogicDiagramDetail: (predefinedogicId) => ({ type: actionTypes.GET_PREDEFINED_LOGIC_DIAGRAM_DATA_API_CALL, payload: { predefinedogicId } }),
  getListOfPredefinedLogicAPI: () => ({ type: actionTypes.GET_LIST_PREDEFINED_LOGIC_API }),
  getListOfStaticLoopAPI: () => ({ type: actionTypes.GET_LIST_STATIC_LOOP_API }),
  updateDiagram: (diagramData) => ({ type: actionTypes.UPDATE_DIAGRAM, payload: { diagramData } }),
  getPredefinedLogicDiagramData: (diagramData) => ({ type: actionTypes.GET_PREDEFINED_LOGIC_DIAGRAM_DATA, payload: { diagramData } }),
  isLoading: (isLoading) => ({
    type: actionTypes.LOADING,
    payload: { isLoading },
  }),
  getListOfStaticLoop: (result) => ({ type: actionTypes.GET_LIST_STATIC_LOOP, payload: { result } }),
  getListOfPredefinedLogic: (result) => ({ type: actionTypes.GET_LIST_PREDEFINED_LOGIC, payload: { result } }),
  error: (error) => ({ type: actionTypes.ERROR, payload: { error } }),
  getStaticLoopVariablesAPI: (loopId) => ({ type: actionTypes.GET_LIST_STATIC_LOOP_VARIABLES_API, payload: { loopId } }),
  getStaticLoopVariablesList: (result) => ({ type: actionTypes.GET_LIST_STATIC_LOOP_VARIABLES, payload: { result } }),
};

export function* saga() {
  yield takeLatest(actionTypes.ADD, function* addSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { diagramData } = action.payload;
      yield call(PREDEFINED_LOGIC_SERVICES.savePredefinedLogic, diagramData);
      yield put(actions.getListOfPredefinedLogicAPI());
      yield put(actions.isLoading(false));
      // yield put(actions.redirect(true));
    } catch (error) {
      yield put(actions.error("Failed Create Diagram!"));
      yield put(actions.isLoading(false));
      setToast(error?.response?.data?.message);
    }
  });

  yield takeLatest(actionTypes.UPDATE, function* UpdateSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { diagramData } = action.payload;
      yield call(PREDEFINED_LOGIC_SERVICES.updatePredefinedLogic, diagramData);
      yield put(actions.getListOfPredefinedLogicAPI());
      yield put(actions.isLoading(false));
      // yield put(actions.redirect(true));
    } catch (error) {
      yield put(actions.error("Failed Create Diagram!"));
      yield put(actions.isLoading(false));
      setToast(error?.response?.data?.message);
    }
  });

  yield takeLatest(actionTypes.ADD_DIAGRAM, function* addDiagramSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { diagramData } = action.payload;
      yield call(PREDEFINED_LOGIC_SERVICES.savePredefinedLogicDiagram, diagramData);
      yield put(
        actions.getPredefinedLogicDiagramData({
          nodeDataArray: diagramData.nodeDataArray, linkDataArray: diagramData.linkDataArray
        } || initialPredefinedLogicState.diagramDetails)
      );
      yield put(actions.isLoading(false));
      // yield put(actions.redirect(true));
    } catch (error) {
      yield put(actions.error("Failed Create Diagram!"));
      yield put(actions.isLoading(false));
      setToast(error?.response?.data?.message);
    }
  });

  yield takeLatest(actionTypes.DELETE, function* deleteSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { id } = action.payload;
      yield call(PREDEFINED_LOGIC_SERVICES.deletePredefinedLogic, id);
      yield put(actions.getListOfPredefinedLogicAPI());
      yield put(actions.isLoading(false));
      // yield put(actions.redirect(true));
    } catch (error) {
      yield put(actions.error("Failed Delete PredefinedLogic!"));
      yield put(actions.isLoading(false));
      setToast(error?.response?.data?.message);
    }

  });

  yield takeLatest(actionTypes.UPDATE_DIAGRAM, function* updateSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { diagramData } = action.payload;
      yield call(PREDEFINED_LOGIC_SERVICES.updatePredefinedLogicDiagram, diagramData);
      yield put(
        actions.getPredefinedLogicDiagramData({
          nodeDataArray: diagramData.nodeDataArray, linkDataArray: diagramData.linkDataArray
        } || initialPredefinedLogicState.diagramDetails)
      );
    } catch (error) {
      yield put(actions.error("Failed Update Diagram!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.GET_PREDEFINED_LOGIC_DIAGRAM_DATA_API_CALL, function* getPredefinedLogicDiagramDetailSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { predefinedogicId } = action.payload;
      const response = yield call(PREDEFINED_LOGIC_SERVICES.getPredefinedLogicDiagram, predefinedogicId);

      if (Object.keys(response.data?.data).length > 0) {
        let predefinedLogicName = response.data?.data?.label;
        yield put(
          actions.getPredefinedLogicDiagramData({
            predefinedLogicName: predefinedLogicName, linkDataArray: response.data?.data?.tree?.linkDataArray || [],
            nodeDataArray: response.data?.data?.tree?.nodeDataArray || []
          } || initialPredefinedLogicState.predefinedLogic.diagramDetails)
        );
      } else {
        yield put(
          actions.getPredefinedLogicDiagramData({
            predefinedLogicName: '', linkDataArray: [], nodeDataArray: []
          } || initialPredefinedLogicState.predefinedLogic.diagramDetails)
        );
      }

    } catch (error) {

      yield put(actions.error("Failed Get Diagram!"));
      yield put(
        actions.getPredefinedLogicDiagramData(initialPredefinedLogicState.predefinedLogic.diagramDetails)
      );
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.GET_LIST_PREDEFINED_LOGIC_API, function* listSaga() {
    yield put(actions.isLoading(true));
    try {
      const response = yield call(PREDEFINED_LOGIC_SERVICES.getPredefinedLogicList);
      yield put(actions.getListOfPredefinedLogic(response.data?.data || initialPredefinedLogicState.predefinedLogicData));

    } catch (error) {

      yield put(actions.error("Failed Predefined Logic fetch!"));
    }
    yield put(actions.isLoading(false));
  });
  
  yield takeLatest(actionTypes.GET_LIST_STATIC_LOOP_API, function* staticLooplistSaga() {
    yield put(actions.isLoading(true));
    try {
      const response = yield call(PREDEFINED_LOGIC_SERVICES.getStaticLoop);
      yield put(actions.getListOfStaticLoop(response.data?.data || initialPredefinedLogicState.staticLoop));

    } catch (error) {

      yield put(actions.error("Failed Static Loop fetch!"));
    }
    yield put(actions.isLoading(false));
  });

  yield takeLatest(actionTypes.GET_LIST_STATIC_LOOP_VARIABLES_API, function* staticLoopVariablesListSaga(action) {
    yield put(actions.isLoading(true));
    try {
      const { loopId } = action.payload;

      const response = yield call(PREDEFINED_LOGIC_SERVICES.getStaticLoopVariables, loopId);
      yield put(actions.getStaticLoopVariablesList(response.data?.data || initialPredefinedLogicState.staticLoopVariables));


    } catch (error) {
      yield put(actions.error("Failed Variable fetch!"));
      yield put(actions.getStaticLoopVariablesList(initialPredefinedLogicState.staticLoopVariables));
    }
    yield put(actions.isLoading(false));
  });
}
