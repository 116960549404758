import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import { AddTopicDialog } from "../../modals/addtopic-dialog/AddTopicDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsLoading,
  getTopicsList,
} from "../../../../app/modules/Topic/_redux/topicSelectors";
import { actions as Topics } from "../../../../app/modules/Topic/_redux/topicRedux";
import RingLoader from "react-spinners/RingLoader";
import "./common.css";
import { format } from "date-fns";
import parseISO from "date-fns/parseISO";
import { DeleteButtonWithConfirm } from "./DeleteButtonWithConfirm";

export const TopicTablesWidget1 = ({ className }) => {
  const dispatch = useDispatch();
  const topics = useSelector(getTopicsList);
  const isLoading = useSelector(getIsLoading);
  const [modalShow, setModalShow] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    dispatch(Topics.getTopicList());
  }, []);

  const handleDelete = (deleteId) => {
    dispatch(Topics.delete(deleteId));
  };

  const handleHide = () => {
    setId("");
    setName("");
    setIsUpdate(false);
    setModalShow(false);
  };

  const handleUpdate = (updateId, updateName) => {
    setId(updateId);
    setName(updateName);
    setIsUpdate(true);
    setModalShow(true);
  };

  return (
    <div className={`card card-custom ${className}`}>
      {/* begin::Header */}
      {isLoading && (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      )}
      <div className="card-header border-0 py-5 custom-header align-items-center">
        <h3 className="card-title flex-column m-0">
          <span className="card-label font-weight-bolder text-dark">
            Topics
          </span>
        </h3>
        <div className="card-toolbar">
          <a
            onClick={() => setModalShow(true)}
            className="btn btn-success font-weight-bolder font-size-sm"
          >
            <span className="svg-icon svg-icon-md svg-icon-white">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
                className="h-50 align-self-center"
              ></SVG>
            </span>
            Add New Topic
          </a>
          <AddTopicDialog
            show={modalShow}
            isUpdate={isUpdate}
            id={id}
            name={name}
            onHide={handleHide}
          />
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-0">
        {/* begin::Table */}
        <div className="table-responsive">
          <table
            className="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr className="text-left">
                <th className="pl-0" style={{ minWidth: "300px" }}>
                  name
                </th>
                <th style={{ minWidth: "200px" }}>sub topic number</th>
                <th style={{ minWidth: "200px" }}>modified date</th>
                <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                  action
                </th>
              </tr>
            </thead>
            <tbody>
              {topics?.map((topic, index) => (
                <tr key={"topic_" + index}>
                  <td className="pl-0">
                    <Link
                      to={`/subtopic/${topic._id}`}
                      className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >
                      {topic?.name}
                    </Link>
                  </td>
                  <td>
                    <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                      Sub Topic: {topic?.subTopicsCount}
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                      Modified on{" "}
                      {format(parseISO(topic?.updatedAt), "MMM dd yyyy")}
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a
                      onClick={handleUpdate.bind(this, topic?._id, topic?.name)}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        ></SVG>
                      </span>
                    </a>
                    {/* <a
                      onClick={handleDelete.bind(this, topic._id)}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                        ></SVG>
                      </span>
                    </a> */}
                    <DeleteButtonWithConfirm
                      onDelete={handleDelete.bind(this, topic._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* end::Table */}
      </div>
      {/* end::Body */}
    </div>
  );
};
