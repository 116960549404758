import React, { useEffect, useState } from "react";
import RingLoader from "react-spinners/RingLoader";
import SVG from "react-inlinesvg";

import { useDispatch, useSelector } from "react-redux";
import { actions as UPLOAD_FILE_ACTIONS } from "../../../../app/modules/UploadFile/_redux/UploadFileRedux";
import {
  getFileUploadIsLoading,
  getFileUploadList,
} from "../../../../app/modules/UploadFile/_redux/UploadFileSelector";
import { IconButton } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_helpers";
import { GetApp, Visibility } from "@material-ui/icons";

import { format, parseISO } from "date-fns";
import { FileUploadDialog } from "../../modals/fileupload-dialog/FileUploadDialog";
import { FileDetailDialog } from "../../modals/FileDetailDialog/FileDetailDialog";

import { Services } from "../../../../_service/Services";
import { DeleteButtonWithConfirm } from "../../../_partials/widgets/advance-tables/DeleteButtonWithConfirm";
import HelpIcon from "../../Help/HelpIcon";

export function UploadFileWidget() {
  const dispatch = useDispatch();
  const isLoading = useSelector(getFileUploadIsLoading);
  const fileList = useSelector(getFileUploadList);

  const [showModel, setShowModel] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileId, setFileId] = useState("");
  const [editMode, setEditMode] = useState(false);

  const [showDetailModal, setShowDetailModal] = useState(false);

  useEffect(() => {
    dispatch(UPLOAD_FILE_ACTIONS.getFileList());
  }, []);

  const handleDelete = (fileId) => {
    dispatch(UPLOAD_FILE_ACTIONS.delete(fileId));
  };

  const print = (fileName) => {
    Services({
      url: `/admin/sampleFile?originalFileName=${fileName}`, //your url
      method: "GET",
      // responseType: "blob", // important
    })
      .then((response) => {
        const reportUrl = response?.data?.data?.url;
        if (reportUrl) {
          let file_path = reportUrl;
          let a = document.createElement("a");
          a.href = file_path;
          a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
          a.target = "_blank";
          a.click();
        }
      })
      .catch(console.error);
  };

  return (
    <div>
      <div className={`card card-custom`}>
        {/* begin::Header */}
        {isLoading && (
          <div className="loader_wrapper">
            <RingLoader color={"darkcyan"} loading={true} size={40} />
          </div>
        )}
        <div className="card-header border-0 py-5 custom-header align-items-center">
          <h3 className="card-title flex-column m-0">
            <span className="card-label font-weight-bolder text-dark">
              File Upload
            </span>
          </h3>
          <div className="card-toolbar">
            {/* <Link
            to="/admin/sampleFile?originalFileName=Report"
            className="btn btn-success"
            target="_blank"
            download
          >
            Download Sample File
          </Link> */}
            <a
              onClick={() => setShowModel(true)}
              className="btn btn-success font-weight-bolder font-size-sm"
            >
              <span className="svg-icon svg-icon-md svg-icon-white">
                <SVG
                  src={toAbsoluteUrl("/media/svg/files/csv.svg")}
                  className="h-50 align-self-center"
                ></SVG>
              </span>
              Upload New File
            </a>
            {/* <AddUserDialog
            show={modalShow}
            toggleModal={(value) => setModalShow(value)}
            data={modalData}
          /> */}
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pl-0" style={{ minWidth: "200px" }}>
                    Name
                  </th>
                  <th
                    className="pr-0 text-center"
                    style={{ minWidth: "150px" }}
                  >
                    Created By
                  </th>
                  <th
                    className="pr-0 text-center"
                    style={{ minWidth: "150px" }}
                  >
                    Updated At
                  </th>
                  <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                    Sample File
                  </th>
                  <th className="pr-0 text-right" style={{ minWidth: "80px" }}>
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {fileList?.length > 0 ? (
                  fileList?.map((file) => (
                    <tr key={`${file?._id}`}>
                      <td className="pl-0 py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <a className="text-dark-75 font-weight-bolder text-capitalize text-hover-primary d-block mb-1 font-size-lg">
                              {`${file?.fileName}`}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="pr-0 text-center">
                        <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                          {file?.createdBy?.firstName +
                            " " +
                            file?.createdBy?.lastName}
                        </span>
                      </td>
                      <td className="pr-0 text-center">
                        <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                          {format(parseISO(file?.updatedAt), "PPP p")}
                        </span>
                      </td>
                      <td className="pr-0 text-right">
                        {file?.hasSampleFile && (
                          <IconButton
                            className="btn btn-icon btn-light btn-hover-primary btn-sm"
                            onClick={print.bind(this, file?.fileName)}
                            aria-label="detail"
                            style={{
                              marginRight: 10,
                              height: 34,
                              width: 34,
                              borderRadius: 5,
                              color: "#0072B8",
                            }}
                          >
                            <GetApp className="download" />
                          </IconButton>
                        )}
                      </td>
                      <td className="pr-0 text-right">
                        <a
                          onClick={() => {
                            setFileName(file?.fileName);
                            setFileId(file?._id);
                            setEditMode(true);
                            setShowModel(true);
                          }}
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            ></SVG>
                          </span>
                        </a>

                        <IconButton
                          onClick={() => {
                            setFileName(file?.fileName);
                            setFileId(file?._id);
                            setShowDetailModal(true);
                          }}
                          aria-label="detail"
                        >
                          <Visibility style={{ color: "#3699FF" }} />
                        </IconButton>
                        <DeleteButtonWithConfirm
                          parentClassName="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          onDelete={() => handleDelete(file._id)}
                          iconClassName="svg-icon svg-icon-md svg-icon-primary"
                        />
                        {/* <Link
                        to={`/admin/sampleFile?originalFileName=${file?.fileName}`}
                        target="_blank"
                        download
                      >
                        <IconButton aria-label="detail">
                          <FontDownload style={{ color: "#3699FF" }} />
                        </IconButton>
                      </Link> */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="pl-0 py-2 text-center" colSpan={3}>
                      <span className="svg-icon d-block p-3 nodata__icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/ico_nodata.svg")}
                        ></SVG>
                      </span>
                      <h3 className="text-dark font-weight-bolder d-block mb-0">
                        No Record Found
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
          {/* Delete Modal */}
        </div>
        {/* end::Body */}
        <FileUploadDialog
          show={showModel}
          showModal={showModel}
          fileName={fileName}
          fileId={fileId}
          editMode={editMode}
          toggleModal={(toggle) => {
            setShowModel(toggle);
            setFileName("");
            setEditMode(false);
          }}
        />
        <FileDetailDialog
          show={showDetailModal}
          showModal={showDetailModal}
          fileName={fileName}
          fileId={fileId}
          toggleModal={(toggle) => {
            setShowDetailModal(toggle);
            setFileName("");
          }}
        />
      </div>

      <HelpIcon pageName="FileUploadListAddUpdateSection" />
    </div>
  );
}
