import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { actions as PREDEFINED_LOGIC_ACTIONS } from "../../../../app/modules/PredefinedLogic/_redux/PredefinedLogicRedux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export function AddPredefinedProcess({ onHide, isUpdate, id, name,modalData, ...rest }) {
  const [input, setInput] = useState({
    description: "",
    label: "",
  });
  const dispatch = useDispatch();
  const [errors, setError] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);

  useEffect(() => {
    let updateInput = {
      description: modalData?.description,
      label: modalData?.displayLabel,
      
    }
    setInput(updateInput)
  },[modalData])
  const handleInputChange = (value, inputName) => {
    if (value !== "") {
      let data = {
        [inputName]: { noInput: false },
      };
      setError(data);
      setInput(value);
     
      if (!isNaN(value)) {
        let errorData = {
          [inputName]: { valueValidationError: true },
        };
        setError(errorData);
        setFormSubmit(false);
     
      } else {
        setFormSubmit(true);
        let noErrorData = {
          [inputName]: { valueValidationError: false },
        };
        setError(noErrorData);
        Object.freeze(input);
        let inputChanges = { ...input };
        inputChanges[inputName] = value;
        setInput(inputChanges);
      }
    } else {
      let inputChanges = input;
      inputChanges[inputName] = value;
      setInput(inputChanges);

      let noInput = {
        [inputName]: { noInput: true },
      };
      setError(noInput);
    }
  };
  
  const handleSubmit = () => {
    if (input.label == "") {
      handleInputChange(input.label, "label");
    } else if (input.description == "") {
      handleInputChange(input.description, "description");
    } else {
      if (formSubmit) {
        if (isUpdate) {
          let newData = input;
          newData.processId = modalData?.id
          console.log(newData);
          dispatch(PREDEFINED_LOGIC_ACTIONS.update(newData));
        } else {
          dispatch(PREDEFINED_LOGIC_ACTIONS.add(input));
        }
        onHide();
        setInput({
          description: "",
          label: "",
        });
        dispatch(PREDEFINED_LOGIC_ACTIONS.getListOfPredefinedLogicAPI());
      }
    }
  };

  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">
          {isUpdate ? "Update" : "Add"} Predefined Process
        </h3>
        <IconButton
          position="top right"
          aria-label="close"
          onClick={onHide}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="form-group fv-plugins-icon-container mb-0">
          <label className="form-label text-dark-50 font-weight-bolder">
            Predefined Process
          </label>
          <input
            placeholder="Predefined Process..."
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="label"
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
            value={input["label"]}
            maxLength="100"
          />
          {errors?.label?.noInput && (
            <span class="text-danger">Please enter Predefined Process</span>
          )}
          {errors?.label?.valueValidationError && (
            <span class="text-danger">Please enter valid value</span>
          )}
        </div>
        <div className="form-group fv-plugins-icon-container mb-0">
          <label className="form-label text-dark-50 font-weight-bolder">
            Description
          </label>
          <textarea
            placeholder="Description..."
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="description"
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
            value={input["description"]}
            maxLength="100"
          />
          {errors?.description?.noInput && (
            <span class="text-danger">Please enter description</span>
          )}
          {errors?.description?.valueValidationError && (
            <span class="text-danger">Please enter valid value</span>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-0 mb-8">
        <Button className="w-100px btn-primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
