import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
const CommentInput = ({ formData, localVariableArray, nodeKey, save }) => {
  const [inputData, setInputData] = useState(
    formData || {
      comment: "",
      nodeDisplayLabel: "",
    }
  );

  const [sameAsNodeLabel, setSameAsNodeLabel] = useState(false);
  const [nodeDisplayLabelLength, setNodeDisplayLabelLength] = useState(
    inputData?.nodeDisplayLabel?.length
  );

  const [errorValidationObject, setErrorValidation] = useState({
    comment: "",
    nodeDisplayLabel: "",
  });

  const saveForm = () => {
    let errorValidation = {
      comment: "",
      nodeDisplayLabel: "",
    };
    let error = false;
    if (!inputData.comment) {
      error = true;
      errorValidation.comment = "Comment is required!";
    }
    if (
      inputData.nodeDisplayLabel == "" ||
      inputData.nodeDisplayLabel == undefined
    ) {
      error = true;
      errorValidation.nodeDisplayLabel = "Node Display Label is required!";
    }
    if (nodeDisplayLabelLength > 25) {
      error = true;
      errorValidation.nodeDisplayLabel =
        "Please Enter Node Display Label less then 25 characters!";
    }
    if (error) {
      setErrorValidation(errorValidation);
    } else {
      save(inputData);
    }
  };

  return (
    <>
      <form className="py-5 px-5 form-col">
        <div className="">
          <div className="form-group fv-plugins-icon-container">
            <label className={"form-label text-dark-50 font-weight-bolder"}>
              Node Display Label{" "}
              <small>
                {" "}
                <span
                  className={nodeDisplayLabelLength > 25 ? "error" : "info"}
                >
                  ({nodeDisplayLabelLength} / 25 charaters)
                </span>
              </small>
              :
            </label>

            <input
              placeholder="Node Display Label"
              type="text"
              value={inputData?.nodeDisplayLabel}
              onChange={(e) => {
                const nodeDisplayLabel = e.target.value;
                setNodeDisplayLabelLength(nodeDisplayLabel.length);
                setErrorValidation((oldVal) => ({
                  ...oldVal,
                  nodeDisplayLabel:
                    nodeDisplayLabel.length > 25
                      ? "Please Enter Node Display Label less then 25 characters!"
                      : "",
                }));
                setInputData((oldVal) => ({
                  ...oldVal,
                  nodeDisplayLabel,
                }));
              }}
              className="form-control form-control-solid h-auto py-5 px-6"
              name={"nodeDisplayLabel"}
              style={
                errorValidationObject?.nodeDisplayLabel !== ""
                  ? {
                      border: "1px solid red",
                    }
                  : {}
              }
            />
            {errorValidationObject.nodeDisplayLabel !== "" && (
              <span className="text-danger">
                {errorValidationObject.nodeDisplayLabel}
              </span>
            )}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <span className="multiOption-displayName">
              <label className={"form-label text-dark-50 font-weight-bolder"}>
                Add Comment
              </label>
              <div className="form-icon">
                <Checkbox
                  checked={sameAsNodeLabel}
                  onChange={(e) => {
                    setSameAsNodeLabel(!sameAsNodeLabel);
                    setInputData((oldVal) => ({
                      ...oldVal,
                      comment: inputData?.nodeDisplayLabel,
                    }));
                  }}
                />
                <label>Same as Node Label</label>
              </div>
            </span>

            <br />

            <textarea
              placeholder="Please, add comment..."
              // type="text"
              value={inputData.comment}
              onChange={(e) => {
                const comment = e.target.value;

                setErrorValidation((oldVal) => ({
                  ...oldVal,
                  comment: "",
                }));
                setInputData((oldVal) => ({
                  ...oldVal,
                  comment,
                }));
              }}
              style={
                errorValidationObject?.comment !== ""
                  ? {
                      border: "1px solid red",
                    }
                  : {}
              }
              className="form-control form-control-solid h-auto py-5 px-6"
              name={"label"}
            />

            {errorValidationObject?.comment !== "" && (
              <span className="text-danger">
                {errorValidationObject.comment}
              </span>
            )}
          </div>
        </div>
        <div className="btn-block">
          <button
            type="button"
            className="btn btn-success w-100px"
            onClick={() => saveForm()}
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default CommentInput;
