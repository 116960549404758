import axios from "axios";
// console.log(process.env)
const API_URL = process.env.REACT_APP_API_URL;
let headers = {
  Accept: "application/json",
};

const instance = axios.create({
  baseURL: API_URL,
  timeout: 100000,
  headers: headers,

});

export const Services = instance;
